export class IPermits {
  constructor() {
    this.id = null;
    this.type = {};
    this.situation = {};
    this.legal_base = "";
    this.detail = "";
    this.project = {};
    this.order = null;
    // this.link_spij = "http://spijlibre.minjus.gob.pe/normativa_libre/main.asp";
    // Time in days
    this.actual_term = 0;
    this.planned_term = 0;
    this.legal_term = 0;
    // Dates
    this.expiration_date = null;
    this.presentation_date = null;
    this.expiration_date_disabled = false;
    this.approval_date = null;
    // Authority
    this.authority = {};
    this.local_water_authority = {};
    this.water_administrative_authority = {};
    // User | Responsible
    this.creator = {};
    this.primary_responsible = {};
    this.secondary_responsible = {};
    // Checklist
    this.pending_tasks = [];
    this.requirements = [];
    // Map
    this.zone = {};
    this.datum = {};
    this.locations = [];
    this.location_disabled = false;
    // Files
    this.submission_fee = null;
    this.approval_resolution = null;
  }
}
