<template>
  <v-col cols="12" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h4
          class="d-block mb-2 card-input-title align-center justify-center"
          style="width: 100%;"
        >
          {{ field === "pending-tasks" ? "To-Do" : "Requisitos" }}
          <v-btn
            v-if="!disabled"
            style="float:right;margin: 0;"
            small
            depressed
            :disabled="disabled"
            @click="openDialogAdd('add')"
          >
            <v-icon left>add</v-icon> Agregar
            {{ field === "pending-tasks" ? "To-Do" : "requisito" }}
          </v-btn>
        </h4>
      </v-col>
      <v-col cols="12" class="py-0">
        <div class="checklist-progress">
          <div class="checklist-progress__numb">
            <span>{{ widthSelectTask.toFixed(0) }}%</span>
          </div>
          <div class="checklist-progress__bar">
            <v-progress-linear
              height="10"
              rounded
              :value="widthSelectTask"
            ></v-progress-linear>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-data-table
          v-model="selectTask"
          class="mb-3 checklist-table"
          :headers="headersTask"
          :items="auxTasks"
          item-key="id"
          hide-default-footer
          :show-select="false"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-if="auxTasks.length">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td v-if="type !== 'new'">
                    <v-checkbox
                      v-model="item.check"
                      class="mt-0"
                      hide-details
                      :disabled="true"
                      :ripple="false"
                      name="checkList"
                    ></v-checkbox>
                  </td>
                  <td
                    v-if="type !== 'new'"
                    class="td-hover"
                    @click="openDialogEdit(item, index)"
                  >
                    <template v-if="item && item.state">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <span
                            class="checklist-table__state"
                            :class="validateColor(item.state.slug)"
                            v-on="on"
                          ></span>
                        </template>
                        <span>{{ formatStateLabel(item.state.label) }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                  <td class="td-hover" @click="openDialogEdit(item, index)">
                    {{ item.title }}
                  </td>
                  <td class="td-hover" @click="openDialogEdit(item, index)">
                    {{
                      item.responsible
                        ? `${item.responsible.first_name} ${item.responsible.last_name}`
                        : ""
                    }}
                  </td>
                  <td class="td-hover" @click="openDialogEdit(item, index)">
                    {{ item.date.split(" ")[0] }}
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      depressed
                      :disabled="disabled"
                      @click="openDialogDelete(item, index)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr v-if="!auxTasks.length" class="v-data-table__empty-wrapper">
                <td :colspan="headersTask.length">
                  No hay
                  {{ field === "pending-tasks" ? "To-Do" : "Requisitos" }}
                  registrados
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <dialog-delete
      ref="dialogDelete"
      v-model="dialogDelete"
      :title="field === 'pending-tasks' ? 'To-Do' : 'Requisito'"
      :is-module="false"
      @delete="onSubmitDelete"
    ></dialog-delete>

    <v-dialog
      v-model="dialogUpdate"
      max-width="600"
      persistent
      open-on-focus
      content-class="dialog-custom"
      @keydown.esc="onCloseDialog"
    >
      <v-card>
        <v-card-title class="py-1">
          <p class="subheading mb-0">
            To-Do
          </p>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formUpdateUser"
            v-model="validUpdateUser"
            :lazy-validation="lazyUpdateUser"
          >
            <v-row>
              <v-col cols="12" class="py-0 mt-4">
                <v-text-field
                  v-model="formItem.title"
                  class="pt-0 input-custom-56"
                  label="Nombre de la tarea:"
                  outlined
                  height="56"
                  :hide-details="isEmpty(listError.title)"
                  :rules="rulesTitle"
                  :disabled="!disabledTodo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 mb-5">
                <v-textarea
                  v-model="formItem.description"
                  class="pt-0"
                  label="Descripción:"
                  outlined
                  :hide-details="isEmpty(listError.description)"
                  :rules="rulesDescription"
                  :disabled="!disabledTodo"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="pickerEnd"
                  v-model="showDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      slot="activator"
                      v-model="dateFormatted"
                      class="pt-0 input-custom-56"
                      label="Fecha de Vencimiento"
                      outlined
                      height="56"
                      :hide-details="isEmpty(listError.date)"
                      :rules="rulesDate"
                      :disabled="!disabledTodo"
                      append-icon="event"
                      readonly
                      @click:append="showDate = true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formItem.date"
                    no-title
                    scrollable
                    reactive
                    :show-current="true"
                    locale="es"
                    :allowed-dates="allowedDates"
                    @input="onChangeDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="py-0 mb-3">
                <selector-user
                  ref="selectorUser"
                  v-model="formItem.responsible"
                  placeholder=""
                  :text-error="listError.responsible_id"
                  :filter-ejecutor="filterEjecutor"
                  classes="pt-0 mb-0"
                  :disabled="!disabledTodo"
                ></selector-user>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <template v-if="typeUpdate === 'edit'">
            <v-btn
              v-if="!disabledTodo"
              color="primary"
              dark
              small
              depressed
              class="btn-transform-none"
              @click="disabledTodo = true"
              >Habilitar edición</v-btn
            >
          </template>
          <v-spacer></v-spacer>
          <v-btn
            color="action"
            small
            class="btn-transform-none"
            text
            @click="onCloseDialog"
            >Cerrar</v-btn
          >
          <v-btn
            color="action"
            :disabled="!disabledTodo"
            :dark="disabledTodo"
            depressed
            small
            class="btn-transform-none"
            @click="validateChecList()"
          >
            <v-progress-circular
              v-if="loadAdd"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            <template v-if="typeUpdate === 'edit'">
              {{ loadAdd ? "Guardando" : "Guardar" }}
            </template>
            <template v-else>
              {{ loadAdd ? "Agregando" : "Agregar" }}
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-todo-detail
      ref="dialogDetail"
      v-model="dialogTodo"
      :item="selectedItem"
      :show-edit="true"
      :is-drafts="isDrafts"
      :type-field="field"
      @alert="onChangeAlertToDo"
      @edit-todo="onChangeToDoById"
      @edit="showDialogEdit"
    ></dialog-todo-detail>
  </v-col>
</template>
<script>
import { mapState } from "vuex";
import { findIndex, cloneDeep } from "lodash";
import { IChecklistTodo } from "@/models";
import DialogTodoDetail from "@/components/todos/DialogTodoDetail";
import DialogDelete from "@/components/commons/DialogDelete";
import mixinApiModule from "../modules/mixins/mixinApiModule";
import SelectorUser from "./SelectorUser";

export default {
  name: "CheckListTask",
  components: {
    DialogTodoDetail,
    SelectorUser,
    DialogDelete,
  },
  mixins: [mixinApiModule],
  props: {
    id: {
      type: String,
      default: "",
    },
    tasks: {
      type: Array,
      default() {
        return [];
      },
    },
    page: {
      type: String,
      default: "",
    },
    field: {
      type: String,
      default: "pending-tasks",
    },
    filterEjecutor: {
      type: Boolean,
      defualt: false,
    },
    type: {
      type: String,
      default: "new",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDrafts: {
      type: Boolean,
      default: false,
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  
  data: () => ({
    disabledTodo: false,
    selectTask: [],
    typeUpdate: "",
    // dialogDetail: false,
    showDate: false,
    // add
    loadAdd: false,
    selectAdd: null,
    dialogUpdate: false,
    // delete
    dialogDelete: false,
    selectDelete: null,
    loadDelete: false,
    selectedItem: null,
    tempTasks: [],
    indexItem: 0,
    dialogTodo: false,
    validUpdateUser: false,
    lazyUpdateUser: false,
    formItem: new IChecklistTodo(),
    rulesTitle: [],
    // rulesResponsible: [],
    rulesDescription: [],
    rulesDate: [],
    listError: {
      title: null,
      responsible_id: null,
      description: null,
      date: null,
    },
  }),
  computed: {
    headersTask() {
      let baseHeaders = [
        { text: "Nombre", value: "title", sortable: false },
        {
          text: "Responsable",
          value: "resp",
          sortable: false,
          width: this.type === "new" ? 250 : 220,
        },
        { text: "Fecha", value: "date", sortable: false, width: 150 },
        { text: "Acción", value: "actions", sortable: false, width: 60 },
      ];
      if (this.type === "new") {
        return baseHeaders;
      }
      return [
        { text: "Check", value: "check", sortable: false, width: 40 },
        { text: "Estado", value: "situation", sortable: false, width: 70 },
        ...baseHeaders,
      ];
    },
    dateFormatted() {
      return this.formatDate(this.parseFormatDate(this.formItem.date));
    },
    ...mapState({
      activeModule: (state) => state.modules.activeModule,
      activeSubModule: (state) => state.modules.activeSubModule,
      listUser: (state) => state.users.listAllUsers,
      listMasters: (state) => state.masters.listMasters,
      listState: (state) => state.masters.listState,
    }),
    widthSelectTask() {
      if (this.auxTasks.length) {
        return (this.selectTask.length / this.auxTasks.length) * 100;
      } else {
        return 0;
      }
    },
    colorProgress() {
      return this.widthSelectTask < 34
        ? "error"
        : this.widthSelectTask < 67
        ? "warning"
        : "success";
    },

    auxTasks() {
      ///console.log(this.id, this.tasks, this.tempTasks, 'bjar 1');
      return this.id ? this.tasks : this.tempTasks;
    },
  },
  watch: {
    tasks: {
      handler(val) {

        this.validateSelectTable(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.$refs.formUpdateResponsible) {
      this.$refs.formUpdateResponsible.resetValidation();
    }
    this.clearRules();
    this.validateSelectTable(this.tasks);
  },
  // mounted() {
  //   this.tempTasks = cloneDeep(this.tasks);
  // },
  methods: {
    onChangeAlertToDo() {
      const inx = findIndex(this.auxTasks, {
        id: this.selectedItem.id,
      });
      if (inx >= 0) {
        this.updateAlert(inx);
      }
    },
    clearRules() {
      this.rulesTitle = [];
      // this.rulesResponsible = [];
      this.rulesDescription = [];
      this.rulesDate = [];
    },
    listErrorUser(err) {
      const {
        title = null,
        description = null,
        responsible_id = null,
        date = null,
      } = err;
      this.showError = true;
      this.listError.title = title ? title[0] : null;
      this.listError.description = description ? description[0] : null;
      this.listError.responsible_id = responsible_id ? responsible_id[0] : null;
      this.listError.date = date ? date[0] : null;
      // Rules
      this.rulesTitle = !this.isEmpty(this.listError.title)
        ? [() => this.listError.title]
        : [];
      // this.rulesResponsible = !this.isEmpty(this.listError.responsible_id)
      //   ? [() => this.listError.responsible_id]
      //   : [];
      this.rulesDescription = !this.isEmpty(this.listError.description)
        ? [() => this.listError.description]
        : [];
      this.rulesDate = !this.isEmpty(this.listError.date)
        ? [() => this.listError.date]
        : [];
      this.$refs.formUpdateUser.validate();
      // this.$nextTick(() => {
      //   this.$refs.selectorUser.validateSelectorUser();
      // });
    },
    validateChecList() {
      const payload = {
        id: null,
        entity_id: this.id,
        check: 0,
        // state_id: this.formItem.state.id,
        title: this.formItem.title,
        description: this.formItem.description,
        responsible_id: this.formItem.responsible.id,
        date: this.formItem.date,
        field: this.field,
        module_id: this.activeModule.id,
        submodule_id: this.activeSubModule.id,
        activities: [],
      };
      this.onSubmitTask(payload);
    },
    async onSubmitTask(payload) {
      if (this.loadAdd) {
        return;
      }
      this.clearRules();
      this.loadAdd = true;
      if (this.id && this.page !== "supervision") {
        if (this.typeUpdate === "add") {
          const urlDrafts = this.isDrafts ? "drafts/" : "";
          const { data } = await this.axios.post(
            `/api/${this.apiByModule.module}/${urlDrafts}${this.apiByModule.submodule}/${this.id}/to_do/create`,
            payload,
          );
          this.loadAdd = false;
          if (data.success) {
            this.tasks.push(data.data);
            this.$emit("update", this.tasks);
            this.dialogUpdate = false;
          } else {
            this.listErrorUser(data.error.data);
          }
        } else {
          delete payload.check;
          delete payload.activities;
          delete payload.id;
          const urlDrafts = this.isDrafts ? "_drafts" : "s";
          const { data } = await this.axios.post(
            `/api/to_do${urlDrafts}/${this.formItem.id}/update`,
            payload,
          );
          this.loadAdd = false;
          if (data.success) {
            this.$set(this.tasks, this.indexItem, data.data);
            this.$nextTick(() => {
              this.selectedItem = data.data;
            });
            this.dialogUpdate = false;
          } else {
            this.listErrorUser(data.error.data);
          }
        }
      } else {
        if (
          this.isEmpty(this.formItem.title) ||
          this.isEmpty(this.formItem.description) ||
          this.isEmpty(this.formItem.responsible) ||
          this.isEmpty(this.formItem.date)
        ) {
          this.loadAdd = false;
          const payload = {
            title: this.isEmpty(this.formItem.title)
              ? ["Debes ecribir el título."]
              : null,
            description: this.isEmpty(this.formItem.description)
              ? ["Debes ecribir la descripción."]
              : null,
            responsible_id: this.isEmpty(this.formItem.responsible.id)
              ? ["Debes elegir un responsable."]
              : null,
            date: this.isEmpty(this.formItem.date)
              ? ["Debes ecribir la fecha."]
              : null,
          };
          this.listErrorUser(payload);
          return false;
        }
        // payload["state"] = this.formItem.state;
        payload["responsible"] = this.formItem.responsible;
        if (this.typeUpdate === "add") {
          // this.tasks.push(payload);
          if (this.id && this.page === "supervision") {
            this.$emit("todo-step", payload);
          } else {
            this.tempTasks.push(payload);
            this.$emit("update", this.tempTasks);
            this.onCloseDialog();
          }
          // this.$emit("update", this.tempTasks);
        } else {
          if (this.page !== "supervision") {
            this.$set(this.tasks, this.indexItem, payload);
          }
          if (this.tempTasks.length) {
            const inx = findIndex(this.tempTasks, { title: payload.title });
            this.$set(this.tempTasks, inx, payload);
          }
          if (this.page === "supervision") {
            this.$emit("update", this.tempTasks);
          }
          this.onCloseDialog();
        }
        // this.$nextTick(() => {
        //   this.$refs.uploadFile.clearData();
        // });
      }
    },
    validateSelectTable(val) {
      this.tempTasks = val;
      this.selectTask = val.filter((item) => item.check);
    },
    openDialogAdd(type) {
      this.formItem = new IChecklistTodo();
      this.listError = {
        title: null,
        responsible_id: null,
        description: null,
        date: null,
      };
      this.disabledTodo = true;
      this.typeUpdate = type;
      this.dialogUpdate = true;
    },
    onChangeToDoById(todo) {
      const inx = findIndex(this.auxTasks, { id: todo.id });
      this.$set(this.tasks, inx, todo);
    },
    openDialogEdit(item, index = 0) {
      if (this.disabled) {
        return false;
      }
      this.indexItem = index;
      this.formItem = cloneDeep(item);
      if (this.id) {
        this.indexItem = index;
        const urlDrafts = this.isDrafts ? "_drafts" : "s";
        this.axios
          .get(`/api/to_do${urlDrafts}/${item.id}`)
          .then(({ data }) => {
            if (data.success) {
              // this.typeDialog = "edit";
              this.$refs.dialogDetail.resetDialog();
              this.selectedItem = cloneDeep(data.data);
              this.dialogTodo = true;
            } else {
              this.selectedItem = null;
              this.dialogTodo = false;
            }
          })
          .catch(() => {
            this.selectedItem = null;
            this.dialogTodo = false;
          });
      } else {
        this.showDialogEdit(item, index, true);
      }
    },
    showDialogEdit(item, index = 0, flag = false) {
      this.typeUpdate = "edit";
      this.disabledTodo = false;
      this.dialogUpdate = true;
      this.indexItem = flag ? index : this.indexItem;
      this.$nextTick(() => {
        this.formItem = flag ? cloneDeep(item) : this.formItem;
      });
    },
    openDialogDelete(item, index = 0) {
      this.indexItem = index;
      this.selectDelete = cloneDeep(item);
      this.dialogDelete = true;
    },
    async onSubmitDelete() {
      this.$refs.dialogDelete.toggleLoad(true);
      if (this.loadDelete) return false;
      this.loadDelete = true;
      if (this.id) {
        const { id } = this.selectDelete;
        const urlDrafts = this.isDrafts ? "drafts/" : "s";
        const { data } = await this.axios.delete(
          `/api/to_do${urlDrafts}/${id}/delete`,
        );
        if (data.success) {
          this.$refs.dialogDelete.toggleLoad(false);
          const index = findIndex(this.tasks, { id });
          this.tasks.splice(index, 1);
          this.$emit("update", this.tasks);
        } else {
          this.$refs.dialogDelete.toggleLoad(false);
        }
      } else {
        this.tempTasks.splice(this.indexItem, 1);
        this.$emit("update", this.tempTasks);
      }
      this.loadDelete = false;
      this.dialogDelete = false;
    },
    onChangeDate(item) {
      this.formItem.date = item ? item : null;
      this.showDate = false;
    },
    onCloseDialog() {
      this.clearRules();
      this.listError = {
        title: null,
        responsible_id: null,
        description: null,
        date: null,
      };
      this.dialogUpdate = false;
      this.dialogDelete = false;
      // this.formItem = new IChecklistTodo();
      this.loadAdd = false;
      this.loadDelete = false;
    },
    validateColor(status) {
      const name = status.toLowerCase();
      return `circle-${name}`;
    },
    parseFormatDate(val) {
      return val ? val.split(" ")[0] : null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
    formatStateLabel(val) {
      return val.split(" . ")[1];
    },
    updateLoadApp(flag) {
      this.loadAdd = flag;
      this.dialogUpdate = flag;
    },
    updateTasks(task) {
      this.$set(this.tasks, this.tasks.length, task);
      // this.$nextTick(() => {
      //   this.tasks.push(task);
      // });
    },
  },
};
</script>
