<template>
  <v-container
    id="pageDashboard"
    fluid
    fill-height
    class="page-dashboard fill-height"
  >
    <v-row align="center" justify="center" fill-height>
      <v-col cols="12" lg="10" class="py-0">
        <v-row>
          <v-col
            v-for="(item, index) in listItem"
            :key="index"
            cols="12"
            lg="4"
            md="4"
            sm="6"
            class="pa-2"
          >
            <card-menu
              :id="index"
              :item="item"
              @click.native="$router.push({ name: item.name })"
            ></card-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { MenuAdmin } from "@/api/menu";
import CardMenu from "@/components/commons/CardMenu";

export default {
  name: "SettingsPage",
  components: {
    CardMenu,
  },
  data() {
    const listItem = MenuAdmin[MenuAdmin.length - 1].submenu;
    return {
      listItem,
    };
  },
  created() {},
  methods: {},
};
</script>
