<template>
  <v-card-text class="pb-0">
    <v-form
      ref="formUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-row>
        <v-col
          v-if="typeDialog !== 'new' && !isEmpty(selectedItem)"
          cols="4"
          class="py-0"
        >
          <v-text-field
            v-model="selectedItem.id"
            disabled
            outlined
            label="Código"
            height="56"
            hide-details
            class="pt-0 input-custom-56"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 mb-5">
          <v-textarea
            v-model="inputDescription"
            label="Descripción de obligación*"
            outlined
            height="100"
            class="pt-0 textarea-custom-100"
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.description)"
            :rules="rulesDescription"
            @input="onChangeDescription"
          >
          </v-textarea>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedType"
            class="pt-0 input-custom-56"
            :hide-details="isEmpty(listError.type_id)"
            :rules="rulesType"
            label="Tipo*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            :items="listType.options"
            return-object
            :disabled="disabledOptions"
            @input="onChangeType"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedSituation"
            class="pt-0 input-custom-56"
            :hide-details="isEmpty(listError.situation_id)"
            :rules="rulesSituation"
            label="Situación*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            :items="listSituations.options"
            :disabled="disabledOptions"
            return-object
            @input="onChangeSituation"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0" style="margin-bottom: 20px; !important;">
          <v-container fluid class="py-0 px-0">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-row>
                  <v-col cols="12" class="pr-3 py-0">
                    <v-menu
                      v-model="showDateExpiration"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          slot="activator"
                          v-model="dateFormattedExpiration"
                          label="Fecha de Vencimiento de la obligación*"
                          class="my-0 pt-0 input-custom-56"
                          style="margin-bottom: 0 !important;"
                          height="56"
                          outlined
                          :disabled="
                            !!expirationDateDisabled || disabledOptions
                          "
                          :hide-details="isEmpty(listError.expiration_date)"
                          :rules="rulesExpirationDate"
                          append-icon="event"
                          readonly
                          @click:append="showDateExpiration = true"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateExpiration"
                        :show-current="true"
                        locale="es"
                        no-title
                        scrollable
                        reactive
                        :allowed-dates="allowedDates"
                        @input="onChangeDateExpiration"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="mt-0 py-0">
                    <v-switch
                      v-model="expirationDateDisabled"
                      :ripple="false"
                      :disabled="disabledOptions"
                      hide-details
                      class="mt-0"
                      :false-value="0"
                      :true-value="1"
                      label="N/A"
                      @change="onChangeExpirationDateDisabled"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-menu
                  v-model="showDateEffectiveCompliance"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      slot="activator"
                      v-model="dateFormattedEffectiveCompliance"
                      class="my-0 pt-0 input-custom-56"
                      label="Fecha de cumplimiento efectiva*"
                      style="margin-bottom: 0 !important;"
                      :disabled="disabledOptions"
                      :hide-details="
                        isEmpty(listError.effective_compliance_date)
                      "
                      :rules="rulesEffectiveCompliance"
                      outlined
                      height="56"
                      append-icon="event"
                      readonly
                      @click:append="showDateEffectiveCompliance = true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEffectiveCompliance"
                    :show-current="true"
                    locale="es"
                    no-title
                    scrollable
                    reactive
                    :allowed-dates="allowedDates"
                    @input="onChangeDateEffectiveCompliance"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedAuthority"
            class="pt-0 mb-3 input-custom-56"
            label="Autoridad"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            :hide-details="isEmpty(listError.authority_id)"
            :rules="rulesAuthority"
            :items="listAuto.options"
            :disabled="disabledOptions"
            return-object
            @input="onChangeAuthority"
          ></v-autocomplete>
        </v-col>
        <responsable-section
          ref="responsibleSection"
          :selected-item="selectedItem"
          :type-dialog="typeDialog"
          :filter-ejecutor="true"
          :type-submodule="typeSubmodule"
          :list-error="listError"
          :disabled="disabledOptions"
          @clear-error="onClearErrorResponsible"
        ></responsable-section>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            class="pt-0 input-custom-56"
            label="Proyecto*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            :hide-details="isEmpty(listError.project_id)"
            :rules="rulesProject"
            :items="listProjects.options"
            :disabled="disabledOptions"
            @input="onChangeProject"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <v-textarea
            v-model="inputDetail"
            class="pt-0 mb-0"
            label="Detalle"
            hide-details
            outlined
            name="detalle"
            :disabled="disabledOptions"
            @input="onChangeDetail"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 mt-2 mb-4">
          <v-divider></v-divider>
        </v-col>
        <check-list-task
          :id="selectedItem.id"
          class="mb-0"
          :tasks="selectedItem.pending_tasks"
          :type="typeDialog"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          :filter-ejecutor="true"
          :disabled="disabledOptions"
          @update="onChangePendingTask"
        ></check-list-task>
        <v-col cols="12" class="py-0 mt-2 mb-4">
          <v-divider></v-divider>
        </v-col>
        <map-section
          :id="selectedItem.id"
          ref="mapSection"
          v-model="selectedItem.location_disabled"
          class="mb-0"
          :zone="selectedItem.zone"
          :datum="selectedItem.datum"
          :locations="selectedItem.locations"
          :type-dialog="typeDialog"
          :disabled="disabledOptions"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          @location-disabled="onChangeLocationDisabled"
          @update="onChangeLocations"
          @datum-zone="onChangeDatumZone"
        ></map-section>
        <v-col cols="12" class="py-0 mt-2 mb-4">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <p class="mb-2 card-input-title">Base legal</p>
          <v-textarea
            v-model="inputLegalBase"
            class="pt-0 mb-0"
            hide-details
            outlined
            height="100"
            label="Comentario"
            :disabled="disabledOptions"
            @input="onChangeLegalBase"
          >
          </v-textarea>
        </v-col>
        <v-col v-if="!isEmpty(spij)" cols="12" class="py-0 mb-0">
          <p class="mb-1 card-input-title">SPIJ</p>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <a :href="spij.spij_url" target="_blank">{{ spij.spij_url }}</a>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_user"
                outlined
                readonly
                label="Usuario"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 input-custom-56"
                @click:append="onCopyText('spij_user', spij.spij_user)"
              ></v-text-field>
              <input id="spij_user" type="hidden" :value="spij.spij_user" />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_password"
                outlined
                readonly
                label="Contraseña"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 input-custom-56"
                @click:append="onCopyText('spij_password', spij.spij_password)"
              ></v-text-field>
              <input
                id="spij_password"
                type="hidden"
                :value="spij.spij_password"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import ResponsableSection from "../commons/ResponsableSection";
import CheckListTask from "../commons/CheckListTask";
import MapSection from "../commons/MapSection";

export default {
  name: "EnvironmentalsSection",
  components: {
    ResponsableSection,
    CheckListTask,
    MapSection,
  },
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: { type: Object, default: () => {} },
    spij: { type: Object, default: () => {} },
    typeModule: { type: String, default: "" },
    typeSubmodule: { type: String, default: "" },
    typeDialog: { type: String, default: "new" },
    disabledEdit: { type: Boolean, default: false },
    isDrafts: { type: Boolean, default: false },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    form: {},
    copyText: "",
    inputDescription: null,
    inputDetail: null,
    inputLegalBase: null,
    selectedType: null,
    selectedSituation: null,
    selectedProject: null,
    selectedAuthority: null,
    // debounce
    debounceDetail: null,
    debounceLegalBase: null,
    // date picker
    dateEffectiveCompliance: null,
    dateExpiration: null,
    showDateEffectiveCompliance: false,
    showDateExpiration: false,
    expirationDateDisabled: 0,
    // rules
    rulesType: [],
    rulesProject: [],
    rulesDescription: [],
    rulesSituation: [],
    rulesExpirationDate: [],
    rulesEffectiveCompliance: [],
    rulesAuthority: [],
    // error
    listError: {
      type_id: "",
      situation_id: "",
      project_id: "",
      authority_id: "",
      description: "",
      // user
      primary_responsible_id: "",
      secondary_responsible_id: "",
      // date
      expiration_date: "",
      effective_compliance_date: "",
    },
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    dateFormattedEffectiveCompliance() {
      return this.formatDate(this.dateEffectiveCompliance);
    },
    dateFormattedExpiration() {
      return this.formatDate(this.dateExpiration);
    },
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
    listType() {
      const types = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-tipo`;
      });
      return types.length ? types[0] : { options: [] };
    },
    listAuto() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-autoridad`;
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  mounted() {
    this.debounceDetail = debounce(this.onChangeDetail, 300);
    this.debounceLegalBase = debounce(this.onChangeLegalBase, 300);
  },
  methods: {
    clearErrorSection() {
      if (this.$refs.responsibleSection)
        this.$refs.responsibleSection.resetValidation();

      if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation();
      // set errror
      this.$nextTick(() => {
        this.listError = {
          type_id: "",
          situation_id: "",
          project_id: "",
          authority_id: "",
          description: "",
          // user
          primary_responsible_id: "",
          secondary_responsible_id: "",
          // date
          expiration_date: "",
          effective_compliance_date: "",
        };
      });
    },
    listErrorSection(err) {
      const {
        type_id = null,
        situation_id = null,
        authority_id = null,
        project_id = null,
        description = null,
        expiration_date = null,
        base_legal = null,
        effective_compliance_date = null,
      } = err;
      this.showError = true;
      this.listError.type_id = type_id ? type_id[0] : null;
      this.listError.situation_id = situation_id ? situation_id[0] : null;
      this.listError.authority_id = authority_id ? authority_id[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      this.listError.description = description ? description[0] : null;
      this.listError.base_legal = base_legal ? base_legal[0] : null;
      this.listError.expiration_date = expiration_date
        ? expiration_date[0]
        : null;
      this.listError.effective_compliance_date = effective_compliance_date
        ? effective_compliance_date[0]
        : null;
      this.rulesType = this.listError.type_id
        ? [() => this.listError.type_id]
        : [];
      this.rulesDescription = this.listError.description
        ? [() => this.listError.description]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.rulesSituation = this.listError.situation_id
        ? [() => this.listError.situation_id]
        : [];
      this.rulesExpirationDate = this.listError.expiration_date
        ? [() => this.listError.expiration_date]
        : [];
      this.rulesEffectiveCompliance = this.listError.effective_compliance_date
        ? [() => this.listError.effective_compliance_date]
        : [];
      this.$refs.formUpdate.validate();
      this.$nextTick(() => {
        this.$refs.mapSection.validateError(err);
        this.$refs.responsibleSection.validateError(err);
      });
    },
    setForm() {
      this.rulesType = [];
      this.rulesProject = [];
      this.rulesSituation = [];
      this.rulesExpirationDate = [];
      this.rulesEffectiveCompliance = [];
      this.rulesAuthority = [];
      this.$nextTick(() => {
        const {
          detail = null,
          project,
          situation,
          type,
          authority,
          description,
          legal_base,
          expiration_date,
          effective_compliance_date,
          expiration_date_disabled = 0,
        } = this.selectedItem;
        this.selectedProject = project;
        this.selectedSituation = situation;
        this.selectedType = type;
        this.selectedAuthority = authority;
        this.inputDetail = detail;
        this.inputLegalBase = legal_base;
        this.inputDescription = description;
        this.dateExpiration = expiration_date;
        this.dateEffectiveCompliance = effective_compliance_date;
        this.expirationDateDisabled = expiration_date_disabled;
        this.$refs.mapSection.setForm();
        this.$refs.responsibleSection.setForm();
      });
    },
    onChangeType(type) {
      this.selectedItem.type = type ? type : null;
      this.listError.type_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeSituation(situation) {
      this.selectedItem.situation = situation ? situation : null;
      this.listError.situation_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    clearFormSection() {
      this.selectedProject = null;
      this.selectedSituation = null;
      this.selectedType = null;
      this.selectedAuthority = null;
      this.dateExpiration = null;
      this.dateEffectiveCompliance = null;
      this.inputDescription = null;
      this.inputDetail = null;
      this.inputLegalBase = null;
    },
    onChangeResponsible(primary, secondary) {
      this.selectedItem.primary_responsible = primary ? primary : null;
      this.selectedItem.secondary_responsible = secondary ? secondary : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeProject(project) {
      this.selectedItem.project = project ? project : null;
      this.listError.project_id = null;
      this.rulesProject = [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDetail(detail) {
      this.selectedItem.detail = detail ? detail : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDescription(description) {
      this.selectedItem.description = description ? description : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeAuthority(authority) {
      this.selectedItem.authority = authority ? authority : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDateExpiration(expiration) {
      this.selectedItem.expiration_date = expiration ? expiration : null;
      this.selectedItem.expiration_date_disabled = this.expirationDateDisabled;
      this.listError.expiration_date = null;
      this.showDateExpiration = false;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDateEffectiveCompliance(effective_compliance) {
      this.selectedItem.effective_compliance_date = effective_compliance
        ? effective_compliance
        : null;
      this.listError.effective_compliance_date = null;
      this.showDateEffectiveCompliance = false;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLocationDisabled() {
      this.$emit("change-form");
    },
    onChangeLocations(locations) {
      this.selectedItem.locations = locations ? locations : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDatumZone(datum, zone) {
      this.selectedItem.datum = datum ? datum : [];
      this.selectedItem.zone = zone ? zone : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePendingTask(task) {
      this.selectedItem.pending_tasks = task ? task : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLegalBase(item) {
      this.selectedItem.legal_base = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeExpirationDateDisabled(flag) {
      this.selectedItem.expiration_date_disabled = flag;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onClearErrorResponsible(type) {
      if (type === "primary") {
        this.listError.primary_responsible_id = null;
      } else if (type === "secondary") {
        this.listError.secondary_responsible_id = null;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },
    onCopyText(id, text) {
      let inputPassword = document.querySelector(`#${id}`);
      inputPassword.setAttribute("type", "text");
      inputPassword.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        if (msg === "successful") this.copyText = "Copiado";
      } catch (err) {
        console.error(err);
      }
      inputPassword.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      setTimeout(() => {
        this.copyText = "";
      }, 500);
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>
