const Supervision = [
  {
    name: "Expediente 1",
    type: "Tipo 1",
    deadline: "Hace 2 días",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Expediente 2",
    type: "Tipo 2",
    deadline: "Hace 5 horas",
    situation: "Culminado",
    className: "row__active",
  },
  {
    name: "Expediente 3",
    type: "Tipo 3",
    deadline: "Hace 2 horas",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Expediente 4",
    type: "Tipo 4",
    deadline: "08/02/2019",
    situation: "Culminado",
    className: "row__active",
  },
  {
    name: "Expediente 5",
    type: "Tipo 5",
    deadline: "13/02/2019",
    situation: "Culminado",
    className: "row__active",
  },
  {
    name: "Expediente 6",
    type: "Tipo 6",
    deadline: "Hace 2 días",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Expediente 7",
    type: "Tipo 7",
    deadline: "10/01/2019",
    situation: "Culminado",
    className: "row__active",
  },
  {
    name: "Expediente 8",
    type: "Tipo 8",
    deadline: "Hace 12 horas",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Expediente 9",
    type: "Tipo 9",
    deadline: "06/12/2018",
    situation: "Culminado",
    className: "row__active",
  },
  {
    name: "Expediente 10",
    type: "Tipo 10",
    deadline: "20/02/2019",
    situation: "Culminado",
    className: "row__active",
  },
];
const Supervision2 = [
  {
    name: "Nombre 1",
    type: "Tipo 1",
    deadline: "15/04/2020",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Nombre 2",
    type: "Tipo 2",
    deadline: "10/05/2020",
    situation: "Vigente",
    className: "row__active",
  },
  {
    name: "Nombre 3",
    type: "Tipo 3",
    deadline: "01/06/2020",
    situation: "En proceso",
    className: "row__process",
  },
  {
    name: "Nombre 4",
    type: "Tipo 4",
    deadline: "24/04/2020",
    situation: "Vigente",
    className: "row__active",
  },
  {
    name: "Nombre 5",
    type: "Tipo 5",
    deadline: "22/09/2020",
    situation: "Vencido",
    className: "row__expired",
  },
];
const entity = [
  {
    label: "OSINERGMIN (seguridad y salud)",
    key: "osinergmin",
  },
  {
    label: "SUNAFIL (laboral)",
    key: "sunafil",
  },
  {
    label: "OEFA (ambiental)",
    key: "oefa",
  },
  {
    label: "SUCAMEC (explosivos)",
    key: "sucame",
  },
  {
    label: "ANA (seguridad y salud)",
    key: "ala",
  },
  {
    label: "OTROS",
    key: "otros",
  },
];
Supervision.push(...Supervision);
Supervision.push(...Supervision2);

export { Supervision, Supervision2, entity };
