<template>
  <v-dialog
    v-model="dialogNew"
    content-class="dialog-custom"
    persistent
    :attach="true"
    open-on-focus
    scrollable
    max-width="950px"
    @keydown.esc="onCloseDialogUpdate()"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="subheading">{{
          typeDialog === "new" ? "Nueva ficha" : "Ficha"
        }}</span>
        <v-spacer></v-spacer>
        <template v-if="!isEmpty(selectedItem.id)">
          <export-word
            v-if="typeDialog === 'edit' && !flagSelectDraft && !loadDetail"
            :id="selectedItem.id"
            :api-module="apiByModule"
          ></export-word>
        </template>
        <v-btn
          slot="activator"
          class="m-0"
          icon
          fab
          small
          @click="onCloseDialogUpdate()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col v-show="loadDetail" cols="12">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <vue-perfect-scrollbar
        v-show="!loadDetail"
        id="dialogDetailCard"
        class="drawer-menu--scroll"
        :settings="scrollSettings"
        :swicher="!loadDetail"
      >
        <permits-section
          v-if="typeModule === 'permisos'"
          ref="permitSection"
          v-model="selectedItem"
          :list-items="listItems"
          :disabled-edit="disabledEdit"
          :type-dialog="typeDialog"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDraft"
          :spij="null"
          @change-form="validateDialogDraft"
        ></permits-section>
        <contracts-section
          v-if="typeModule === 'contratos'"
          ref="contractsSection"
          v-model="selectedItem"
          :disabled-edit="disabledEdit"
          :type-module="typeModule"
          :type-dialog="typeDialog"
          :type-submodule="typeSubmodule"
          :is-drafts="isDraft"
          @change-form="validateDialogDraft"
        ></contracts-section>
        <commitments-section
          v-if="typeModule === 'compromisos'"
          ref="commitmentsSection"
          v-model="selectedItem"
          :disabled-edit="disabledEdit"
          :type-module="typeModule"
          :type-dialog="typeDialog"
          :type-submodule="typeSubmodule"
          :is-drafts="isDraft"
          :extended-attributes="extendedAttributes"
          @change-form="validateDialogDraft"
          @change-dynamic-attributes="validateDynamicAttribs"
          @draft-extended="validateDynamicAttribs"
        ></commitments-section>
        <occupational-safetys-section
          v-if="typeModule === 'seguridad'"
          ref="occupationalSafetysSection"
          v-model="selectedItem"
          :disabled-edit="disabledEdit"
          :type-module="typeModule"
          :type-dialog="typeDialog"
          :type-submodule="typeSubmodule"
          :is-drafts="isDraft"
          :spij="null"
          @change-form="validateDialogDraft"
        ></occupational-safetys-section>
        <environmentals-section
          v-if="typeModule === 'ambiental'"
          ref="environmentalsSection"
          v-model="selectedItem"
          :disabled-edit="disabledEdit"
          :type-module="typeModule"
          :type-dialog="typeDialog"
          :type-submodule="typeSubmodule"
          :is-drafts="isDraft"
          :spij="null"
          @change-form="validateDialogDraft"
        ></environmentals-section>
      </vue-perfect-scrollbar>
      <v-card-actions
        v-show="!loadDetail"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <template v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
          <v-btn
            v-if="typeDialog === 'edit' && !flagSelectDraft && !disabledEdit"
            color="primary"
            depressed
            small
            class="btn-transform-none"
            @click="disabledEdit = true"
            >Habilitar edición</v-btn
          >
        </template>
        <v-spacer></v-spacer>
        <template v-if="typeDialog === 'edit' && !isDraft">
          <v-btn
            color="action"
            class="btn-transform-none"
            text
            small
            @click="onCloseDialogUpdate()"
            >Cancelar</v-btn
          >
          <v-btn
            v-if="roleUser !== 'visualizer' && roleUser !== 'executor'"
            color="action"
            class="btn-transform-none"
            :dark="disabledEdit"
            :disabled="!disabledEdit"
            depressed
            small
            @click="onSubmitSaveCard()"
          >
            <v-progress-circular
              v-if="loadUpdate"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            {{ !loadUpdate ? "Guardar" : "Guardando" }}</v-btn
          >
        </template>
        <template v-else>
          <v-btn
            color="action"
            class="btn-transform-none"
            text
            small
            depressed
            @click="onCloseDialogUpdate()"
            >Cancelar</v-btn
          >
          <v-btn
            color="action"
            class="btn-transform-none"
            small
            dark
            depressed
            @click="onSubmitDraft()"
          >
            <v-progress-circular
              v-if="loadDraft || auxLoadDraft"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            {{ loadDraft ? "Guardando borrador" : "Guardar borrador" }}
          </v-btn>
          <v-btn
            color="action"
            class="btn-transform-none"
            small
            dark
            depressed
            @click="onSubmitDraftToCard()"
          >
            <v-progress-circular
              v-if="loadUpdate"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            {{ !loadUpdate ? "Crear ficha" : "Creando ficha" }}</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>

    <dialog-question
      v-model="dialogQuestion"
      @force-close="onCloseDialogQuestion"
    ></dialog-question>
  </v-dialog>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { isEqual, cloneDeep } from "lodash";
import mixinSort from "./mixins/mixinSort";
import mixinApiModule from "./mixins/mixinApiModule";
import mixinModuleCommon from "./mixins/mixinModuleCommon";
import PermitsSection from "./PermitsSection";
import ContractsSection from "./ContractsSection";
import CommitmentsSection from "./CommitmentsSection";
import OccupationalSafetysSection from "./OccupationalSafetysSection";
import EnvironmentalsSection from "./EnvironmentalsSection";
import ExportWord from "@/components/commons/ExportWord";
import DialogQuestion from "@/components/commons/DialogQuestion";

export default {
  name: "DialogDetailCard",
  components: {
    VuePerfectScrollbar,
    PermitsSection,
    CommitmentsSection,
    ContractsSection,
    OccupationalSafetysSection,
    EnvironmentalsSection,
    ExportWord,
    DialogQuestion,
  },
  mixins: [mixinSort, mixinApiModule, mixinModuleCommon],
  model: { prop: "dialogNew", event: "change" },
  props: {
    flagSelectDraft: {
      type: Boolean,
      default: false,
    },
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
    loadDraft: {
      type: Boolean,
      default: false,
    },
    paginationData: {
      type: Object,
      default: () => {},
    },
    selectedSpij: {
      type: Object,
      default: () => {},
    },
    extendedAttributes: {
      type: Array,
      default: () => {},
    },
    listItems: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    uuid: "",
    urlDownload: "",
    disabledEdit: false,
    selectedItem: { id: null },
    selectedExtendedAttributes: [],
    cloneItem: null,
    isDraft: false,
    isComponentParent: false,
    dialogQuestion: false,
    loadingQuestion: false,
    dialogDetail: false,
    auxLoadDraft: false,
    flagChangeForm: false,
    loadUpdate: false,
    loadDetail: false,
    height: 100,
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    draftToCard: false,
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      filterData: (state) => state.permits.filterData,
      pages: (state) => state.permits.pages,
      sortWP: (state) => state.permits.sort,
      noResultWP: (state) => state.permits.isNoResult,
    }),
  },
  mounted() {
    this.flagChangeForm = false;
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onSubmitSaveCard() {
      this.draftToCard = false;
      this.validateSubmitForm();
    },
    onSubmitDraftToCard() {
      this.draftToCard = true;
      this.validateSubmitForm();
    },
    onSubmitDraft() {
      this.draftToCard = false;
      this.validateSubmitForm(true);
    },
    validateHeader(head) {
      console.log(head);
    },
    onResize() {
      if (document.getElementById("containerTable")) {
        this.height = document.getElementById("containerTable").clientHeight;
      }
    },
    onCloseDialogQuestion(type = "close") {
      if (type === "save") {
        this.$emit("save-draft", this.selectedItem);
      } else {
        this.dialogQuestion = false;
        this.$emit("change", false);
        // this.dialogNew = false;
      }
    },
    setLoadQuestion(flag) {
      this.$emit("change", false);
      this.loadingQuestion = flag;
    },
    validUndefined(val) {
      return val === undefined ? null : val;
    },
    openDialogNew(item, flagEdit = false, isDraft = false) {
      this.selectedItem = this.getInterface();
      this.isDraft = isDraft;
      this.flagChangeForm = false;
      if (flagEdit) this.disabledEdit = flagEdit;
      this.$nextTick(() => {
        this.selectedItem = cloneDeep(item);
        this.cloneItem = cloneDeep(item);
        this.selectedExtendedAttributes = [];
        this.validateSetFormSection();
        this.moveScollTop();
      });
    },
    async openDialogUpdate(type = "edit", item, isDraft = false) {
      this.isDraft = isDraft;
      if (type === "edit") {
        this.loadDetail = true;
      }
      this.flagChangeForm = false;
      const { data } = await this.axios.get(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${item.id}`,
      );
      if (data.success) {
        this.selectedItem = cloneDeep(data.data);
        this.cloneItem = cloneDeep(data.data);
        this.disabledEdit = false;
        this.loadDetail = false;
        this.$nextTick(() => {
          this.validateSetFormSection();
          this.moveScollTop();
          this.selectedExtendedAttributes = [];
        });
      }
    },
    moveScollTop() {
      const idDialog = document.getElementById("dialogDetailCard");
      if (idDialog) {
        idDialog.scrollTop = 0;
      }
    },
    validateDialogDraft() {
      this.flagChangeForm = true;
    },
    validateDynamicAttribs(event) {
      this.flagChangeForm = true;
      this.$nextTick(() => {
        this.selectedExtendedAttributes = event;
      });
    },
    onCloseDialogUpdate() {
      const aux = cloneDeep(this.selectedItem);
      if (!isEqual(aux, this.cloneItem)) {
        this.dialogQuestion = true;
      } else {
        this.dialogQuestion = false;
        this.$emit("change", false);
        this.$nextTick(() => {
          this.selectedItem = this.getInterface();
        });
        this.$emit("close");
        // this.flagChangeForm = false;
      }
    },
    onFocusDialog() {
      const element = document.getElementById("inputDialogFocus");
      if (element) {
        this.$nextTick(() => {
          element.focus();
        });
      }
    },
  },
};
</script>
