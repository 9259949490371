<template>
  <div class="block-search">
    <v-card class="mx-auto" width="300">
      <v-card-title class="d-block subtitle-2 w-100"
        >Búsqueda en Geocatmin
        <v-btn
          class="float-right"
          icon
          x-small
          depressed
          :ripple="false"
          @click="toggleForm = !toggleForm"
        >
          <v-icon>{{
            toggleForm ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</v-icon>
        </v-btn>
      </v-card-title>
      <template v-if="toggleForm">
        <v-card-text class="text--primary">
          <v-text-field
            v-model="searchCode"
            name="geocatmin_filter_code"
            autocomplete="off"
            label="Código"
            outlined
            hide-details
            height="56"
            class="input-custom-56 mb-5"
          ></v-text-field>
          <v-text-field
            v-model="searchName"
            name="geocatmin_filter_name"
            autocomplete="off"
            label="Nombre"
            outlined
            hide-details
            height="56"
            class="input-custom-56 mb-5"
          ></v-text-field>
          <v-text-field
            v-model="searchOwner"
            name="geocatmin_filter_owner"
            autocomplete="off"
            label="Titular"
            outlined
            hide-details
            height="56"
            class="input-custom-56 mb-0"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" class="pt-0 pb-1">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-btn
                  class="ma-0"
                  color="action"
                  text
                  depressed
                  small
                  block
                  @click="clearFilters()"
                  >Limpiar
                </v-btn>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  block
                  @click="onSubmitSearch()"
                  >Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "BlockSearchQuery",
  data: () => ({
    searchCode: null,
    searchName: null,
    searchOwner: null,
    toggleForm: true,
  }),
  mounted() {
    this.searchCode = null;
    this.searchName = null;
    this.searchOwner = null;
  },
  methods: {
    onSubmitSearch() {
      const payload = {
        searchCode: this.searchCode,
        searchName: this.searchName,
        searchOwner: this.searchOwner,
      };
      // this.$emit("query", payload);
      this.$eventBus.$emit("search-query", payload);
    },
    clearFilters() {
      this.searchCode = null;
      this.searchName = null;
      this.searchOwner = null;
      this.$eventBus.$emit("search-query-clear");
      this.$emit("clear");
    },
  },
};
</script>
