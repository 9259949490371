import { mapState } from "vuex";

export default {
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: null,
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    disabledOptions: {
      type: Boolean,
      default: false,
    },
    typeDialog: {
      type: String,
      default: "new",
    },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    loadSave: false,
    changeInstance: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
      eight: false,
      nine: false,
      ten: false,
    },
    payloadInstance: {
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      six: {},
      seven: {},
      eight: {},
      nine: {},
      ten: {},
    },
  }),
  computed: {
    // courts
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listProjects: (state) => state.masters.listProjects,
    }),
    listRooms() {
      const rooms = this.listMasters.filter((e) => {
        return e.slug === "rooms";
      });
      return rooms.length ? rooms[0] : { options: [] };
    },
    listCourts() {
      const courts = this.listMasters.filter((e) => {
        return e.slug === "courts";
      });
      return courts.length ? courts[0] : { options: [] };
    },
    selectedId() {
      return this.selectedItem ? this.selectedItem.id : null;
    },
  },
  mounted() {
    this.activeTab = 1;
    this.flagEdit = false;
  },
  methods: {
    onUpdateInstance(nameInstance, payload) {
      this.$set(this.payloadInstance, nameInstance, payload);
    },
  },
};
