<template>
  <user-table
    ref="moduleItemTable"
    :list-item="listUser"
    :load-data="loadFirstSearch"
    @scroll="scrollHanle"
  ></user-table>
</template>
<script>
import { mapActions, mapState } from "vuex";
import UserTable from "@/components/users/UserTable";

export default {
  name: "UsersPage",
  components: {
    UserTable,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    subId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadData: false,
    };
  },
  computed: {
    ...mapState({
      listUser: (state) => state.users.listUser,
      pages: (state) => state.users.pages,
      filterData: (state) => state.users.filterData,
      loadFirstSearch: (state) => state.users.loadData,
      metaData: (state) => state.users.metadata,
      sortToDo: (state) => state.users.sort,
      noResultUser: (state) => state.users.noResult,
    }),
  },
  created() {
    this.clearUsers();
  },
  mounted() {
    this.loadData = false;
    this.updatePageUsers(1);
    const payload = `?response=lite&page=${this.pages}`;
    this.fetchUsers({ payload, step: true }).then(() => {
      this.loadData = true;
      setTimeout(() => {
        this.loadData = false;
      }, 500);
    });
  },
  beforeDestroy() {
    this.clearUsers();
  },
  methods: {
    ...mapActions(["fetchUsers", "clearUsers", "updatePageUsers"]),
    scrollHanle() {
      if (this.loadFirstSearch) {
        const { current_page, last_page } = this.metaData;
        if (!this.loadData && current_page < last_page) {
          this.loadData = true;
          const payload = `?response=lite&page=${this.pages + 1}${
            this.filterData
          }`;
          if (this.$refs.moduleItemTable) {
            this.$refs.moduleItemTable.setLoadScroll(true);
            this.fetchUsers({ payload, step: false }).then(() => {
              this.$refs.moduleItemTable.setLoadScroll(false);
              this.updatePageUsers(this.pages + 1);
              this.loadData = false;
            });
          }
        }
        if (current_page === last_page) {
          if (this.$refs.moduleItemTable) {
            this.$refs.moduleItemTable.setFinishedScroll(true);
            setTimeout(() => {
              if (this.$refs.moduleItemTable) {
                this.$refs.moduleItemTable.setFinishedScroll(false);
              }
            }, 3500);
          }
        }
      }
    },
  },
};
</script>
