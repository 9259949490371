<template>
  <v-container
    id="containerTable"
    fluid
    class="page-section pa-0 fill-height"
    style="position: relative;"
  >
    <v-card class="card-toolbar" color="transparent" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>Notificaciones</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <div class="content-table-notification">
      <v-row
        class="fill-height"
        style="padding: 0 12px 0 12px; position: relative;"
      >
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%;"
          class="content-meta-scroll"
          :settings="scrollSettings"
          @ps-y-reach-end="scrollHanle"
        >
          <v-col cols="12" class="">
            <v-card elevation="7" style="overflow:hidden;">
              <v-card-text class="pa-0">
                <block-notifications
                  class="block-notifications"
                  :notifications="notifications"
                ></block-notifications>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="loadScroll && loadData"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
              >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll && loadData"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <span class="caption">No hay mas registros.</span>
          </v-col>
        </vue-perfect-scrollbar>
        <!-- Pagination -->
        <module-detail-table
          :is-todo="true"
          :total-todo="true"
          :hide-pages="true"
          :meta-data="metaData"
        ></module-detail-table>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import BlockNotifications from "../components/users/BlockNotifications";
import ModuleDetailTable from "@/components/commons/ModuleDetailTable";

export default {
  name: "NotificationsPage",
  components: {
    VuePerfectScrollbar,
    BlockNotifications,
    ModuleDetailTable,
  },
  data: () => ({
    expanded: [],
    scrollSettings: {
      maxScrollbarLength: 160,
      suppressScrollX: true,
    },
    pages: 1,
    disabledEdit: true,
    dialogCard: false,
    dialogTodo: false,
    typeModule: null,
    typeSubmodule: null,
    typeDialog: "todo",
    selectedItem: null,
    loadScroll: false,
    isFinishedScroll: false,
    // spij
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    loadData: false,
    notifications: [],
    metaData: {},
    flagDelayScroll: false,
  }),
  mounted() {
    this.fetchSettingSpij();
    this.loadData = false;
    const payload = `?page=${this.pages}`;
    this.axios.get(`/api/notifications${payload}`).then(({ data }) => {
      this.flagDelayScroll = true;
      this.notifications = data.data;
      this.metaData = data.meta;
      this.loadData = true;
      setTimeout(() => {
        this.flagDelayScroll = false;
      }, 500);
    });
  },
  methods: {
    scrollHanle() {
      if (!this.flagDelayScroll) {
        const { current_page, last_page } = this.metaData;
        if (this.loadData && current_page < last_page) {
          this.flagDelayScroll = true;
          this.loadData = true;
          const payload = `?page=${this.pages + 1}`;
          this.loadScroll = true;
          this.axios.get(`/api/notifications${payload}`).then(({ data }) => {
            this.loadScroll = false;
            this.notifications = [...this.notifications, ...data.data];
            this.metaData = data.meta;
            this.pages = this.pages + 1;
            setTimeout(() => {
              this.flagDelayScroll = false;
            }, 500);
          });
        }
        if (current_page === last_page) {
          this.isFinishedScroll = true;
          setTimeout(() => {
            this.isFinishedScroll = false;
          }, 3500);
        }
      }
    },
    onCloseDialogCard() {
      this.dialogCard = false;
      this.disabledEdit = true;
      setTimeout(() => {
        this.selectedItem = null;
      }, 800);
    },
    async fetchSettingSpij() {
      const { data } = await this.axios.get(`/api/settings`);
      if (data.success) {
        this.selectedSpij = data.data;
      }
    },
  },
};
</script>
