<template>
  <v-app id="login">
    <v-main fill-height>
      <div class="page-login">
        <div class="page-login__right">
          <div class="bg__black"></div>
        </div>
        <div class="page-login__left">
          <v-row>
            <v-col cols="12" class="py-0">
              <div class="block-logo mx-0">
                <img class="logo" src="@/assets/logo-j.svg" alt="Jaru Logo" />
              </div>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-card class="elevation-0">
                <v-card-title class="px-0">
                  <p class="subtitle-2 mb-1">
                    CAMBIAR CONTRASEÑA
                  </p>
                </v-card-title>
                <v-card-text class="px-0 pb-2">
                  <template v-if="tokenError">
                    <p class="mb-0 body-1">¡Su token ha expirado!</p>
                  </template>
                  <template v-else>
                    <template v-if="!flagReset">
                      <v-text-field
                        v-model="formReset.password"
                        append-icon="lock"
                        autocomplete="off"
                        name="formReset_password"
                        class=" input-custom-56"
                        label="Contraseña"
                        hide-details
                        type="password"
                        height="56"
                        outlined
                        @keydown.enter="onSubmitReset"
                      ></v-text-field>
                      <v-text-field
                        id="password"
                        v-model="formReset.repassword"
                        autocomplete="off"
                        class="mt-3 input-custom-56"
                        name="formReset_repassword"
                        append-icon="lock"
                        label="Repetir contraseña"
                        type="password"
                        height="56"
                        hide-details
                        outlined
                        @keydown.enter="onSubmitReset"
                      ></v-text-field>
                      <!--
                      <div class="text-lg-right">
                        <button
                          type="button"
                          class="btn-forget caption primary--text"
                          @click="isLogin = !isLogin"
                        >
                          ¿Olvidaste tu contraseña?
                        </button>
                      </div>
                      -->
                    </template>
                    <template v-else>
                      <div class="b-block text-center">
                        <p>Su contraseña ha sido cambiada correctamente</p>
                      </div>
                      <div class="d-flex justify-center">
                        <v-btn
                          class="ma-0"
                          color="primary"
                          depressed
                          small
                          :loading="loadReset"
                          @click="$router.push({ name: 'LoginPage' })"
                          >Regresar al inicio</v-btn
                        >
                      </div>
                    </template>
                  </template>
                </v-card-text>
                <v-card-actions v-if="!tokenError && !flagReset" class="px-0">
                  <v-btn
                    block
                    color="primary"
                    :loading="loadReset"
                    :disabled="loadReset"
                    @click="onSubmitReset"
                    >Guardar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-main>
    <v-dialog v-model="dialogForget" max-width="290">
      <v-card>
        <v-card-text>
          <p class="mb-0">Cambiar estado</p>
          En breve se le enviara su contraseña al correo.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="btn-transform-none"
            text
            @click="dialogForget = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
export default {
  name: "ResetPage",
  data: () => ({
    flagReset: false,
    loadReset: false,
    flagError: false,
    tokenError: false,
    token: null,
    formReset: {
      password: null,
      repassword: null,
    },
    dialogForget: false,
  }),
  mounted() {
    const { query } = this.$route;
    this.token = query.token || null;
    this.$nextTick(() => {
      this.onValidateToken();
    });
  },
  methods: {
    onValidateToken() {
      this.tokenError = false;
      this.axios
        .post(`/api/auth/validate/token`, {
          token: this.token,
        })
        .then(({ data }) => {
          if (!data.success) {
            this.tokenError = true;
          }
        })
        .catch(() => {
          this.tokenError = true;
        });
    },
    onSubmitReset() {
      if (this.loadReset) return false;
      this.flagError = false;
      if (this.formReset.password !== this.formReset.repassword) {
        this.flagError = true;
        setTimeout(() => {
          this.flagError = false;
        }, 1500);
      }
      if (this.isEmpty(this.token) || this.loadReset) {
        return false;
      }
      const payload = {
        token: this.token,
        password: this.formReset.password,
      };
      this.loadReset = true;
      this.flagReset = false;
      this.axios
        .post("/api/auth/password/change", payload)
        .then(() => {
          this.loadReset = false;
          this.flagReset = true;
        })
        .catch(() => {
          this.loadReset = false;
          this.flagReset = false;
        });
    },
  },
};
</script>
