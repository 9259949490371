<template>
  <v-card class="card-link" flat elevation="5">
    <v-img
      class="pt-4 pb-1"
      align-center
      justify-center
      style="text-align: center;"
    >
      <img
        class="card-link__image"
        :src="`${baseURL}/img/icons/black/${item.icon}`"
      />
    </v-img>
    <v-card-title class="card-twoLine pt-0 pb-1 px-1 text-center">
      <p class="text-center">{{ item.label }}</p>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  name: "CardMenu",
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
