<template>
  <v-dialog
    v-model="showDialog"
    max-width="300"
    persistent
    open-on-focus
    @keydown.esc="onCloseDialog()"
  >
    <v-card>
      <v-card-title class="py-1">
        <p class="subheading mb-0">{{ title }}</p>
      </v-card-title>
      <v-card-text>
        <div>¿Estás seguro en restablecer la contraseña?</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn-transform-none" small text @click="onCloseDialog()"
          >Cerrar</v-btn
        >
        <v-btn
          color="primary"
          small
          text
          class="btn-transform-none"
          :loading="loadDelete"
          :disabled="loadDelete"
          @click="onSubmitReset()"
          >Restablecer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogResetPassword",
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Eliminar",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadDelete: false,
    };
  },
  methods: {
    onSubmitReset() {
      if (this.loadDelete) {
        return false;
      }
      this.loadDelete = true;
      this.$emit("delete");
    },
    onCloseDialog() {
      this.$emit("change", !this.showDialog);
    },
    toggleLoad(flag) {
      this.loadDelete = flag;
    },
  },
};
</script>
