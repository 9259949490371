<template>
  <v-container
    id="containerTable"
    fluid
    class="page-section pa-0 fill-height"
    style="position: relative"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>{{ parseSlugModule(typeSubmodule) }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <div class="content-table-no-drawer">
      <h1>Expediente {{ id }}</h1>
      <v-row class="fill-height" style="position: relative">
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%"
          class="content-meta-scroll"
          :settings="scrollModuleSettings"
          :swicher="loadData"
          @ps-scroll-y="onScrollY($event, true)"
          @ps-y-reach-end="scrollHandle"
        >
          <v-col cols="12">
            <v-card elevation="7" style="overflow: hidden">
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="configHeader"
                  :items="actuaciones"
                  :loading="!loadData"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="id"
                  class="table-striped"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-if="!noResult && loadData">
                        <tr v-for="item in items" :id="item.id" :key="item.id">
                          <td class="td-hover">
                            {{ item.descripcion_usr }}
                          </td>
                          <td class="td-hover">
                            {{ item.resolucion !== "" ? item.resolucion : "-" }}
                          </td>
                          <td class="td-hover">
                            {{
                              item.tiponotificacion !== ""
                                ? item.tiponotificacion
                                : "-"
                            }}
                          </td>
                          <td class="td-hover">
                            {{ item.acto }}
                          </td>
                          <td class="td-hover">
                            {{ item.fojas !== null ? item.fojas : "-" }}
                          </td>
                          <td class="td-hover">
                            <a
                              v-if="item.resolucion_archivo !== null"
                              target="_blank"
                              @click="descargarArchivo(item)"
                              >DESCARGAR</a
                            >
                            <a v-else>Archivos ocultados por parte del CEJ.</a>
                          </td>
                        </tr>
                      </template>
                      <tr v-if="noResult" class="v-data-table__empty-wrapper">
                        <td :colspan="headers.length">
                          No se encontró resultados
                        </td>
                      </tr>
                      <tr
                        v-if="!loadData && !noResult"
                        class="v-data-table__empty-wrapper"
                      >
                        <td :colspan="headers.length">Esperando resultados</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="loadScroll && loadData"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <v-progress-circular
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
              >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll && loadData && !noResult"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <span class="caption">No hay más registros.</span>
          </v-col>
        </vue-perfect-scrollbar>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { cloneDeep } from "lodash";

export default {
  name: "JudicialProcessFilePage",
  props: {
    id: {
      type: String,
      default: "",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    expediente: {},
    actuaciones: [],
    loadData: false,
    loadScroll: false,
    scrollModuleSettings: { maxScrollbarLength: 160, suppressScrollX: true },
    noResult: false,
    isFinishedScroll: false,
    flagDelayScroll: false,
    pages: 1,
    paginationData: {},
    headers: [
      {
        text: "Usuario",
        class: "sticky-header",
        value: "descripcion_usr",
        width: 70,
      },
      {
        text: "Resolución",
        class: "sticky-header",
        value: "resolucion",
        width: 70,
      },
      {
        text: "Notificación",
        class: "sticky-header",
        value: "tiponotificacion",
        width: 70,
      },
      {
        text: "Acto",
        class: "sticky-header",
        value: "acto",
        width: 70,
      },
      {
        text: "Fojas",
        class: "sticky-header",
        value: "fojas",
        width: 70,
      },
      {
        text: "Expediente",
        class: "sticky-header",
        value: "resolucion_archivo",
        width: 70,
      },
    ],
  }),
  computed: {
    configHeader() {
      if (this.roleUser === "visualizer" || this.roleUser === "executor") {
        const headTable = cloneDeep(this.headers);
        return headTable.splice(0, 4);
      }
      return this.headers;
    },
  },
  mounted() {
    this.clearScrollTopTable();
    this.getExpedientes();
    const payload = `?response=lite&page=${this.pages}`;
    setTimeout(() => {
      this.getActuacionesFromExpedientes(payload);
    }, 1000);
  },
  methods: {
    getExpedientes() {
      this.axios.get(`/api/expedientes/${this.id}`).then(({ data }) => {
        this.expediente = data.data;
      });
    },
    descargarArchivo(payload) {
      this.axios
        .get(`/api/expedientes/${payload.id}/download`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            payload.resolucion_archivo.split("/")[1]
          ); // Nombre del archivo
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },
    getActuacionesFromExpedientes(payload) {
      this.axios
        .get(
          `/api/expedientes/${this.expediente.idExpediente}/actuaciones${payload}`
        )
        .then(({ data }) => {
          this.loadScroll = false;
          this.pages = this.pages + 1;

          if (data.success) {
            this.noResult = false;
            this.paginationData = data.pagination;
            this.actuaciones = data.data;
          } else {
            this.noResult = true;
          }

          this.loadData = true;
          setTimeout(() => {
            this.flagDelayScroll = false;
          }, 500);
        });
    },
    setLoadScroll(flag) {
      this.loadScroll = flag;
    },
    setFinishedScroll(flag) {
      this.isFinishedScroll = flag;
    },
    scrollHandle() {
      if (!this.flagDelayScroll) {
        console.log("entro??");
        const payload = `?response=lite&page=${this.pages}`;
        const { current_page, total_pages } = this.paginationData;
        if (this.loadData && current_page < total_pages) {
          this.flagDelayScroll = true;
          this.isFinishedScroll = false;
          this.loadScroll = true;
          this.getActuacionesFromExpedientes(payload);
        }
        if (current_page === total_pages) {
          this.isFinishedScroll = true;
        }
      }
    },
  },
};
</script>
