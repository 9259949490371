export const contingencies = [
  {
    id: "Posible",
    label: "Posible",
  },
  {
    id: "Remoto",
    label: "Remoto",
  },
  {
    id: "Probable",
    label: "Probable",
  },
];
