<template>
  <div class="page-section fill-height">
    <vue-perfect-scrollbar
      class="drawer-full--scroll"
      :settings="scrollSettings"
    >
      <v-container fluid>
        <v-row row wrap>
          <v-col cols="12" md="6" offset-md="3">
            <v-card>
              <v-card-text>
                <h3>Notificaciones</h3>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_sms"
                      label="Via SMS"
                      value="sms"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_email"
                      label="Via Email"
                      value="email"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_push"
                      label="Via Navegador"
                      value="navegador"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn-transform-none"
                  :loading="loadSettings"
                  color="action"
                  dark
                  small
                  depressed
                  @click="onSubmitSettings"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" offset-md="3">
            <v-card>
              <v-card-text>
                <h3 class="mb-4">SPIJ</h3>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="selectedSpij.spij_url"
                      label="URL"
                      height="36"
                      outlined
                      class="mb-4 py-0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="selectedSpij.spij_user"
                      label="Usuario"
                      height="36"
                      outlined
                      class="mb-4 py-0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="selectedSpij.spij_password"
                      label="Password"
                      height="36"
                      outlined
                      class="mb-4 py-0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="action"
                  dark
                  small
                  depressed
                  :loading="loadSpij"
                  @click="onSettingSpij"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </vue-perfect-scrollbar>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "NotificationPage",
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    optionNoti: [],
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    settings: {},
    profile: {},
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    loadSettings: false,
    loadSpij: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
  },
  created() {
    this.fetchSettingSpij();
    this.settings = cloneDeep(this.currentUser.settings);
  },
  methods: {
    async onSubmitSettings() {
      if (this.loadSettings) {
        return false;
      }
      this.loadSettings = true;
      const payload = {
        user_id: this.currentUser.id,
        ...this.settings,
      };
      const { data } = await this.axios.post(
        `/api/users/${this.currentUser.id}/update-settings`,
        payload,
      );
      if (data.success) {
        this.loadSettings = false;
      }
    },
    async fetchSettingSpij() {
      const { data } = await this.axios.get(`/api/settings`);
      if (data.success) {
        this.selectedSpij = data.data;
      }
    },
    async onSettingSpij() {
      this.loadSpij = true;
      const { data } = await this.axios.post(
        `/api/settings/update`,
        this.selectedSpij,
      );
      if (data.success) {
        this.loadSpij = false;
      }
    },
  },
};
</script>
