export default {
  data() {
    return {
      activeTab: 1,
      isEditable: false,
    };
  },
  methods: {
    nextStep(step, flag = false) {
      if (step === 2) {
        setTimeout(() => {
          this.showBtnInfo = true;
          this.showStepTwo = true;
          this.activeTab = "tab-2";
          this.disabledStepTwo = false;
        }, 500);
      } else if (step === 3) {
        // this.showStepTwo = false
        this.loadSave = true;
        setTimeout(() => {
          // this.showBtnInfo = true}
          if (flag) {
            this.activeTab = "tab-1";
            this.loadSave = false;
            this.dialogUpdateActa = false;
          } else {
            this.loadSave = false;
            this.activeTab = "tab-3";
          }
        }, 500);
      }
    },
  },
};
