export class IMiningProperty {
  constructor() {
    this.id = null;
    this.type = {};
    this.project = null;
    this.concession_code = null;
    this.concession_name = null;
    this.holder_name = null;
    this.assignee_name = null;
    this.granting_date = null;
    this.hectares_formulated = null;
    this.effective_hectares = null;
  }
}
