export class IChecklistTodo {
  constructor() {
    this.id = null;
    this.title = null;
    this.description = null;
    this.responsible = {
      id: null,
    };
    this.date = null;
    this.activities = [];
  }
}
