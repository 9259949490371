<template>
  <v-navigation-drawer
    class="sectionDrawer"
    width="250"
    value="true"
    absolute
    permanent
    right
    style="height: 100% !important;"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll block-filter"
      :settings="scrollSettings"
    >
      <v-row>
        <v-col cols="12" class="mb-1 py-0">
          <span class="font-weight-bold">Menú</span>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" class=" pt-0 px-0">
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                v-scroll-to="{
                  el: `#${slugify(item.label)}`,
                  container: '#listMastersGroup',
                  duration: 500,
                  easing: 'linear',
                }"
                href="#"
              >
                <v-list-item-content>
                  <v-tooltip top max-width="200">
                    <template v-slot:activator="{ on }">
                      <v-list-item-title v-on="on">
                        <span>{{ item.label }}</span>
                      </v-list-item-title>
                    </template>
                    <span class="d-block text-center">{{ item.label }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { isMobile } from "mobile-device-detect";

export default {
  name: "MasterDrawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    toggleDrawer: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isMobile,
    disabledSubmenu: true,
    selectedProject: null,
    selectedResponsible: null,
    optionsSelectSub: [],
    selectedState: null,
    optionChart: {},
    checkTaskPending: false,
    selectedAlert: null,
    selectedTask: null,
    loadSearch: false,
  }),
  computed: {
    ...mapState({
      listModule: (state) => state.modules.modules,
      listUser: (state) => state.users.listUser,
      listMasters: (state) => state.masters.listMasters,
      listState: (state) => state.masters.listState,
      listProjects: (state) => state.masters.listProjects,
    }),
  },
  methods: {
    onScrollToMaster(item) {
      this.$emit("select", item.label);
    },
    setLoadSearch(flag) {
      this.loadSearch = flag;
    },
  },
};
</script>
