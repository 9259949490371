<template>
  <v-container
    id="containerMain"
    fluid
    class="page-section py-0 px-0 fill-height"
    style="position: relative"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>{{ parseSlugModule(typeSubmodule) }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <filter-drawer ref="commonDrawer" @filter="onSearchFilter"></filter-drawer>
    <div class="content-table-module">
      <v-row
        class="fill-height"
        style="padding: 0 12px 0 12px; position: relative"
      >
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%"
          class="content-meta-scroll"
          :settings="scrollModuleSettings"
          :swicher="loadData"
          @ps-scroll-y="onScrollY($event, true)"
          @ps-y-reach-end="scrollHanle"
        >
          <v-col cols="12">
            <v-card id="containerTable" elevation="7" style="overflow: hidden">
              <v-toolbar flat dense color="transparent">
                <v-toolbar-title>
                  <h4>Fichas</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <export-excel
                  :api-module="apiByModule"
                  :filter-data="filterData"
                  :sort-items="sortCommon"
                ></export-excel>
                <template
                  v-if="roleUser !== 'visualizer' && roleUser !== 'executor'"
                >
                  <v-btn
                    class="btn-transform-none ml-2"
                    small
                    depressed
                    @click="onDialogDraft()"
                  >
                    Borradores
                  </v-btn>
                  <v-btn
                    class="btn-transform-none ml-2"
                    color="primary"
                    small
                    depressed
                    @click="onClickModalAddFicha()"
                  >
                    <v-icon>add</v-icon>
                    Agregar Ficha
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="configHeader"
                  :items="listItem"
                  :loading="!loadData"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="id"
                  class="table-striped"
                  @update:sort-by="eventSortBy"
                  @update:sort-desc="eventSortDesc"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                    <template v-if="!noResult && loadData">
                      <tr
                        v-for="(item, index) in items"
                        :id="item.id"
                        :key="item.id"
                      >
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                        >
                          {{ item.id }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                        >
                          <template
                            v-if="apiByModule.module === 'commitments'"
                          >
                            {{ item.type || "---" }}
                          </template>
                          <template v-else>
                            {{ parseFormatLabel(item.type) }}
                          </template>
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="containsModule(apiByModule.module)"
                        >
                          {{ item.situation.label }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.number_rule }}
                        </td>
                        <td
                          class="td-hover"
                          v-if="apiByModule.module === 'commitments'"
                          @click="openDialogUpdate('edit', item, index)"
                        >
                          {{ item.detail | stripHTML }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.standard_text | stripHTML }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.article_number }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                        >
                          {{ parseFormatLabel(item.project) }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="containsModuleWithoutContracts(apiByModule.module)"
                        >
                          {{ item.legal_base }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.entity_emisor }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.intern_area }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.periodicity }}
                        </td>
                        <td
                          class="td-hover"
                          @click="openDialogUpdate('edit', item, index)"
                          v-if="apiByModule.module === 'commitments'"
                        >
                          {{ item.main_obligations | stripHTML }}
                        </td>
                        <td
                          v-if="
                              roleUser !== 'visualizer' &&
                              roleUser !== 'executor'
                            "
                          class="text-center"
                        >
                          <v-btn
                            small
                            icon
                            depressed
                            @click="openDialogDelete(item, index)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <tr v-if="noResult" class="v-data-table__empty-wrapper">
                      <td :colspan="headers.length">
                        No se encontró resultados
                      </td>
                    </tr>
                    <tr
                      v-if="!loadData && !noResult"
                      class="v-data-table__empty-wrapper"
                    >
                      <td :colspan="headers.length">Esperando resultados</td>
                    </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="loadScroll && loadData"
            cols="12"
            class="pt-2 pb-2 text-center"
          >
            <v-progress-circular
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
            >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll && loadData"
            cols="12"
            class="pt-0 pb-2 text-center"
          >
            <span class="caption">No hay más registros.</span>
          </v-col>
        </vue-perfect-scrollbar>
        <module-detail-table
          v-if="loadData"
          class="type-submodule"
          :meta-data="metaData"
        ></module-detail-table>
      </v-row>
    </div>
    <!-- create ficha -->
    <dialog-detail-card
      ref="dialogDetailCard"
      v-model="dialogNew"
      :list-items="listItem"
      :type-dialog="typeDialog"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
      :selected-spij="selectedSpij"
      :load-draft="loadDraft"
      :flag-select-draft="flagSelectDraft"
      :extended-attributes="extendedAttributes"
      @create-active="onActiveNewItem"
      @save-draft="onSubmitDraft"
      @update-draft="onChangeLoadDraft"
      @close="onCloseDetailCard"
    />

    <dialog-draft
      ref="dialogDraft"
      v-model="dialogDraft"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
      @select="onSelectItemDraft"
    ></dialog-draft>

    <dialog-delete
      ref="dialogDelete"
      v-model="dialogDelete"
      title="Eliminar ficha"
      :is-module="true"
      :item="selectedItem"
      @delete="onSubmitDelete"
    ></dialog-delete>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {mapActions, mapState} from "vuex";
import mixinDraft from "@/components/modules/mixins/mixinDraft";
import mixinSort from "@/components/modules/mixins/mixinSort";
import mixinApiModule from "@/components/modules/mixins/mixinApiModule";
import mixinModuleCommon from "@/components/modules/mixins/mixinModuleCommon";
import ExportExcel from "@/components/commons/ExportExcel";
import DialogDraft from "@/components/commons/DialogDraft";
import DialogDelete from "@/components/commons/DialogDelete";
import ModuleDetailTable from "@/components/commons/ModuleDetailTable";
import FilterDrawer from "@/components/modules/FilterDrawer";
import {isEqual, cloneDeep, uniqBy} from "lodash";
import DialogDetailCard from "@/components/modules/DialogDetailCard.vue";
import { commonModules } from "../../utils/commonModules";

export default {
  name: "CommonModulePage",
  components: {
    DialogDelete,
    DialogDetailCard,
    DialogDraft,
    ModuleDetailTable,
    VuePerfectScrollbar,
    FilterDrawer,
    ExportExcel,
  },
  mixins: [mixinSort, mixinDraft, mixinApiModule, mixinModuleCommon],
  props: {
    fichaId: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    extendedAttributes: [],
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    scrollModuleSettings: {maxScrollbarLength: 160, suppressScrollX: true},
    disabledEdit: false,
    headers: [
      {
        text: "Código",
        class: "sticky-header",
        value: "id",
        width: 40,
        sortable: true,
      },
      {
        text: "Tipo",
        class: "sticky-header",
        value: "type",
        width: 40,
        sortable: true,
      },
      {
        text: "Situación",
        class: "sticky-header",
        value: "situation",
        width: 40,
        sortable: false,
      },
      {
        text: "N° de Norma",
        class: "sticky-header",
        value: "number_rule",
        width: 40,
        sortable: false,
      },
      {
        text: "Descripción de la norma",
        class: "sticky-header",
        value: "detail",
        width: 40,
        sortable: false,
      },
      {
        text: "Texto de la norma",
        class: "sticky-header",
        value: "standard_text",
        width: 40,
        sortable: false,
      },
      {
        text: "N° de artículo",
        class: "sticky-header",
        value: "article_number",
        width: 40,
        sortable: false,
      },
      {
        text: "Proyecto",
        class: "sticky-header",
        value: "project.label",
        sortable: true,
        width: 130,
      },
      {
        text: "Base Legal",
        class: "sticky-header",
        value: "legal_base",
        sortable: true,
        width: 130,
      },
      {
        text: "Entidad Emisora",
        class: "sticky-header",
        value: "entity_emisor",
        sortable: false,
        width: 130,
      },
      {
        text: "Área Interna",
        class: "sticky-header",
        value: "intern_area",
        sortable: false,
        width: 130,
      },
      {
        text: "Periodicidad",
        class: "sticky-header",
        value: "periodicity",
        sortable: false,
        width: 70,
      },
      {
        text: "Observaciones",
        class: "sticky-header",
        value: "main_obligations",
        sortable: false,
        width: 70,
      },
      {
        text: "Acción",
        class: "sticky-header",
        value: "actions",
        sortable: false,
        width: 50,
      },
    ],
    isComponentParent: true,
    selectedItem: null,
    cloneItem: null,
    dialogNew: false,
    dialogQuestion: false,
    loadingQuestion: false,
    dialogDetail: false,
    flagChangeForm: false,
    loadUpdate: false,
    typeDialog: "new",
    loadDetail: false,
    height: 100,
    loadScroll: false,
    isFinishedScroll: false,
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // export
    loadWord: false,
    // spij
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    // list
    listItem: [],
    extendedAttributes: [],
    loadData: false,
    metaData: {},
    noResult: false,
    filterData: "",
    paginationData: {},
    sortCommon: null,
    pages: 1,
    flagDelayScroll: false,
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
    }),
    configHeader() {
      if (this.roleUser === "visualizer" || this.roleUser === "executor") {
        const headTable = cloneDeep(this.headers);

        return headTable.splice(0, 5);
      }

      if (commonModules.includes(this.typeModule)) {
        const headTable = cloneDeep(this.headers);

        const filterArray = [
          "parts",
          "detail",
          "standard_text",
          "article_number",
          "entity_emisor",
          "intern_area",
          "periodicity",
          "main_obligations",
          "number_rule",
        ];

        const filterLegalBaseContracts = ['legal_base'];

        if (this.typeModule === 'contratos') {

          const filterHeadersContract = filterArray.concat(filterLegalBaseContracts);

          return headTable.filter(
            (header) => !filterHeadersContract.includes(header.value),
          ).concat();
        }

        return headTable.filter(
          (header) => !filterArray.includes(header.value),
        );
      }

      const headTable = cloneDeep(this.headers);

      const filterArray = ["legal_base"];

      return headTable.filter((header) => !filterArray.includes(header.value));
    },
  },
  watch: {
    typeModule(val) {
      if (!this.isEmpty(val)) {
        this.clearScrollTopTable();
        this.flagDelayScroll = false;
        this.clearCommonModule();
        const payload = `?response=lite&page=${this.pages}`;
        this.fetchCommonModule(payload, true);
        this.fetchExtendedAttributes()
      }
    },
    typeSubmodule(val) {
      if (!this.isEmpty(val)) {
        this.clearScrollTopTable();
        this.flagDelayScroll = false;
        this.clearCommonModule();
        const payload = `?response=lite&page=${this.pages}`;
        this.fetchCommonModule(payload, true);
        this.fetchExtendedAttributes()
      }
    },
  },
  created() {
    this.fetchAllUsers();
    this.fetchSettingSpij();
  },
  mounted() {
    this.clearScrollTopTable();
    this.flagChangeForm = false;
    window.addEventListener("resize", this.onResize);
    let query = "";
    if (this.fichaId) {
      query = `&search=${this.fichaId}`;
    }
    const payload = `?response=lite&page=${this.pages}${query}`;
    this.fetchCommonModule(payload, true);
    this.headers[1].text =
      this.typeModule === "compromisos" ? "Obligación" : "Tipo";
  },
  methods: {
    ...mapActions(["fetchAllUsers"]),
    containsModule(module) {
      return commonModules.includes(module);
    },
    containsModuleWithoutContracts(module) {
      const modules = commonModules.filter((commonModule) => commonModule !== 'contracts');

      return modules.includes(module);
    },
    fetchExtendedAttributes() {
      this.axios.get(`/api/extended_attributes_values`).then(({data}) => {
        if (data.success) {
          this.extendedAttributes = data.data;
        }
      });
    },
    onSearchFilter(payload, filterData) {
      this.listItem = [];
      this.filterData = filterData;
      this.pages = 1;
      this.noResult = false;
      this.fetchCommonModule(payload, true);
    },
    fetchCommonModule(payload, step = false) {
      if (step) {
        this.loadData = false;
      }

      this.axios
        .get(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}${payload}`,
        )
        .then(({data}) => {
          this.flagDelayScroll = true;
          this.loadScroll = false;
          this.pages = this.pages + 1;
          this.$refs.commonDrawer.setLoadSearch(false);
          if (data.success) {
            this.metaData = data.metadata;
            this.paginationData = data.pagination;
            if (!this.isEmpty(data.data)) {
              this.noResult = false;
              this.listItem = uniqBy([...this.listItem, ...data.data], "id");
              if (this.fichaId) {
                this.openDialogUpdate("edit", {id: this.fichaId});
              }
            } else {
              this.noResult = true;
            }
          } else {
            this.metaData = {
              total_situation_active: 0,
              total_situation_expired: 0,
              total_situation_in_process: 0,
            };
            this.listItem = [];
            this.noResult = true;
          }
          this.loadData = true;
          setTimeout(() => {
            this.flagDelayScroll = false;
          }, 500);
        });
    },
    scrollHanle() {
      if (!this.flagDelayScroll) {
        const {current_page, total_pages} = this.paginationData;
        if (this.loadData && current_page < total_pages) {
          this.loadData = true;
          let sortCommon = "";
          if (!this.isEmpty(this.sortCommon)) {
            sortCommon += `&sort=${this.sortCommon}`;
          }
          const payload = `?response=lite&page=${this.pages}${this.filterData}${sortCommon}`;
          this.flagDelayScroll = true;
          this.isFinishedScroll = false;
          this.loadScroll = true;
          this.fetchCommonModule(payload, false);
        }
        if (current_page === total_pages) {
          this.isFinishedScroll = true;
          // setTimeout(() => {
          //   this.isFinishedScroll = false;
          // }, 3500);
        }
      }
    },
    /*scrollHeight() {
      const cMain = document.getElementById("containerMain").clientHeight;
      const cTable = document.getElementById("containerTable").clientHeight;
      if (cMain >= cTable) {
        this.scrollHanle();
      }
    },*/
    onResize() {
      if (document.getElementById("containerTable")) {
        this.height = document.getElementById("containerTable").clientHeight;
      }
    },
    onCloseDialogQuestion(type = "close") {
      if (type === "save") {
        this.loadingQuestion = true;
        this.validateSubmitForm();
      } else {
        this.loadingQuestion = false;
        this.dialogQuestion = false;
        this.dialogNew = false;
      }
    },
    validUndefined(val) {
      return val === undefined ? null : val;
    },
    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? null : value.id;
      } else {
        return this.isEmpty(value) ? null : value;
      }
    },
    openDialogDelete(item, index) {
      this.deleteIndex = index;
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    onSubmitDelete() {
      this.$refs.dialogDelete.toggleLoad(true);
      this.axios
        .delete(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/delete`,
        )
        .then(() => {
          this.$refs.dialogDelete.toggleLoad(false);
          this.listItem.splice(this.deleteIndex, 1);
          this.dialogDelete = false;
        })
        .catch(() => {
          this.$refs.dialogDelete.toggleLoad(false);
        });
    },
    validateColor(status) {
      if (status.label) {
        const name = status.label.toLowerCase();
        const type =
          name === "en proceso"
            ? "process"
            : name === "vencido"
              ? "expired"
              : "active";
        return `row__${type}`;
      }
      return "";
    },
    clearCommonModule() {
      this.filterData = "";
      this.sortCommon = null;
      this.loadData = false;
      this.noResult = false;
      this.pagination = {};
      this.metaData = {};
      this.pages = 1;
      this.listItem = [];
    },
    onActiveNewItem() {
      this.noResult = false;
      this.axios(`/api/${this.apiByModule.module}/${this.apiByModule.submodule}?response=lite&page=1`).then(({data}) => {
        if (data.success) {
          this.$nextTick(() => {
            this.listItem = data.data;
          });
        }
      });
    },
    onCloseDetailCard() {
      this.selectedItem = this.getInterface();
      this.$refs.dialogDetailCard.clearErrors();
    },
    onClickModalAddFicha() {
      this.selectedItem = this.getInterface();
      this.disabledEdit = false;
      this.dialogDraft = false;
      this.typeDialog = "new";
      this.dialogNew = true;
      this.$nextTick(() => {
        this.$refs.dialogDetailCard.openDialogNew(this.selectedItem);
        this.$refs.dialogDetailCard.clearErrors();
      });
    },
    async openDialogUpdate(type = "edit", item) {
      this.typeDialog = type;
      this.flagSelectDraft = false;
      this.$refs.dialogDetailCard.openDialogUpdate("edit", item, false);
      this.dialogNew = true;
    },
    validateDialogDraft() {
      this.flagChangeForm = true;
    },
    onCloseDialogUpdate() {
      if (!isEqual(this.selectedItem, this.cloneItem)) {
        this.dialogQuestion = true;
      } else {
        this.dialogNew = false;
        this.dialogQuestion = false;
        this.selectedItem = this.getInterface();
      }
    },
    onChangeLoadDraft(flag) {
      this.loadDraft = flag;
    },
    async fetchSettingSpij() {
      const {data} = await this.axios.get(`/api/settings`);
      if (data.success) {
        this.selectedSpij = data.data;
      }
    },
    setLoadScroll(flag) {
      this.loadScroll = flag;
    },
    setFinishedScroll(flag) {
      this.isFinishedScroll = flag;
    },
  },
};
</script>
