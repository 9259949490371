// import { cloneDeep } from "lodash";
export default {
  data: () => ({
    // catastro
    catastro0: null,
    catastro1: null,
    // area reservada
    area0: null,
    area1: null,
    area2: null,
    area3: null,
    area4: null,
    area5: null,
    area6: null,
    area7: null,
    area8: null,
    area9: null,
    area10: null,
    area11: null,
    area12: null,
    area13: null,
    area14: null,
    area15: null,
    area16: null,
    area17: null,
    // catografia
    cartografia0: null,
    // catastro-query
    graphicsNivel0: null,
  }),
  methods: {
    formatSqlExpression(payload) {
      let sqlExpression = [];
      if (!this.isEmpty(payload.searchCode)) {
        sqlExpression.push(
          `upper(CODIGOU) like upper('%${payload.searchCode}%')`,
        );
      }
      if (!this.isEmpty(payload.searchName)) {
        sqlExpression.push(
          `upper(CONCESION) like upper('%${payload.searchName}%')`,
        );
      }
      if (!this.isEmpty(payload.searchOwner)) {
        sqlExpression.push(
          `upper(TIT_CONCES) like upper('%${payload.searchOwner}%')`,
        );
      }
      return sqlExpression.join(" AND ");
    },
    setOptionsGraphic(
      GraphicsLayer,
      // SimpleLineSymbol,
      // SimpleFillSymbol,
      // SimpleMarkerSymbol,
      // Color,
    ) {
      // Graphic Layer serarch
      this.graphicsNivel0 = new GraphicsLayer();
      this.symbolDpto = this.getSimpleFill([23, 41, 60, 1]);
    },
    activateLayersMap(checkLayers, FeatureLayer) {
      if (checkLayers.catastro) {
        if (!this.catastro1) {
          for (let index = 0; index < 2; index++) {
            this[`catastro${index}`] = new FeatureLayer(
              `${this.CATASTRO_LAYER}/${index}`,
            );
            this[`catastro${index}`].on("click", (evt) =>
              this.onClickGeocatminLayer(evt, index),
            );
            this.map.addLayer(this[`catastro${index}`]);
          }
        } else {
          for (let index = 0; index < 2; index++) {
            this[`catastro${index}`].setOpacity(1);
          }
        }
      } else {
        if (this.catastro1) {
          for (let index = 0; index < 2; index++) {
            this[`catastro${index}`].setOpacity(0);
          }
        }
      }
      if (checkLayers.area) {
        if (!this.area17) {
          for (let index = 0; index < 18; index++) {
            this[`area${index}`] = new FeatureLayer(
              `${this.AREA_RESERVADA_LAYER}/${index}`,
            );
            this[`area${index}`].on("click", (evt) =>
              this.onClickGeocatminLayer(evt, index),
            );
            this.map.addLayer(this[`area${index}`]);
          }
        } else {
          for (let index = 0; index < 18; index++) {
            this[`area${index}`].setOpacity(1);
          }
        }
      } else {
        if (this.area17) {
          for (let index = 0; index < 18; index++) {
            this[`area${index}`].setOpacity(0);
          }
        }
      }
      if (checkLayers.cartografia) {
        if (!this.cartografia0) {
          this.cartografia0 = new FeatureLayer(
            `${this.CARTOGRAFIA_DEMARCACION_LAYER}/0`,
          );
          this.cartografia0.on("click", (evt) =>
            this.onClickGeocatminLayer(evt, 0),
          );
          this.map.addLayer(this.cartografia0);
        } else {
          this.cartografia0.setOpacity(1);
        }
      } else {
        if (this.cartografia0) {
          this.cartografia0.setOpacity(0);
        }
      }
    },
  },
};
