<template>
  <v-col :cols="typeSubmodule === 'hidricos' ? 12 : 4" class="py-0">
    <!-- <h4 class="mb-2 card-input-title">Autoridad</h4> -->
    <v-form
      ref="formUpdateAuthority"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <!--  mb-3 -->
      <v-container fluid class="py-0 px-0">
        <v-row>
          <v-col :cols="typeSubmodule === 'hidricos' ? 4 : 12" class="py-0">
            <v-autocomplete
              v-model="selectedItem.authority"
              class="pt-0 mb-3 input-custom-56"
              :label="
                typeSubmodule === 'terrenos-erizados-del-estado'
                  ? 'Autoridad'
                  : 'Autoridad Nacional'
              "
              item-text="label"
              item-value="id"
              outlined
              height="56"
              :hide-details="isEmpty(listError.authority_id)"
              :rules="rulesNationalWater"
              :items="listAuto.options"
              :disabled="disabled"
              return-object
              @input="onChangeAuthority"
            ></v-autocomplete>
          </v-col>
          <template v-if="typeSubmodule === 'hidricos'">
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="selectedItem.water_administrative_authority"
                class="pt-0 mb-3 input-custom-56"
                :label="
                  typeSubmodule === 'terrenos-erizados-del-estado'
                    ? 'Autoridad'
                    : 'Autoridad Administrativa'
                "
                item-text="label"
                item-value="id"
                outlined
                height="56"
                :hide-details="
                  isEmpty(listError.water_administrative_authority_id)
                "
                :rules="rulesAdminWater"
                :items="optionsWaterAdministrativeAuthority.options"
                :loading="loadWaterAdmin"
                :disabled="disabled || isEmpty(selectedItem.authority)"
                return-object
                @input="onChangeWaterAdministrativeAuthority"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="selectedItem.local_water_authority"
                class="pt-0 mb-3 input-custom-56"
                :label="
                  typeSubmodule === 'terrenos-erizados-del-estado'
                    ? 'Autoridad'
                    : 'Autoridad Local'
                "
                item-text="label"
                item-value="id"
                outlined
                height="56"
                :hide-details="isEmpty(listError.local_water_authority_id)"
                :rules="rulesLocalWater"
                :items="optionsLocalWaterAuthority.options"
                :loading="loadLocalWater"
                :disabled="
                  disabled ||
                  isEmpty(selectedItem.water_administrative_authority)
                "
                return-object
                @input="onChangeLocalWaterAuthority"
              ></v-autocomplete>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-form>
  </v-col>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "WaterPermitAuthority",
  props: {
    selectedItem: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    listError: {
      type: Object,
      default: () => {},
    },
    typeModule: { type: String, default: "" },
    typeSubmodule: { type: String, default: "" },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    selectedAuthority: null,
    optionsWaterAdministrativeAuthority: { options: [] },
    selectedWaterAdministrativeAuthority: null,
    loadWaterAdmin: false,
    optionsLocalWaterAuthority: { options: [] },
    selectedLocalWaterAuthority: null,
    loadLocalWater: false,
    rulesNationalWater: [],
    rulesAdminWater: [],
    rulesLocalWater: [],
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
    }),
    listAuto() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-autoridad`;
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  methods: {
    ...mapActions(["fetchMasterByMaster", "fetchMasterOptionsByMaster"]),
    resetValidation() {
      this.$refs.formUpdateAuthority.resetValidation();
    },
    validateError(err) {
      const {
        authority_id = null,
        water_administrative_authority_id = null,
        local_water_authority_id = null,
      } = err;
      this.listError.authority_id = authority_id ? authority_id[0] : null;
      this.listError.water_administrative_authority_id = water_administrative_authority_id
        ? water_administrative_authority_id[0]
        : null;
      this.listError.local_water_authority_id = local_water_authority_id
        ? local_water_authority_id[0]
        : null;

      this.rulesNationalWater = this.listError.authority_id
        ? [() => this.listError.authority_id]
        : [];
      this.rulesAdminWater = this.listError.water_administrative_authority_id
        ? [() => this.listError.water_administrative_authority_id]
        : [];
      this.rulesLocalWater = this.listError.local_water_authority_id
        ? [() => this.listError.local_water_authority_id]
        : [];
      this.$refs.formUpdateAuthority.validate();
    },
    setAuthority(flag = true) {
      // this.selectedItem = selectedItem;
      // const {
      //   authority,
      //   water_administrative_authority,
      //   local_water_authority,
      // } = selectedItem;
      // this.selectedAuthority = authority;
      // this.selectedWaterAdministrativeAuthority = water_administrative_authority;
      // this.selectedLocalWaterAuthority = local_water_authority;
      if (this.selectedItem.authority) {
        // this.flagError = flag;
        this.validUpdate = flag;
        this.rulesNationalWater = [];
        this.rulesAdminWater = [];
        this.rulesLocalWater = [];
        if (flag) {
          this.resetValidation();
        }
        this.onChangeAuthority(this.selectedItem.authority, true);
      }
    },
    onChangeAuthority(item = null, flagInit = false) {
      this.loadWaterAdmin = false;
      this.loadLocalWater = false;
      if (!flagInit) {
        this.optionsWaterAdministrativeAuthority.options = [];
        this.selectedItem.water_administrative_authority = null;
        this.optionsLocalWaterAuthority.options = [];
        this.selectedItem.local_water_authority = null;
      }
      if (!this.isEmpty(item)) {
        this.loadWaterAdmin = true;
        this.fetchMasterByMaster(item.master_id).then((resp) => {
          this.rulesNationalWater = [];
          const list = resp.options.filter(
            (e) => e.master_id === item.master_id,
          );
          this.fetchMasterOptionsByMaster(list[0].id).then((secondResp) => {
            this.optionsWaterAdministrativeAuthority.options =
              secondResp.options;
            this.loadWaterAdmin = false;
            if (flagInit && this.typeSubmodule === "hidricos") {
              this.onChangeWaterAdministrativeAuthority(
                this.selectedItem.water_administrative_authority,
                true,
              );
            }
          });
        });
      }
      if (!flagInit) {
        this.$emit("change");
      }
    },
    onChangeWaterAdministrativeAuthority(item, flagInit = false) {
      if (!flagInit) {
        this.selectedItem.water_administrative_authority = item ? item : null;
      }
      this.loadLocalWater = false;
      this.optionsLocalWaterAuthority.options = [];
      this.listError.water_administrative_authority = "";
      if (item) {
        this.loadLocalWater = true;
        this.fetchMasterByMaster(item.master_id).then((resp) => {
          this.rulesAdminWater = [];
          const list = resp.options.filter((e) => e.id === item.id);
          this.fetchMasterOptionsByMaster(list[0].id).then((secondResp) => {
            this.optionsLocalWaterAuthority.options = secondResp.options;
            this.loadLocalWater = false;
          });
        });
      }
      this.$emit("change-form");
    },
    onChangeLocalWaterAuthority(item) {
      this.rulesLocalWater = [];
      this.selectedItem.local_water_authority = item ? item : null;
      this.listError.local_water_authority_id = "";
      this.$emit("change-form");
    },
    onEmitChange() {
      const payload = {
        authority: this.selectedAuthority,
      };
      if (this.typeSubmodule === "hidricos") {
        payload[
          "water_administrative_authority"
        ] = this.selectedWaterAdministrativeAuthority;
        payload["local_water_authority"] = this.selectedLocalWaterAuthority;
      }
      this.$emit("change", payload);
    },
  },
};
</script>
