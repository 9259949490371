import { indexOf } from "lodash";

export default {
  watch: {
    changeInstance: {
      deep: true,
      handler(obj) {
        let flag = false;
        for (let prop in obj) {
          if (obj[prop]) {
            flag = true;
            break;
          }
        }
        //console.log('cambio desde mixin', flag);
        this.$emit("flag-edit", { estado: flag, posicion: -1, bjar: "mixin" });
      },
    },
    incidencias: {
      deep: true,
      handler(newVal, oldVal) {
        newVal.forEach((incidencia, index) => {
          Object.keys(incidencia.changeInstance).forEach((key) => {
            if (incidencia.changeInstance[key]) {
              this.$nextTick(() => {
                this.$emit("flag-edit", {
                  estado: true,
                  posicion: index,
                  pasos_incidencias: true,
                });
              });
            }

            //if (incidencia.changeInstance[key] && oldVal[index].changeInstance[key] !== incidencia.changeInstance[key]) {
            // Emite un evento aquí, por ejemplo:
            //  }
          });
        });
      },
    },
  },
  mounted() {
    this.activeTab = 1;
    this.flagEdit = false;
  },
  methods: {
    areChangeInstancesEqual(oldInstance, newInstance) {
      // Compara cada propiedad de changeInstance
      return Object.keys(oldInstance).every(
        (key) => oldInstance[key] === newInstance[key]
      );
    },

    styleCursorStep(step = 1) {
      let flag = !this.isEmpty(
        this.instance[`${this.listNumber[step - 1]}`].id
      );
      return `cursor: ${flag ? "pointer" : "initial"}`;
    },
    /*Controla los tabs de las instancias */
    onValidateStep(step) {
      switch (step) {
        case 1:
          this.activeTab = step;
          break;
        case 2:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.one.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.two.id))
          )
            this.activeTab = step;
          break;
        case 3:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.two.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.three.id))
          )
            this.activeTab = step;
          break;
        case 4:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.three.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.four.id))
          )
            this.activeTab = step;
          break;
        case 5:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.four.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.five.id))
          )
            this.activeTab = step;
          break;
        case 6:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.five.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.six.id))
          )
            this.activeTab = step;
          break;
        case 7:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.six.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.seven.id))
          )
            this.activeTab = step;
          break;
        case 8:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.seven.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.eight.id))
          )
            this.activeTab = step;
          break;
        case 9:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.eight.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.nine.id))
          )
            this.activeTab = step;
          break;
        case 10:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.nine.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.ten.id))
          )
            this.activeTab = step;
          break;
        default:
          console.log(
            `Lo lamentamos, por el momento no disponemos de instancia_${step}.`
          );
      }
    },
    /*Controla los tabs de las incidencias */
    onValidateStepDinamico(step, position) {
      switch (step) {
        case 1:
          this.incidencias[position].activeTab = step;
          break;
        case 2:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_1.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_2.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 3:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_2.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_3.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 4:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_3.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_4.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 5:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_4.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_5.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 6:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_5.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_6.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 7:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_6.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_7.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 8:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_7.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_8.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 9:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_8.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_9.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        case 10:
          if (
            (!this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_9.id)) ||
            (this.disabledOptions &&
              !this.isEmpty(this.incidencias[position].instance_10.id))
          )
            this.incidencias[position].activeTab = step;
          break;
        default:
          console.log(
            `Lo lamentamos, por el momento no disponemos de instancia_${step}.`
          );
      }
    },

    /*Se ejecuta desde el componente padre */
    async validateStepByStep() {
      const listEditedStep = [];
      //console.log(this.changeInstance);
      for (let prop in this.changeInstance) {
        //Busca cual incidencia esta activa
        if (this.changeInstance[prop]) {
          listEditedStep.push(prop);
        }
      }
      if (listEditedStep.length) {
        if (listEditedStep.length === 1 && listEditedStep[0] === "one") {
          if (!this.instance.one.id) {
            await this.onSubmitStep(false);
          }
          //Aqui se registra el paso uno de la instancia principal
          this.validateStepInstance(2, "one");
        } else {
          for (let inx = 0; inx < listEditedStep.length; inx++) {
            //Se registran los demas pasos de la instancia
            //this.listNumber, contiene los nombres de los componentes de la instancia principal(one, two, etc
            const index = indexOf(this.listNumber, listEditedStep[inx]);
            // index es igual a la posicion en la que se encuentra y luego le suma dos para pasar al siguiente paso
            await this.nextInstance(index + 2, listEditedStep[inx]);
          }
        }
      } else if (!listEditedStep.length && this.flagEdit) {
        ///console.log(7);
        await this.onSubmitStep(false, -1);
      }
    },

    /*Se ejecutara dinamicamente */

    async validateStepByStepDinamicamente(position) {
      const listEditedStep = [];
      for (let prop in this.incidencias[position].changeInstance) {
        //Busca cual incidencia esta activa
        if (this.incidencias[position].changeInstance[prop]) {
          listEditedStep.push(prop);
        }
      }
      //Si encontro algun cambio lo coloca en un array
      if (listEditedStep.length) {
        if (listEditedStep.length === 1 && listEditedStep[0] === "one") {
          if (!this.incidencias[position].instance_1.id) {
            await this.onSubmitStep(false, position);
          }
          this.validateStepInstanceDinamicamente(2, "instance_1", position);
        } else {
          
          for (let inx = 0; inx < listEditedStep.length; inx++) {
           
            const keys = Object.keys(this.incidencias[position].changeInstance);
            const index = keys.indexOf(listEditedStep[inx]);
            await this.nextInstanceDinamicamente(
              index + 2,
              listEditedStep[inx],
              position,
            );
          }
        }
      } else if (!listEditedStep.length && this.incidencias[position].flagEdit) {
        //Se crea la primera instacia de la incidencia
        console.log(4);
        await this.onSubmitStep(false, position);
      }
    },

    validateStepInstanceDinamicamente(step, name, position) {



      if (this.isEmpty(this.incidencias[position][name].id)) {
        if (name === "instance_1") {
          this.validateStepOneDinamicamente(step - 1, name, position, name);
        } else {
          this.nextInstanceDinamicamente(step, name, position);
        }
      } else {
        console.log(5, step, name, position);
        this.nextInstanceDinamicamente(step, name, position);
      }
    },

    validateStepInstance(step, name) {
      if (this.isEmpty(this.instance[name].id)) {
        if (name === "one") {
          this.validateStepOne(step - 1, name);
        } else {
          this.nextInstance(step, name);
        }
      } else {
        this.nextInstance(step, name);
      }
    },

    async nextInstance(step, name) {
      if (this.changeInstance[name]) await this.validateStepOne(step - 1, name);
      else {
        if (this.stage === 1) {
          if (step === 10) {
            this.$emit("next", 2);
          } else {
            if (this.instance[name].id) {
              this.activeTab = step;
            } else {
              return false;
            }
          }
        } else if (this.stage === 2) {
          if (step === 6) {
            this.$emit("next", 3);
          } else {
            if (this.instance[name].id) {
              this.activeTab = step;
            } else {
              return false;
            }
          }
        } else {
          this.activeTab = step === 7 ? 6 : step;
        }
      }
    },

    obtenerValorInstancia(instance) {
      let data = {
        'instance_1': 'one',
        'instance_2': 'two',
        'instance_3': 'three',
        'instance_4': 'four',
        'instance_5': 'five',
        'instance_6': 'six',
        'instance_7': 'seven',
        'instance_8': 'eight',
        'instance_9': 'nine',
        'one':'one',
        'two':'two',
        'three':'three',
        'four':'four',
        'five':'five',
        'six':'six',
        'seven':'seven',
        'eight':'eight',
        'nine':'nine'
      };
      return data[instance];
    },

    async nextInstanceDinamicamente(step, name, position) {

      const propName = "instance_" + (step - 1);
      const instanceValue = this.incidencias[position][propName];

      const valor_estado = this.obtenerValorInstancia(name);

      if (this.incidencias[position].changeInstance[valor_estado]){
        await this.validateStepOneDinamicamente( step - 1, name, position,  propName, );
      } else {
        //this.stage hace referencia a los intancias: primera instancia. segunda instancia
        if (this.stage === 1) {
          if (step === 10) {
            this.incidencias[position].activeTab = 2;
            //this.$emit("next", 2);
          } else {
            if (instanceValue.id) {
              this.incidencias[position].activeTab = step;
            } else {
              return false;
            }
          }
        } else if (this.stage === 2) {
          if (step === 6) {
            this.incidencias[position].activeTab = 3;
          } else {
            if (instanceValue.id) {
              this.incidencias[position].activeTab = step;
            } else {
              return false;
            }
          }
        } else {
          this.incidencias[position].activeTab = step === 7 ? 6 : step;
        }
      }
    },

    async createInstance(step, name, position = -1, instance_name = "") {
     
      const formData = new FormData();
      this.$emit("flag-loading", true);
      let url = "";
      // Si es que existe position, entonces es por que la instancia debe ser creada en la incidencia
      if (position > -1) {
        formData.append(
          "attachment",
          this.incidencias[position][instance_name].attachment || ""
        );
        formData.append(
          "comments",
          this.incidencias[position][instance_name].comments || ""
        );
        const incidente_id = this.incidencias[position].id;
        // Se incializa en URL para crear instancia
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${incidente_id}/stage/${this.stage}/instance/incidencia/create`;

        // Se cambia la URL para actualizar la instancia si es que ta existe la instancia (instance_name) de la incidencia (incidencias[position])
        if (this.incidencias[position][instance_name]?.id) {
          url = `/api/${this.apiByModule.module}/stage/instance/${this.incidencias[position][instance_name].id}/update`;
        }
      } else {
        // Crea la instancia de la incidencia principal
        formData.append("attachment", this.payloadInstance[name].file || "");
        formData.append("comments", this.payloadInstance[name].comment || "");

        // Se incializa en URL para crear instancia
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/${this.stage}/instance/create`;

        // Se cambia la URL para actualizar la instancia si es que ta existe la instancia (instance_name) de la incidencia (incidencias[position])
        if (!this.isEmpty(this.instance[name]?.id)) {
          // formData.append("model_id", this.instance[name].id);
          // formData.append("stage", this.stage);
          url = `/api/${this.apiByModule.module}/stage/instance/${this.instance[name].id}/update`;
        }
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      });

      this.$set(this.changeInstance, name, false);
      this.$emit("flag-loading", false);

      if (data.success) {
        this.$set(this.instance, name, data.data);

        if (position > -1) {
          this.incidencias[position][instance_name] = data.data;
          //Si es una actualizacion, cambiar el estado FlagEdit a false
          console.log(instance_name, name);

          if (this.incidencias[position][instance_name]?.id){
            this.incidencias[position][instance_name].flagEdit = false;
            this.incidencias[position].changeInstance[name] = false;
          }else{
            console.log(444);
            this.incidencias[position].flagEdit = false;
          }

        } else {
          //si ya tenemos registrado el primer paso de la instacia entonces activamos el boton de agregar incidencia
          if (this.instance?.one?.id) {
            this.active_incidence = true;
          }
        }

        //console.log('bjar');

        // Solo si tiene todo lo envía
        if (this.payloadInstance[name]?.todo?.length) {
          await this.loopToDoStep(
            step,
            data.data.id,
            this.payloadInstance[name].todo,
            0,
            null,
            name
          );
        }

        this.activeTab = step ? step + 1 : 2;

        if(this.incidencias[position]){
          this.incidencias[position].activeTab = step ? step + 1 : 2;
        }
        if (step === this.countStep) {
          this.$emit("next", this.stage + 1);
        }
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.attachment) ? null : err.attachment[0],
          comment: this.isEmpty(err.comment) ? null : err.comment[0],
        };
        this.$refs[`instance${this.nameCapitalize(name)}`].setListError(
          payload
        );
      }
    },

    onUpdatePendingTask({ item, name }, instance) {
      this.loopToDoStep(
        instance,
        this.instance[name].id,
        [item],
        0,
        true,
        name
      );
    },

    async loopToDoStep(
      instance,
      id,
      checklist,
      index,
      flagChecklist = false,
      name
    ) {
      if (index === checklist.length) {
        this.$set(this.payloadInstance.four, "todo", []);
        return false;
      }
      const item = checklist[index];
      item.entity_id = id;
      // item["model_id"] = this.id;
      delete item.responsible;
      const { data } = await this.axios.post(
        `/api/${this.apiByModule.module}/stage/instance/${id}/to_do/create`,
        item
      );
      if (data.success) {
        if (flagChecklist) {
          this.instance[this.listNumber[instance - 1]].pending_tasks.push(
            data.data
          );
          const nameInstance = `instance${this.nameCapitalize(name)}`;
          this.$refs[nameInstance][0].updateLoadChecklist(false);
          this.$refs[nameInstance][0].setForm();
        } else if (flagChecklist === null) {
          const nameInstance = `instance${this.nameCapitalize(name)}`;
          this.instance[name].pending_tasks.push(data.data);

          this.$refs[nameInstance][0].setForm();
        }
        this.loopToDoStep(
          instance,
          id,
          checklist,
          index + 1,
          flagChecklist,
          name
        );
      }
    },

    async validateStepOne(step, name) {
      if (!this.selectedItem.id) {
        const data = await this.createInitStep();
        if (data.success) {
          this.$emit("change", data.data);
          this.createInstance(step, name);
        }
      } else {
        this.createInstance(step, name);
      }
    },

    async validateStepOneDinamicamente(step, name, position, instance_name) {
      //Si no existe la instancia,(primera, segunda, Casación) la crea
      if (!this.selectedItem.id) {
        const data = await this.createInitStep();
        if (data.success) {
          this.$emit("change", data.data);
          this.createInstance(step, name);
        }
      } else {
        ///console.log("validateStepOneDinamicamente", position, instance_name);
        this.createInstance(step, name, position, instance_name);
      }
    },

    async createInitStep() {
      const { data } = await this.axios.post(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/${this.stage}/create`
      );
      return data;
    },

    onUpdateInstance(nameInstance, payload) {
      this.$set(this.payloadInstance, nameInstance, payload);
    },
  },
};
