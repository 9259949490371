var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"parent--drawer",attrs:{"id":"adminDrawer","app":"","clipped":"","fixed":"","permanent":"","width":_vm.widthDrawer},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[(_vm.getMenu[0])?_c('v-navigation-drawer',{attrs:{"width":"60","mini-variant-width":"60","mini-variant":""}},[_c('vue-perfect-scrollbar',{staticClass:"drawer-menu--scroll",attrs:{"settings":_vm.scrollSettings}},[_c('v-list',{staticClass:"grow px-0",attrs:{"dense":"","nav":""}},[_vm._l((_vm.getMenu[0]),function(item){return [_c('v-list-item',{key:`menu-${item.key}`,class:`${_vm.validateActiveMenu(0, item)} py-0 px-1`,attrs:{"disabled":!_vm.validateRole(item),"ripple":false},on:{"click":function($event){return _vm.validateRoute(item, 0)}}},[_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"menu-link__status",attrs:{"ripple":false,"depressed":"","icon":""}},on),[_c('span',{staticClass:"drawer-icon-svg"},[_c('img',{attrs:{"src":`${
                            !_vm.validateRole(item)
                              ? `/img/icons/${item.disableIcon}`
                              : `${_vm.baseURL}/img/icons/white/${item.icon}`
                          }`}})])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.label))])])],1)],1)]})],2)],1)],1):_vm._e(),(_vm.showModuleDrawer)?[_c('modules-drawer',{attrs:{"modules":_vm.listModules}}),(
          _vm.validatePageForDrawer(_vm.$route.name) &&
          _vm.activeModule.slug !== 'propiedades-mineras'
        )?_c('modules-drawer',{attrs:{"modules":_vm.activeModule.submodules,"is-submodules":true}}):_vm._e()]:_vm._e(),(_vm.isSettings)?_c('settings-drawer'):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }