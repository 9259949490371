<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    content-class="dialog-custom dialog-todo"
    :fullscreen="isMobile"
    persistent
    open-on-focus
    scrollable
    @keydown.esc="onCloseDialog"
  >
    <v-card>
      <v-card-title
        class="py-1 title-detail-todo"
        :class="`to-do__${selectedItem.state.slug}`"
        style="height: auto; min-height: 58px;"
      >
        <div class="subheading mb-0" style="width: 100%;">
          To-Do
          <img
            v-if="selectedItem.alert"
            class="icon-alert"
            src="@/assets/icon-notification.png"
          />
        </div>
        <template v-if="selectedItem.id">
          <div v-if="!isEmpty(selectedItem.module)" class="caption mb-0">
            Módulo: {{ selectedItem.module.label }}<br />Submódulo:
            {{ selectedItem.submodule.label }}
          </div>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <vue-perfect-scrollbar
        id="scrollActivity"
        class="drawer-filter--scroll"
        :settings="scrollSettings"
      >
        <v-card-text id="checklistDetail" class="checklist-detailx pb-1">
          <div
            id="fixedDetail"
            class="fixed-detail"
            :class="{ active: activeFixed }"
          >
            <v-row v-if="!isEmpty(selectedItem)">
              <v-col cols="6" class="py-0 mb-0">
                <v-checkbox
                  v-model="selectedItem.check"
                  dense
                  :ripple="false"
                  color="primary"
                  :disabled="true"
                  class="mt-0 pt-0 float-left"
                  hide-details
                >
                  <template v-slot:label>
                    <p class="mb-0 subtitle-2 font-weight-bold">
                      To-Do completada
                    </p>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col
                v-if="selectedItem.id"
                cols="12"
                md="6"
                class="pb-0 mb-0 pt-1"
              >
                <p class="mb-0 d-inline-block font-weight-bold">Estado:</p>
                <div class="b-state ml-2">
                  <v-tooltip
                    right
                    :disabled="
                      !(validateColor(selectedItem.state.slug) === 'rojo')
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        class="b-state__item mr-2 circle-rojo"
                        :class="{
                          active:
                            validateColor(selectedItem.state.slug) === 'rojo',
                        }"
                        v-on="on"
                      ></div>
                    </template>
                    <span>{{
                      selectedItem.state.label | formatTooltipState
                    }}</span>
                  </v-tooltip>
                  <v-tooltip
                    right
                    :disabled="
                      !(validateColor(selectedItem.state.slug) === 'amarillo')
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        class="b-state__item mr-2 circle-amarillo"
                        :class="{
                          active:
                            validateColor(selectedItem.state.slug) === 'amarillo',
                        }"
                        v-on="on"
                      ></div>
                    </template>
                    <span>{{
                      selectedItem.state.label | formatTooltipState
                    }}</span>
                  </v-tooltip>
                  <v-tooltip
                    right
                    :disabled="
                      !(validateColor(selectedItem.state.slug) === 'verde')
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        class="b-state__item circle-verde"
                        :class="{
                          active:
                            validateColor(selectedItem.state.slug) === 'verde',
                        }"
                        v-on="on"
                      ></div>
                    </template>
                    <span>{{
                      selectedItem.state.label | formatTooltipState
                    }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" class="py-0 mb-5">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="py-0 mb-0">
                <v-text-field
                  v-model="selectedItem.title"
                  class="pt-0 input-custom-56"
                  label="Nombre de la tarea:"
                  outlined
                  height="56"
                  hide-details
                  :disabled="disabledEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 mb-1">
                <v-textarea
                  v-model="selectedItem.description"
                  class="pt-0 mb-4"
                  label="Descripción:"
                  outlined
                  hide-details
                  :disabled="disabledEdit"
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="py-0 mb-0">
                <selector-user
                  ref="responsibleMain"
                  v-model="selectedItem.responsible"
                  label="Responsable"
                  classes="pt-0 mb-3"
                  placeholder=""
                  :filter-ejecutor="true"
                  :clearable="false"
                  :disabled="disabledEdit"
                ></selector-user>
              </v-col>
              <v-col cols="6" class="py-0 mb-0">
                <v-text-field
                  class="pt-0 input-custom-56"
                  label="Asignado por:"
                  :value="
                    `${selectedItem.creator.first_name} ${selectedItem.creator.last_name}`
                  "
                  outlined
                  height="56"
                  hide-details
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0 mb-1">
                <v-menu
                  ref="pickerEnd"
                  v-model="showDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      slot="activator"
                      v-model="dateFormatted"
                      class="pt-0 input-custom-56"
                      label="Fecha de Vencimiento"
                      outlined
                      height="56"
                      hide-details
                      :disabled="disabledEdit"
                      append-icon="event"
                      @click:append="showDate = true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedItem.date"
                    no-title
                    scrollable
                    reactive
                    :show-current="true"
                    locale="es"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-show="!disabledEdit">
              <v-col cols="12" class="py-0">
                <v-row
                  :style="{
                    height: heightActivity > 1 ? `${heightActivity}px` : 'auto',
                  }"
                >
                  <v-col cols="12" class="py-0 my-0">
                    <p class="mb-0 font-weight-bold">Actividad:</p>
                    <activity-list
                      :id="selectedItem.id"
                      ref="activities"
                      :activities="selectedItem.activities"
                    ></activity-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </vue-perfect-scrollbar>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="disabledEdit"
          color="primary"
          small
          depressed
          dark
          class="btn-transform-none"
          @click="disabledEdit = false"
          >Habilitar edición</v-btn
        >
        <v-btn
          v-if="!selectedItem.check"
          color="primary"
          text
          small
          :disabled="disabledEdit"
          depressed
          class="btn-transform-none"
          @click="dialogRealized = true"
          >Realizado</v-btn
        >
        <v-btn
          v-if="!selectedItem.alert && isAdmin && !selectedItem.check"
          color="red"
          text
          small
          depressed
          class="btn-transform-none"
          :disabled="loadAlert || disabledEdit"
          @click="dialogAlert = true"
          >Alertar!</v-btn
        >
        <v-btn
          v-if="fromToDo"
          color="primary"
          text
          small
          class="btn-transform-none"
          @click="onRedirectToCard"
          >Ir a la ficha</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="btn-transform-none"
          color="action"
          small
          text
          @click="onCloseDialog()"
          >Cerrar</v-btn
        >
        <!--
            :loading="loadUpdate"
            :disabled="loadUpdate"-->
        <v-btn
          color="action"
          :disabled="disabledEdit"
          :dark="!disabledEdit"
          depressed
          small
          class="btn-transform-none"
          @click="onSubmitEdit()"
        >
          <v-progress-circular
            v-if="loadEdit"
            :size="20"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadEdit ? "Guardando" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialogRealized"
      max-width="350"
      content-class="dialog-custom"
      persistent
      @keydown.esc="dialogRealized = false"
    >
      <v-card>
        <v-card-title class="py-1 title-detail-todo">
          <div class="subheading mb-0" style="width: 100%;">
            Realizar tarea
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4">
          <v-row>
            <v-col cols="12" class="py-0">
              <div class="mb-0">
                ¿Está seguro que el To-Do está completo?
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            color="action"
            small
            text
            @click="dialogRealized = false"
            >Cancelar</v-btn
          >
          <v-btn
            class="btn-transform-none"
            color="action"
            dark
            depressed
            small
            @click="onSubmitRealized()"
            ><v-progress-circular
              v-if="loadUpdate"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAlert"
      max-width="350"
      content-class="dialog-custom"
      persistent
      @keydown.esc="dialogAlert = false"
    >
      <v-card>
        <v-card-title class="py-1 title-detail-todo">
          <div class="subheading mb-0" style="width: 100%;">
            Alerta
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4">
          <v-row>
            <v-col v-if="item" cols="12" class="py-0">
              <div class="mb-0">
                ¿Estás de acuerdo con enviar una alerta y notificar a
                {{ item.responsible.first_name }}
                {{ item.responsible.last_name }}?
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            color="action"
            small
            text
            @click="dialogAlert = false"
            >Cancelar</v-btn
          >
          <v-btn
            class="btn-transform-none"
            color="action"
            dark
            depressed
            small
            @click="onSubmitAlert()"
            ><v-progress-circular
              v-if="loadAlert"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SelectorUser from "@/components/commons/SelectorUser";
import { mapActions, mapState } from "vuex";
import { IToDo } from "@/models";
import { MenuAdmin } from "@/api/menu";
import { isMobile } from "mobile-device-detect";
import ActivityList from "./ActivityList";

export default {
  name: "DialogDetailTodo",
  components: {
    VuePerfectScrollbar,
    ActivityList,
    SelectorUser,
  },
  filters: {
    formatTooltipState(val) {
      return val.split(" . ")[1];
    },
  },
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    fromToDo: {
      type: Boolean,
      default: false,
    },
    isDrafts: {
      type: Boolean,
      default: false,
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    typeField: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isMobile,
    showDate: false,
    scrollSettings: {
      maxScrollbarLength: 100,
      suppressScrollX: true,
    },
    dialogRealized: false,
    dialogAlert: false,
    disabledEdit: true,
    loadUpdate: false,
    loadEdit: false,
    selectedItem: new IToDo(),
    listItem: MenuAdmin,
    // alert
    loadAlert: false,
    activeFixed: false,
    paddingTop: 100,
    heightActivity: 1,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
    dateFormatted() {
      return this.formatDate(this.parseFormatDate(this.selectedItem.date));
    },
    isAdmin() {
      if (this.currentUser) {
        const { role } = this.currentUser;
        return role.slug === "super-admin" || role.slug === "admin";
      }
      return "";
    },
  },
  watch: {
    item(val) {
      this.selectedItem = this.isEmpty(val) ? new IToDo() : val;
      this.$nextTick(() => {
        this.calculatePaddingTop();
      });
    },
  },
  methods: {
    ...mapActions([
      "editTodos",
      "clearActiveModule",
      "setActiveModules",
      "setActiveSubModules",
    ]),
    calculatePaddingTop() {
      setTimeout(() => {
        const hDetail = document.getElementById("checklistDetail").clientHeight;
        const hfixed = document.getElementById("fixedDetail").clientHeight;
        this.heightActivity = Math.abs(hDetail - hfixed);
        // this.paddingTop = hfixed;
        // const hfixed = document.getElementById("fixedDetail").clientHeight;
      }, 200);
    },
    onFixedDetail() {
      this.activeFixed = true;
    },
    onCloseDialog() {
      this.$refs.activities.clearActivityForm();
      this.$nextTick(() => {
        this.$emit("change", false);
        this.heightActivity = 1;
        // this.item = new IToDo();
      });
    },
    async onSubmitRealized() {
      if (this.loadUpdate) return false;
      this.loadUpdate = true;
      // const payload = {
      //   to_do_id: this.selectedItem.id,
      //   entity_id: this.selectedItem.entity_id,
      //   module_id: this.selectedItem.module_id,
      //   submodule_id: this.selectedItem.submodule_id,
      //   check: 1,
      // };
      this.axios
        .post(`/api/to_dos/${this.selectedItem.id}/finish`)
        .then(({ data }) => {
          if (data.success) {
            this.loadUpdate = false;
            this.selectedItem.check = 1;
            this.dialogRealized = false;
            this.$emit("update", data.data);
          }
        });
    },
    async onSubmitAlert() {
      if (this.loadAlert) {
        return false;
      }
      this.loadAlert = true;
      const { data } = await this.axios.post(
        `/api/to_dos/${this.selectedItem.id}/alert`,
      );
      this.loadAlert = false;
      if (data.success) {
        this.selectedItem.alert = 1;
        this.$set(this.selectedItem, "alert", 1);
        this.dialogAlert = false;
        this.$emit("alert");
      }
    },
    clearActivities() {
      this.$nextTick(() => {
        this.$refs.activities.clearActivities();
      });
    },
    validateColor(status) {
      if (!this.isEmpty(status)) {
        return status.toLowerCase();
      }
      return "";
    },
    setTodo(item) {
      this.selectedItem = item;
    },
    onRedirectToCard() {
      const menu = {
        icon: "icon-modulos.png",
        label: "Módulos",
        key: "modules",
        leaf: false,
        submenu: [],
      };
      this.clearActiveModule("all");
      this.$store.dispatch(`menuAdmin/setMenu`, {
        menu: this.listItem,
        init: true,
        index: 0,
      });
      this.$store.dispatch(`menuAdmin/setMenuActive`, menu);
      this.$store.dispatch(`menuAdmin/setListActive`, {
        active: menu,
        init: true,
        index: 0,
      });
      this.setStoreMenu(this.item.module);
      this.setStoreMenu(this.item.submodule);
      if (this.isNotification) {
        this.$emit("change", false);
        this.resetDialog();
      }
      this.$router.push({
        name: "SwitchModulePage",
        params: {
          typeModule: this.item.module.slug,
          typeSubmodule: this.item.submodule.slug,
        },
        query: {
          ficha: this.item.entity_id,
        },
      });
    },
    setStoreMenu(item) {
      if (item.slug === "propiedades-mineras") {
        this.setActiveModules(item);
      } else {
        if (item.submodules) {
          this.setActiveModules(item);
        } else {
          this.setActiveSubModules(item);
        }
      }
    },
    async onSubmitEdit() {
      if (this.loadEdit) return false;
      const payload = {
        title: this.selectedItem.title,
        description: this.selectedItem.description,
        responsible_id: this.selectedItem.responsible.id,
        date: this.selectedItem.date,
        // module_id: this.activeModule.id,
        // submodule_id: this.activeSubModule.id,
      };
      const urlDrafts = this.isDrafts ? "_drafts" : "s";
      this.loadEdit = true;
      const { data } = await this.axios.post(
        `/api/to_do${urlDrafts}/${this.selectedItem.id}/update`,
        payload,
      );
      if (data.success) {
        this.loadEdit = false;
        this.$emit("edit-todo", data.data);
        this.$emit("change", false);
        this.resetDialog();
        this.$toast.success("El todo ha sido actualizado correctamente.")
      } else {
        this.listErrorUser(data.error.data);
      }
    },
    parseFormatDate(val) {
      return val ? val.split(" ")[0] : null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
    resetDialog() {
      this.dialogRealized = false;
      this.dialogAlert = false;
      this.disabledEdit = true;
      this.loadUpdate = false;
      this.loadEdit = false;
      this.loadAlert = false;
      this.activeFixed = false;
    },
  },
};
</script>
