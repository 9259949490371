const Todos = [
  {
    name: "Tarea 1",
    module: "Permisos",
    submodule: "Hidrocarburos",
    deadline: "Hace 2 días",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, cupiditate eum omnis nesciunt, alias corporis, illo delectus eaque molestias id officia eveniet quae quod repudiandae quo totam quibusdam corrupti eius.",
    className: "row__process",
    id: "task_1",
  },
  {
    name: "Tarea 2",
    module: "Contratos",
    submodule: "Contratistas",
    deadline: "Hace 5 horas",
    situation: "Vigente",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum, beatae blanditiis dolorum suscipit corrupti magni unde maxime, molestiae est, voluptatum voluptate eveniet dolorem distinctio recusandae maiores optio autem? Asperiores, laboriosam?",
    className: "row__active",
    id: "task_2",
  },
  {
    name: "Tarea 3",
    module: "Compromisos",
    submodule: "Obligaciones técnicas",
    deadline: "Hace 2 horas",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla soluta harum, recusandae consequuntur commodi blanditiis beatae saepe, ratione doloribus necessitatibus dolor ut, nemo impedit temporibus error omnis animi! Modi, autem.",
    className: "row__process",
    id: "task_3",
  },
  {
    name: "Tarea 4",
    module: "Seguridad y salud ocupacional",
    submodule: "Obligaciones formales",
    deadline: "13/02/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore rem amet illum voluptatum? Quaerat minus sunt repudiandae veritatis quibusdam consequatur vel dolor officia odio exercitationem culpa, facilis dolorum aliquam! Harum.",
    className: "row__active",
    id: "task_4",
  },
  {
    name: "Tarea 5",
    module: "Ambiental",
    submodule: "OSINERGMIN",
    deadline: "Hace 2 días",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, cupiditate eum omnis nesciunt, alias corporis, illo delectus eaque molestias id officia eveniet quae quod repudiandae quo totam quibusdam corrupti eius.",
    className: "row__process",
    id: "task_5",
  },
  {
    name: "Tarea 6",
    module: "Superviciones",
    submodule: "Autorizaciones",
    deadline: "08/02/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum, beatae blanditiis dolorum suscipit corrupti magni unde maxime, molestiae est, voluptatum voluptate eveniet dolorem distinctio recusandae maiores optio autem? Asperiores, laboriosam?",
    className: "row__active",
    id: "task_6",
  },
  {
    name: "Tarea 7",
    module: "Permisos",
    submodule: "Explosivos",
    deadline: "Hace 6 horas",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla soluta harum, recusandae consequuntur commodi blanditiis beatae saepe, ratione doloribus necessitatibus dolor ut, nemo impedit temporibus error omnis animi! Modi, autem.",
    className: "row__process",
    id: "task_7",
  },
  {
    name: "Tarea 8",
    module: "Permisos",
    submodule: "Insumos quimicos",
    deadline: "10/01/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore rem amet illum voluptatum? Quaerat minus sunt repudiandae veritatis quibusdam consequatur vel dolor officia odio exercitationem culpa, facilis dolorum aliquam! Harum.",
    className: "row__active",
    id: "task_8",
  },
];

const Alerts = [
  {
    name: "Tarea 1",
    deadline: "Hace 2 días",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, cupiditate eum omnis nesciunt, alias corporis, illo delectus eaque molestias id officia eveniet quae quod repudiandae quo totam quibusdam corrupti eius.",
    className: "row__process",
    id: "task_1",
  },
  {
    name: "Tarea 2",
    deadline: "Hace 12 día",
    situation: "Vigente",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum, beatae blanditiis dolorum suscipit corrupti magni unde maxime, molestiae est, voluptatum voluptate eveniet dolorem distinctio recusandae maiores optio autem? Asperiores, laboriosam?",
    className: "row__active",
    id: "task_2",
  },
  {
    name: "Tarea 3",
    deadline: "Hace 5 horas",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla soluta harum, recusandae consequuntur commodi blanditiis beatae saepe, ratione doloribus necessitatibus dolor ut, nemo impedit temporibus error omnis animi! Modi, autem.",
    className: "row__process",
    id: "task_3",
  },
  {
    name: "Tarea 4",
    deadline: "13/02/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore rem amet illum voluptatum? Quaerat minus sunt repudiandae veritatis quibusdam consequatur vel dolor officia odio exercitationem culpa, facilis dolorum aliquam! Harum.",
    className: "row__active",
    id: "task_4",
  },
  {
    name: "Tarea 5",
    deadline: "Hace 2 días",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, cupiditate eum omnis nesciunt, alias corporis, illo delectus eaque molestias id officia eveniet quae quod repudiandae quo totam quibusdam corrupti eius.",
    className: "row__process",
    id: "task_5",
  },
  {
    name: "Tarea 6",
    deadline: "08/02/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum, beatae blanditiis dolorum suscipit corrupti magni unde maxime, molestiae est, voluptatum voluptate eveniet dolorem distinctio recusandae maiores optio autem? Asperiores, laboriosam?",
    className: "row__active",
    id: "task_6",
  },
  {
    name: "Tarea 7",
    deadline: "Hace 6 horas",
    situation: "En proceso",
    comment:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla soluta harum, recusandae consequuntur commodi blanditiis beatae saepe, ratione doloribus necessitatibus dolor ut, nemo impedit temporibus error omnis animi! Modi, autem.",
    className: "row__process",
    id: "task_7",
  },
  {
    name: "Tarea 8",
    deadline: "10/01/2019",
    situation: "Vigente",
    comment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore rem amet illum voluptatum? Quaerat minus sunt repudiandae veritatis quibusdam consequatur vel dolor officia odio exercitationem culpa, facilis dolorum aliquam! Harum.",
    className: "row__active",
    id: "task_8",
  },
];

Todos.push(...Todos);

export { Todos, Alerts };
