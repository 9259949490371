// import { capitalize } from "lodash";
import { mapState } from "vuex";
Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
export default {
  data() {
    return {
      scrollSettings: { maxScrollbarLength: 160 },
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
    roleUser() {
      if (this.isEmpty(this.currentUser)) {
        return "";
      }
      return this.currentUser.role.slug;
    },
  },
  methods: {
    onScrollY(e, flagModule) {
      if (e.target) {
        const val =
          e.target.scrollTop <= 12
            ? e.target.scrollTop
            : flagModule
            ? e.target.scrollTop - 61
            : e.target.scrollTop - 13;
        this.clearScrollTopTable(val);
      }
    },
    clearScrollTopTable(value = 0) {
      document.documentElement.style.setProperty(
        "--toolbarHeight",
        `${value}px`
      );
    },
    goToScroll(element, container) {
      const containerID = document.getElementById(container);
      const elementID = document.getElementById(element);
      containerID.scrollTop = elementID.offsetHeight + 50;
      // if (elementID.offsetTop < containerID.scrollTop) {
      //   containerID.scrollTop = elementID.offsetTop;
      // } else {
      //   const offsetBottom = elementID.offsetTop + elementID.offsetHeight;
      //   const scrollBottom = containerID.scrollTop + containerID.offsetHeight;
      //   if (offsetBottom > scrollBottom) {
      //     containerID.scrollTop = offsetBottom - containerID.offsetHeight;
      //   }
      // }
    },
    scrollToMaster(element, to, duration) {
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    },
    slugify(str) {
      let parteLabel = str.replace(/\s/g, "");
      parteLabel = parteLabel.replace(/,/g, "");
      parteLabel = parteLabel.replace(/\)/g, "");
      parteLabel = parteLabel.replace(/\(/g, "");
      const map = {
        a: "á|à|ã|â|ä|A|À|Á|Ã|Â|Ä",
        e: "é|è|ê|ë|E|É|È|Ê|Ë",
        i: "í|ì|î|ï|I|Í|Ì|Î|Ï",
        o: "ó|ò|ô|õ|ö|O|Ó|Ò|Ô|Õ|Ö",
        u: "ú|ù|û|ü|U|Ú|Ù|Û|Ü",
      };

      parteLabel = parteLabel.toLowerCase();

      for (const pattern in map) {
        parteLabel = parteLabel.replace(new RegExp(map[pattern], "g"), pattern);
      }
      return parteLabel;
    },
    formatNumberWithCommas(x) {
      return x.toString().replace(/B(?=(d{3})+(?!d))/g, ",");
    },
    generateNameRandom() {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    },
    formatPercentage(value, total) {
      return `${((value / total) * 100).toFixed(2)}%`;
    },
    parseSlugModule(val) {
      let label = val.split("-").join(" ");
      label = label === "hidricos" ? "hídricos" : label;
      if (label.includes("supervisiones ")) {
        return `Supervisiones ${label.split(" ")[1].toUpperCase()}`;
      }
      return this.$store.state.modules.activeSubModule.label;
      // capitalize(label);
    },
    formatNameModule(name) {
      return name.split("-").join("");
    },
    parseFormatLabel(val) {
      return val ? val.label : "---";
    },
    formatDateTable(val) {
      if (val === null) return "---";
      const d = new Date(val);
      const dformat = [
        d.getFullYear(),
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
      ].join("-");
      return dformat;
    },
    nameCapitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    parseStringToNumber(val) {
      return val !== null || val !== undefined ? parseInt(val) : "";
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    validateDateFormat(fullDate) {
      if (this.isEmpty(fullDate) || typeof(fullDate) === 'number') {
        return "";
      }
      return fullDate.split(" ")[0];
    },
    dynamicColors() {
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    parseFilterReport(val) {
      let arr = val.split("&");
      arr = arr.splice(1, arr.length - 1);
      const payload = {
        module_id: null,
        submodule_id: null,
        project_id: null,
        primary_responsible_id: null,
      };
      arr.forEach((e) => {
        const part = e.split("=");
        payload[part[0]] = part[1];
      });
      return payload;
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    generateTooltipSearch(typeModule, submodulo = null) {
      if (typeModule === "permisos") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>tipo, proyecto, responsable, situación, descripción, y base legal)";
      } else if (typeModule === "contratos") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>tipo, proyecto, responsable, situación, descripción y partes)";
      } else if (typeModule === "compromisos") {
        var nuevos_campos = "";
        if (submodulo == "obligaciones-tecnicas") {
          nuevos_campos =
            ", autoridad competente, plazo legal, plazo estimado y consecuencia de incumplimento";
        }
        if (submodulo == "obligaciones-formales") {
          nuevos_campos = ", medidas correctivas y observaciones";
        }
        if (submodulo == "obligaciones-internas" || submodulo == "laboral") {
          nuevos_campos = ", sanciones OSINERMING y observaciones";
        }
        return (
          "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>obligación, proyecto, responsables" +
          nuevos_campos +
          ")"
        );
      } else if (typeModule === "seguridad") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>tipo, proyecto, responsable, situación y descripción)";
      } else if (typeModule === "ambiental") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>tipo, proyecto, responsable, situación y descripción)";
      } else if (typeModule === "supervisiones") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>proyecto, responsable, situación y autoridad)";
      } else if (typeModule === "procesos-judiciales") {
        return "Busqueda por palabra en las fichas (La búsqueda se realizará en los campos:<br/>proyecto, responsable, demandante, demandado y estudio a cargo)";
      } else if (typeModule === "propiedades-mineras") {
        return "La búsqueda se realizará en todos los campos.<br/>En caso de querer buscar un código, ingresar el código completo de la ficha";
      }
    },
  },
};
