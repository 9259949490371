export class IToDo {
  constructor() {
    this.id = null;
    this.entity = {};
    this.check = 0;
    this.alert = 0;
    this.state = {
      label: "",
    };
    this.order = null;
    this.title = null;
    this.description = null;
    this.creator = {
      first_name: "",
      last_name: "",
    };
    this.responsible = {
      id: null,
      first_name: "",
      last_name: "",
    };
    this.date = "";
    this.module = {
      label: "",
    };
    this.submodule = {
      label: "",
    };
    this.activities = [];
  }
}
