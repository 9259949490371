import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: {},
    },
    options: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    chartData() {
      this.$data._chart.update();
      // this.renderChartReport();
    },
  },
  mounted() {
    // this.$data._chart.destroy();
    this.renderChartReport();
  },
  methods: {
    renderChartReport() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        // maintainAspectRatio: false,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        // legend: {
        //   position: "top",
        // },
        title: {
          display: true,
          text: this.title,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: this.showLegend,
          position: "top",
          align: "end",
          labels: {
            fontColor: "rgb(255, 99, 132)",
          },
        },
      };
      // this.$data._chart.destroy();
      this.renderChart(this.chartData, options);
      // this.$nextTick(() => {
      //   this.$data._chart.update();
      // });
    },
  },
};
