<template>
  <v-container fluid class="py-0 px-0">
    <h3 class="d-inline-block mb-0 card-input-title mb-3">
      Versiones del contrato <v-icon @click="onAddContractVersion">add</v-icon>
    </h3>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="d-inline-block mb-0 card-input-title mb-3">Versión 1</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                class="pt-0 input-custom-56"
                label="Nombre de version"
                height="56"
                outlined
                hide-details
                :readonly="disabledOptions"
                v-model="versionNameModel"
                @input="onChangeVersionName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 mb-3">
              <p class="mb-1 card-input-title">Sustento*</p>
              <upload-file
                ref="versionSupportFile"
                :item-file="versionSupportFile"
                :disabled="disabledOptions"
                :is-multiple="false"
                :is-table="true"
                @update="onChangeSupportFile($event, 'version_support_file')"
              ></upload-file>
            </v-col>
            <v-col cols="12" class="py-0 mb-3">
              <p class="mb-1 card-input-title">Archivo*</p>
              <upload-file
                ref="versionFile"
                :item-file="versionFile"
                :disabled="disabledOptions"
                :is-multiple="false"
                :is-table="true"
                @update="onChangeVersionFile($event, 'version_file')"
              ></upload-file>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu
                v-model="showVersionDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    slot="activator"
                    v-model="dateFormattedVersion"
                    label="Fecha de versión*"
                    class="my-0 pt-0 input-custom-56"
                    style="margin-bottom: 0 !important;"
                    outlined
                    height="56"
                    append-icon="event"
                    :readonly="disabledOptions"
                    @click:append="showVersionDate = true"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="versionDateModel"
                  :show-current="true"
                  locale="es"
                  no-title
                  scrollable
                  reactive
                  :allowed-dates="allowedDates"
                  :disabled="disabledOptions"
                  @input="onChangeDateVersion"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(item, index) in versionItems" :key="index" v-if="versionItems.length > 0">
        <v-expansion-panel-header>
          <h3 class="d-inline-block mb-0 card-input-title mb-3">Versión {{ index + 2 }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                class="pt-0 input-custom-56"
                label="Nombre de version"
                height="56"
                outlined
                hide-details
                v-model="item.versionName"
                @input="onChangeVersionNameInMultiple($event, `version_name_${index+1}`)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 mb-3">
              <p class="mb-1 card-input-title">Sustento*</p>
              <upload-file
                ref="versionSupportFile"
                :item-file="item.versionSupportFile"
                :disabled="disabledOptions"
                :is-multiple="false"
                :is-table="true"
                @update="onChangeSupportFileInMultiple($event, `version_support_file_${index+1}`)"
              ></upload-file>
            </v-col>
            <v-col cols="12" class="py-0 mb-3">
              <p class="mb-1 card-input-title">Archivo*</p>
              <upload-file
                ref="versionFile"
                :item-file="item.versionFile"
                :disabled="disabledOptions"
                :is-multiple="false"
                :is-table="true"
                @update="onChangeVersionFileInMultiple($event, `version_file_${index+1}`)"
              ></upload-file>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu
                v-model="item.showVersionDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    slot="activator"
                    label="Fecha de versión*"
                    class="my-0 pt-0 input-custom-56"
                    style="margin-bottom: 0 !important;"
                    outlined
                    height="56"
                    v-model="item.versionDate"
                    append-icon="event"
                    readonly
                    @click:append="item.showVersionDate = true"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :show-current="true"
                  locale="es"
                  no-title
                  scrollable
                  reactive
                  v-model="item.versionDate"
                  :allowed-dates="allowedDates"
                  @input="onChangeDateVersionInMultiple($event, `version_date_${index+1}`)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import UploadFile from "@/components/commons/UploadFile.vue";

export default {
  name: 'ContractVersionSection',
  components: {UploadFile},
  data: () => ({
    lazyUpdate: false,
    validUpdate: false,
    showVersionDate: false,
    showVersionMultiple: false,
    versionDateModel: null,
    versionNameModel: null,
    versionItems: [],
  }),
  props: {
    versionName: {type: String, default: ""},
    versionFile: { default: null, },
    versionSupportFile: { default: null, },
    versionDate: {type: String, default: ""},
    disabledOptions: {type: Boolean, default: false},
    selectedItem: {
      type: Object, default: () => {
      }
    },
  },
  computed: {
    dateFormattedVersion() {
      return this.formatDate(this.versionDateModel);
    },
  },
  watch: {
    versionName(val) {
      this.versionNameModel = val;
    },
    versionDate(val) {
      this.versionDateModel = val;
    },
    selectedItem(val) {
      if (val !== undefined) {
        if (val.versions.length > 0) {
          this.versionItems = val.versions.map((version) => ({
            versionName: version.version_name,
            versionDate: version.version_date,
            versionFile: version.version_file,
            versionSupportFile: version.version_support_file,
            showVersionDate: false,
          }));

          this.$emit('setMultipleVersionForm', this.versionItems);
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },
    onAddContractVersion() {
      if (this.versionItems.length < 3) {
        this.versionItems.push({
          versionName: '',
          versionSupportFile: null,
          versionFile: null,
          versionDate: null,
          showVersionDate: false
        });
      } else {
        this.$toast.warning('¡Ooops! Excediste el límite de versiones para este contrato, solo admitimos hasta cuatro versiones.')
      }
    },
    onChangeVersionFile(file, type) {
      if (type === "version_file") {
        this.$emit('versionFile', file);
      }
    },
    onChangeSupportFile(file, type) {
      if (type === "version_support_file") {
        this.$emit('versionSupportFile', file);
      }
    },
    onChangeDateVersion(version_date) {
      this.versionDateModel = version_date ? version_date : null;
      this.showVersionDate = false;

      this.$emit('version-date', version_date);
    },
    onChangeVersionName(name) {
      this.versionNameModel = name;

      this.$emit('versionName', name);
    },
    onChangeVersionNameInMultiple(name, field) {
      const index = field.split('_').pop() - 1;
      this.versionItems[index].versionName = name;

      if (field === 'version_name_1') {
        this.$emit('versionMultipleName', { value: name, field });
      }

      if (field === 'version_name_2') {
        this.$emit('versionMultipleName', { value: name, field });
      }

      if (field === 'version_name_3') {
        this.$emit('versionMultipleName', { value: name, field });
      }
    },
    onChangeDateVersionInMultiple(version_date, field) {
      const index = field.split('_').pop() - 1;
      this.versionItems[index].versionDate = version_date;
      this.versionItems[index].showVersionDate = false;

      if (field === 'version_date_1') {
        this.$emit('versionMultipleDate', { value: version_date, field });
      }

      if (field === 'version_date_2') {
        this.$emit('versionMultipleDate', { value: version_date, field });
      }

      if (field === 'version_date_3') {
        this.$emit('versionMultipleDate', { value: version_date, field });
      }
    },
    onChangeVersionFileInMultiple(file, field) {
      const index = field.split('_').pop() - 1;
      this.versionItems[index].versionFile = file;

      if (field === "version_file_1") {
        this.$emit('versionMultipleFile', { value: file, field });
      }

      if (field === "version_file_2") {
        this.$emit('versionMultipleFile', { value: file, field });
      }

      if (field === "version_file_3") {
        this.$emit('versionMultipleFile', { value: file, field });
      }
    },
    onChangeSupportFileInMultiple(file, field) {
      const index = field.split('_').pop() - 1;
      this.versionItems[index].versionSupportFile = file;

      if (field === "version_support_file_1") {
        this.$emit('versionMultipleSupportFile', { value: file, field });
      }

      if (field === "version_support_file_2") {
        this.$emit('versionMultipleSupportFile', { value: file, field });
      }

      if (field === "version_support_file_3") {
        this.$emit('versionMultipleSupportFile', { value: file, field });
      }
    },
    resetContractVersion() {
      this.versionDateModel = null;
      this.versionNameModel = null;
      this.versionFile = null;
      this.versionSupportFile = null;
    },
    allowedDates(val) {
      return true;
    },
  },
}
</script>
