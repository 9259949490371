import Vue from "vue";
import { isEmpty } from "lodash";
// import { MenuAdmin } from "../api/menu";

const state = {
  menu: [],
  menuActive: {},
  listActive: [],
  indexActive: [],
  valIndex: [],
};

const getters = {
  getMenu: (state) => {
    return state.menu;
  },
  getMenuActive: (state) => {
    return state.menuActive;
  },
  getListActive: (state) => {
    return state.listActive;
  },
  getIndexActive: (state) => {
    return state.indexActive;
  },
  getValIndex: (state) => {
    return state.valIndex;
  },
};

const actions = {
  setMenu({ commit }, payload) {
    commit("setMenu", payload);
  },
  setMenuInit({ commit }, payload) {
    commit("setMenuInit", payload);
  },
  setMenuActive({ commit }, menuActive) {
    commit("setMenuActive", menuActive);
  },
  setListActive({ commit }, listActive) {
    commit("setListActive", listActive);
  },
  setIndexActive({ commit }, indexActive) {
    commit("setIndexActive", indexActive);
  },
};

const mutations = {
  setMenu(state, { menu, init, index }) {
    if (init) {
      state.menu = [];
      state.menu.push(menu);
    } else {
      if (state.menu.length > 0) {
        if (index <= state.menu.length - 1) {
          state.menu.splice(index, state.menu.length - 1);
        }
        if (isEmpty(state.menu[index])) {
          state.menu.push(menu);
        } else {
          state.menu[index] = null;
          Vue.set(state.menu, index, menu);
        }
      }
    }
  },
  setMenuInit(state, menu) {
    state.menu = menu;
  },
  setMenuActive(state, menu) {
    state.menuActive = menu;
  },
  setListActive(state, { active, init, index }) {
    if (init) {
      state.listActive = [];
      state.listActive.push(active);
    } else {
      if (index <= state.listActive.length - 1) {
        state.listActive.splice(index, state.listActive.length - 1);
      }
      if (state.listActive[index]) {
        Vue.set(state.listActive, index, active);
      } else {
        state.listActive.push(active);
      }
    }
  },
  setIndexActive(state, { parent, child, init }) {
    if (init) {
      state.indexActive = [];
      state.valIndex = [];
    }
    let flag = false;
    state.valIndex.forEach((item, index) => {
      if (index > 0) {
        if (item.parent === parent) {
          state.valIndex[index] = { parent, child };
          flag = true;
          return;
        }
      }
    });
    if (!flag) {
      state.valIndex.push({
        parent,
        child,
      });
    }
    state.indexActive[parent] = child;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
