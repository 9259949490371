<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    open-on-focus
    @keydown.esc="$emit('change', false)"
  >
    <v-card>
      <v-card-text class="py-2">
        <p class="mb-0">
          ¿Estás seguro que deseas cancelar la edición y salir?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="action"
          class="btn-transform-none"
          text
          small
          @click="$emit('force-close')"
          >Aceptar</v-btn
        >
        <v-btn
          color="action"
          class="btn-transform-none"
          dark
          depressed
          small
          @click="$emit('change', false)"
          >Continuar editando</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogQuestion",
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
