import LoginPage from "../pages/LoginPage.vue";
// import PreDashboardPage from '../pages/PreDashboardPage.vue'
import DashboardPage from "../pages/DashboardPage.vue";
import SubMenuPage from "../pages/SubMenuPage.vue";
import ModulesPage from "../pages/modules/ModulesPage.vue";
// import SwitchModulePage from "../pages/modules/SwitchModulePage.vue";
// import SectionPage from "../pages/SectionPage.vue";
import ReportPage from "../pages/ReportPage.vue";
import AlertPage from "../pages/AlertPage.vue";
import TodoPage from "../pages/TodoPage.vue";
import SwitchModulePage from "../pages/modules/SwitchModulePage.vue";
import UserPage from "../pages/users/UserPage.vue";
import RolesPage from "../pages/users/RolesPage.vue";
import ResetPage from "../pages/users/ResetPage.vue";
import ProfilePage from "../pages/users/ProfilePage.vue";
import NotificationsPage from "../pages/NotificationsPage.vue";
import MapPage from "../pages/MapPage.vue";
import settings from "./settingsPath";
import JudicialProcessFilePage from "../pages/modules/JudicialProcessFilePage.vue";

function requireAuth(to, from, next) {
  const token = localStorage.getItem("JARU_TOKEN") || null;
  if (!token) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

export default [
  {
    path: "/login",
    meta: {
      public: true,
    },
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/",
    meta: {},
    name: "Root",
    props: true,
    redirect: {
      name: "DashboardPage",
    },
  },
  {
    path: "/notificaciones",
    meta: { breadcrumb: true },
    name: "NotificationsPage",
    component: NotificationsPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/perfil",
    meta: { breadcrumb: true },
    name: "ProfilePage",
    component: ProfilePage,
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/premenu/:id',
  //   meta: { breadcrumb: true },
  //   name: 'PreMenuPage',
  //   component: DashboardPage
  // },
  {
    path: "/dashboard",
    meta: { breadcrumb: true },
    name: "DashboardPage",
    props: true,
    component: DashboardPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/reporte",
    meta: { breadcrumb: true },
    name: "ReportPage",
    props: true,
    component: ReportPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/mapa/:id?",
    meta: { breadcrumb: true },
    name: "MapPage",
    props: true,
    component: MapPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/alertas",
    meta: { breadcrumb: true },
    name: "AlertPage",
    props: true,
    component: AlertPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/todos",
    meta: { breadcrumb: true },
    name: "TodoPage",
    props: true,
    component: TodoPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/:id",
    meta: { breadcrumb: true },
    name: "Level1MenuPage",
    props: true,
    component: SubMenuPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/modules",
    meta: { breadcrumb: true },
    name: "ModulesPage",
    props: true,
    component: ModulesPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/modules/:typeModule",
    meta: { breadcrumb: true },
    name: "SubModulesPage",
    props: true,
    component: ModulesPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/modules/:typeModule/:typeSubmodule",
    meta: { breadcrumb: true },
    name: "SwitchModulePage",
    props: true,
    component: SwitchModulePage,
    beforeEnter: requireAuth,
  },
  {
    path: "/modules/:typeModule/:typeSubmodule/:id",
    meta: { breadcrumb: true },
    name: "JudicialProcessFilePage",
    props: true,
    component: JudicialProcessFilePage,
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/:id/:typeModule",
    meta: { breadcrumb: true },
    name: "Level2MenuPage",
    props: true,
    component: SubMenuPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/usuario",
    meta: { breadcrumb: true },
    name: "UserPage",
    props: true,
    component: UserPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/usuario/roles",
    meta: { breadcrumb: true },
    name: "RolesPage",
    props: true,
    component: RolesPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/reset",
    meta: { breadcrumb: true },
    name: "ResetPage",
    component: ResetPage,
  },
  ...settings,
];
