<template>
  <div class="block-count">
    <div class="block-count__inner d-flex">
      <button
        type="button"
        class="btn-count btn-left"
        :class="{
          'btn-disabled': !inputDays || disabled,
          'v-input--is-disabled': !inputDays || disabled,
        }"
        :disabled="!inputDays || disabled"
        @click="decreaseDays()"
      >
        <i class="material-icons">
          remove
        </i>
      </button>
      <v-text-field
        v-model="inputDays"
        mask="###"
        outlined
        height="56"
        placeholder="Días"
        class="pt-0 mt-0 input-custom-56"
        style="margin-bottom: 0!important;"
        :disabled="disabled"
        :error="!isEmpty(listError.term)"
        :rules="rulesTerm"
        @input="onChangeDays"
      >
      </v-text-field>
      <button
        type="button"
        class="btn-count btn-right"
        :disabled="disabled"
        :class="{
          'btn-disabled': disabled,
          'v-input--is-disabled': disabled,
        }"
        @click="increaseDays()"
      >
        <i class="material-icons">
          add
        </i>
      </button>
    </div>
    <div v-if="!isEmpty(listError.term)" class="block-count__error">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ listError.term }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlockCount",
  model: { prop: "inputDays", event: "change" },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    inputDays: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    days: 0,
    rulesTerm: [],
    listError: {
      term: "",
    },
  }),
  created() {
    this.days = this.inputDays;
  },
  methods: {
    validateError(err) {
      this.$nextTick(() => {
        this.rulesTerm = [(v) => !this.isEmpty(v) || this.listError.term];
      });
      this.listError.term = !this.isEmpty(err) ? err[0] : "";
    },
    clearError() {
      this.rulesTerm = [];
      this.listError.term = null;
    },
    onChangeDays(item) {
      this.rulesTerm = [];
      this.$emit("change", item);
      this.$emit("input", this.inputDays);
    },
    increaseDays() {
      this.clearError();
      // this.inputDays += 1;
      this.$emit("change", this.inputDays + 1);
      this.$emit("input", this.inputDays + 1);
    },
    decreaseDays() {
      if (this.inputDays - 1 >= 0) {
        this.clearError();
        // this.days -= 1;
        this.$emit("change", this.inputDays - 1);
        this.$emit("input", this.inputDays - 1);
      }
    },
  },
};
</script>
