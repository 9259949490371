<template>
  <v-container
    id="containerTable"
    fluid
    class="page-section pa-0 fill-height"
    style="position: relative;"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-app-bar-nav-icon v-if="isMobile" small>
          <template v-slot:default>
            <v-btn text icon small @click.stop="toggleDrawer = !toggleDrawer">
              <img
                v-if="toggleDrawer"
                style="width: 24px; height: 24px;"
                src="@/assets/menu-black.svg"
                alt=""
              />
              <img
                v-else
                style="width: 24px; height: 24px;"
                src="@/assets/menu_open-black.svg"
                alt=""
              />
            </v-btn>
          </template>
        </v-app-bar-nav-icon>
        <v-toolbar-title>To-Dos</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="roleUser !== 'executor'">
          <v-btn
            class="btn-transform-none"
            depressed
            small
            :ripple="!loadExcel"
            @click="onSubmitExcel()"
          >
            <v-progress-circular
              v-if="loadExcel"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="black"
            ></v-progress-circular>
            {{ loadExcel ? "Exportando" : "Exportar" }}
          </v-btn>
          <a id="urlDownload" :href="urlDownload" class="d-none" target="_blank"
            >download...</a
          >
        </template>
      </v-toolbar>
    </v-card>
    <todo-drawer
      ref="todoDrawer"
      :toggle-drawer="toggleDrawer"
      @filter="onSearchFilter"
    ></todo-drawer>
    <div class="content-table-module" :class="{ 'todo-responsive': isMobile }">
      <v-row
        class="fill-height"
        style="position: relative;"
        :style="styleTable"
      >
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%;"
          class="content-todo-scroll"
          :settings="scrollSettings"
          :swicher="loadData"
          @ps-scroll-y="onScrollY($event, false)"
          @ps-y-reach-end="scrollHanle"
        >
          <v-col cols="12">
            <v-card elevation="7" style="overflow:hidden;">
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="configMobileHeader"
                  :items="listTodos"
                  :loading="!loadData"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="id"
                  class="table-striped checklist-table"
                  @update:sort-by="eventSortBy"
                  @update:sort-desc="eventSortDesc"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-if="!noResultToDo && loadData">
                        <tr v-for="item in items" :key="item.id">
                          <td
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            {{ item.title }}
                          </td>
                          <td
                            v-if="!isMobile"
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            {{ item.module.label }}
                          </td>
                          <td
                            v-if="!isMobile"
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            {{ item.submodule.label }}
                          </td>
                          <td
                            v-if="!isMobile"
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            {{
                              `${item.responsible.first_name} ${item.responsible.last_name}`
                            }}
                          </td>
                          <td
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            {{ item.date.split(" ")[0] }}
                          </td>
                          <td
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            <template v-if="item.alert">
                              <img
                                style="width: 30px;height: 30px;"
                                src="@/assets/icons/icon-notificaciones.png"
                                alt=""
                              />
                            </template>
                            <template v-else>
                              <span class="px-2">---</span>
                            </template>
                          </td>
                          <td
                            class="td-hover"
                            @click="onSelectTodo('edit', item)"
                          >
                            <template v-if="!item.check">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <span
                                    class="checklist-table__state"
                                    :class="validateColor(item.state.slug)"
                                    v-on="on"
                                  ></span>
                                </template>
                                <span>{{
                                  item.state.label | formatTooltipState
                                }}</span>
                              </v-tooltip>
                            </template>
                          </td>
                          <td v-if="roleUser !== 'executor' && !isMobile" >
                            <v-btn
                              v-if="!item.check"
                              small
                              icon
                              depressed
                              @click="openDialogDelete(item)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                      <tr
                        v-if="noResultToDo"
                        class="v-data-table__empty-wrapper"
                      >
                        <td :colspan="headers.length">
                          No se encontró resultados
                        </td>
                      </tr>
                      <tr
                        v-if="!loadData && !noResultToDo"
                        class="v-data-table__empty-wrapper"
                      >
                        <td :colspan="headers.length">
                          Esperando resultados
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="loadScroll" cols="12" class="pt-2 pb-1 text-center">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
              >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll && loadScroll"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <span class="caption">No hay mas registros.</span>
          </v-col>
        </vue-perfect-scrollbar>

        <module-detail-table
          v-if="loadData"
          :is-todo="true"
          :hide-pages="true"
          :total-todo="true"
          :meta-data="metaData"
        ></module-detail-table>
      </v-row>
    </div>

    <dialog-delete
      ref="dialogDelete"
      v-model="dialogDelete"
      title="Eliminar Tarea"
      :item="selectedItem"
      @delete="onSubmitDelete"
    ></dialog-delete>

    <dialog-todo-detail
      ref="dialogDetail"
      v-model="dialogTodo"
      :item="selectedItem"
      :show-edit="true"
      :from-to-do="true"
      @alert="onChangeAlertToDo"
      @update="onUpdateToDo"
    ></dialog-todo-detail>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";
import { findIndex, uniqBy, cloneDeep } from "lodash";
import DialogDelete from "@/components/commons/DialogDelete";
import ModuleDetailTable from "@/components/commons/ModuleDetailTable";
import TodoDrawer from "@/components/todos/TodoDrawer";
import DialogTodoDetail from "@/components/todos/DialogTodoDetail";
import { isMobile } from "mobile-device-detect";
import {v4} from "uuid";

const colOnlyMobile = ["title", "date", "alert", "state.label"];

export default {
  name: "TodoPage",
  components: {
    DialogDelete,
    TodoDrawer,
    VuePerfectScrollbar,
    DialogTodoDetail,
    ModuleDetailTable,
  },
  filters: {
    formatTooltipState(val) {
      return val.split(" . ")[1];
    },
  },
  data: () => ({
    isMobile,
    expanded: [],
    scrollSettings: {
      maxScrollbarLength: 160,
      suppressScrollX: true,
    },
    toggleDrawer: true,
    headers: [

      {
        text: "Nombre",
        value: "title",
        class: "sticky-header",
        sortable: true,
      },
      { text: "Módulo", class: "sticky-header", value: "module.label", sortable: true }, //, width: 220
      { text: "Submódulo", class: "sticky-header", value: "submodule.label", sortable: true }, //, width: 270
      {
        text: "Responsable",
        class: "sticky-header",
        value: "responsible.label",
        sortable: false,
        width: 250,
      },
      {
        text: "Vencimiento",
        class: "sticky-header",
        value: "date",
        sortable: false,
        width: 115,
      },
      {
        text: "Alerta",
        class: "sticky-header",
        value: "alert",
        sortable: false,
        width: 80,
      },
      {
        text: "Estado",
        class: "sticky-header",
        value: "state.label",
        sortable: false,
        width: 80,
      },
      {
        text: "Acción",
        class: "sticky-header",
        value: "action",
        sortable: false,
        width: 60,
      },
    ],
    dialogFile: false,
    selectedItem: null,
    cloneItem: null,
    typeDialog: "new",
    loadScroll: false,
    isFinishedScroll: false,
    // flagScroll: false,
    loadTable: false,
    dialogTodo: false,
    dialogDelete: false,
    // sort
    sortDesc: null,
    sortBy: null,
    // export
    loadExcel: false,
    urlDownload: "",
    // data
    listTodos: [],
    loadData: false,
    metaData: {},
    noResultToDo: false,
    filterData: "",
    sortToDo: null,
    pages: 1,
    flagDelayScroll: false,
  }),
  computed: {
    isAdmin() {
      if (this.currentUser) {
        const { role } = this.currentUser;
        return role.slug === "super-admin" || role.slug === "admin";
      }
      return "";
    },
    configHeader() {
      if (this.roleUser === "executor") {
        const headTable = cloneDeep(this.headers);
        return headTable.splice(0, 8);
      }
      return this.headers;
    },
    configMobileHeader() {
      if (isMobile) {
        const configH = cloneDeep(this.configHeader);
        configH.forEach((e) => {
          if (e.width) e.width = null;
        });
        return configH.filter((e) => colOnlyMobile.includes(e.value));
      }
      return this.configHeader;
    },
    styleTable() {
      if (this.isMobile) {
        return { padding: "0 12px 0 12px" };
      } else {
        return { padding: "0 12px 0 12px" };
      }
    },
  },
  created() {
    if (!this.isMobile) {
      this.$nextTick(() => {
        this.toggleDrawer = false;
      });
    }
    if (this.roleUser !== "executor") {
      this.fetchModules();
    }
    this.clearToDo();
  },
  mounted() {
    this.clearScrollTopTable();
    this.loadData = false;
    const payload = `?response=lite&page=${this.pages}`;
    this.fetchToDo(payload, true);
  },
  methods: {
    ...mapActions(["fetchModules"]),
    onSearchFilter(payload, filterData) {
      this.listTodos = [];
      this.filterData = filterData;
      this.pages = 1;
      this.sortToDo = null;
      this.fetchToDo(payload, true);
    },
    fetchToDo(payload, step = false) {
      if (step) {
        this.loadData = false;
      }
      this.axios.get(`/api/to_dos${payload}`).then(({ data }) => {
        this.flagDelayScroll = true;
        this.loadScroll = false;
        this.pages = this.pages + 1;
        this.$refs.todoDrawer.setLoadSearch(false);
        if (data.success) {
          this.metaData = data.metadata;
          if (!this.isEmpty(data.data)) {
            this.noResultToDo = false;
            this.listTodos = uniqBy([...this.listTodos, ...data.data], "id");
          } else {
            this.noResultToDo = true;
          }
        } else {
          this.metaData = {
            current_page: 0,
            from: 0,
            last_page: 0,
            path: "",
            per_page: 0,
            to: 0,
            total: 0,
          };
          this.listTodos = [];
          this.noResultToDo = true;
        }
        this.loadData = true;
        const tableToDo = document.querySelectorAll(
          ".v-data-table-header-mobile > tr > th",
        );
        if (!this.isEmpty(tableToDo)) {
          tableToDo[0].colSpan = 4;
          const labelInput = document.querySelectorAll(
            ".v-data-table-header-mobile > tr > th .v-label",
          );
          labelInput[0].innerHTML = "Ordenar por";
        }
        setTimeout(() => {
          this.flagDelayScroll = false;
        }, 500);
      });
    },
    scrollHanle() {
      if (!this.flagDelayScroll) {
        const { current_page, last_page } = this.metaData;
        if (this.loadData && current_page < last_page) {
          this.loadData = true;
          let sortToDo = "";
          if (!this.isEmpty(this.sortToDo)) {
            sortToDo += `&sort=${this.sortToDo}`;
          }
          const payload = `?response=lite&page=${this.pages}${
            this.filterData
          }${sortToDo}`;
          this.loadScroll = true;
          this.fetchToDo(payload, false);
        }
        if (current_page === last_page) {
          this.isFinishedScroll = true;
        }
      }
    },
    eventSortBy(e) {
      this.sortBy = e.length ? e[0] : null;
      this.fetchToDoSort();
    },
    eventSortDesc(e) {
      this.sortDesc = e.length ? e[0] : null;
      this.fetchToDoSort();
    },
    fetchToDoSort() {
      const sortUrl = `${this.sortName(this.sortBy)}:${
        this.sortDesc ? "desc" : "asc"
      }`;
      this.loadData = false;
      if (this.sortBy !== null && this.sortDesc !== null) {
        this.clearToDo();
        this.sortToDo = sortUrl;
        this.fetchToDo(this.getCompleteUrl(sortUrl), true);
      } else  {
        this.clearToDo();
        this.sortToDo = null;
        const payload = "?response=lite&page=1";
        this.fetchToDo(payload, true);
      }
    },
    sortName(name) {
      if (name === "module.label") {
        return "module";
      } else if (name === "submodule.label") {
        return "submodule";
      } else if (name === "state.label") {
        return "state";
      } else if (name === "responsible.label") {
        return "responsible";
      }
      return name;
    },
    getCompleteUrl(sortUrl) {
      return `?response=lite&page=1${this.filterData}&sort=${sortUrl}`;
    },
    onUpdateToDo(item) {
      const inx = findIndex(this.listTodos, {
        id: item.id,
      });
      this.$set(this.listTodos, inx, item);
    },
    onChangeAlertToDo() {
      const inx = findIndex(this.listTodos, {
        id: this.selectedItem.id,
      });
      if (inx >= 0) {
        this.$set(this.listTodos[inx], "alert", 1);
      }
    },
    closeDialogDetail() {
      this.dialogTodo = false;
      this.selectedItem = null;
    },
    clearToDo() {
      this.sortToDo = null;
      this.loadData = false;
      this.isNoResult = false;
      this.pages = 1;
      this.listTodos = [];
    },
    onSelectTodo(type = "edit", item) {
      this.axios
        .get(`/api/to_dos/${item.id}`)
        .then(({ data }) => {
          if (data.success) {
            this.$refs.dialogDetail.resetDialog();
            this.typeDialog = type;
            this.selectedItem = cloneDeep(data.data);
            this.dialogTodo = true;
            // this.$nextTick(() => {
            //   this.$refs.dialogDetail.clearActivities();
            // });
          } else {
            this.selectedItem = null;
            this.dialogTodo = false;
          }
        })
        .catch(() => {
          this.selectedItem = null;
          this.dialogTodo = false;
        });
    },
    openDialogDelete(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    onSubmitDelete() {
      this.$refs.dialogDelete.toggleLoad(true);
      this.axios
        .delete(`/api/to_dos/${this.selectedItem.id}/delete`)
        .then(() => {
          this.$refs.dialogDelete.toggleLoad(false);
          const inx = findIndex(this.listTodos, { id: this.selectedItem.id });
          this.listTodos.splice(inx, 1);
          this.dialogDelete = false;
        })
        .catch(() => {
          this.$refs.dialogDelete.toggleLoad(false);
        });
    },
    async onSubmitExcel() {
      if (this.loadExcel) {
        return false;
      }
      this.loadExcel = true;
      const filterExcel = this.filterData.slice(1, this.filterData.length - 1);
      let urlSort = "";
      if (this.sortToDo) {
        urlSort = `&sort=${this.sortToDo}`;
      }
      this.axios(
        {
          url: `/api/to_dos/export/excel?${filterExcel}${urlSort}`,
          responseType: 'arraybuffer',
        }
      ).then(({ data }) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        let file = `${v4()}.xlsx`;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file;
        link.click();

        this.$toast.success(`El archivo ${file} se ha descargado correctamente.`)

        this.loadExcel = false;
      });
    },
    validateColor(status) {
      if (!this.isEmpty(status)) {
        const name = status.toLowerCase();
        return `circle-${name}`;
      }
      return "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    setLoadScroll(flag) {
      this.loadScroll = flag;
    },
    setFinishedScroll(flag) {
      this.isFinishedScroll = flag;
    },
  },
};
</script>
<style lang="scss" scope>
.title-detail-todo {
  .icon-alert {
    width: 25px;
    height: 25px;
    position: relative;
    top: 5px;
  }
}
.v-data-table .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table .v-data-table__wrapper {
  overflow: unset;
}
</style>
