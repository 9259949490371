var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.typeSubmodule === 'hidricos' ? 12 : 4}},[_c('v-form',{ref:"formUpdateAuthority",attrs:{"lazy-validation":_vm.lazyUpdate},model:{value:(_vm.validUpdate),callback:function ($$v) {_vm.validUpdate=$$v},expression:"validUpdate"}},[_c('v-container',{staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.typeSubmodule === 'hidricos' ? 4 : 12}},[_c('v-autocomplete',{staticClass:"pt-0 mb-3 input-custom-56",attrs:{"label":_vm.typeSubmodule === 'terrenos-erizados-del-estado'
                ? 'Autoridad'
                : 'Autoridad Nacional',"item-text":"label","item-value":"id","outlined":"","height":"56","hide-details":_vm.isEmpty(_vm.listError.authority_id),"rules":_vm.rulesNationalWater,"items":_vm.listAuto.options,"disabled":_vm.disabled,"return-object":""},on:{"input":_vm.onChangeAuthority},model:{value:(_vm.selectedItem.authority),callback:function ($$v) {_vm.$set(_vm.selectedItem, "authority", $$v)},expression:"selectedItem.authority"}})],1),(_vm.typeSubmodule === 'hidricos')?[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"pt-0 mb-3 input-custom-56",attrs:{"label":_vm.typeSubmodule === 'terrenos-erizados-del-estado'
                  ? 'Autoridad'
                  : 'Autoridad Administrativa',"item-text":"label","item-value":"id","outlined":"","height":"56","hide-details":_vm.isEmpty(_vm.listError.water_administrative_authority_id),"rules":_vm.rulesAdminWater,"items":_vm.optionsWaterAdministrativeAuthority.options,"loading":_vm.loadWaterAdmin,"disabled":_vm.disabled || _vm.isEmpty(_vm.selectedItem.authority),"return-object":""},on:{"input":_vm.onChangeWaterAdministrativeAuthority},model:{value:(_vm.selectedItem.water_administrative_authority),callback:function ($$v) {_vm.$set(_vm.selectedItem, "water_administrative_authority", $$v)},expression:"selectedItem.water_administrative_authority"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"pt-0 mb-3 input-custom-56",attrs:{"label":_vm.typeSubmodule === 'terrenos-erizados-del-estado'
                  ? 'Autoridad'
                  : 'Autoridad Local',"item-text":"label","item-value":"id","outlined":"","height":"56","hide-details":_vm.isEmpty(_vm.listError.local_water_authority_id),"rules":_vm.rulesLocalWater,"items":_vm.optionsLocalWaterAuthority.options,"loading":_vm.loadLocalWater,"disabled":_vm.disabled ||
                _vm.isEmpty(_vm.selectedItem.water_administrative_authority),"return-object":""},on:{"input":_vm.onChangeLocalWaterAuthority},model:{value:(_vm.selectedItem.local_water_authority),callback:function ($$v) {_vm.$set(_vm.selectedItem, "local_water_authority", $$v)},expression:"selectedItem.local_water_authority"}})],1)]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }