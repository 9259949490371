<template>
  <v-card class="px-3">
    <v-card-text class="px-0">
      <v-form
        ref="formUpdate"
        v-model="validUpdate"
        :lazy-validation="lazyUpdate"
      >
        <v-row>
          <v-col cols="6" class="py-0 mb-5">
            <p class="mb-1 card-input-title">Informe de supervisión*</p>
            <upload-file
              :item-file="fileMonitoringReport"
              :disabled="!disabledOptions"
              :is-multiple="false"
              :is-table="true"
              @update="onChangeFile($event, 'monitoring_report')"
            ></upload-file>
            <p v-if="!isEmpty(listError.monitoring_report)" class="red--text">
              {{ listError.monitoring_report }}
            </p>
          </v-col>
          <v-col cols="6" class="py-0 mb-5">
            <p class="mb-1 card-input-title">&nbsp;</p>
            <v-menu
              v-model="showDatePresentation"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  slot="activator"
                  v-model="dateFormattedPresentation"
                  label="Fecha de recepción*"
                  class="my-0 pt-0 input-custom-56"
                  style="margin-bottom: 0 !important;"
                  outlined
                  height="56"
                  :disabled="!disabledOptions"
                  :hide-details="isEmpty(listError.presentation_date)"
                  :rules="rulesDatePresentation"
                  append-icon="event"
                  readonly
                  @click:append="showDatePresentation = true"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="datePresentation"
                no-title
                scrollable
                reactive
                :show-current="true"
                locale="es"
                :allowed-dates="allowedDates"
                @input="onChangeDatePresentation"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="py-0 mb-3">
            <v-textarea
              v-model="inputMonitoringReportSummary"
              class="pt-0 mb-0"
              outlined
              label="Resumen del informe de supervisión"
              :disabled="!disabledOptions"
              hide-details
              @input="onChangeMonitoringReportSummary"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" class="py-0 mt-4 mb-3">
            <v-divider></v-divider>
          </v-col>
          <check-list-task
            :id="selectedItem.id"
            ref="checklist"
            class="mb-0"
            page="supervision"
            :tasks="pendingTasks"
            :type="typeDialog"
            :type-module="typeModule"
            :type-submodule="typeSubmodule"
            :disabled="!disabledOptions"
            @update="onChangePendingTask"
            @todo-step="onUpdatePendingTask"
          ></check-list-task>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
      <template v-if="selectedItem.id">
        <v-spacer></v-spacer>
        <v-btn
          class="btn-transform-none"
          color="action"
          dark
          small
          depressed
          @click="$emit('next', 3)"
        >
          Ir a etapa sancionadora
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script>
import mixinApiModule from "../../modules/mixins/mixinApiModule";
import mixinCommon from "../mixins/mixinCommon";
import CheckListTask from "../../commons/CheckListTask";
import UploadFile from "../../commons/UploadFile";
import { cloneDeep, isEqual } from "lodash";

export default {
  name: "StepTwo",
  components: {
    CheckListTask,
    UploadFile,
  },
  mixins: [mixinCommon, mixinApiModule],
  data: () => ({
    // form
    auxPendingTasks: [],
    pendingTasks: [],
    datePresentation: null,
    showDatePresentation: false,
    fileMonitoringReport: null,
    inputMonitoringReportSummary: null,
    // rules
    rulesDatePresentation: [],
    rulesMonitoringReport: [],
    // listError
    listError: {
      presentation_date: "",
      monitoring_report: "",
    },
  }),
  computed: {
    dateFormattedPresentation() {
      return this.formatDate(this.datePresentation);
    },
  },
  mounted() {},
  methods: {
    validateSubmitStep() {
      if (this.isEmpty(this.selectedItem.id)) {
        this.onSubmitStep();
      } else {
        this.validateEdit();
      }
    },
    validateEdit() {
      const equalTasks = isEqual(
        this.pendingTasks,
        this.selectedItem.pending_tasks,
      );
      const equalDate = isEqual(
        this.datePresentation,
        this.selectedItem.presentation_date,
      );
      const equalFile = isEqual(
        this.fileMonitoringReport,
        this.selectedItem.monitoring_report,
      );
      const equalInput = isEqual(
        this.inputMonitoringReportSummary,
        this.selectedItem.monitoring_report_summary,
      );
      if (equalTasks && equalDate && equalFile && equalInput) {
        this.$emit("next", 3);
      } else {
        this.onSubmitStep();
      }
    },
    async onSubmitStep() {
      this.$emit("flag-loading", true);
      let flagCreate = true;
      const formData = new FormData();
      formData.append("monitoring_report", this.fileMonitoringReport || "");
      formData.append(
        "presentation_date",
        this.validateDateFormat(this.datePresentation),
      );
      formData.append(
        "monitoring_report_summary",
        this.inputMonitoringReportSummary || "",
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/2/create`;
      if (!this.isEmpty(this.selectedItem.id)) {
        formData.append("model_id", this.id);
        url = `/api/${this.apiByModule.module}/stage/2/${this.selectedItem.id}/update`;
        flagCreate = false;
      }
      const { data } = await this.axios({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.success) {
        this.$toast.success(`La ficha ha sido actualizada correctamente.`);
        if (this.auxPendingTasks.length) {
          this.$emit("change", data.data);
          await this.loopToDoStep(data.data.id, this.auxPendingTasks, 0);
        } else {
          this.$emit("change", data.data);
        }
        if (flagCreate) {
          this.$emit("next", 3);
        }
        this.$emit("flag-edit", false);
        this.flagEdit = false;
      } else {
        this.listErrorSection(data.error.data);
      }
      this.$emit("flag-loading", false);
    },
    onUpdatePendingTask(item) {
      this.$refs.checklist.updateLoadApp(true);
      this.loopToDoStep(item.entity_id, [item], 0, true);
    },
    async loopToDoStep(id, checklist, index, flagChecklist = false) {
      if (index === checklist.length) {
        this.$refs.checklist.updateLoadApp(false);
        this.auxPendingTasks = [];
        return false;
      }
      const item = checklist[index];
      item.entity_id = id;
      delete item.responsible;
      const { data } = await this.axios.post(
        `/api/${this.apiByModule.module}/stage/2/${this.id}/to_do/create`,
        item,
      );
      if (data.success) {
        if (flagChecklist) {
          this.$nextTick(() => {
            this.pendingTasks.push(data.data);
          });
        }
        this.loopToDoStep(id, checklist, index + 1, flagChecklist);
      }
    },
    listErrorSection(err) {
      const { presentation_date = null, monitoring_report = null } = err;
      this.showError = true;
      this.listError.presentation_date = presentation_date
        ? presentation_date[0]
        : null;
      this.listError.monitoring_report = monitoring_report
        ? monitoring_report[0]
        : null;

      this.rulesDatePresentation = this.listError.presentation_date
        ? [() => this.listError.presentation_date]
        : [];
      this.rulesMonitoringReport = this.listError.monitoring_report
        ? [() => this.listError.monitoring_report]
        : [];
      this.$refs.formUpdate.validate();
    },
    setForm() {
      this.rulesDatePresentation = [];
      this.rulesMonitoringReport = [];
      this.$nextTick(() => {
        this.pendingTasks = this.isEmpty(this.selectedItem)
          ? []
          : cloneDeep(this.selectedItem.pending_tasks);
        this.datePresentation = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.presentation_date);
        this.fileMonitoringReport = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.monitoring_report);
        this.inputMonitoringReportSummary = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.monitoring_report_summary);
      });
    },
    onChangeDatePresentation() {
      this.flagEdit = true;
      this.$emit("flag-edit", true);
      this.listError.presentation_date = "";
      this.showDatePresentation = false;
    },
    onChangeMonitoringReportSummary() {
      this.flagEdit = true;
      this.$emit("flag-edit", true);
      this.listError.monitoring_report = "";
    },
    onChangePendingTask(task) {
      this.$emit("flag-edit", true);
      this.flagEdit = true;
      this.auxPendingTasks = task;
      this.listError.pending_tasks = null;
    },
    onChangeFile($event) {
      this.flagEdit = true;
      this.$emit("flag-edit", true);
      this.fileMonitoringReport = $event;
    },
    clearForm() {
      this.flagEdit = false;
      this.datePresentation = null;
      this.showDateVisit = false;
      this.fileMonitoringReport = null;
      this.inputMonitoringReportSummary = null;
    },
    formatDate(date) {
      if (!date) return null;
      date = date.split(" ");
      const [year, month, day] = date[0].split("-");
      return `${day}/${month}/${year}`;
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>
