<template>
  <v-row class="mx-0">
    <v-col cols="12" class="py-0">
      <v-menu
        ref="dateStart"
        v-model="showRangeDates"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            slot="activator"
            v-model="dateRangeText"
            label="Fecha de Vencimiento"
            class="pt-0 input-custom-56"
            hide-details
            placeholder="Seleccione rango"
            format="DD-MM-YYYY"
            clearable
            height="56"
            outlined
            readonly
            @click:clear="clearStartDate()"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="rangeDates"
          no-title
          scrollable
          locale="es"
          range
          @change="debounceChange"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <!--
      <v-col cols="12" class="py-0">
        <v-menu
          ref="dateStart"
          v-model="showDateStart"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              slot="activator"
              v-model="dateFormattedStart"
              label="Vencimiento - Fecha inicio"
              class="pt-0 input-custom-56"
              hide-details
              placeholder="Fecha de inicio"
              append-icon="event"
              format="DD-MM-YYYY"
              clearable
              height="56"
              outlined
              readonly
              @click:clear="clearStartDate()"
              @click:append="showDateStart = true"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateStart"
            no-title
            scrollable
            locale="es"
            :min="minDateStart"
            :max="maxDateStart"
            @change="changeDateStart"
            @input="showDateStart = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-menu
          ref="dateEnd"
          v-model="showDateEnd"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              slot="activator"
              v-model="dateFormattedEnd"
              label="Vencimiento - Fecha fin"
              class="pt-0 input-custom-56"
              hide-details
              placeholder="Fecha fin"
              append-icon="event"
              format="DD-MM-YYYY"
              height="56"
              clearable
              outlined
              readonly
              @click:clear="clearEndDate()"
              @click:append="showDateEnd = true"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateEnd"
            no-title
            scrollable
            locale="es"
            :min="minDateEnd"
            :max="maxDateEnd"
            @change="changeDateEnd"
            @input="showDateEnd = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    -->
  </v-row>
</template>
<script>
import { debounce } from "lodash";

export default {
  name: "RangeDateFilter",
  data: () => ({
    rangeDates: [null, null],
    showRangeDates: false,
    showDateStart: false,
    dateStart: null,
    minDateStart: null,
    maxDateStart: null,
    showDateEnd: false,
    dateEnd: null,
    minDateEnd: null,
    maxDateEnd: null,
    debounceChange: null,
  }),
  computed: {
    dateRangeText() {
      if (this.rangeDates[0] === null && this.rangeDates[1] === null) {
        return null;
      }
      return this.rangeDates.join(" ~ ");
    },
    dateFormattedStart() {
      return this.formatDate(this.dateStart);
    },
    dateFormattedEnd() {
      return this.formatDate(this.dateEnd);
    },
  },
  watch: {
    showRangeDates(val) {
      if (
        !val &&
        !this.isEmpty(this.rangeDates[0]) &&
        this.isEmpty(this.rangeDates[1])
      ) {
        this.$set(this.rangeDates, 1, this.rangeDates[0]);
      }
    },
  },
  mounted() {
    this.debounceChange = debounce(this.onChangeFilter, 900);
  },
  methods: {
    changeDateStart(e) {
      if (this.rangeDates[0] !== null && this.rangeDates[1] !== null) {
        this.onChangeFilter();
      } else if (this.rangeDates[0] === null && this.rangeDates[1] === null) {
        this.clearDate();
      }
    },
    changeDateEnd(e) {
      if (this.dateStart === null && this.dateEnd !== null) {
        this.maxDateStart = e;
      }
      if (this.dateStart !== null && this.dateEnd !== null) {
        this.onChangeFilter();
      } else if (this.dateStart === null && this.dateEnd === null) {
        this.clearDate();
      }
    },
    onChangeFilter() {
      this.$emit("change", this.rangeDates[0], this.rangeDates[1]);
    },
    clearStartDate() {
      this.rangeDates = [null, null];
      this.$emit("change", this.rangeDates[0], this.rangeDates[1]);
      // this.dateStart = null;
      // this.changeDateStart();
    },
    clearEndDate() {
      this.dateEnd = null;
      this.changeDateEnd();
    },
    clearDate() {
      this.onlyClearDate();
      this.onChangeFilter();
    },
    onlyClearDate() {
      this.dateStart = null;
      this.dateEnd = null;
      this.maxDateStart = null;
      this.minDateEnd = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
