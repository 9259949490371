<template>
  <v-col cols="12" class="py-0" style="margin-bottom: 20px; !important;">
    <!--<h4 class="mb-2 card-input-title">Fecha</h4>-->
    <v-container fluid class="py-0 px-0">
      <v-row>
        <v-col v-if="situationLabel !== 'En proceso'" cols="4" class="py-0">
          <v-row>
            <v-col cols="12" class="pr-3 py-0">
              <v-menu
                v-model="showDateExpiration"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    slot="activator"
                    v-model="dateFormattedExpiration"
                    label="Fecha de Vencimiento*"
                    class="my-0 pt-0 input-custom-56"
                    style="margin-bottom: 0 !important"
                    height="56"
                    outlined
                    :disabled="!!expirationDateDisabled || disabled"
                    :hide-details="isEmpty(listError.expiration_date)"
                    :rules="rulesExpiration"
                    append-icon="event"
                    readonly
                    @click:append="showDateExpiration = true"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateExpiration"
                  :show-current="true"
                  locale="es"
                  no-title
                  scrollable
                  reactive
                  :allowed-dates="allowedDates"
                  @input="onChangeDateExpiration"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="situationLabel === 'En proceso'" cols="4" class="py-0">
          <v-menu
            v-model="showDatePresentation"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                slot="activator"
                v-model="dateFormattedPresentation"
                class="my-0 pt-0 input-custom-56"
                label="Fecha de Presentación*"
                style="margin-bottom: 0 !important"
                :disabled="presentationDateDisabled || disabled"
                :hide-details="isEmpty(listError.presentation_date)"
                :rules="rulesPresentation"
                outlined
                height="56"
                append-icon="event"
                readonly
                @click:append="showDatePresentation = true"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datePresentation"
              :show-current="true"
              locale="es"
              no-title
              scrollable
              reactive
              :allowed-dates="allowedDates"
              @input="onChangeDatePresentation"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-menu
            v-model="showDateApproval"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                slot="activator"
                v-model="dateFormattedApproval"
                class="my-0 pt-0 input-custom-56"
                style="margin-bottom: 0 !important"
                :disabled="approvalDateDisabled || disabled"
                :label="`Fecha de Aprobación${
                  situationLabel === 'En proceso' ? '' : '*'
                }`"
                :hide-details="isEmpty(listError.approval_date)"
                :rules="rulesApproval"
                outlined
                height="56"
                append-icon="event"
                readonly
                @click:append="showDateApproval = true"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateApproval"
              locale="es"
              no-title
              :show-current="true"
              scrollable
              reactive
              :allowed-dates="allowedDates"
              @input="onChangeDateApproval"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          class="mt-0 py-0"
        >
          <v-switch
            v-model="expirationDateDisabled"
            :ripple="false"
            :disabled="disabled"
            hide-details
            class="mt-0"
            :false-value="0"
            :true-value="1"
            label="N/A"
            @change="onChangeDateDisabled"
          ></v-switch>
        </v-col>
      </v-row>
    </v-container>
    <template v-if="!disabled">
      <v-dialog v-model="dialogInProgress" max-width="350" persistent>
        <v-card>
          <v-card-text class="pt-3">
            <p class="body-2 mb-0">
              La situación ha cambiado a vigente al seleccionar la fecha de
              aprobación.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-transform-none"
              text
              small
              @click="onCloseDialogProgress()"
            >Cerrar
            </v-btn
            >
            <v-btn
              color="primary"
              class="btn-transform-none"
              text
              small
              @click="onChangeState()"
            >Aceptar
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-col>
</template>
<script>
export default {
  name: "DatePermisos",
  props: {
    situation: {
      type: Object,
      default() {
        return {
          label: "",
        };
      },
    },
    listError: {
      type: Object,
      default: () => {
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePresentation: null,
      dateExpiration: null,
      dateApproval: null,
      showDatePresentation: false,
      showDateExpiration: false,
      showDateApproval: false,
      expirationDateDisabled: false,
      approvalDateDisabled: false,
      presentationDateDisabled: false,
      rulesPresentation: [],
      rulesExpiration: [],
      rulesApproval: [],
      dialogInProgress: false,
    };
  },
  computed: {
    situationLabel() {
      return !this.isEmpty(this.situation) ? this.situation.label : "";
    },
    dateFormattedPresentation() {
      return this.formatDate(this.datePresentation);
    },
    dateFormattedExpiration() {
      return this.formatDate(this.dateExpiration);
    },
    dateFormattedApproval() {
      return this.formatDate(this.dateApproval);
    },
  },
  watch: {
    dateFormattedApproval(val) {
      if (!this.isEmpty(val) && this.situationLabel === "En proceso") {
        this.dialogInProgress = true;
      } else {
        this.dialogInProgress = false;
      }
    },
  },
  methods: {
    onChangeState() {
      this.$emit("change-state");
      this.dialogInProgress = false;
    },
    onCloseDialogProgress() {
      this.dateApproval = null;
      this.dialogInProgress = false;
    },
    validateError(err) {
      const {
        presentation_date = null,
        expiration_date = null,
        approval_date = null,
      } = err;
      this.listError.presentation_date = presentation_date
        ? presentation_date[0]
        : null;
      this.listError.expiration_date = expiration_date
        ? expiration_date[0]
        : null;
      this.listError.approval_date = approval_date ? approval_date[0] : null;
      this.$nextTick(() => {
        this.rulesPresentation = !this.isEmpty(this.listError.presentation_date)
          ? [() => this.listError.presentation_date]
          : [];
        this.rulesExpiration = !this.isEmpty(this.listError.expiration_date)
          ? [() => this.listError.expiration_date]
          : [];
        this.rulesApproval = !this.isEmpty(this.listError.approval_date)
          ? [() => this.listError.approval_date]
          : [];
      });
      // this.$refs.formUpdateAuthority.validate();
    },
    setDates(
      presentation,
      expiration,
      approval,
      expirationDateDisabled = false,
    ) {
      this.rulesPresentation = [];
      this.rulesExpiration = [];
      this.rulesApproval = [];
      this.datePresentation = this.parseFormatDate(presentation);
      this.dateExpiration = this.parseFormatDate(expiration);
      this.dateApproval = this.parseFormatDate(approval);
      this.expirationDateDisabled = expirationDateDisabled;
    },
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },
    parseFormatDate(val) {
      return val ? val.split(" ")[0] : null;
    },
    onChangeDateExpiration(item) {
      this.dateExpiration = item ? item : null;
      this.showDateExpiration = false;
      this.approvalDateDisabled = item ? item : null;
      this.presentationDateDisabled = item ? item : null;
      this.$emit(
        "update",
        this.datePresentation,
        this.dateExpiration,
        this.dateApproval,
        this.expirationDateDisabled,
      );
    },
    onChangeDateDisabled(item) {
      this.onChangeDateExpiration(item);
    },
    onChangeDatePresentation(item) {
      this.datePresentation = item ? item : null;
      this.showDatePresentation = false;
      this.$emit(
        "update",
        this.datePresentation,
        this.dateExpiration,
        this.dateApproval,
        this.expirationDateDisabled,
      );
    },
    onChangeDateApproval(item) {
      this.dateApproval = item ? item : null;
      this.showDateApproval = false;
      this.$emit(
        "update",
        this.datePresentation,
        this.dateExpiration,
        this.dateApproval,
        this.expirationDateDisabled,
      );
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>
