<template>
  <v-navigation-drawer width="60" mini-variant-width="60" mini-variant>
    <vue-perfect-scrollbar
      class="drawer-menu--scroll"
      :settings="scrollSettings"
    >
      <v-list dense nav class="grow px-0">
        <v-list-item
          v-for="item in listItem"
          :key="`settings-${item.key}`"
          :class="`${validateActiveMenu(item)}  py-0 px-1`"
          :ripple="false"
          @click="onSelectMenu(item)"
        >
          <v-list-item-action>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="menu-link__status"
                  depressed
                  :ripple="false"
                  icon
                  v-on="on"
                >
                  <span class="drawer-icon-svg">
                    <img :src="`${baseURL}/img/icons/white/${item.icon}`"
                  /></span>
                </v-btn>
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { MenuAdmin } from "@/api/menu";
import { mapState } from "vuex";

export default {
  name: "SettingsDrawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    modules: {
      type: Array,
      default() {
        return [];
      },
    },
    isSubmodules: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    listItem: MenuAdmin[MenuAdmin.length - 1].submenu,
    selectedMenu: {
      key: "",
    },
    scrollSettings: {
      maxScrollbarLength: 160,
    },
  }),
  computed: {
    ...mapState({
      listModules: (state) => state.modules.modules,
      activeModule: (state) => state.modules.activeModule,
      toggleDrawer: (state) => state.session.toggleDrawer,
    }),
  },
  methods: {
    onSelectMenu(item) {
      this.selectedMenu = item;
      this.$router.push({ name: item.name });
    },
    validateActiveMenu(item) {
      const { path } = this.$route;
      return path.includes(item.key) ? "menu-active" : "";
    },
  },
};
</script>
