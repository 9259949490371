<template>
  <v-app-bar
    ref="toolbar"
    dense
    class="header--toolbar"
    style="border-radius: 0;"
    fixed
    app
    flat
    permanent
    :clipped-left="true"
  >
    <v-toolbar-title class="ml-0 pl-3">
      <router-link
        :to="{ name: 'DashboardPage' }"
        style="color: #fff;"
        class="d-inline-block mt-2"
        ><img src="@/assets/jaru-logo-color.svg" alt="Jaru Software logo"
      /></router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu v-if="roleUser !== 'visualizer'" class="elelvation-1" left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="btn-notification" dark depressed small icon v-on="on">
          <v-badge
            v-if="
              !isEmpty(currentUser) && currentUser.metas.unread_notifications
            "
            color="primary"
            overlap
            style="position: absolute;top: 3px;right: 2px;"
          >
            <span slot="badge">{{
              currentUser.metas.unread_notifications | formatUnread
            }}</span>
          </v-badge>
          <img src="@/assets/icon-notification.png" alt="" />
        </v-btn>
      </template>
      <notification-list></notification-list>
    </v-menu>
    <v-menu class="elelvation-1" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn depressed text dark small class="ml-4" v-on="on">
          <template v-if="currentUser">{{
            `${currentUser.first_name} ${currentUser.last_name}`
          }}</template>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :href="item.href"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          @click="onClickMenu(item)"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import NotificationList from "./NotificationList";
import { mapActions, mapState } from "vuex";
import { MenuAdmin } from "@/api/menu";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AppToolbar",
  components: {
    NotificationList,
  },
  filters: {
    formatUnread(val) {
      return val > 9 ? "9+" : val;
    },
  },
  data: () => ({
    isMobile,
    items: [
      {
        icon: "account_circle",
        href: "",
        name: "ProfilePage",
        title: "Perfil",
        type: "profile",
      },
      {
        icon: "exit_to_app",
        href: "",
        title: "Cerrar sesión",
        type: "logout",
      },
    ],
    dialogPassword: false,
    dialogLogout: false,
    loadUpdate: false,
    loadLogout: false,
    formPass: {
      user_id: null,
      old_password: null,
      password: null,
      password_confirmation: null,
    },
    error: {
      show: false,
      text: "",
    },
  }),
  computed: {
    ...mapState({
      toggleDrawer: (state) => state.session.toggleDrawer,
      unauthorized: (state) => state.session.unauthorized,
    }),
    unread() {
      if (this.currentUser) {
        return this.currentUser.metas.unread_notifications;
      }
      return 0;
    },
    flagDialogPassword() {
      if (this.isEmpty(this.currentUser)) {
        return false;
      }
      return this.currentUser.password_changed_at === null;
    },
  },
  watch: {
    flagDialogPassword: {
      immediate: true,
      handler(val) {
        if (!isMobile) {
          this.dialogPassword = val;
        }
      },
    },
    unread: {
      immediate: true,
      handler(val) {
        if (this.currentUser) {
          document.title = `${!val ? "" : "(" + val + ") "}Jaru`;
        } else {
          document.title = `Jaru`;
        }
      },
    },
  },
  mounted() {
    this.formPass.user_id = this.currentUser ? this.currentUser.id : null;
  },
  methods: {
    ...mapActions(["logout", "setDrawer", "setUnauthorized"]),
    onClickMenu(item) {
      if (item.type === "logout") {
        this.logout().then(() => {
          localStorage.removeItem("jaru");
          localStorage.removeItem("jaru-web");
          this.$router.push({ name: "LoginPage" });
        });
      } else {
        this.$store.dispatch(`menuAdmin/setMenu`, {
          menu: MenuAdmin,
          init: true,
          index: 0,
        });
        this.$store.dispatch(`menuAdmin/setMenuActive`, null);
        this.$store.dispatch(`menuAdmin/setListActive`, {
          active: null,
          init: true,
          index: 0,
        });
        this.$router.push({ name: "ProfilePage" });
      }
    },
    listErrorPassword(err) {
      this.error.text = err.password[0];
      this.error.show = true;
    },
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    onCloseDialogUnauthorized() {
      this.setUnauthorized({ show: false, text: "" });
    },
    onRedirectLogin() {
      if (this.loadLogout) {
        return false;
      }
      this.loadLogout = true;
      this.logout().then(() => {
        this.loadLogout = true;
        this.dialogLogout = true;
        this.onCloseDialogUnauthorized();
        this.$router.push({ name: "LoginPage" });
      });
    },
    onCloseDialogPassword() {
      this.dialogPassword = false;
    },
    onSumbitChangePassword() {
      if (
        this.isEmpty(this.formPass.password_confirmation) ||
        this.isEmpty(this.formPass.old_password) ||
        this.isEmpty(this.formPass.password)
      ) {
        return false;
      }
      if (this.loadUpdate) return false;
      this.error.show = false;
      this.loadUpdate = true;
      this.axios
        .post(
          `/api/users/${this.currentUser.id}/update-password`,
          this.formPass,
        )
        .then(({ data }) => {
          this.loadUpdate = false;
          if (data.success) {
            this.clearDialogPassword();
          } else {
            this.listErrorPassword(data.error.data);
          }
        })
        .catch(() => {
          this.loadUpdate = false;
        });
    },
    clearDialogPassword() {
      this.dialogPassword = false;
      this.formPass.old_password = null;
      this.formPass.password = null;
      this.formPass.password_confirmation = null;
    },
  },
};
</script>
