import axios from "../../http";
import { nameToken } from "@/const";

const session = {
  state: {
    isAuth: false,
    token: null,
    currentUser: null,
    toggleDrawer: true,
    unauthorized: {
      show: false,
      text: "",
    },
  },
  mutations: {
    setIsAuth(state, value) {
      state.isAuth = value;
    },
    setCurrentUser(state, value) {
      state.currentUser = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setUnauthorized(state, flag) {
      state.unauthorized = flag;
    },
    setDrawer(state, value) {
      state.toggleDrawer = value;
    },
    updateReadNotification(state) {
      state.currentUser.metas.unread_notifications =
        state.currentUser.metas.unread_notifications - 1;
    },
  },
  actions: {
    setDrawer({ commit }, flag) {
      commit("setDrawer", flag);
    },
    async login({ commit }, values) {
      commit("setUnauthorized", false);
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(`/api/auth/login`, values);
        if (data.success) {
          localStorage.setItem(nameToken, data.data.token);
          commit("setToken", data.data.token);
          // dispatch("fetchMasterOption");
          commit("setIsAuth", true);
          // dispatch("fetchUsers", { payload: "", step: true });
          resolve();
        } else {
          reject(data.error);
          commit("setIsAuth", false);
        }
      });
    },
    async getMe({ commit }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/users/me`);
        if (data.success) {
          commit("setCurrentUser", data.data);
          resolve(data.data);
        } else {
          reject();
          commit("setCurrentUser", null);
        }
      });
    },
    logout({ commit }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(`/api/auth/logout`);
        if (data.success) {
          localStorage.removeItem(nameToken);
          commit("setIsAuth", false);
          commit("setCurrentUser", null);
          commit("setToken", null);
          resolve();
        } else {
          reject();
        }
      });
    },
    updateReadNotification({ commit }) {
      commit("updateReadNotification");
    },
    setUnauthorized({ commit }, payload) {
      commit("setUnauthorized", payload);
    },
  },
};

export default session;
