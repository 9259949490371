export class IContracts {
  constructor() {
    this.id = null;
    this.type = {};
    this.situation = {};
    this.detail = "";
    this.project = {};
    // this.link_spij = "http://spijlibre.minjus.gob.pe/normativa_libre/main.asp";
    // Time in days
    this.planned_term = 0;
    this.contract_term = 0;
    // Dates
    this.expiration_date = null;
    this.expiration_date_disabled = false;
    // User | Responsible
    this.creator = {};
    this.primary_responsible = {};
    this.secondary_responsible = {};
    // Checklist
    this.pending_tasks = [];
    this.requirements = [];
    // Map
    this.zone = {};
    this.datum = {};
    this.locations = [];
    this.location_disabled = false;
  }
}
