import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import session from "./modules/session";
import modules from "./modules/modules";
import masters from "./modules/masters";
import menuAdmin from "./modules/menuAdmin";
import users from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "jaru",
    }),
  ],
  modules: {
    masters,
    modules,
    session,
    menuAdmin,
    users,
  },
});
