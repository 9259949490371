const setColors = [
  "#14141E",
  "#1680A8",
  "#17293C",
  "#1AA8DE",
  "#1B3D61",
  "#1E6079",
  "#1E67B4",
  "#1F5287",
  "#313141",
  "#4A4A5C",
  "#525C60",
  "#5593D6",
  "#707084",
  "#72AABF",
  "#809AA5",
  "#A4E6FF",
  "#BBD1E9",
  "#F6F7F9",
];
//[
//  "#f44336",
//  "#e91e63",
//  "#9c27b0",
//  "#673ab7",
//  "#3f51b5",
//  "#2196f3",
//  "#03a9f4",
//  "#00bcd4",
//  "#009688",
//  "#4caf50",
//  "#8bc34a",
//  "#cddc39",
//  "#ffeb3b",
//  "#ffc107",
//  "#ff9800",
//  "#ff5722",
//  "#795548",
//  "#9e9e9e",
//   "#607d8b",
// ];

export { setColors };
