export class IUsers {
  constructor() {
    this.first_name = null;
    this.last_name = null;
    this.email = null;
    this.role = {};
    this.role_id = null;
    this.phone_number = null;
    this.notify_by_email = true;
    this.notify_by_sms = true;
    this.notify_by_push = true;
    this.settings = {
      notify_by_email: true,
      notify_by_sms: true,
      notify_by_push: true,
    };
  }
}
