<template>
  <v-navigation-drawer width="60" mini-variant-width="60" mini-variant>
    <vue-perfect-scrollbar
      class="drawer-menu--scroll"
      :settings="scrollSettings"
    >
      <v-list dense nav class="grow px-0">
        <v-list-item
          v-for="item in modules"
          v-show="item.disabled == 0"
          :key="`menu-${item.id}`"
          :class="`${validateActiveMenu(item)}  py-0 px-1`"
          :ripple="false"
          @click="goToModule(item)"
        >
          <v-list-item-action>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="menu-link__status"
                  depressed
                  :ripple="false"
                  icon
                  v-on="on"
                >
                  <span class="drawer-icon-svg">
                    <img :src="`${baseURL}/img/icons/white/${item.icon}`"
                  /></span>
                </v-btn>
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "ModulesDrawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    modules: {
      type: Array,
      default() {
        return [];
      },
    },
    isSubmodules: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scrollSettings: {
      maxScrollbarLength: 160,
    },
  }),
  computed: {
    ...mapState({
      activeModule: (state) => state.modules.activeModule,
      activeSubModule: (state) => state.modules.activeSubModule,
    }),
  },
  methods: {
    ...mapActions(["setActiveModules", "setActiveSubModules"]),
    goToModule(menu) {
      if (menu.slug === "propiedades-mineras") {
        this.setActiveModules(menu).then(() => {
          this.$router.push({
            name: "SwitchModulePage",
            params: {
              typeModule: this.activeModule.slug,
              typeSubmodule: menu.slug,
            },
          });
        });
      } else {
        if (menu.submodules) {
          this.setActiveModules(menu).then(() => {
            this.$router.push({
              name: "SubModulesPage",
              params: { typeModule: menu.slug },
            });
          });
        } else {
          this.setActiveSubModules(menu).then(() => {
            this.$router.push({
              name: "SwitchModulePage",
              params: {
                typeModule: this.activeModule.slug,
                typeSubmodule: menu.slug,
              },
            });
          });
        }
      }
    },
    validateActiveMenu(item) {
      if (!this.isSubmodules) {
        return item.id === this.activeModule.id ? "menu-active" : "";
      } else {
        return item.id === this.activeSubModule.id ? "menu-active" : "";
      }
    },
  },
};
</script>
