<template>
  <v-container
    id="containerMain"
    fluid
    class="page-section py-0 px-0 fill-height"
    style="position: relative;"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>{{ parseSlugModule(typeSubmodule) }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <mining-drawer ref="miningDrawer" @filter="onSearchFilter"></mining-drawer>
    <div class="content-table-module">
      <v-row
        class="fill-height"
        style="padding: 0 12px 0 12px; position: relative;"
      >
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%;"
          class="content-meta-scroll"
          :settings="scrollModuleSettings"
          :swicher="loadData"
          @ps-scroll-y="onScrollY($event, true)"
          @ps-y-reach-end="scrollHanle"
        >
          <v-col cols="12">
            <v-card elevation="7" style="overflow:hidden;">
              <v-toolbar flat dense color="transparent">
                <v-toolbar-title><h4></h4></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn-transform-none"
                  depressed
                  small
                  :ripple="!loadExcel"
                  @click="onSubmitExcel()"
                >
                  <v-progress-circular
                    v-if="loadExcel"
                    :size="20"
                    class="d-inline-block mr-3"
                    :width="2"
                    indeterminate
                    color="black"
                  ></v-progress-circular>
                  {{ loadExcel ? "Exportando" : "Exportar" }}
                </v-btn>
                <a
                  id="urlDownload"
                  :href="urlDownload"
                  class="d-none"
                  target="_blank"
                  >download...</a
                >
                <template
                  v-if="roleUser !== 'visualizer' && roleUser !== 'executor'"
                >
                  <v-btn
                    class="btn-transform-none ml-2"
                    color="primary"
                    small
                    depressed
                    @click="openDialogUpdate('add')"
                  >
                    <v-icon>add</v-icon>
                    Agregar Propiedad minera
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="configHeader"
                  :items="listItem"
                  :loading="!loadData"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="id"
                  class="table-striped"
                  @update:sort-by="eventSortBy"
                  @update:sort-desc="eventSortDesc"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-if="!noResult && loadData">
                        <tr
                          v-for="(item, index) in items"
                          :id="item.id"
                          :key="item.id"
                        >
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item, index)"
                          >
                            {{ item.id }}
                          </td>
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item, index)"
                          >
                            {{ item.project }}
                          </td>
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item, index)"
                          >
                            {{ item.type ? item.type.label : "---" }}
                          </td>
                          <td
                            v-if="
                              roleUser !== 'visualizer' &&
                                roleUser !== 'executor'
                            "
                            class="text-center"
                          >
                            <v-btn
                              small
                              icon
                              depressed
                              @click="openDialogDelete(item, index)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                      <tr v-if="noResult" class="v-data-table__empty-wrapper">
                        <td :colspan="headers.length">
                          No se encontró resultados
                        </td>
                      </tr>
                      <tr
                        v-if="!loadData && !noResult"
                        class="v-data-table__empty-wrapper"
                      >
                        <td :colspan="headers.length">
                          Esperando resultados
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="loadScroll && loadData"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <v-progress-circular
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
              >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll && loadData"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <span class="caption">No hay más registros.</span>
          </v-col>
        </vue-perfect-scrollbar>
        <module-detail-table
          v-if="loadData"
          class="type-submodule"
          :meta-data="metaData"
          :show-content="false"
        ></module-detail-table>
      </v-row>
    </div>
    <!-- Modal Supervision -->
    <dialog-detail-mining
      ref="dialogDetailCard"
      v-model="dialogNew"
      :type-dialog="typeDialog"
      @close="onCloseDetailCard"
      @create-active="onActiveNewItem"
    ></dialog-detail-mining>

    <dialog-delete
      ref="dialogDelete"
      v-model="dialogDelete"
      title="Eliminar ficha"
      :is-module="true"
      :item="selectedItem"
      @delete="onSubmitDelete"
    ></dialog-delete>
  </v-container>
</template>
<script>
import { IMiningProperty } from "@/models";
import { mapActions, mapState } from "vuex";
import { cloneDeep, uniqBy } from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import MiningDrawer from "@/components/mining/MiningDrawer";
import DialogDelete from "@/components/commons/DialogDelete";
import DialogDetailMining from "@/components/mining/DialogDetailMining";
import ModuleDetailTable from "@/components/commons/ModuleDetailTable";
import mixinSort from "@/components/mining/mixins/mixinSort";

export default {
  name: "MiningPropertyPage",
  components: {
    DialogDelete,
    DialogDetailMining,
    VuePerfectScrollbar,
    ModuleDetailTable,
    MiningDrawer,
  },
  mixins: [mixinSort],
  props: {
    fichaId: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    scrollModuleSettings: { maxScrollbarLength: 160, suppressScrollX: true },
    disabledEdit: true,
    headers: [
      { text: "Código", class: "sticky-header", value: "id", sortable: true },
      {
        text: "Proyecto",
        class: "sticky-header",
        value: "project",
        sortable: true,
        width: 200,
      },
      {
        text: "Tipo",
        class: "sticky-header",
        value: "type.label",
        sortable: true,
        width: 100,
      },
      {
        text: "Acción",
        class: "sticky-header",
        value: "actions",
        sortable: false,
        width: 80,
      },
    ],
    selectedItem: null,
    cloneItem: null,
    typeDialog: "new",
    dialogNew: false,
    loadExcel: false,
    urlDownload: "",
    loadScroll: false,
    isFinishedScroll: false,
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // list
    listItem: [],
    loadData: false,
    metaData: {},
    noResult: false,
    filterData: "",
    paginationData: {},
    sortMining: null,
    pages: 1,
    flagDelayScroll: false,
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
    }),
    configHeader() {
      if (this.roleUser === "visualizer" || this.roleUser === "executor") {
        const headTable = cloneDeep(this.headers);
        return headTable.splice(0, 4);
      }
      return this.headers;
    },
  },
  watch: {
    typeModule(val) {
      if (!this.isEmpty(val)) {
        this.clearScrollTopTable();
        this.flagDelayScroll = false;
        this.clearMining();
        const payload = `?response=lite&page=${this.pages}`;
        this.fetchMiningProperties(payload, true);
      }
    },
    typeSubmodule(val) {
      if (!this.isEmpty(val)) {
        this.clearScrollTopTable();
        this.flagDelayScroll = false;
        this.clearMining();
        const payload = `?response=lite&page=${this.pages}`;
        this.fetchMiningProperties(payload, true);
      }
    },
  },
  created() {
    this.fetchAllUsers();
  },
  mounted() {
    this.clearScrollTopTable();
    let query = "";
    if (this.fichaId) {
      query = `&search=${this.fichaId}`;
    }
    const payload = `?response=lite&page=${this.pages}${query}`;
    this.fetchMiningProperties(payload, true);
  },
  methods: {
    ...mapActions(["fetchAllUsers"]),
    onActiveNewItem(item) {
      if (item.id) {
        this.listItem.unshift(item);
        this.$nextTick(() => {
          const elem = document.getElementById(`${item.id}`);
          if (elem) {
            elem.classList.add("active-item");
            setTimeout(() => {
              elem.classList.remove("active-item");
            }, 8000);
          }
        });
      }
    },
    onSearchFilter(payload, filterData) {
      this.listItem = [];
      this.filterData = filterData;
      this.pages = 1;
      this.noResult = false;
      this.fetchMiningProperties(payload, true);
    },
    fetchMiningProperties(payload, step = false) {
      if (step) {
        this.loadData = false;
      }
      this.axios.get(`/api/mining_properties${payload}`).then(({ data }) => {
        this.flagDelayScroll = true;
        this.loadScroll = false;
        this.pages = this.pages + 1;
        this.$refs.miningDrawer.setLoadSearch(false);
        if (data.success) {
          this.metaData = data.meta;
          this.paginationData = data.meta;
          if (!this.isEmpty(data.data)) {
            this.noResult = false;
            this.listItem = uniqBy([...this.listItem, ...data.data], "id");
            if (this.fichaId) {
              this.openDialogUpdate("edit", { id: this.fichaId });
            }
          } else {
            this.noResult = true;
          }
        } else {
          this.metaData = {
            total_situation_active: 0,
            total_situation_expired: 0,
            total_situation_in_process: 0,
          };
          this.listItem = [];
          this.noResult = true;
        }
        this.loadData = true;
        setTimeout(() => {
          this.flagDelayScroll = false;
        }, 500);
      });
    },
    scrollHanle() {
      if (!this.flagDelayScroll) {
        const { current_page, last_page } = this.paginationData;
        if (this.loadData && current_page < last_page) {
          this.loadData = true;
          let sortMining = "";
          if (!this.isEmpty(this.sortMining)) {
            sortMining += `&sort=${this.sortMining}`;
          }
          const payload = `?response=lite&page=${this.pages}${this.filterData}${sortMining}`;
          this.flagDelayScroll = true;
          this.isFinishedScroll = false;
          this.loadScroll = true;
          this.fetchMiningProperties(payload, false);
        }
        if (current_page === last_page) {
          this.isFinishedScroll = true;
          // setTimeout(() => {
          //   this.isFinishedScroll = false;
          // }, 3500);
        }
      }
    },
    openDialogDelete(item, index) {
      this.deleteIndex = index;
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    onSubmitDelete() {
      this.$refs.dialogDelete.toggleLoad(true);
      this.axios
        .delete(`/api/mining_properties/${this.selectedItem.id}/delete`)
        .then(() => {
          this.$refs.dialogDelete.toggleLoad(false);
          this.listItem.splice(this.deleteIndex, 1);
          this.dialogDelete = false;
        })
        .catch(() => {
          this.$refs.dialogDelete.toggleLoad(false);
        });
    },
    openDialogUpdate(type = "add", item) {
      if (type === "add") {
        this.selectedItem = new IMiningProperty();
        this.disabledEdit = false;
        this.dialogDraft = false;
        this.typeDialog = "new";
        this.dialogNew = true;
        this.$nextTick(() => {
          this.$refs.dialogDetailCard.clearErrors();
          this.$refs.dialogDetailCard.openDialogDetail();
        });
      } else {
        this.typeDialog = "edit";
        this.$refs.dialogDetailCard.openDialogEdit(item);
        this.dialogNew = true;
      }
    },
    async onSubmitExcel() {
      if (this.loadExcel) {
        return false;
      }
      this.loadExcel = true;
      const filterExcel = this.filterMiningProperty.slice(
        1,
        this.filterMiningProperty.length,
      );
      let urlSort = "";
      if (this.sortMining) {
        urlSort = `&sort=${this.sortMining}`;
      }
      const { data } = await this.axios.post(
        `/api/mining_properties/export/excel?${filterExcel}${urlSort}`,
      );
      if (data.success) {
        this.urlDownload = `${this.baseURL}/${data.data.url}`;
        this.$nextTick(() => {
          document.getElementById("urlDownload").click();
        });
      }
      this.loadExcel = false;
    },
    onCloseDetailCard() {
      this.selectedItem = new IMiningProperty();
      this.$refs.dialogDetailCard.clearErrors();
    },
    setLoadScroll(flag) {
      this.loadScroll = flag;
    },
    setFinishedScroll(flag) {
      this.isFinishedScroll = flag;
    },
    clearMining() {
      this.filterData = "";
      this.sortMining = null;
      this.loadData = false;
      this.noResult = false;
      this.pagination = {};
      this.metaData = {};
      this.pages = 1;
      this.listItem = [];
    },
  },
};
</script>
