import {
  IPermits,
  IContracts,
  IEnvironmentals,
  ICommitments,
  IOccupationalSafetys,
  ISupervision,
} from "@/models";
import { cloneDeep, isEmpty } from "lodash";
import {
  extendedPrefix,
  submoduleNamePrefix,
} from "../../../utils/extended_attributes_prefix";
import {commonModules} from "../../../utils/commonModules";

export default {
  methods: {
    getInterface() {
      if (this.typeModule === "permisos") {
        return new IPermits();
      } else if (this.typeModule === "contratos") {
        return new IContracts();
      } else if (this.typeModule === "ambiental") {
        return new IEnvironmentals();
      } else if (this.typeModule === "compromisos") {
        return new ICommitments();
      } else if (this.typeModule === "seguridad") {
        return new IOccupationalSafetys();
      } else if (this.typeModule === "supervisiones") {
        return new ISupervision();
      } else {
        return {
          id: null,
        };
      }
    },
    validateSubmitForm(fromDrafts = false) {
      if (fromDrafts && this.auxLoadDraft) return false;
      if (!fromDrafts && this.loadUpdate) return true;
      if (this.apiByModule.module === "permits") {
        return this.onSubmitPermit(fromDrafts);
      } else if (this.apiByModule.module === "contracts") {
        return this.onSubmitContracts(fromDrafts);
      } else if (this.apiByModule.module === "environmentals") {
        return this.onSubmitEnvironmentals(fromDrafts);
      } else if (this.apiByModule.module === "commitments") {
        return this.onSubmitCommitments(fromDrafts);
      } else if (this.apiByModule.module === "occupational_safetys") {
        return this.onSubmitOccupationalSafety(fromDrafts);
      }
    },
    validateErrorSection(err) {
      if (this.apiByModule.module === "permits") {
        return this.$refs.permitSection.listErrorSection(err);
      } else if (this.apiByModule.module === "contracts") {
        return this.$refs.contractsSection.listErrorSection(err);
      } else if (this.apiByModule.module === "environmentals") {
        return this.$refs.environmentalsSection.listErrorSection(err);
      } else if (this.apiByModule.module === "commitments") {
        return this.$refs.commitmentsSection.listErrorSection(err);
      } else if (this.apiByModule.module === "occupational_safetys") {
        return this.$refs.occupationalSafetysSection.listErrorSection(err);
      }
    },
    validateSetFormSection() {
      if (this.apiByModule.module === "permits") {
        return this.$refs.permitSection.setForm();
      } else if (this.apiByModule.module === "contracts") {
        return this.$refs.contractsSection.setForm();
      } else if (this.apiByModule.module === "environmentals") {
        return this.$refs.environmentalsSection.setForm();
      } else if (this.apiByModule.module === "commitments") {
        return this.$refs.commitmentsSection.setForm();
      } else if (this.apiByModule.module === "occupational_safetys") {
        return this.$refs.occupationalSafetysSection.setForm();
      }
    },
    clearErrors() {
      if (this.apiByModule.module === "permits") {
        if (this.$refs.permitSection) {
          this.$refs.permitSection.clearFormSection();
          this.$refs.permitSection.clearErrorSection();
        }
      } else if (this.apiByModule.module === "contracts") {
        if (this.$refs.contractsSection) {
          this.$refs.contractVersionSection.resetContractVersion();
          this.$refs.contractsSection.clearFormSection();
          this.$refs.contractsSection.clearErrorSection();
        }
      } else if (this.apiByModule.module === "environmentals") {
        if (this.$refs.environmentalsSection) {
          this.$refs.environmentalsSection.clearFormSection();
          this.$refs.environmentalsSection.clearErrorSection();
        }
      } else if (this.apiByModule.module === "commitments") {
        if (this.$refs.commitmentsSection) {
          this.$refs.commitmentsSection.clearFormSection();
          this.$refs.commitmentsSection.clearErrorSection();
        }
      } else if (this.apiByModule.module === "occupational_safetys") {
        if (this.$refs.occupationalSafetysSection) {
          this.$refs.occupationalSafetysSection.clearFormSection();
          this.$refs.occupationalSafetysSection.clearErrorSection();
        }
      }
    },
    onSubmitOccupationalSafety(fromDrafts = false) {
      const selectedItem = cloneDeep(this.selectedItem);
      const payload = {
        // Extra
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail || "",
        description: selectedItem.description || "",
        legal_base: selectedItem.legal_base || "",
        type_id: this.validateFormat(selectedItem.type, "id"),
        term: this.parseStringToNumber(selectedItem.term),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        authority_id: this.validateFormat(selectedItem.authority, "id"),
        // Dates
        expiration_date: this.validateDateFormat(selectedItem.expiration_date),
        expiration_date_disabled: selectedItem.expiration_date_disabled ? 1 : 0,
        // location
        location_disabled: selectedItem.location_disabled ? 1 : 0,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        // Users
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
      };
      if (fromDrafts) {
        this.auxLoadDraft = true;
      } else {
        this.loadUpdate = true;
      }
      let auxPayload = cloneDeep(payload);
      delete auxPayload.pending_tasks;
      delete auxPayload.locations;
      if (payload.location_disabled) {
        delete auxPayload.zone_id;
        delete auxPayload.datum_id;
      }
      if (payload.expiration_date_disabled) {
        delete auxPayload.expiration_date;
      }
      let formData = new FormData();
      for (const prop in auxPayload) {
        formData.append(prop, payload[prop]);
      }
      this.onSubmitCommon(formData, fromDrafts);
    },
    onSubmitCommitments(fromDrafts = false) {
      const selectedItem = cloneDeep(this.selectedItem);
      const extendedAttributes = cloneDeep(this.selectedExtendedAttributes);

      const payload = {
        // Extra
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail || "",
        type: selectedItem.type || "",
        oportunity: selectedItem.oportunity || "",
        term: this.parseStringToNumber(selectedItem.term),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        parts: selectedItem.parts || "",
        main_obligations: selectedItem.main_obligations || "",
        // Dates
        expiration_date: this.validateDateFormat(selectedItem.expiration_date),
        expiration_date_disabled: selectedItem.expiration_date_disabled ? 1 : 0,
        // location
        location_disabled: selectedItem.location_disabled ? 1 : 0,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        // Users
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
        autoridad_competente: selectedItem.autoridad_competente || "",
        plazo_legal: selectedItem.plazo_legal || "",
        plazo_estimado: selectedItem.plazo_estimado || "",
        consecuencia_incumplimiento:
          selectedItem.consecuencia_incumplimiento || "",
        medidad_correctivas: selectedItem.medidad_correctivas || "",
        observaciones: selectedItem.observaciones || "",
        observaciones_ambiental: selectedItem.observaciones_ambiental || "",
        sansiones_osinergmin: selectedItem.sansiones_osinergmin || "",
        periodicity: selectedItem.periodicity || "",
        article_number: selectedItem.article_number || "",
        intern_area: selectedItem.intern_area || "",
        entity_emisor: selectedItem.entity_emisor || "",
        standard_text: selectedItem.standard_text || "",
        applicability: selectedItem.applicability || "",
        number_rule: selectedItem.number_rule || "",
      };
      if (fromDrafts) {
        this.auxLoadDraft = true;
      } else {
        this.loadUpdate = true;
      }
      let auxPayload = cloneDeep(payload);
      delete auxPayload.pending_tasks;
      delete auxPayload.locations;
      if (payload.location_disabled) {
        delete auxPayload.zone_id;
        delete auxPayload.datum_id;
      }
      if (payload.expiration_date_disabled) {
        delete auxPayload.expiration_date;
      }
      let formData = new FormData();
      for (const prop in auxPayload) {
        formData.append(prop, payload[prop]);
      }
      this.onSubmitCommon(formData, fromDrafts, extendedAttributes);
    },
    onSubmitEnvironmentals(fromDrafts = false) {
      const selectedItem = cloneDeep(this.selectedItem);

      const payload = {
        // Extra
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail || "",
        description: selectedItem.description || "",
        type_id: this.validateFormat(selectedItem.type, "id"),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        legal_base: selectedItem.legal_base || "",
        authority_id: selectedItem.authority.id || "",
        // Dates
        expiration_date: this.validateDateFormat(selectedItem.expiration_date),
        expiration_date_disabled: selectedItem.expiration_date_disabled ? 1 : 0,
        effective_compliance_date: this.validateDateFormat(
          selectedItem.effective_compliance_date,
        ),
        // location
        location_disabled: selectedItem.location_disabled ? 1 : 0,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        // Users
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
      };
      if (fromDrafts) {
        this.auxLoadDraft = true;
      } else {
        this.loadUpdate = true;
      }
      let auxPayload = cloneDeep(payload);
      delete auxPayload.pending_tasks;
      delete auxPayload.locations;
      if (payload.location_disabled) {
        delete auxPayload.zone_id;
        delete auxPayload.datum_id;
      }
      if (payload.expiration_date_disabled) {
        delete auxPayload.expiration_date;
      }
      let formData = new FormData();
      for (const prop in auxPayload) {
        formData.append(prop, payload[prop]);
      }
      this.onSubmitCommon(formData, fromDrafts);
    },
    onSubmitContracts(fromDrafts = false) {
      const selectedItem = cloneDeep(this.selectedItem);
      const payload = {
        // Extra
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail || "",
        parts: selectedItem.parts || "",
        main_obligations: selectedItem.main_obligations || "",
        type_id: this.validateFormat(selectedItem.type, "id"),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        // Dates
        expiration_date: this.validateDateFormat(selectedItem.expiration_date),
        expiration_date_disabled: selectedItem.expiration_date_disabled ? 1 : 0,
        // location
        location_disabled: selectedItem.location_disabled ? 1 : 0,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        // Period in days
        planned_term: this.parseStringToNumber(selectedItem.planned_term),
        contract_term: this.parseStringToNumber(selectedItem.contract_term),
        // Users
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
        // version section
        version_name: selectedItem.version_name || "",
        version_date: selectedItem.version_date || "",
        version_file: selectedItem.version_file || "",
        version_support_file: selectedItem.version_support_file || "",
        // version section 2
        version_name_1: selectedItem.version_name_1 || "",
        version_date_1: selectedItem.version_date_1 || "",
        version_file_1: selectedItem.version_file_1 || "",
        version_support_file_1: selectedItem.version_support_file_1 || "",
        // version section 3
        version_name_2: selectedItem.version_name_2 || "",
        version_date_2: selectedItem.version_date_2 || "",
        version_file_2: selectedItem.version_file_2 || "",
        version_support_file_2: selectedItem.version_support_file_2 || "",
        // version section 4
        version_name_3: selectedItem.version_name_3 || "",
        version_date_3: selectedItem.version_date_3 || "",
        version_file_3: selectedItem.version_file_3 || "",
        version_support_file_3: selectedItem.version_support_file_3 || "",
      };
      if (fromDrafts) {
        this.auxLoadDraft = true;
      } else {
        this.loadUpdate = true;
      }
      let auxPayload = cloneDeep(payload);
      delete auxPayload.pending_tasks;
      delete auxPayload.locations;
      if (payload.location_disabled) {
        delete auxPayload.zone_id;
        delete auxPayload.datum_id;
      }
      if (!this.isEmpty(selectedItem.situation)) {
        if (selectedItem.situation.label.toLowerCase() !== "vigente") {
          delete auxPayload.contract_term;
        }
        if (selectedItem.situation.label.toLowerCase() !== "en proceso") {
          delete auxPayload.planned_term;
        }
      }
      if (payload.expiration_date_disabled) {
        delete auxPayload.expiration_date;
      }
      let formData = new FormData();
      for (const prop in auxPayload) {
        formData.append(prop, payload[prop]);
      }
      this.onSubmitCommon(formData, fromDrafts);
    },
    onSubmitPermit(fromDrafts = false) {
      const selectedItem = cloneDeep(this.selectedItem);
      const payload = {
        // Extra
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail || "",
        legal_base: selectedItem.legal_base || "",
        order: 1,
        type_id: this.validateFormat(selectedItem.type, "id"),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        expiration_date_disabled: selectedItem.expiration_date_disabled ? 1 : 0,
        // Dates
        expiration_date: this.validateDateFormat(selectedItem.expiration_date),
        presentation_date: this.validateDateFormat(
          selectedItem.presentation_date,
        ),
        approval_date: this.validateDateFormat(selectedItem.approval_date),
        // location
        location_disabled: selectedItem.location_disabled ? 1 : 0,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        // Period in days
        legal_term: this.parseStringToNumber(selectedItem.legal_term),
        planned_term: this.parseStringToNumber(selectedItem.planned_term),
        actual_term: this.parseStringToNumber(selectedItem.actual_term),
        // Authority
        authority_id: this.validateFormat(selectedItem.authority, "id"),
        water_administrative_authority_id: this.validateFormat(
          selectedItem.water_administrative_authority,
          "id",
        ),
        local_water_authority_id: this.validateFormat(
          selectedItem.local_water_authority,
          "id",
        ),
        // Users
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
        // files
        submission_fee: selectedItem.submission_fee || "",
        submission_fee_1: selectedItem.submission_fee_1 || "",
        submission_fee_2: selectedItem.submission_fee_2 || "",
        submission_fee_3: selectedItem.submission_fee_3 || "",
        approval_resolution: selectedItem.approval_resolution || "",
        resolution: selectedItem.resolution || "",
        permission_class: selectedItem.permission_class || "",
        file_relation: selectedItem.file_relation || "",
      };
      if (fromDrafts) {
        this.auxLoadDraft = true;
      } else {
        this.loadUpdate = true;
      }
      let auxPayload = cloneDeep(payload);
      delete auxPayload.pending_tasks;
      delete auxPayload.locations;
      if (payload.location_disabled) {
        delete auxPayload.zone_id;
        delete auxPayload.datum_id;
      }
      if (!this.isEmpty(selectedItem.situation)) {
        if (
          selectedItem.situation.label.toLowerCase() === "vencido" ||
          selectedItem.situation.label.toLowerCase() === "vigente"
        ) {
          delete auxPayload.legal_term;
          delete auxPayload.planned_term;
          delete auxPayload.actual_term;
        }
        if (selectedItem.situation.label.toLowerCase() === "vigente") {
          delete auxPayload.presentation_date;
        }
        if (
          auxPayload.expiration_date_disabled ||
          selectedItem.situation.label.toLowerCase() === "en proceso"
        ) {
          delete auxPayload.expiration_date;
          auxPayload.expiration_date_disabled = 1;
        }
      }
      let formData = new FormData();
      for (const prop in auxPayload) {
        formData.append(prop, payload[prop]);
      }
      this.onSubmitCommon(formData, fromDrafts);
    },
    onSubmitCommon(formData, fromDrafts = false, extendedAttributes = null) {
      if (this.selectedItem.id && !this.draftToCard) {
        this.axios({
          method: "post",
          url: `/api/${this.apiByModule.module}/${fromDrafts ? "drafts/" : ""}${
            this.apiByModule.submodule
          }/${this.selectedItem.id}/update`,
          data: formData,
        })
          .then(async ({ data }) => {
            if (data.success) {
              if (commonModules.includes(this.apiByModule.module)) {
                this.$toast.success(
                  `La ficha ha sido actualizada correctamente.`,
                );

                this.loadingQuestion = false;
                this.dialogQuestion = false;
                this.auxLoadDraft = false;
                this.loadUpdate = false;
              } else {
                await this.axios({
                  method: "post",
                  url: `/api/${
                    fromDrafts ? "drafts/" : ""
                  }extended_attributes_values/update`,
                  data: {
                    fields: extendedAttributes
                      .map((attribute) => ({
                        ...attribute,
                        [extendedPrefix[this.apiByModule.submodule]]:
                          data.data.id,
                      }))
                      .filter((attribute) => attribute.value),
                  },
                });

                this.loadingQuestion = false;
                this.dialogQuestion = false;
                this.auxLoadDraft = false;
                this.loadUpdate = false;

                this.$toast.success(
                  `La ficha de ${
                    submoduleNamePrefix[this.apiByModule.submodule]
                  } ha sido actualizada correctamente.`,
                );
              }

              if (this.isComponentParent) {
                this.dialogNew = false;
              } else {
                this.$emit("change", false);
              }
            } else {
              this.loadUpdate = false;
              this.auxLoadDraft = false;
              this.validateErrorSection(data.error.data);
            }
          })
          .catch((error) => {
            this.loadUpdate = false;
            this.auxLoadDraft = false;
            if (error.statusCode === 413) {
              this.$toast.error(error.message);
            }
          });
      } else {
        const pendingTask = this.selectedItem.pending_tasks || [];
        const requirements = this.selectedItem.requirements || [];
        const locations = this.selectedItem.locations;
        const checklist = [...pendingTask, ...requirements];

        this.axios({
          method: "post",
          url: `/api/${this.apiByModule.module}/${fromDrafts ? "drafts/" : ""}${
            this.apiByModule.submodule
          }/create`,
          data: formData,
        })
          .then(async ({ data }) => {
            if (data.success) {
              if (!isEmpty(extendedAttributes)) {
                await this.axios({
                  method: "post",
                  url: `/api/${
                    fromDrafts ? "drafts/" : ""
                  }extended_attributes_values/create`,
                  data: {
                    fields: extendedAttributes
                      .map((attribute) => ({
                        ...attribute,
                        [extendedPrefix[this.apiByModule.submodule]]:
                          data.data.id,
                      }))
                      .filter((attribute) => attribute.value),
                  },
                });
              }

              if (commonModules.includes(this.apiByModule.module)) {
                this.$toast.success(
                  `La ficha ha sido ha sido creada correctamente.`,
                );
              } else {
                this.$toast.success(
                  `La ficha de ${
                    submoduleNamePrefix[this.apiByModule.submodule]
                  } ha sido creada correctamente.`,
                );
              }

              this.loopLocation(data.data.id, locations, 0, false);
              this.loopToDo(data.data.id, checklist, 0, false);
              this.loadDraf = false;
              this.dialogDraft = false;
              this.auxLoadDraft = false;
              this.loadUpdate = false;
              if (this.isDraft) {
                this.axios.delete(
                  `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}/${this.selectedItem.id}/delete`,
                );
              }
              if (this.isComponentParent) {
                this.dialogNew = false;
              } else {
                this.$emit("create-active", data.data);
                this.$emit("change", false);
              }
            } else {
              this.auxLoadDraft = false;
              this.loadUpdate = false;
              this.validateErrorSection(data.error.data);
            }
          })
          .catch((error) => {
            this.auxLoadDraft = false;
            this.loadUpdate = false;
            if (error.statusCode === 413) {
              this.$toast.error(error.message);
            }
          });
      }
    },
    async loopLocation(id, locations, index, isDraft = false) {
      if (index === locations.length) return false;
      const item = locations[index];
      item.entity_id = id;
      const urlDraft = isDraft ? "/drafts/" : "/";
      await this.axios.post(
        `/api/${this.apiByModule.module}${urlDraft}${this.apiByModule.submodule}/${id}/location/create`,
        item,
      );
      this.loopLocation(id, locations, index + 1, isDraft);
    },
    async loopToDo(id, checklist, index, isDraft = false) {
      if (index === checklist.length) {
        return false;
      }
      const item = checklist[index];
      item.entity_id = id;
      const todoDraft = isDraft ? "/drafts/" : "/";
      if (isDraft) {
        delete item.alert;
        delete item.created_at;
        delete item.updated_at;
        delete item.responsible;
        delete item.module;
        delete item.submodule;
      }
      await this.axios.post(
        `/api/${this.apiByModule.module}${todoDraft}${this.apiByModule.submodule}/${id}/to_do/create`,
        item,
      );
      this.loopToDo(id, checklist, index + 1, isDraft);
    },
    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? "" : value.id;
      } else {
        return this.isEmpty(value) ? "" : value;
      }
    },
  },
};
