<template>
  <v-dialog
    v-model="dialogNew"
    content-class="dialog-custom"
    persistent
    open-on-focus
    scrollable
    max-width="950px"
    @keydown.esc="onCloseDialogUpdate()"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">{{
          typeDialog === "new" ? "Nueva propiedad minera" : "Propiedad Minera"
        }}</span>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 my-0"
          small
          depressed
          :ripple="!loadWord"
          @click="onSubmitWork()"
        >
          <v-progress-circular
            v-if="loadWord"
            :size="20"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="black"
          ></v-progress-circular>
          {{ loadWord ? "Descargando ficha" : "Descargar ficha" }}
          <v-icon v-if="!loadWord" color="grey">class</v-icon>
        </v-btn>
        <v-btn
          slot="activator"
          class="m-0"
          icon
          fab
          small
          @click="onCloseDialogUpdate()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <a
          id="urlDownloadWord"
          :href="urlDownload"
          class="d-none"
          target="_blank"
          >download...</a
        >
      </v-card-title>
      <v-col v-if="loadDetail" cols="12">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <vue-perfect-scrollbar
        v-show="!loadDetail"
        class="drawer-menu--scroll"
        :settings="scrollSettings"
        :swicher="!loadDetail"
      >
        <v-card-text>
          <v-form
            ref="formUpdate"
            v-model="validUpdate"
            :lazy-validation="lazyUpdate"
          >
            <v-row>
              <v-col
                v-if="typeDialog !== 'new' && selectedItem && selectedItem.id"
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="selectedItem.id"
                  disabled
                  outlined
                  label="Código de expediente"
                  height="56"
                  hide-details
                  class="pt-0 input-custom-56"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.project"
                  label="Proyecto*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.project)"
                  :rules="rulesProject"
                  @input="onChangeInput($event, 'project')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.concession_code"
                  label="Código de la concesión*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.concession_code)"
                  :rules="rulesConcessionCode"
                  @input="onChangeInput($event, 'concession_code')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.concession_name"
                  label="Nombre de la concesión*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.concession_name)"
                  :rules="rulesConcessionName"
                  @input="onChangeInput($event, 'concession_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.holder_name"
                  label="Nombre de titular*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.holder_name)"
                  :rules="rulesHolderName"
                  @input="onChangeInput($event, 'holder_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.assignee_name"
                  label="Nombre de cesionario*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.assignee_name)"
                  :rules="rulesAssigneeName"
                  @input="onChangeInput($event, 'assignee_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-menu
                  ref="pickerEnd"
                  v-model="showGrantingDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      slot="activator"
                      v-model="dateFormatted"
                      label="Fecha de otorgamiento del título de concesión*"
                      class="my-0 pt-0 input-custom-56"
                      style="margin-bottom: 0 !important;"
                      outlined
                      height="56"
                      :disabled="disabledOptions"
                      :hide-details="isEmpty(listError.granting_date)"
                      :rules="rulesGrantingDate"
                      append-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedItem.granting_date"
                    no-title
                    scrollable
                    reactive
                    :show-current="true"
                    locale="es"
                    :allowed-dates="allowedDates"
                    @input="onChangeGrantingDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.type"
                  class="pt-0 mb-3 input-custom-56"
                  label="Tipo*"
                  item-text="label"
                  item-value="id"
                  height="56"
                  outlined
                  return-object
                  :hide-details="isEmpty(listError.type_id)"
                  :rules="rulesType"
                  :items="listType.options"
                  :disabled="disabledOptions"
                  @input="onChangeType"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.hectares_formulated"
                  label="Hectareas formuladas*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.hectares_formulated)"
                  :rules="rulesHectaresFormulated"
                  @input="onChangeInput($event, 'hectares_formulated')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.effective_hectares"
                  label="Hectáreas efectivas*"
                  class="pt-0 input-custom-56"
                  height="56"
                  outlined
                  :disabled="disabledOptions"
                  :hide-details="isEmpty(listError.effective_hectares)"
                  :rules="rulesEffectiveHectares"
                  @input="onChangeInput($event, 'effective_hectares')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </vue-perfect-scrollbar>
      <v-card-actions
        v-show="!loadDetail"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12);"
      >
        <template v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
          <v-btn
            v-if="typeDialog === 'edit' && !disabledEdit"
            color="primary"
            small
            depressed
            dark
            class="btn-transform-none"
            @click="disabledEdit = true"
            >Habilitar edición</v-btn
          >
        </template>
        <v-spacer></v-spacer>
        <v-btn
          color="action"
          class="btn-transform-none"
          text
          small
          @click="onCloseDialogUpdate()"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="roleUser !== 'visualizer' && roleUser !== 'executor'"
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          :ripple="false"
          @click="onSubmitUpdate('new')"
        >
          <v-progress-circular
            v-if="loadUpdate"
            :size="22"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadUpdate ? "Guardando" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-question
      v-model="dialogQuestion"
      @force-close="onCloseDialogQuestion"
    ></dialog-question>
  </v-dialog>
</template>
<script>
import { IMiningProperty } from "@/models";
import { mapState } from "vuex";
import { isEqual, cloneDeep } from "lodash";
import mixinSort from "./mixins/mixinSort";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DialogQuestion from "@/components/commons/DialogQuestion";

export default {
  name: "DialogDetailMining",
  components: {
    VuePerfectScrollbar,
    DialogQuestion,
  },
  mixins: [mixinSort],
  model: { prop: "dialogNew", event: "change" },
  props: {
    typeDialog: {
      type: String,
      default: "new",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    disabledEdit: false,
    inputGrantingDate: null,
    selectedItem: new IMiningProperty(),
    cloneItem: null,
    showGrantingDate: false,
    dialogQuestion: false,
    dialogDetail: false,
    flagChangeForm: false,
    loadUpdate: false,
    loadDetail: false,
    // export
    loadWord: false,
    urlDownload: "",
    // rules
    rulesType: [],
    rulesProject: [],
    rulesConcessionCode: [],
    rulesConcessionName: [],
    rulesHolderName: [],
    rulesAssigneeName: [],
    rulesGrantingDate: [],
    rulesHectaresFormulated: [],
    rulesEffectiveHectares: [],
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // export
    listError: {
      project: null,
      type_id: null,
      concession_code: null,
      concession_name: null,
      holder_name: null,
      assignee_name: null,
      granting_date: null,
      hectares_formulated: null,
      effective_hectares: null,
    },
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
    }),
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
    dateFormatted() {
      return this.formatDate(this.selectedItem.granting_date);
    },
    listType() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === "propiedades-mineras-tipo";
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  mounted() {
    this.flagChangeForm = false;
  },
  methods: {
    validateSubmitUpdate(step) {
      if (this.selectedItem.id) {
        const cloneItem = cloneDeep(this.cloneItem);
        const cloneSelectedItem = cloneDeep(this.selectedItem);
        if (isEqual(cloneItem, cloneSelectedItem)) {
          this.onSubmitUpdate();
        }
      } else {
        this.onSubmitUpdate();
      }
    },
    onSubmitUpdate() {
      if (this.loadUpdate) return false;
      this.$refs.formUpdate.resetValidation();
      this.loadUpdate = true;
      const payload = cloneDeep(this.selectedItem);
      if (!this.isEmpty(payload.type)) {
        payload["type_id"] = payload.type.id;
      }
      payload.hectares_formulated = payload.hectares_formulated
        ? parseInt(payload.hectares_formulated)
        : null;

      payload.granting_date = this.validateDateFormat(payload.granting_date);
      payload.effective_hectares = payload.effective_hectares
        ? parseInt(payload.effective_hectares)
        : null;
      delete payload.creator;
      delete payload.type;
      delete payload.creator_id;
      delete payload.updated_at;
      delete payload.created_at;
      if (this.typeDialog === "new") {
        delete payload.id;
        this.axios
          .post(`/api/mining_properties/create`, payload)
          .then(({ data }) => {
            if (data.success) {
              this.selectedItem = cloneDeep(data.data);
              this.cloneItem = cloneDeep(data.data);
              this.loadUpdate = false;
              this.$emit("create-active", data.data);
              this.$emit("change", false);
            } else {
              this.clearErrors();
              this.loadUpdate = false;
              this.listErrorSection(data.error.data);
            }
          })
          .catch(() => {
            this.loadUpdate = false;
          });
      } else {
        this.axios
          .post(
            `/api/mining_properties/${this.selectedItem.id}/update`,
            payload,
          )
          .then(({ data }) => {
            if (data.success) {
              this.selectedItem = cloneDeep(data.data);
              this.cloneItem = cloneDeep(data.data);
              this.loadUpdate = false;
              this.$emit("change", false);
            } else {
              this.clearErrors();
              this.loadUpdate = false;
              this.listErrorSection(data.error.data);
            }
          })
          .catch(() => {
            this.loadUpdate = false;
          });
      }
    },
    listErrorSection(err) {
      const {
        project = null,
        type_id = null,
        concession_code = null,
        concession_name = null,
        holder_name = null,
        assignee_name = null,
        granting_date = null,
        hectares_formulated = null,
        effective_hectares = null,
      } = err;
      this.listError.type_id = type_id ? type_id[0] : null;
      this.listError.concession_code = concession_code
        ? concession_code[0]
        : null;
      this.listError.concession_name = concession_name
        ? concession_name[0]
        : null;
      this.listError.holder_name = holder_name ? holder_name[0] : null;
      this.listError.assignee_name = assignee_name ? assignee_name[0] : null;
      this.listError.granting_date = granting_date ? granting_date[0] : null;
      this.listError.hectares_formulated = hectares_formulated
        ? hectares_formulated[0]
        : null;
      this.listError.effective_hectares = effective_hectares
        ? effective_hectares[0]
        : null;
      this.listError.project = project ? project[0] : null;

      this.rulesType = this.listError.type_id
        ? [() => this.listError.type_id]
        : [];
      this.rulesProject = this.listError.project
        ? [() => this.listError.project]
        : [];
      this.rulesGrantingDate = this.listError.granting_date
        ? [() => this.listError.granting_date]
        : [];
      this.rulesConcessionCode = this.listError.concession_code
        ? [() => this.listError.concession_code]
        : [];
      this.rulesConcessionName = this.listError.concession_name
        ? [() => this.listError.concession_name]
        : [];
      this.rulesHolderName = this.listError.holder_name
        ? [() => this.listError.holder_name]
        : [];
      this.rulesAssigneeName = this.listError.assignee_name
        ? [() => this.listError.assignee_name]
        : [];
      this.rulesHectaresFormulated = this.listError.hectares_formulated
        ? [() => this.listError.hectares_formulated]
        : [];
      this.rulesEffectiveHectares = this.listError.effective_hectares
        ? [() => this.listError.effective_hectares]
        : [];
      this.$refs.formUpdate.validate();
    },
    onCloseDialogQuestion() {
      this.dialogQuestion = false;
      this.$emit("change", false);
    },
    validUndefined(val) {
      return val === undefined ? null : val;
    },
    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? null : value.id;
      } else {
        return this.isEmpty(value) ? null : value;
      }
    },
    validateDialogDraft() {
      this.flagChangeForm = true;
    },
    onCloseDialogUpdate() {
      this.clearErrors();
      const aux = cloneDeep(this.selectedItem);
      if (!isEqual(aux, this.cloneItem)) {
        this.dialogQuestion = true;
      } else {
        this.$emit("close");
        this.$emit("change", false);
        // this.dialogNew = false;
        this.dialogQuestion = false;
        this.selectedItem = new IMiningProperty();
        // this.flagChangeForm = false;
      }
    },
    openDialogDetail(item, flagEdit = false) {
      this.selectedItem = new IMiningProperty();
      this.cloneItem = new IMiningProperty();
      this.clearErrors();
      this.$nextTick(() => {
        if (flagEdit) this.disabledEdit = flagEdit;
      });
      // this.dialogNew = true;
    },
    async openDialogEdit(item) {
      this.loadDetail = true;
      const { data } = await this.axios.get(
        `/api/mining_properties/${item.id}`,
      );
      if (data.success) {
        const selectedItem = cloneDeep(data.data);
        this.selectedItem = cloneDeep(selectedItem);
        this.cloneItem = cloneDeep(selectedItem);
        this.disabledEdit = false;
        this.loadDetail = false;
      }
    },
    clearErrors() {
      // set errror
      this.rulesType = [];
      this.rulesProject = [];
      this.rulesGrantingDate = [];
      this.rulesConcessionCode = [];
      this.rulesConcessionName = [];
      this.rulesHolderName = [];
      this.rulesAssigneeName = [];
      this.rulesHectaresFormulated = [];
      this.rulesEffectiveHectares = [];
      this.listError = {
        project: null,
        type_id: null,
        concession_code: null,
        concession_name: null,
        holder_name: null,
        assignee_name: null,
        granting_date: null,
        hectares_formulated: null,
        effective_hectares: null,
      };
    },
    onChangeInput($event, name) {
      this.$nextTick(() => {
        this.$set(this.listError, name, null);
      });
    },
    onChangeType() {
      this.listError.type_id = "";
    },
    onChangeGrantingDate() {
      this.listError.granting_date = "";
      this.showGrantingDate = false;
    },
    async onSubmitWork() {
      if (this.loadWord) return false;
      this.loadWord = true;
      const { data } = await this.axios.post(
        `/api/mining_properties/export/word/${this.selectedItem.id}`,
      );
      if (data.success) {
        this.urlDownload = `${this.baseURL}/${data.data.url}`;
        this.loadWord = false;
        this.$nextTick(() => {
          document.getElementById("urlDownloadWord").click();
        });
      }
      this.loadWord = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split(" ")[0].split("-");
      return `${day}/${month}/${year}`;
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
    validateDateFormat(fullDate) {
      if (this.isEmpty(fullDate)) {
        return null;
      }
      return fullDate.split(" ")[0];
    },
  },
};
</script>
