<template>
  <v-container fluid fill-height class="page-dashboard pa-0">
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>{{
          isEmpty(typeModule)
            ? menuActive.label
            : $store.state.modules.activeModule.label
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <vue-perfect-scrollbar
      class="card-menu--scroll"
      style="width: 100%"
      :settings="scrollSettings"
    >
      <v-container fluid fill-height style="height: calc(100% - 65px)">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="10" class="py-0">
            <v-row justify="center">
              <template v-if="modules === null">
                <!-- Contenido para elementos deshabilitados -->
                <div>Contenido para elementos deshabilitados</div>
              </template>
              <template v-else>
                <v-col
                  v-for="item in modules"
                  :key="'module_' + item.id"
                  cols="12"
                  lg="3"
                  md="4"
                  sm="6"
                  class="pa-2"
                >
                  <card-menu
                    :id="item.id"
                    :key="`cardMenu${item.id}`"
                    :item="item"
                    :class="{ disabled: item.disabled === 1 }"
                    @click.native="item.disabled === 0 && goToMenu(item)"
                  ></card-menu>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </vue-perfect-scrollbar>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CardMenu from "@/components/commons/CardMenu";

export default {
  name: "ModulesPage",
  components: {
    VuePerfectScrollbar,
    CardMenu,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    scrollSettings: {
      maxScrollbarLength: 100,
      suppressScrollX: true,
    },
    menu: {},
    submenu: [],
  }),
  computed: {
    ...mapState({
      listModules: (state) => state.modules.modules,
      activeModule: (state) => state.modules.activeModule,
      menuActive: (state) => state.menuAdmin.menuActive,
    }),
    modules() {
      if (this.activeModule?.disabled === 1) return null;
      if (this.activeModule && this.typeModule !== "") {
        return this.activeModule.submodules;
      } else {
        return this.listModules;
      }
    },
  },
  created() {
    this.fetchModules();
  },
  methods: {
    ...mapActions(["fetchModules", "setActiveModules", "setActiveSubModules"]),
    goToMenu(menu) {
      if (menu.slug === "propiedades-mineras") {
        this.setActiveModules(menu).then(() => {
          this.$router.push({
            name: "SwitchModulePage",
            params: {
              typeModule: this.activeModule.slug,
              typeSubmodule: menu.slug,
            },
          });
        });
      } else {
        if (menu.submodules) {
          this.setActiveModules(menu).then(() => {
            this.$router.push({
              name: "SubModulesPage",
              params: { typeModule: menu.slug },
            });
          });
        } else {
          this.setActiveSubModules(menu).then(() => {
            this.$router.push({
              name: "SwitchModulePage",
              params: {
                typeModule: this.activeModule.slug,
                typeSubmodule: menu.slug,
              },
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scope>
.v-sheet.v-card.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
