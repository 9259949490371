<template>
  <v-dialog
    v-model="showDialog"
    max-width="300"
    persistent
    open-on-focus
    @keydown.esc="onCloseDialog()"
  >
    <v-card>
      <v-card-title class="py-1">
        <p class="subheading mb-0">{{ title }}</p>
      </v-card-title>
      <v-card-text>
        <div v-if="isModule">
          <span v-if="!isEmpty(item)"
            >¿Estás seguro que deseas eliminar la ficha {{ item.id }}?</span
          >
        </div>
        <div v-else>¿Estás seguro que deseas eliminarlo?</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-transform-none"
          color="action"
          small
          text
          @click="onCloseDialog()"
          >Cerrar</v-btn
        >
        <v-btn
          color="action"
          dark
          small
          depressed
          class="btn-transform-none"
          @click="onSubmitDelete()"
          ><v-progress-circular
            v-if="loadDelete"
            :size="20"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadDelete ? "Eliminando" : "Eliminar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogDelete",
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Eliminar",
    },
    item: {
      type: Object,
      default: () => {},
    },
    isModule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadDelete: false,
    };
  },
  methods: {
    onSubmitDelete() {
      if (this.loadDelete) {
        return false;
      }
      this.loadDelete = true;
      this.$emit("delete");
    },
    onCloseDialog() {
      this.$emit("change", !this.showDialog);
    },
    toggleLoad(flag) {
      this.loadDelete = flag;
    },
  },
};
</script>
