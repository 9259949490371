<template>
  <v-navigation-drawer
    class="sectionDrawer gmapDrawer"
    :class="{ 'active-collapse': toggleDrawer }"
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: 100% !important; top: 0 !important;"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll block-filter"
      :settings="scrollSettings"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="searchText"
            placeholder="Buscar"
            height="56"
            outlined
            class="mb-3 input-custom-56"
            hide-details
            append-icon="search"
            v-on="on"
          ></v-text-field>
        </template>
        <span>Búsqueda por palabra</span>
      </v-tooltip>
      <v-row>
        <v-col cols="12" class="mb-2 py-0">
          <span class="font-weight-bold">Filtrar</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectModule"
            :items="listModule"
            class="mt-0 pt-0 input-custom-56"
            label="Módulo"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectSubmodule"
            :items="optionsSelectSub"
            :disabled="disabledSubmodule"
            class="mt-0 pt-0 input-custom-56"
            label="Submódulo"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedType"
            :items="listType.options"
            :disabled="disabledType"
            class="mt-0 pt-0 input-custom-56"
            label="Tipo"
            placeholder="Seleccione tipo"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            :items="listProjects.options"
            class="mt-0 pt-0 input-custom-56"
            label="Proyecto"
            placeholder="Seleccione proyecto"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <selector-user
            v-model="selectedResponsible"
            :text-error="''"
          ></selector-user>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedState"
            :items="listSituations.options"
            class="mt-0 pt-0 input-custom-56"
            label="Estado"
            placeholder="Seleccione estado"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 mb-3">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-btn
                class="ma-0"
                color="white"
                depressed
                small
                block
                @click="clearFilters()"
                >Limpiar
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-btn
                class="ma-0"
                color="action"
                :loading="loadSearch"
                dark
                depressed
                small
                block
                @click="onSearch()"
                >Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-1 py-0">
          <span class="font-weight-bold">Layers de Geocatmin</span>
        </v-col>
        <v-col cols="12" class="py-0 filter-layer mb-2">
          <v-checkbox
            v-model="selectedLayer.catastro"
            :false-value="false"
            :true-value="true"
            hide-details
            dense
            :ripple="false"
            class="py-0 mt-0"
            label="Catastro minero"
            @change="onChangeLayer"
          ></v-checkbox>
          <v-checkbox
            v-model="selectedLayer.area"
            :false-value="false"
            :true-value="true"
            hide-details
            dense
            :ripple="false"
            class="py-0 mt-0"
            label="Áreas reservada"
            @change="onChangeLayer"
          ></v-checkbox>
          <v-checkbox
            v-model="selectedLayer.cartografia"
            :false-value="false"
            :true-value="true"
            hide-details
            dense
            :ripple="false"
            class="py-0 mt-0"
            label="Cartografía demarcación"
            @change="onChangeLayer"
          ></v-checkbox>
        </v-col>
      </v-row>
    </vue-perfect-scrollbar>
    <button type="button" class="btn-toggleDrawer" @click="onToggleDrawer()">
      <img v-if="toggleDrawer" src="@/assets/arrow-expand-right.png" />
      <img v-else src="@/assets/arrow-expand-left.png" />
    </button>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SelectorUser from "@/components/commons/SelectorUser";
import { mapState } from "vuex";
import { isEmpty, debounce } from "lodash";

export default {
  name: "MapDrawer",
  components: {
    VuePerfectScrollbar,
    SelectorUser,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    selectModule: null,
    selectSubmodule: null,
    searchText: null,
    disabledSubmodule: true,
    selectedProject: null,
    disabledType: true,
    selectedType: null,
    selectedState: null,
    selectedResponsible: null,
    optionsSelectSub: [],
    loadSearch: false,
    toggleDrawer: false,
    selectedLayer: {
      catastro: false,
      area: false,
      cartografia: false,
    },
    debounceChange: null,
    debounceModule: null,
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listModule: (state) => state.modules.modules,
      listProjects: (state) => state.masters.listProjects,
      filterData: (state) => state.report.filterData,
      listSituations: (state) => state.masters.listSituations,
    }),
    listType() {
      if (
        !this.isEmpty(this.selectModule) &&
        !this.isEmpty(this.selectSubmodule)
      ) {
        const types = this.listMasters.filter((e) => {
          return (
            e.slug ===
            `${this.selectModule.slug}-${this.selectSubmodule.slug}-tipo`
          );
        });
        return types.length ? types[0] : { options: [] };
      }
      return { options: [] };
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (!this.isEmpty(val)) {
          this.searchText = val;
          this.onSearch();
        }
      },
    },
    selectModule(val) {
      if (val) {
        this.disabledSubmodule = false;
        this.optionsSelectSub = val.submodules;
      }
    },
    selectSubmodule(val) {
      if (val) {
        this.disabledType = false;
      }
    },
    selectedLayer: {
      deep: true,
      handler(val) {
        this.$emit("catastro", val.catastro);
      },
    },
  },
  mounted() {
    this.debounceModule = debounce(this.onSearch, 700);
    this.debounceChange = debounce(this.onSearch, 900);
  },
  methods: {
    onToggleDrawer() {
      this.toggleDrawer = !this.toggleDrawer;
      this.$emit("collapse", this.toggleDrawer);
    },
    onChangeLayer() {
      this.$emit("geocatmin-layer", this.selectedLayer);
    },
    clearFilters() {
      this.selectModule = null;
      this.selectSubmodule = null;
      this.disabledSubmodule = true;
      this.optionsSelectSub = [];
      this.selectedResponsible = null;
      this.selectedProject = null;
      this.selectedType = null;
      this.disabledType = true;
      this.selectedState = false;
      this.$emit("clear-filter");
    },
    searchById(val) {
      this.searchText = val;
      this.onSearch();
    },
    onSearch() {
      let payload = "?response=lite";
      let filterData = "";
      if (!isEmpty(this.selectModule)) {
        filterData += `&module_id=${this.selectModule.id}`;
      }
      if (!isEmpty(this.selectSubmodule)) {
        filterData += `&submodule_id=${this.selectSubmodule.id}`;
      }
      if (!isEmpty(this.selectedType)) {
        filterData += `&type_id=${this.selectedType.id}`;
      }
      if (!isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!isEmpty(this.selectedResponsible)) {
        filterData += `&responsible_id=${this.selectedResponsible.id}`;
      }
      if (!isEmpty(this.selectedState)) {
        filterData += `&situation_id=${this.selectedState.id}`;
      }
      if (!isEmpty(this.searchText)) {
        filterData += `&search=${this.searchText}`;
      }
      this.loadSearch = true;
      payload += filterData;
      this.$emit("filter", payload);
    },
    setLoadSearch(flag) {
      this.loadSearch = flag;
    },
  },
};
</script>
