<template>
  <v-card class="block-report" flat tile>
    <v-btn
      v-if="loading"
      class="btn-bottom"
      fab
      dark
      small
      color="primary"
      @click="goToScroll('chartThree1', 'contentReports')"
    >
      <v-icon dark>keyboard_arrow_down</v-icon>
    </v-btn>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col id="chartThree1" cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 2
            </h4>
            <doughnut-chart
              v-if="loading"
              ref="reportThree1"
              chart-id="reportThree1"
              :chart-data="optionChart"
              :title="titleChartPie"
            ></doughnut-chart>
            <v-row v-if="loading">
              <!--<v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadThree1"
                  @click="onDownloadChart('reportThree1', 'three1')"
                  >Descargar Reporte
                </v-btn>
              </v-col>!-->
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ formatPercentage(item.value, dataTotal) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal }}</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 2 de 2
            </h4>
            <bar-chart
              v-if="loading"
              ref="reportThree2"
              chart-id="reportThree2"
              :chart-data="optionChart"
              :title="titleChartBar"
            ></bar-chart>
            <v-row v-if="loading">
              <!--<v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadThree2"
                  @click="onDownloadChart('reportThree2', 'three2')"
                  >Descargar Reporte
                </v-btn>
              </v-col>!-->
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ formatPercentage(item.value, dataTotal) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal }}</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DoughnutChart from "./charts/DoughnutChart";
import BarChart from "./charts/BarChart";
import { setColors } from "./colors";

export default {
  name: "ReportThree",
  components: {
    DoughnutChart,
    BarChart,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loadThree1: false,
    loadThree2: false,
    loading: false,
    optionChartColumn: {},
    optionChartPie: {},
    optionChart: {},
    dataTable: [],
    dataTotal: 0,
  }),
  computed: {
    titleChartPie() {
      let label = "% de Tipo ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "% de Tipo General";
    },
    titleChartBar() {
      let label = "Cantidad de Tipo ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "Cantidad de Tipo General";
    },
  },
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/four${this.filterData}`,
      );
      if (data.success) {
        this.loading = true;
        let dataChart = data.data;

        let optionChart = {
          labels: [],
          datasets: [
            {
              label: "DataSet1",
              backgroundColor: [],
              data: [],
            },
          ],
        };
        let count = 0;
        dataChart.forEach((e, i) => {
          optionChart.labels.push(e.name);
          optionChart.datasets[0].backgroundColor.push(setColors[i]);
          optionChart.datasets[0].data.push(e.count);
          this.dataTable.push({
            label: e.name,
            value: e.count,
          });
          count += e.count;
        });
        this.dataTotal = count;
        this.optionChart = optionChart;
      }
    },
    async onDownloadChart(nameChart, type) {
      const element = document.getElementById(nameChart);
      if (type === "three1") {
        this.loadThree1 = true;
      } else {
        this.loadThree2 = true;
      }
      var dataURL = element.toDataURL("image/png");
      let file = null;
      await fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "File name", { type: "image/png" });
        });
      const formData = new FormData();
      formData.append("file", file);
      const listFilter = this.parseFilterReport(this.filterData);
      for (let key in listFilter) {
        if (listFilter[key]) {
          formData.append(key, listFilter[key]);
        }
      }
      const { data } = await this.axios({
        method: "post",
        url: "/api/reports/four/export",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (type === "three1") {
        this.loadThree1 = false;
      } else {
        this.loadThree2 = false;
      }
      this.$nextTick(() => {
        document.getElementById(
          "urlDownload",
        ).href = `${this.baseURL}/${data.data.url}`;
        document.getElementById("urlDownload").click();
      });
    },
  },
};
</script>
