import axios from "../../http";
import { findIndex } from "lodash";

const masters = {
  state: {
    listMasters: [],
    listMasterOptions: [],
    listSituations: [],
    listProjects: [],
    listDatum: [],
    listZone: [],
    listState: [],
    listMastersGroup: [],
    loadMasters: false,
    loadMasterOptions: false,
    loadMastersGroup: false,
    pageMasters: 1,
    pageMasterOptions: 1,
    sortMasters: "",
    sortMasterOptions: "",
  },
  mutations: {
    setLoadMasters(state, value) {
      state.loadMasters = value;
    },
    setLoadMasterOptions(state, value) {
      state.loadMasterOptions = value;
    },
    setMasters(state, value) {
      state.listMasters = value;
    },
    setMasterOptions(state, value) {
      state.listMasterOptions = value;
    },
    setMastersGroup(state, value) {
      state.listMastersGroup = value;
    },
    setLoadMastersGroup(state, value) {
      state.loadMastersGroup = value;
    },
    setSituations(state, value) {
      state.listSituations = value;
    },
    setProjects(state, value) {
      state.listProjects = value;
    },
    setDatum(state, value) {
      state.listDatum = value;
    },
    setZone(state, value) {
      state.listZone = value;
    },
    setState(state, value) {
      state.listState = value;
    },
    deleteMasters(state, id) {
      const inx = findIndex(state.listMasters, { id });
      state.listMasters.splice(inx, 1);
    },
    createMasters(state, payload) {
      state.listMasters.unshift(payload);
    },
    editMasters(state, payload) {
      const { id } = payload;
      const inx = findIndex(state.listMasters, { id });
      state.listMasters[inx] = payload;
    },
    clearMasters(state) {
      state.listMasters = [];
      state.pageMaster = 1;
      state.loadMasters = false;
      state.sortMaster = "";
    },
    updatePageMasters(state, value) {
      state.pageMasters = value;
    },
    createMasterOptions(state, payload) {
      state.listMasterOptions.unshift(payload);
    },
    deleteMasterOptions(state, id) {
      const inx = findIndex(state.listMasterOptions, { id });
      state.listMasterOptions.splice(inx, 1);
    },
    editMasterOptions(state, payload) {
      const { id } = payload;
      const inx = findIndex(state.listMasterOptions, { id });
      state.listMasterOptions[inx] = payload;
    },
    clearMasterOptions(state) {
      state.listMasterOptions = [];
      state.pageMasterOptions = 1;
      state.loadMasterOptions = false;
      state.sortMasterOptionss = "";
    },
    updatePageMasterOptions(state, value) {
      state.pageMastersOption = value;
    },
  },
  actions: {
    fetchMasters({ commit }, { payload = "", step }) {
      return new Promise(async (resolve, reject) => {
        if (step) {
          commit("setLoadMasters", false);
        }
        const { data } = await axios.get(`/api/masters${payload}`);
        if (data.success) {
          const items = data.data;
          commit("setMasters", items);
          commit(
            "setState",
            items.filter((e) => {
              return e.slug === "estado";
            })[0],
          );
          commit(
            "setSituations",
            items.filter((e) => {
              return e.slug === "situacion";
            })[0],
          );
          commit(
            "setProjects",
            items.filter((e) => {
              return e.slug === "proyectos";
            })[0],
          );
          commit(
            "setDatum",
            items.filter((e) => {
              return e.slug === "datum";
            })[0],
          );
          commit(
            "setZone",
            items.filter((e) => {
              return e.slug === "zona";
            })[0],
          );
          if (step) {
            setTimeout(() => {
              commit("setLoadMasters", true);
            }, 500);
          }
          resolve(items);
        } else {
          commit("setMasters", []);
          if (step) {
            setTimeout(() => {
              commit("setLoadMasters", true);
            }, 500);
          }
          reject();
        }
      });
    },
    fetchMasterByMaster({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/masters/${id}`);
        if (data.success) {
          resolve(data.data);
          // commit("setSubModules", data.data);
        } else {
          reject();
        }
      });
    },
    editMasters({ commit }, { id, payload }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(`/api/masters/${id}/update`, payload);
        if (data.success) {
          commit("editMasters", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    createMasters({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(`/api/masters/create`, payload);
        if (data.success) {
          commit("createMaster", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    deleteMasters({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.delete(`/api/masters/${id}/delete`);
        if (data.success) {
          commit("deleteMaster", id);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    fetchMasterOptionsByMaster({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/masters/master_option/${id}`);
        if (data.success) {
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    fetchMastersGroup({ commit }, id) {
      commit("setLoadMastersGroup", false);
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/masters/grouped`);
        if (data.success) {
          commit("setMastersGroup", data.data);
          commit("setLoadMastersGroup", true);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    fetchMasterOptions({ commit }, { payload = "", step }) {
      return new Promise(async (resolve, reject) => {
        if (step) {
          commit("setLoadMasterOptions", false);
        }
        const { data } = await axios.get(`/api/master_options/${payload}`);
        if (data.success) {
          const items = data.data;
          commit("setMasterOptions", items);
          if (step) {
            setTimeout(() => {
              commit("setLoadMasterOptions", true);
            }, 500);
          }
          resolve(data.data);
        } else {
          commit("setMasterOptions", []);
          if (step) {
            setTimeout(() => {
              commit("setLoadMasterOptions", true);
            }, 500);
          }
          reject();
        }
      });
    },
    editMasterOptions({ commit }, { id, payload }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(
          `/api/master_options/${id}/update`,
          payload,
        );
        if (data.success) {
          commit("editMasterOptions", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    createMasterOptions({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(
          `/api/master_options/create`,
          payload,
        );
        if (data.success) {
          commit("createMasterOptions", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    deleteMasterOptions({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.delete(`/api/master_options/${id}/delete`);
        if (data.success) {
          commit("deleteMasterOptions", id);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    updatePageMasters({ commit }, pages) {
      commit("updatePageMasters", pages);
    },
    clearMasters({ commit }) {
      commit("clearMasters");
    },
    updatePageMasterOptions({ commit }, pages) {
      commit("updatePageMasterOptions", pages);
    },
    clearMasterOptions({ commit }) {
      commit("clearMasterOptions");
    },
  },
};

export default masters;
