export class IEnvironmentals {
  constructor() {
    this.id = null;
    this.type = {};
    this.situation = {};
    this.detail = null;
    this.description = null;
    this.legal_base = null;
    this.project = {};
    this.authority = {};
    // Dates
    this.expiration_date = null;
    this.expiration_date_disabled = 0;
    this.effective_compliance_date = null;
    // User | Responsible
    this.creator = {};
    this.primary_responsible = {};
    this.secondary_responsible = {};
    // Checklist
    this.pending_tasks = [];
    // Map
    this.zone = {};
    this.datum = {};
    this.locations = [];
    this.location_disabled = 0;
  }
}
