<template>
  <div class="page-dashboard fill-height">
    <v-container fluid fill-height>
      <v-row align="center" justify="center" fill-height>
        <v-col cols="12" lg="10">
          <v-row>
            <v-col
              v-for="item in listModules"
              :key="'module_' + item.id"
              cols="12"
              lg="3"
              md="4"
              sm="6"
              class="pa-2"
            >
              <v-card
                class="card-link"
                flat
                tile
                elevation="5"
                @click="goToMenu(item)"
              >
                <v-img
                  height="126px"
                  class="py-2"
                  align="center"
                  justify="center"
                  style="text-align: center;"
                >
                  <!--<img
                    :src="require(`../assets/icons/icon-${item.icon}.png`)"
                    class="mx-auto"
                    width="110"
                  />-->
                </v-img>
                <v-divider></v-divider>
                <v-card-title class="card-twoLine">
                  <p class="my-0 body-2">{{ item.label }}</p>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { MenuAdmin } from "../api/menu";
import { findIndex } from "lodash";
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import PageHeader from '../commons/PageHeader'
export default {
  name: "SubMenuPage",
  // components: {
  //   VuePerfectScrollbar
  // },
  props: {
    id: {
      type: String,
      default: "",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     vm.filterMenu(vm.menu, 1)
  //   })
  // },
  // beforeRouteUpdate (to, from, next) {
  //   this.filterMenu(this.menu, 1)
  //   next()
  // },
  data() {
    return {
      menu: MenuAdmin,
      submenu: [],
    };
  },
  computed: {
    getMenu() {
      return this.$store.getters[`menuAdmin/getMenu`];
    },
    getMenuActive() {
      return this.$store.getters[`menuAdmin/getMenuActive`];
    },
    getListActive() {
      return this.$store.getters[`menuAdmin/getListActive`];
    },
    ...mapState({
      listModules: (state) => state.modules.modules,
    }),
  },
  created() {
    this.fetchModules();
  },
  methods: {
    ...mapActions(["fetchModules", "fetchSubModule"]),
    goToMenu(menu) {
      this.fetchSubModule(menu);
      const findInx = findIndex(this.getMenu[this.getMenu.length - 1], {
        key: this.getListActive[this.getListActive.length - 1].key,
      });
      if (!menu.leaf) {
        this.$store.dispatch(`menuAdmin/setMenu`, {
          menu: this.getMenu[this.getMenu.length - 1][findInx].submenu,
          init: this.getMenu.length === 0,
          index: this.getMenu.length,
        });

        this.$store.dispatch(`menuAdmin/setMenuActive`, menu);

        this.$store.dispatch(`menuAdmin/setListActive`, {
          active: menu,
          init: this.getMenu.length === 0,
          index: this.getMenu.length,
        });
        this.$router.push({
          name: "Level2MenuPage",
          params: { id: this.id, typeModule: menu.key },
        });
      } else {
        this.$store.dispatch(`menuAdmin/setMenu`, {
          menu: this.getMenu[this.getMenu.length - 1][findInx].submenu,
          init: this.getMenu.length === 0,
          index: this.getMenu.length,
        });
        this.$store.dispatch(`menuAdmin/setListActive`, {
          active: menu,
          init: this.getMenu.length === 0,
          index: this.getMenu.length,
        });

        const settings = [
          "TermPage",
          "PoliticPage",
          "CopyPage",
          "NotificationPage",
          "MastersPage",
          "MastersOptionPage",
        ];
        if (menu.name === "ProcessPage") {
          this.$router.push({
            name: menu.name,
            params: { id: this.id, typeModule: menu.key },
          });
        } else if (settings.includes(menu.name)) {
          this.$router.push({ name: menu.name });
        } else {
          if (menu.name === "SurveillancePage") {
            this.$router.push({
              name: "SurveillancePage",
              params: {
                id: this.id,
                typeModule: this.typeModule,
                typeSubmodule: menu.key,
              },
            });
          } else {
            this.$router.push({
              name: "LastLevelAdminPage",
              params: {
                id: this.id,
                typeModule: this.typeModule,
                typeSubmodule: menu.key,
              },
            });
          }
        }
      }
    },
    validateStatus(status) {
      return status === "done"
        ? "green"
        : status === "timer"
        ? "yellow"
        : "red";
    },
  },
};
</script>
