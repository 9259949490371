export class IStepInstances {
  constructor() {}
  getInstanceOne() {
    return {
      id: null,
      finished: 0,
      attachment: null,
      receipt_date: null,
      summary_resolution_sanction: null,
    };
  }
  getInstanceTwo() {
    return {
      id: null,
      finished: 0,
      release_writing_charge_1: null,
      pending_tasks: [],
    };
  }
  getInstanceThree() {
    return {
      id: null,
      finished: 0,
      release_writing_charge_2: null,
      release_writing_charge_2_disabled: 0,
      pending_tasks: [],
    };
  }
  getInstanceFour() {
    return {
      id: null,
      finished: 0,
      pending_tasks: [],
      additional_information: null,
      additional_information_disabled: 0,
    };
  }
  getInstanceFive() {
    return {
      id: null,
      finished: 0,
      pending_tasks: [],
      attachment: null,
      receipt_date: null,
      summary_resolution_sanction: null,
    };
  }
  getInstanceSix() {
    return {
      id: null,
      finished: 0,
      pending_tasks: [],
      release_writing_charge_1: null,
    };
  }
  getInstanceSeven() {
    return {
      id: null,
      finished: 0,
      first_instance_resolution_result: 0,
      first_instance_resolution_attachment: null,
      first_instance_resolution_summary: null,
    };
  }
  getInstanceEight() {
    return {
      id: null,
      finished: 0,
      appeal_fee_disabled: 0,
      appeal_fee: null,
      pending_tasks: [],
    };
  }
  getInstanceNine() {
    return {
      id: null,
      finished: 0,
      date_oral_report: null,
      oral_report_notification_disabled: 0,
      oral_report_notification: null,
      pending_tasks: [],
    };
  }
  getInstanceTen() {
    return {
      id: null,
      finished: 0,
      first_instance_resolution_result: 0,
      second_instance_resolution_attachment: null,
      second_instance_resolution_summary: null,
      administrative_contentious_lawsuit: 0,
      pending_tasks: [],
    };
  }
}
