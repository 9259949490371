import {cloneDeep, isEmpty} from "lodash";

export default {
  data() {
    return {
      flagSelectDraft: false,
      dialogDraft: false,
      loadDraft: false,
      drafts: [],
    };
  },
  methods: {
    onSubmitDraft(selectedItem = null) {
      selectedItem = selectedItem ? selectedItem : cloneDeep(this.selectedItem);
      const payload = {
        type_id: this.validateFormat(selectedItem.type, "id"),
        situation_id: this.validateFormat(selectedItem.situation, "id"),
        expiration_date: selectedItem.expiration_date || null,
        presentation_date: selectedItem.presentation_date || null,
        approval_date: selectedItem.approval_date || null,
        datum_id: this.validateFormat(selectedItem.datum, "id"),
        zone_id: this.validateFormat(selectedItem.zone, "id"),
        legal_term: this.parseStringToNumber(selectedItem.legal_term),
        planned_term: this.parseStringToNumber(selectedItem.planned_term),
        actual_term: this.parseStringToNumber(selectedItem.actual_term),
        authority_id: this.validateFormat(selectedItem.authority, "id"),
        water_administrative_authority_id: this.validateFormat(
          selectedItem.water_administrative_authority,
          "id",
        ),
        local_water_authority_id: this.validateFormat(
          selectedItem.local_water_authority,
          "id",
        ),
        creator_id: this.validateFormat(selectedItem.creator, "id"),
        primary_responsible_id: this.validateFormat(
          selectedItem.primary_responsible,
          "id",
        ),
        secondary_responsible_id: this.validateFormat(
          selectedItem.secondary_responsible,
          "id",
        ),
        project_id: this.validateFormat(selectedItem.project, "id"),
        detail: selectedItem.detail,
        legal_base: selectedItem.legal_base,
        link_spij: selectedItem.link_spij,
        order: 1,
      };
      this.loadUpdate = false;
      this.loadDraft = true;
      let auxPayload = cloneDeep(payload);
      const pendingTask = selectedItem.pending_tasks;
      const requirements = selectedItem.requirements;
      const locations = selectedItem.locations;
      delete auxPayload.pending_tasks;
      delete auxPayload.requirements;
      delete auxPayload.locations;
      // console.log(this.selectedItem);
      let urlBase = `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}/create`;
      if (selectedItem.id) {
        urlBase = `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}/${selectedItem.id}/update`;
      }
      this.axios
        .post(urlBase, payload)
        .then(({ data }) => {
          if (data.success) {
            if (locations.length)
              this.loopLocation(data.data.id, locations, 0, true);
            const checklist = [...pendingTask, ...requirements];
            if (checklist.length)
              this.loopToDo(data.data.id, checklist, 0, true);
          }
          this.$refs.dialogDetailCard.setLoadQuestion(false);
          this.dialogDraft = false;
          this.dialogNew = false;
        })
        .catch(() => {
          this.loadDraft = false;
        });
    },
    onDialogDraft() {
      this.dialogDraft = true;
    },
    onSelectItemDraft(item) {
      this.dialogDraft = false;
      this.typeDialog = "edit";
      this.dialogNew = true;

      // this.disabledEdit = false;
      this.$nextTick(() => {
        this.selectedItem = cloneDeep(item);
        this.cloneItem = cloneDeep(item);
        this.flagSelectDraft = true;
        setTimeout(() => {
          this.$refs.dialogDetailCard.openDialogNew(
            this.selectedItem,
            true,
            true,
          );

          this.$refs.dialogDetailCard.clearErrors();
        }, 300);
      });
    },
  },
};
