<template>
  <div class="page-section fill-height">
    <vue-perfect-scrollbar
      class="drawer-full--scroll"
      :settings="scrollSettings"
    >
      <v-container grid-list-xl fluid>
        <v-layout row wrap>
          <v-flex xs12 md10 offset-md1>
            <v-card>
              <v-card-text>
                <h3>Terminos y condiciones</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus dolorum ipsa quis consequuntur quae quidem molestiae
                  perspiciatis accusantium consectetur. Tempore facilis quos
                  fugit perspiciatis officiis dolore eligendi obcaecati tempora
                  ipsum?
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Corrupti, sequi nihil quod quidem impedit fugiat! Excepturi ut
                  alias quasi quod impedit quaerat laborum, culpa eaque aut
                  tempora incidunt, architecto cupiditate?
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Reprehenderit animi modi fugit sit, eveniet consequuntur.
                  Suscipit ullam cumque corrupti dignissimos temporibus alias,
                  quasi rerum, officiis blanditiis aliquam facilis ex
                  voluptas.<br />Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Ullam necessitatibus ducimus debitis
                  quibusdam. Autem, impedit perferendis obcaecati nulla
                  voluptate vel sunt nisi odit quia natus optio consequuntur?
                  Magni, consequatur ipsam!
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
                  odio dignissimos sed deserunt obcaecati dolorem porro sapiente
                  ex ad eaque, assumenda possimus, veniam quo ducimus autem
                  maxime commodi repellendus minima? Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Quas voluptate dicta aperiam,
                  illum aspernatur quo impedit perspiciatis cupiditate ex
                  repellendus eos maxime, possimus, quis ipsum vel? Eos ut
                  laborum suscipit. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Cum quos impedit reprehenderit libero
                  voluptates alias nihil eveniet ab recusandae totam mollitia
                  magnam minima, autem tenetur corporis numquam omnis quo
                  ratione.
                </p>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </vue-perfect-scrollbar>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "CopyPage",
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    scrollSettings: {
      maxScrollbarLength: 160,
      // preventParentScroll: true,
      // scrollThrottle: 30,
    },
  }),
};
</script>
