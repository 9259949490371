import axios from "axios";
import store from "./store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("JARU_TOKEN") || null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (!response.data.success) {
      const { error } = response.data;

      if (error?.type === 802) {
        store.commit("setUnauthorized", { show: true, text: error.message });
      }
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject({
        statusCode: 413,
        message: 'El archivo subido es demasiado pesado, solo admitimos 100mb.'
      });
    }

    if (error.response?.status === 401) {
      localStorage.removeItem("JARU_TOKEN");
      window.location = "/login";
    }

    return Promise.reject(error);
  }
);

export default axios;
