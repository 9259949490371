<template>
  <div class="page-section fill-height">
    <vue-perfect-scrollbar
      class="drawer-full--scroll"
      :settings="scrollSettings"
    >
      <v-container fluid>
        <v-row row wrap>
          <v-col cols="12" md="6" offset-md="3">
            <v-card>
              <v-card-text>
                <h3 class="mb-4">Perfil</h3>
                <v-row v-if="!isEmpty(profile)">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="profile.first_name"
                      label="Nombres"
                      height="40"
                      outlined
                      :disabled="isEditable"
                      class="mb-4 py-0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="profile.last_name"
                      label="Apellidos"
                      height="40"
                      class="mb-4 py-0"
                      outlined
                      :disabled="isEditable"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="profile.email"
                      label="Email"
                      height="40"
                      class="mb-4 py-0"
                      outlined
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="profile.phone_number"
                      label="Teléfono"
                      height="40"
                      class="mb-4 py-0"
                      outlined
                      :disabled="isEditable"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="action"
                  text
                  small
                  class="btn-transform-none"
                  @click="isEditable = !isEditable"
                  >{{
                    isEditable ? "Habilitar " : "Deshabilitar "
                  }}edición</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  class="btn-transform-none"
                  color="action"
                  :disabled="isEditable"
                  :dark="!isEditable"
                  small
                  depressed
                  :loading="loadProfile"
                  @click="onSubmitProfile"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" offset-md="3">
            <v-card>
              <v-card-text>
                <h3>Notificaciones</h3>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_sms"
                      label="Via SMS"
                      value="sms"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_email"
                      label="Via Email"
                      value="email"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="settings.notify_by_push"
                      label="Via Navegador"
                      value="navegador"
                      class="py-0 my-0"
                      :ripple="false"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn-transform-none"
                  :loading="loadSettings"
                  color="action"
                  dark
                  small
                  depressed
                  @click="onSubmitSettings"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </vue-perfect-scrollbar>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "ProfilePage",
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    optionNoti: [],
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    isEditable: true,
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    loadSettings: false,
    loadProfile: false,
    settings: {},
    profile: {},
    loadSpij: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
  },
  mounted() {
    this.profile = cloneDeep(this.currentUser);
    this.settings = cloneDeep(this.currentUser.settings);
  },
  methods: {
    async onSubmitProfile() {
      if (this.loadProfile) {
        return false;
      }
      this.loadProfile = true;
      const payload = {
        user_id: this.currentUser.id,
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        phone_number: this.profile.phone_number,
      };
      const { data } = await this.axios.post(
        `/api/users/${this.currentUser.id}/update`,
        payload,
      );
      if (data.success) {
        this.loadProfile = false;
      }
    },
    async onSubmitSettings() {
      if (this.loadSettings) {
        return false;
      }
      this.loadSettings = true;
      const payload = {
        user_id: this.currentUser.id,
        ...this.settings,
      };
      const { data } = await this.axios.post(
        `/api/users/${this.currentUser.id}/update-settings`,
        payload,
      );
      if (data.success) {
        this.loadSettings = false;
      }
    },
  },
};
</script>
