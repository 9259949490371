<template>
  <v-card class="block-report" flat tile>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col cols="12">
            <div class="flex flex-col gap-5" v-if="si || no > 0">
              <h4  class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 1
              </h4>
              <p class="text-center">{{ title }}</p>
              <pie-chart
                ref="report7"
                chart-id="report7"
                :chart-data="optionChartBar"
                :title="title"
              />
            </div>
            <template v-else>
              <p  class="text-center text-black text-h5 my-10">No se ha encontrado ningún resultado.</p>
            </template>
          </v-col>
          <v-col cols="12" class="text-left" v-if="si || no > 0">
            <v-simple-table class="table-report table-small" :dense="true">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Cumplen</th>
                    <th class="text-left">No cumplen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ si }}</td>
                    <td>{{ no }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from "./charts/PieChart";
import { generatePalette } from "../../utils/palette";

export default {
  components: { PieChart },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loading: false,
    optionChartBar: {},
    dataTable: [],
    dataTotal: 0,
    backgroundColor: [],
    title: "Fichas por X Cumplimiento",
    no: 0,
    si: 0,
  }),
  computed: {},
  watch: {
    filterData(val) {
      const slug = val.split('&');
      const value = this.getFulfilled(slug);

      if (value !== null) {
        this.fetchChart();
      } else {
        this.$nextTick(() => {
          this.si = 0;
          this.no = 0;
          this.title = slug === undefined ? this.title : slug.split("=")[1] === 
            'is_fulfilled_1' ? 'Fichas por cumplimiento de Evaluación de Cumplimiento'
            : 'Fichas por cumplimiento de Auditoría Legal';
        });
      }

      
    },
    title: {
      handler: function(val) {
        return val;
      },
      immediate: true,
    }
  },
  mounted() {
    this.loading = true;
  },
  methods: {
    getFulfilled(slug) {
      if (slug !== undefined) {
        for (const element of slug) {
          if (element.includes('fulfilled=')) {
            return element.split('=')[1];
          }
        }
      }

      return null;
    },
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/eight${this.filterData}`,
      );

      if (data.success) {
        let dataChart = data.data;
        this.si = dataChart["yes"];
        this.no = dataChart["no"];
        this.backgroundColor = [...generatePalette(2)];

        this.optionChartBar = {
          labels: [ '¿Sí cumple?', '¿No cumple?' ],
           datasets: [
            {
              backgroundColor: this.backgroundColor,
              data: [data.data["yes"], data.data["no"]],
            },
          ],
        };

        this.dataTable = dataChart;

        this.loading = true;
      }
    },
    formatDecimal(val) {
      return parseFloat((val * 100).toFixed(2));
    },
  },
};
</script>
