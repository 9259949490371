export const generatePalette = (length) => {
  const colors = [];

  for (let i = 0; i < length; i++) {
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    colors.push(randomColor);
  }

  return colors;
}
