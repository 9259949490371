<template>
  <div class="page-section fill-height">
    <vue-perfect-scrollbar
      class="drawer-menu--scroll"
      :settings="scrollSettings"
    >
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 d-flex>
            <v-card>
              <v-toolbar card dense color="transparent">
                <v-flex lg4>
                  <h2>Alertas</h2>
                </v-flex>
                <v-spacer key="divider-1" class="my-3"></v-spacer>
                <v-flex lg3>
                  <v-select
                    v-model="selectSituation"
                    class="mt-0"
                    :items="itemsTable"
                    placeholder="Seleccione una estado"
                    persistent-hint
                    single-line
                  ></v-select>
                </v-flex>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <template v-if="alerts.length">
                  <v-data-table
                    :headers="headers"
                    :items="alerts"
                    :search="selectSituation"
                    :rows-per-page-items="[
                      {
                        text: '$vuetify.dataIterator.rowsPerPageAll',
                        value: -1,
                      },
                    ]"
                    disable-initial-sort
                    :hide-actions="true"
                    :expand="false"
                    item-key="id"
                    class="table-striped elevation-3"
                  >
                    <template slot="headers" slot-scope="props">
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :width="header.width"
                          class="text-xs-left"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td>{{ props.item.name }}</td>
                        <td class="text-xs-left">{{ props.item.deadline }}</td>
                        <td class="text-xs-left">
                          <span :class="props.item.className">{{
                            props.item.situation
                          }}</span>
                        </td>
                        <td class="text-xs-left">
                          <v-layout row wrap>
                            <v-flex xs6>
                              <v-btn
                                style="min-width: 60px;"
                                class="my-0 ml-0"
                                small
                                color="blue lighten-2"
                                @click="props.expanded = !props.expanded"
                              >
                                <v-icon>{{
                                  !props.expanded
                                    ? "keyboard_arrow_down"
                                    : "keyboard_arrow_up"
                                }}</v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex xs6>
                              <v-btn
                                style="min-width: 60px;"
                                class="ma-0"
                                small
                                color="deep-orange lighten-1"
                                @click="dialogAction = true"
                              >
                                <v-icon>open_in_new</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </td>
                      </tr>
                    </template>
                    <template slot="expand" slot-scope="props">
                      <v-card flat>
                        <v-card-text>
                          <h5 class="body-2">Comentario:</h5>
                          <div class="">{{ props.item.comment }}</div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-data-table>
                </template>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </vue-perfect-scrollbar>
    <div class="v-datatable table-action-fixed v-table">
      <div class="v-datatable__actions">
        <v-container fluid class="pt-3 pb-0">
          <v-row>
            <v-col cols="12" xs="3">
              <div class="v-datatable__actions__pagination">
                Vigentes: <span>10</span>
              </div>
            </v-col>
            <v-col cols="12" xs="3">
              <div class="v-datatable__actions__pagination">
                Vencidas: <span>10</span>
              </div>
            </v-col>
            <v-col cols="12" xs="3">
              <div class="v-datatable__actions__pagination">
                En proceso: <span>30</span>
              </div>
            </v-col>
            <v-col cols="12" xs="3">
              <div class="v-datatable__actions__pagination">
                Fichas totales: <span>{{ alerts.length }}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- div class="v-datatable__actions__range-controls">
      </div -->
      </div>
    </div>
    <v-dialog v-model="dialogAction" max-width="400" persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h5 class="title mb-0">Editar</h5>
            </v-col>
          </v-row>
          <v-divider key="divider-1" class="my-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">Estado</p>
              <v-select
                class="pt-0 mb-3"
                hide-details
                :items="['En proceso', 'Vigente', 'Vencido']"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="dialogAction = false">Cerrar</v-btn>
          <v-btn color="blue darken-1" flat @click="dialogAction = false"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Alerts } from "../utils/todo";
export default {
  name: "AlertPage",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      headers: [
        { text: "Nombre", align: "left", value: "name" },
        { text: "Vencimiento", value: "deadline", width: 200 },
        { text: "Estado", value: "situation", width: 200 },
        { text: "Acción", width: 180 },
      ],
      selectSituation: "Vigente",
      itemsTable: ["En proceso", "Vigente", "Vencido"],
      alerts: [],
      files1: null,
      files2: null,
      flagLoading: false,
      dialogAction: false,
    };
  },
  created() {
    this.getProjectAll();
  },
  methods: {
    clickUpdate1() {
      this.$refs.inputFile1.click();
    },
    clickUpdate2() {
      this.$refs.inputFile2.click();
    },
    handleFileUpload1(e) {
      this.files1 = e.files[0];
    },
    handleFileUpload2(e) {
      this.files2 = e.files[0];
    },
    getProjectAll() {
      setTimeout(() => {
        this.alerts = Alerts;
      }, 600);
    },
    validateStatus(status) {
      return status === "done"
        ? "green"
        : status === "timer"
        ? "yellow"
        : "red";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
