export default {
  data() {
    return {
      sortDesc: null,
      sortBy: null,
    };
  },
  methods: {
    eventSortBy(e) {
      this.sortBy = e.length ? e[0] : null;
      this.fetchSortSupervision();
    },
    eventSortDesc(e) {
      this.sortDesc = e.length ? e[0] : null;
      this.fetchSortSupervision();
    },
    fetchSortSupervision() {
      const sortUrl = `${this.sortName(this.sortBy)}:${
        this.sortDesc ? "desc" : "asc"
      }`;
      if (this.sortBy !== null && this.sortDesc !== null) {
        this.clearSupervisions();
        this.sortSupervision = sortUrl;
        this.fetchSupervisions(this.getCompleteUrl(sortUrl), true);
      } else {
        this.clearSupervisions();
        this.sortSupervision = null;
        const payload = "?response=lite&page=1";
        this.fetchSupervisions(payload, true);
      }
    },
    getCompleteUrl(sortUrl) {
      return `?response=lite&page=1${this.filterData}&sort=${sortUrl}`;
    },
    sortName(name) {
      if (name === "authority.label") {
        return "authority";
      } else if (name === "project.label") {
        return "project";
      } else if (name === "situation.label") {
        return "situation";
      }
      return name;
    },
  },
};
