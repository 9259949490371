<template>
  <v-container
    fluid
    class="page-section page-report pa-0 fill-height"
    style="position: relative"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>Maestros</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <master-drawer :items="listMastersGroup"></master-drawer>
    <div class="content-table-module table-masters" style="overflow: hidden">
      <v-row style="padding: 0px 12px">
        <vue-perfect-scrollbar
          id="listMastersGroup"
          ref="perfectScrollbar"
          style="width: 100%"
          class="content-without-metadata"
          :settings="scrollModuleSettings"
          :swicher="loadData"
        >
          <v-col cols="12">
            <v-card
              v-for="(item, index) in listMastersGroup"
              :id="slugify(item.label)"
              :key="index"
              class="mb-2"
              :elevation="selectedIdScroll === slugify(item.label) ? 7 : 3"
              style="overflow: hidden"
            >
              <v-card-text class="">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <h5 class="body-1 mt-0 mb-1">
                      {{ item.label }}
                      <!-- <v-btn
                        text
                        icon
                        color="blue"
                        class="ml-4 my-0 py-0"
                        @click="onOpenDialogUpdate(item, index)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn> -->
                    </h5>
                  </v-col>
                  <template v-if="item.label === 'Permisos - Hídricos'">
                    <master-group-card :item="item.data[0]"></master-group-card>
                    <v-col cols="12">
                      <v-row>
                        <master-group-card
                          type-master="permisos-hidricos"
                          :label="item.label"
                          :item="listAuthorityWater"
                          @change="onChangeAuthority"
                        ></master-group-card>
                        <master-group-card
                          type-master="permisos-hidricos"
                          :label="item.label"
                          :is-disabled="
                            isEmpty(listAuthorityLocalWater.options)
                          "
                          :item="listAuthorityLocalWater"
                          @change="onChangeAuthorityAdministrative"
                        ></master-group-card>
                        <master-group-card
                          type-master="permisos-hidricos"
                          :label="item.label"
                          :is-disabled="
                            isEmpty(listAuthorityAdministrativeWater.options)
                          "
                          :item="listAuthorityAdministrativeWater"
                        ></master-group-card>
                      </v-row>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col
                      v-for="(master, indexM) in item.data"
                      :key="indexM"
                      cols="12"
                    >
                      <v-row>
                        <master-group-card
                          :item="master"
                          :label="item.label"
                        ></master-group-card>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </vue-perfect-scrollbar>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import MasterGroupCard from "@/components/settings/MasterGroupCard";
import MasterDrawer from "@/components/settings/MasterDrawer";
import { IMasters } from "@/models";
import { findIndex } from "lodash";

export default {
  name: "MastersPage",
  components: {
    MasterDrawer,
    MasterGroupCard,
    VuePerfectScrollbar,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    scrollModuleSettings: { maxScrollbarLength: 160, suppressScrollX: true },
    loadData: false,
    listMastersGroup: [],
    listWaterPermit: [],
    listAuthorityWater: {},
    listAuthorityLocalWater: {
      label: "Administrativa del agua",
      options: [],
    },
    loadLocalWater: false,
    listAuthorityAdministrativeWater: {
      label: "Local del agua",
      options: [],
    },
    loadWaterAdmin: false,
    indexItem: null,
    selectedItem: new IMasters(),
    dialogUpdate: false,
    dialogDelete: false,
    disabledEdit: true,
    loadUpdate: false,
    typeDialog: "add",
    selectedIdScroll: null,
    dialogUpdateModule: false,
    listSubmodules: [],
  }),
  computed: {
    ...mapState({
      listModules: (state) => state.modules.modules,
    }),
  },
  created() {
    this.clearMasters();
  },
  async mounted() {
    await this.fetchModules();
    await this.fetchSubmodules();
    this.loadData = false;
    const payload = `?response=lite&page=${this.pageMaster}`;
    this.fetchMastersGroup().then((resp) => {
      const filterHidrico = resp.filter(
        (e) => e.label === "Permisos - Hídricos",
      )[0];
      this.listWaterPermit = filterHidrico.data;
      const authority = filterHidrico.data.filter(
        (e) => e.name === "permisos-hidricos-autoridad",
      )[0];
      this.listAuthorityWater = authority;
      this.loadData = false;
      const inxGeneral = findIndex(resp, { label: "General" });
      resp[inxGeneral].data.push({
        id: "MTGENERALMODULE",
        label: "Modulos",
        levels: 1,
        isModules: true,
        master_option_id: null,
        module_id: null,
        name: "modules",
        options: this.listModules,
        slug: "mtgeneralmodules",
        submodule_id: null,
      });
      resp[inxGeneral].data.push({
        id: "MTGENERALSUBMODULE",
        label: "Submodulos",
        levels: 1,
        isSubmodules: true,
        master_option_id: null,
        module_id: null,
        name: "submodules",
        options: this.listSubmodules,
        slug: "mtgeneralsubmodules",
        submodule_id: null,
      });
      this.listMastersGroup = resp;
    });
    this.fetchMasters({ payload, step: true });
  },
  methods: {
    async fetchSubmodules() {
      try {
        const { data } = await this.axios.get(`/api/submodules`);
        if (data.success) {
          this.listSubmodules = data.data;
        }
      } catch ({ response }) {
        console.log(response?.message || "Error");
      }
    },
    onSubmitUpdate() {
      const payload = {
        id: this.selectedItem.id,
        payload: {
          master_id: this.selectedItem.id,
          label: this.selectedItem.label,
        },
      };
      this.loadUpdate = true;
      this.editMasters(payload)
        .then(() => {
          this.$set(
            this.listMastersGroup[this.indexItem],
            "label",
            this.selectedItem.label,
          );
          this.loadUpdate = false;
          this.dialogUpdateModule = false;
        })
        .catch(() => {
          this.loadUpdate = false;
          this.dialogUpdateModule = false;
        });
    },
    onOpenDialogUpdate(item, index) {
      this.selectedItem = item;
      this.indexItem = index;
      this.dialogUpdateModule = true;
    },
    onCloseDialogUpdate() {
      this.selectedItem = null;
      this.indexItem = null;
      this.dialogUpdateModule = false;
    },
    onChangeAuthority(item) {
      const listAuthority = this.listWaterPermit.filter(
        (e) => e.master_option_id === item.id,
      );
      this.listAuthorityLocalWater = listAuthority[0];
    },
    onChangeAuthorityAdministrative(item) {
      const listAuthority = this.listWaterPermit.filter(
        (e) => e.master_option_id === item.id,
      );
      this.listAuthorityAdministrativeWater = listAuthority[0];
    },
    ...mapActions([
      "fetchModules",
      "fetchMasters",
      "fetchMastersGroup",
      "clearMasters",
      "editMasters",
    ]),
  },
};
</script>
