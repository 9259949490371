<template>
  <v-col cols="12" class="py-0">
    <v-col cols="12" class="py-0 px-0">
      <h4 class="d-inline-block mb-0 card-input-title">
        Ubicación
      </h4>
      <v-btn
        v-if="typeDialog === 'edit' && !applyLocation && !isDrafts"
        color="primary"
        class="ml-2"
        text
        small
        @click="$router.push({ name: 'MapPage', params: { id } })"
      >Ver en mapa
      </v-btn
      >
    </v-col>
    <v-col cols="12" class="py-0 px-0" style="margin-bottom: 20px !important;">
      <v-switch
        v-model="applyLocation"
        :ripple="false"
        :disabled="disabled"
        hide-details
        class="mt-0"
        :false-value="0"
        :true-value="1"
        label="N/A"
        @change="onChangeLocationDisabled"
      ></v-switch>
    </v-col>
    <v-container v-if="!applyLocation" fluid class="py-2 px-0">
      <v-form
        ref="formUpdateMap"
        v-model="validUpdate"
        :lazy-validation="lazyUpdate"
      >
        <v-row>
          <v-col cols="6" md="4" class="py-0">
            <v-select
              v-model="auxDatum"
              class="pt-0 input-custom-56"
              label="Datum*"
              :items="listDatum.options"
              item-text="label"
              item-value="id"
              outlined
              height="56"
              return-object
              :hide-details="isEmpty(listError.datum_id)"
              :rules="rulesDatum"
              :disabled="disabled"
              @input="onChangeDatum"
            ></v-select>
          </v-col>
          <v-col cols="6" md="4" class="py-0">
            <v-select
              v-model="auxZone"
              class="pt-0 mb-3 input-custom-56"
              label="Zona*"
              :items="listZone.options"
              item-text="label"
              item-value="id"
              height="56"
              outlined
              return-object
              :hide-details="isEmpty(listError.zone_id)"
              :rules="rulesZone"
              :disabled="disabled"
              @input="onChangeZone"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-col cols="12" class="py-0 pl-5 pr-0">
        <h4
          class="d-block mb-2 card-input-title"
          style="height: 28px; width: 100%;"
        >
          <span class="d-inline-block" style="padding-top: 4px;">UTM</span>
          <v-btn
            v-if="!disabled"
            style="float:right;margin: 0;"
            class="mt-0"
            small
            depressed
            @click="showDialogDetail('add')"
          >
            <v-icon>add</v-icon>
            Agregar vértice
          </v-btn>
        </h4>
        <v-data-table
          class="mb-2 checklist-table"
          :headers="headerLocation"
          :items="auxLocations"
          item-key="id"
          hide-default-footer
        >
          <template v-slot:body="{ items }">
            <tbody>
            <template v-if="auxLocations.length">
              <tr v-for="(item, index) in items" :key="item.id">
                <td
                  class="td-hover"
                  @click="showDialogDetail('edit', item, index)"
                >
                  {{ item.n }}
                </td>
                <td
                  class="td-hover"
                  @click="showDialogDetail('edit', item, index)"
                >
                  {{ item.e }}
                </td>
                <td class="text-center">
                  <v-btn
                    small
                    icon
                    depressed
                    :disabled="disabled"
                    @click="showDeleteDialog(item, index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr
              v-if="!auxLocations.length"
              class="v-data-table__empty-wrapper"
            >
              <td :colspan="headerLocation.length">
                No hay vértices registrados
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-container>

    <v-dialog
      v-model="dialogDelete"
      max-width="300"
      @keydown.esc="dialogDelete = false"
    >
      <v-card>
        <v-card-title class="py-1">
          <p class="subheading mb-0">Eliminar UTM</p>
        </v-card-title>
        <v-card-text>
          <div>¿Estás seguro que deseas eliminarlo?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            color="action"
            small
            text
            @click="dialogDelete = false"
          >Cerrar
          </v-btn
          >
          <v-btn
            class="btn-transform-none"
            color="primary"
            dark
            small
            depressed
            @click="onSubmitDelete()"
          >
            <v-progress-circular
              v-if="loadDelete"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            {{ loadDelete ? "Eliminando" : "Eliminar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdate"
      max-width="400"
      persistent
      content-class="dialog-custom"
      @keydown.esc="onCloseDialog()"
    >
      <v-card>
        <v-card-title class="py-1">
          <p class="subheading mb-0">Ubicación</p>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="formUpdateUTM"
            v-model="validUpdateUTM"
            :lazy-validation="lazyUpdateUTM"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="formUpdate.n"
                  outlined
                  height="56"
                  label="UTM Norte"
                  class="pt-0 mb-4 input-custom-56"
                  :disabled="disabled && !disabledEdit"
                  :hide-details="isEmpty(listError.n)"
                  :rules="rulesN"
                  required
                  @input="onChangeN"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="formUpdate.e"
                  outlined
                  height="56"
                  label="UTM Este"
                  class="pt-0 mb-2 input-custom-56"
                  :disabled="disabled && !disabledEdit"
                  :hide-details="isEmpty(listError.e)"
                  :rules="rulesE"
                  required
                  @input="onChangeE"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="action"
            class="btn-transform-none"
            small
            text
            @click="onCloseDialog()"
          >Cerrar
          </v-btn
          >
          <v-btn
            color="action"
            class="btn-transform-none"
            small
            dark
            depressed
            @click="onSubmitLocation()"
          >
            <v-progress-circular
              v-if="loadUpdate"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="white"
            ></v-progress-circular>
            {{ loadUpdate ? "Creando" : "Crear" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {findIndex, cloneDeep} from "lodash";
import {IUTM} from "@/models";
import mixinApiModule from "../modules/mixins/mixinApiModule";

const textErrorN = "Debes escribir el norte.";
const textErrorE = "Debes escribir el este.";

export default {
  name: "MapSection",
  mixins: [mixinApiModule],
  model: {prop: "locationDisabled", event: "change"},
  props: {
    id: {type: String, default: ""},
    zone: {
      type: Object, default: () => {
      }
    },
    datum: {
      type: Object, default: () => {
      }
    },
    locations: {type: Array, default: () => []},
    // eslint-disable-next-line vue/require-default-prop
    locationDisabled: {type: [Boolean, Number], defualt: true},
    isDrafts: {type: Boolean, default: false},
    typeDialog: {type: String, default: "new"},
    disabled: {type: Boolean, defualt: false},
    typeModule: {type: String, default: ""},
    typeSubmodule: {type: String, default: ""},
  },
  data: () => ({
    dialogUpdate: false,
    loadUpdate: false,
    dialogDelete: false,
    loadDelete: false,
    selectDelete: null,
    applyLocation: 0,
    headerLocation: [
      {text: "UTM Norte", value: "n", sortable: true, width: "auto"},
      {text: "UTM Este", value: "e", sortable: true, width: "auto"},
      {text: "Acción", value: "action", sortable: false, width: 80},
    ],
    typeUpdate: "add",
    tempLocations: [],
    draftLocations: [],
    disabledEdit: false,
    indexLocation: 0,
    auxDatum: {},
    auxZone: {},
    validUpdate: false,
    lazyUpdate: false,
    validUpdateUTM: false,
    lazyUpdateUTM: false,
    showError: false,
    formUpdate: new IUTM(),
    rulesN: [],
    rulesE: [],
    rulesDatum: [],
    rulesZone: [],
    listError: {
      e: null,
      n: null,
      datum_id: null,
      zone_id: null,
    },
  }),
  computed: {
    ...mapState({
      activeModule: (state) => state.modules.activeModule,
      activeSubModule: (state) => state.modules.activeSubModule,
      listDatum: (state) => state.masters.listDatum,
      listZone: (state) => state.masters.listZone,
    }),
    auxLocations() {
      return this.id ? this.locations : this.tempLocations;
    },
  },
  watch: {
    datum(val) {
      this.auxDatum = val;
    },
    zone(val) {
      this.auxZone = val;
    },
    applyLocation(val) {
      this.$emit("change", val);
    },
  },
  mounted() {
    this.setForm();
  },
  methods: {
    ...mapActions(["editWaterPermit"]),
    setForm() {
      this.auxDatum = this.datum;
      this.auxZone = this.zone;
      this.applyLocation = this.locationDisabled;
      this.tempLocations = this.locations;
    },
    onChangeLocationDisabled(flag) {
      this.$emit("location-disabled", flag);
    },
    resetValidation() {
      if (this.$refs.formUpdateMap) {
        this.$refs.formUpdateMap.resetValidation();
      }
      if (this.$refs.formUpdateUTM) {
        this.$refs.formUpdateUTM.resetValidation();
      }
    },
    clearErrorSection() {
      this.listError.e = null;
      this.listError.n = null;
      this.listError.datum_id = null;
      this.listError.zone_id = null;
      this.rulesN = [];
      this.rulesE = [];
      this.rulesDatum = [];
      this.rulesZone = [];
    },
    onChangeN(val) {
      if (!this.isEmpty(val)) {
        this.listError.n = null;
      }
    },
    onChangeE(val) {
      if (!this.isEmpty(val)) {
        this.listError.e = null;
      }
    },
    validateError(err) {
      const {datum_id = null, zone_id = null} = err;
      this.listError.datum_id = !this.isEmpty(datum_id) ? datum_id[0] : null;
      this.listError.zone_id = !this.isEmpty(zone_id) ? zone_id[0] : null;
      this.rulesDatum = this.listError.datum_id
        ? [() => this.listError.datum_id]
        : [];
      this.rulesZone = this.listError.zone_id
        ? [() => this.listError.zone_id]
        : [];
      this.$nextTick(() => {
        if (this.$refs.formUpdateMap) {
          this.$refs.formUpdateMap.validate();
        }
      });
    },
    onHideDetails(key) {
      if (this.showError && !this.isEmpty(this.listError[key])) {
        return this.formUpdate[key] !== null;
      }
      return true;
    },
    listErrorUTM(err) {
      const {n = null, e = null} = err;
      this.showError = true;
      this.listError.n = n ? n[0] : null;
      this.listError.e = e ? e[0] : null;
      this.rulesN = this.listError.n ? [() => this.listError.n] : [];
      this.rulesE = this.listError.e ? [() => this.listError.e] : [];
      this.$nextTick(() => {
        this.$refs.formUpdateUTM.validate();
      });
    },
    validateFormLocation() {
      if (!this.locationDisabled && this.id) {
        let formData = new FormData();
        formData.append("location_disabled", 0);
        this.editWaterPermit({
          apiModule: this.apiByModule.module,
          apiSubmodule: this.apiByModule.submodule,
          id: this.id,
          payload: formData,
        }).then(() => {
          this.onSubmitLocation();
        });
      } else {
        this.onSubmitLocation();
      }
    },
    async onSubmitLocation() {
      if (this.loadUpdate) return false;
      this.$refs.formUpdateUTM.resetValidation();
      const payload = {
        entity_id: this.id,
        n: this.formUpdate.n,
        e: this.formUpdate.e,
        module_id: this.activeModule.id,
        submodule_id: this.activeSubModule.id,
      };
      this.showError = false;
      this.loadUpdate = true;
      if (this.id) {
        this.typeUpdate === "add"
          ? this.onCreateUtmById(payload)
          : this.onEditUtmById(payload);
      } else {
        this.loadUpdate = false;
        if (
          this.isEmpty(this.formUpdate.n) ||
          this.isEmpty(this.formUpdate.e)
        ) {
          const err = {
            n: !this.isEmpty(this.formUpdate.n) ? null : [textErrorN],
            e: !this.isEmpty(this.formUpdate.e) ? null : [textErrorE],
          };
          this.loadUpdate = false;
          this.listErrorUTM(err);
        } else {
          if (this.typeUpdate === "add") {
            this.tempLocations.push(payload);
          } else {
            this.$set(this.tempLocations, this.indexLocation, payload);
          }
          this.$emit("update", this.tempLocations);
          this.dialogUpdate = false;
        }
      }
    },
    async onEditUtmById(payload) {
      payload.e = this.isEmpty(payload.e) ? null : payload.e;
      payload.n = this.isEmpty(payload.n) ? null : payload.n;
      payload["location_id"] = this.formUpdate.id;
      const {data} = await this.axios.post(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.formUpdate.id}/location/update`,
        payload,
      );
      this.loadUpdate = false;
      if (data.success) {
        const index = findIndex(this.locations, {
          id: this.formUpdate.id,
        });
        this.$nextTick(() => {
          this.$set(this.locations, index, data.data);
          this.$emit("update", this.locations);
        });
        this.dialogUpdate = false;
      } else {
        this.listErrorUTM(data.error.data);
      }
    },
    async onCreateUtmById(payload) {
      const {data} = await this.axios.post(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/location/create`,
        payload,
      );
      this.loadUpdate = false;
      if (data.success) {
        this.locations.push(data.data);
        this.$emit("update", this.locations);
        this.dialogUpdate = false;
      } else {
        this.listErrorUTM(data.error.data);
      }
    },
    showDialogDetail(type, item = new IUTM(), index = 0) {
      this.rulesN = this.rulesE = [];
      this.listError.n = this.listError.e = "";
      if (this.disabled) {
        return false;
      }
      this.showError = false;
      this.indexLocation = index;
      this.typeUpdate = type;
      this.formUpdate = cloneDeep(item);
      this.dialogUpdate = true;
    },
    async onSubmitDelete() {
      if (this.loadDelete) return false;
      this.loadDelete = true;
      if (this.id) {
        const {id} = this.selectDelete;
        const {data} = await this.axios.delete(`/api/locations/${id}/delete`);
        if (data.success) {
          const index = findIndex(this.locations, {id});
          this.locations.splice(index, 1);
          this.$emit("update", this.locations);
        }
      } else {
        this.tempLocations.splice(this.indexLocation, 1);
        this.$emit("update", this.tempLocations);
      }
      this.loadDelete = false;
      this.dialogDelete = false;
    },
    showDeleteDialog(item, index) {
      this.indexLocation = index;
      this.selectDelete = item;
      this.dialogDelete = true;
    },
    onChangeDatum() {
      this.listError.datum_id = null;
      this.rulesDatum = [];
      this.$emit("datum-zone", this.auxDatum, this.auxZone);
    },
    onChangeZone() {
      this.listError.zone_id = null;
      this.rulesZone = [];
      this.$emit("datum-zone", this.auxDatum, this.auxZone);
    },
    onCloseDialog() {
      this.dialogUpdate = false;
      this.dialogDelete = false;
      this.loadDelete = false;
      this.loadUpdate = false;
      this.showError = false;
      this.formUpdate = new IUTM();
    },
  },
};
</script>
