const baseInstance = {
  id: null,
  attachment: null,
  pending_tasks: [],
  comments: null,
};

export class ICriminalProcess {
  constructor() {
    this.id = null;
    this.amount = null;
    this.association_honorable = null;
    this.date_process = null;
    this.contingency_level = null;
    this.contingency_percentage = null;
    this.provision_percentage = null;
    this.provision_amount = null;
    this.risk = null;
    this.situation_description = null;
    this.situation_stage = null;
    this.situation_date = null;
    this.date_process = null;
    this.cargo_study = {};
    this.complaining_party = null;
    this.controversial_matter = null;
    this.file_code = null;
    this.finished = 0;
    this.project = {};
    this.responsible = {};
    this.respondent_party = null;
    this.stage_1 = {
      id: null,
      court: {},
      comments: null,
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
    };
    this.stage_2 = {
      id: null,
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
    };
    this.stage_3 = {
      id: null,
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
      instance_5: baseInstance,
    };
    this.stage_4 = {
      id: null,
      room: null,
      comments: null,
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
      instance_5: baseInstance,
    };
  }
}
