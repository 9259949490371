<template>
  <v-dialog
    v-model="showDialog"
    class="dialog-custom"
    persistent
    open-on-focus
    width="450"
    @keydown.esc="onCloseDialog()"
  >
    <v-card>
      <v-card-title>
        <span class="subheading">Detalle de usuario</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-list v-if="item" subheader three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Id</v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Nombre</v-list-item-title>
                <v-list-item-subtitle>{{
                  `${item.first_name} ${item.last_name}`
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Rol</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.role.label
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Acceso</v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{
                    concatAbilities(item.role.abilities)
                  }}</span></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn-transform-none" text small @click="onCloseDialog()"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogDetail",
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Eliminar",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadDelete: false,
    };
  },
  methods: {
    concatAbilities(abilities) {
      let text = "";
      abilities.forEach((e, index) => {
        text += e.label;
        if (index < abilities.length - 1) text += ", ";
      });
      return text;
    },
    onSubmitReset() {
      if (this.loadDelete) {
        return false;
      }
      this.loadDelete = true;
      this.$emit("delete");
    },
    onCloseDialog() {
      this.$emit("change", !this.showDialog);
    },
    toggleLoad(flag) {
      this.loadDelete = flag;
    },
  },
};
</script>
