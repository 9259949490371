<template>
  <v-container fluid class="page-section page-report pa-0 fill-height">
    <v-container fluid class="pa-0 fill-height" style="position: relative">
      <v-card
        class="card-toolbar"
        color="transparent"
        min-width="100%"
        flat
        tile
      >
        <v-toolbar flat>
          <v-toolbar-title>Reportes</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
      <report-drawer
        @filter="onFilterReport"
        @onModule="onModule"
      ></report-drawer>
      <vue-perfect-scrollbar
        id="contentReports"
        style="width: 100%"
        class="report-full--scroll"
        :settings="scrollSettings"
      >
        <v-row style="padding: 8px 20px 8px 270px">
          <v-col cols="12" class="px-0 py-0">
            <v-card elevation="7" style="overflow: hidden">
              <a id="urlDownload" class="d-none" target="_blank">download...</a>
              <template v-if="filterList.length < 2">
                <v-col cols="12" class="py-10 text-center">
                  <img
                    class="mb-4"
                    style="width: 150px; height: 150px"
                    :src="`${baseURL}/img/icons/black/icon-reportes.png`"
                  />
                  <h5 class="title mb-2">Reporte</h5>
                  <p>
                    Para poder visualizar los reportes es necesario seleccionar
                    un módulo y submódulo.
                  </p>
                </v-col>
              </template>
              <template v-else>
                <v-tabs
                  v-model="currentTab"
                  class="elevation-2"
                  background-color="grey lighten-5"
                  centered
                  grow
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#tab-report1" v-if="showReportByPermission"
                    >SITUACIÓN DE PERMISOS</v-tab
                  >
                  <!-- <v-tab href="#tab-report2">ESTADO DE FICHAS</v-tab> !-->
                  <v-tab v-if="showReportByTypePermission" href="#tab-report3"
                    >FICHAS POR TIPO DE PERMISOS</v-tab
                  >
                  <v-tab href="#tab-report4" v-if="showReportBySituation"
                    >SITUACIÓN POR PROYECTO</v-tab
                  >
                  <v-tab href="#tab-report5"
                    >SITUACIÓN TOTAL DE TO-DO´S Y ALERTAS</v-tab
                  >
                  <v-tab href="#tab-report6" v-if="showReportByResponsible"
                    >SITUACIÓN POR RESPONSABLES</v-tab
                  >
                  <v-tab href="#tab-report7" v-if="showReportByCommitment"
                    >SITUACIÓN POR CUMPLIMIENTO</v-tab
                  >
                  <v-tab href="#tab-report8" v-if="showReportByComplaining"
                    >SITUACIÓN POR DEMANDANTE</v-tab
                  >
                  <v-tab href="#tab-report9" v-if="showReportByControversial"
                    >SITUACIÓN POR DEMANDADO</v-tab
                  >
                  <v-tab href="#tab-report10" v-if="showReportByStudio"
                    >SITUACIÓN POR ESTUDIO A CARGO</v-tab
                  >
                  <!-- <v-tab href="#tab-report11" v-if="showReportByContingency"
                    >SITUACIÓN POR NIVEL DE CONTINGENCIA</v-tab
                  > !-->

                  <v-tab-item value="tab-report1">
                    <report-one
                      v-if="currentTab === 'tab-report1'"
                      ref="report1"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-one>
                  </v-tab-item>
                  <v-tab-item value="tab-report2">
                    <report-two
                      v-if="currentTab === 'tab-report2'"
                      ref="report2"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-two>
                  </v-tab-item>
                  <v-tab-item v-if="isNotCommitments()" value="tab-report3">
                    <report-three
                      v-if="currentTab === 'tab-report3'"
                      ref="report3"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-three>
                  </v-tab-item>
                  <v-tab-item value="tab-report4">
                    <report-four
                      v-if="currentTab === 'tab-report4'"
                      ref="report4"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-four>
                  </v-tab-item>
                  <v-tab-item value="tab-report5">
                    <report-five
                      v-if="currentTab === 'tab-report5'"
                      ref="report5"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-five>
                  </v-tab-item>
                  <v-tab-item value="tab-report6">
                    <report-six
                      v-if="currentTab === 'tab-report6'"
                      ref="report6"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-six>
                  </v-tab-item>
                  <v-tab-item value="tab-report7">
                    <report-seven
                      v-if="currentTab === 'tab-report7'"
                      ref="report7"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-seven>
                  </v-tab-item>
                  <v-tab-item value="tab-report8">
                    <report-eight
                      v-if="currentTab === 'tab-report8'"
                      ref="report8"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-eight>
                  </v-tab-item>
                  <v-tab-item value="tab-report9">
                    <report-nine
                      v-if="currentTab === 'tab-report9'"
                      ref="report9"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-nine>
                  </v-tab-item>
                  <v-tab-item value="tab-report10">
                    <report-ten
                      v-if="currentTab === 'tab-report10'"
                      ref="report9"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-ten>
                  </v-tab-item>
                  <v-tab-item value="tab-report11">
                    <report-eleven
                      v-if="currentTab === 'tab-report11'"
                      ref="report9"
                      :filter-data="filterData"
                      :filter-list="filterList"
                    ></report-eleven>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </vue-perfect-scrollbar>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ReportOne from "@/components/report/ReportOne";
import ReportTwo from "@/components/report/ReportTwo";
import ReportThree from "@/components/report/ReportThree";
import ReportFour from "@/components/report/ReportFour";
import ReportFive from "@/components/report/ReportFive";
import ReportDrawer from "@/components/report/ReportDrawer";
import ReportSix from "@/components/report/ReportSix.vue";
import ReportSeven from "@/components/report/ReportSeven.vue";
import ReportEight from "@/components/report/ReportEight.vue";
import ReportNine from "@/components/report/ReportNine.vue";
import ReportTen from "@/components/report/ReportTen.vue";
import ReportEleven from "@/components/report/ReportEleven.vue";

export default {
  name: "ReportPage",
  components: {
    ReportTen,
    ReportNine,
    ReportEight,
    ReportOne,
    ReportTwo,
    ReportThree,
    ReportFour,
    ReportFive,
    ReportSix,
    ReportDrawer,
    VuePerfectScrollbar,
    ReportSeven,
    ReportEleven,
  },
  data: () => ({
    currentTab: null,
    filterData: "",
    filterList: [],
    showReportByResponsible: false,
    showReportByCommitment: false,
    showReportByComplaining: false, //Demandante
    showReportByControversial: false, //Demandado
    showReportByStudio: false, // Por Estudio a Cargo
    showReportByPermission: true,
    showReportByContingency: true,
    showReportByTypePermission: true,
    showReportBySituation: true,
  }),
  created() {
    this.fetchModules();
  },
  methods: {
    ...mapActions(["fetchModules"]),
    onFilterReport(filterList, filterData) {
      this.filterList = filterList;
      this.filterData = filterData;
    },
    onModule(label) {
      this.showReportBySituation = false;

      if (label === "Procesos Judiciales") {
        this.showReportByComplaining = true;
        this.showReportByControversial = true;
        this.showReportByStudio = true;
        this.showReportByPermission = false;
        this.showReportByTypePermission = false;
        this.showReportBySituation = false;
        this.showReportByContingency = true;
      }

      if (label === "Matrices") {
        this.showReportByCommitment = true;
      }

      if (label !== "Permisos") {
        this.showReportByResponsible = true;
      }
    },
    isNotCommitments() {
      if (this.filterList.length) {
        return !this.filterList.some((e) => e.label === "Compromisos");
      }
      return true;
    },
  },
};
</script>
