export default {
  data() {
    return {
      sortDesc: null,
      sortBy: null,
    };
  },
  methods: {
    eventSortBy(e) {
      this.sortBy = e.length ? e[0] : null;
      this.fetchWPSort();
    },
    eventSortDesc(e) {
      this.sortDesc = e.length ? e[0] : null;
      this.fetchWPSort();
    },
    fetchWPSort() {
      const sortUrl = `${this.sortName(this.sortBy)}:${
        this.sortDesc ? "desc" : "asc"
      }`;
      if (this.sortBy !== null && this.sortDesc !== null) {
        this.clearJudicialProcesses();
        this.sortJudicialProcess = sortUrl;
        this.fetchJudicialProcesses(this.getCompleteUrl(sortUrl), true);
      } else {
        this.clearJudicialProcesses();
        this.sortJudicialProcess = null;
        const payload = "?response=lite&page=1";
        this.fetchJudicialProcesses(payload, true);
      }
    },
    getCompleteUrl(sortUrl) {
      return `?response=lite&page=1${this.filterData}&sort=${sortUrl}`;
    },
    sortName(name) {
      if (name === "cargo_study.label") {
        return "cargo_study";
      }
      return name;
    },
  },
};
