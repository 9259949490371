import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: {},
    },
    options: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  watch: {
    chartData() {
      this.$data._chart.update();
      // this.renderChartReport();
    },
  },
  mounted() {
    // this.$data._chart.destroy();
    this.renderChartReport();
  },
  methods: {
    renderChartReport() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            fontColor: "#333",
            fontSize: 12,
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const total = dataset.data.reduce((acc, value) => acc + value, 0);
              const currentValue = dataset.data[tooltipItem.index];
              const percentage = Math.floor(((currentValue / total) * 100) + 0.5);
              return `${data.labels[tooltipItem.index]}: ${percentage}%`;
            },
          },
        },
      };
      this.renderChart(this.chartData, options);
    },
  },
};
