import SettingsPage from "../pages/settings/SettingsPage.vue";
import TermPage from "../pages/settings/TermPage.vue";
import PoliticPage from "../pages/settings/PoliticPage.vue";
import CopyPage from "../pages/settings/CopyPage.vue";
import NotificationPage from "../pages/settings/NotificationPage.vue";
import MastersPage from "../pages/settings/MastersPage.vue";

function requireAuth(to, from, next) {
  const token = localStorage.getItem("JARU_TOKEN") || null;
  if (!token) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

const settings = [
  {
    path: "/ajustes",
    meta: { breadcrumb: true },
    name: "SettingsPage",
    props: true,
    component: SettingsPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/ajustes/terminos-condiciones",
    meta: { breadcrumb: true },
    name: "TermPage",
    props: true,
    component: TermPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/ajustes/politica-privacidad",
    meta: { breadcrumb: true },
    name: "PoliticPage",
    props: true,
    component: PoliticPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/ajustes/copy",
    meta: { breadcrumb: true },
    name: "CopyPage",
    props: true,
    component: CopyPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/ajustes/ajustes-generales",
    meta: { breadcrumb: true },
    name: "NotificationPage",
    props: true,
    component: NotificationPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/ajustes/maestro",
    meta: { breadcrumb: true },
    name: "MastersPage",
    props: true,
    component: MastersPage,
    beforeEnter: requireAuth,
  },
];

export default settings;
