<template>
  <div>
    <v-dialog
      v-model="showDialog"
      content-class="dialog-custom"
      persistent
      scrollable
      open-on-focus
      width="90%"
      @keydown.esc="onCloseDialog()"
    >
      <v-card>
        <v-card-title class="py-2">
          <span class="subheading">Borradores</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <template v-if="!loadDraft">
          <v-col cols="12">
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </template>
        <template v-else>
          <vue-perfect-scrollbar
            class="drawer-menu--scroll"
            :settings="scrollSettings"
          >
            <v-card-text style="height: 400px;">
              <v-data-table
                class="my-0"
                :headers="headerDraft"
                :items="listDraft"
                :items-per-page="-1"
                item-key="id"
                hide-default-footer
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <template v-if="!noResult && loadDraft">
                      <tr v-for="item in items" :key="item.id">
                        <td class="td-hover" @click="onSelectDraft(item)">
                          {{ item.id }}
                        </td>
                        <td class="td-hover" @click="onSelectDraft(item)">
                          {{ parseFormatLabel(item.type) }}
                        </td>
                        <td class="td-hover" @click="onSelectDraft(item)">
                          {{ parseFormatLabel(item.project) }}
                        </td>
                        <td class="td-hover" @click="onSelectDraft(item)">
                          {{
                            isEmpty(item.expiration_date)
                              ? "---"
                              : item.expiration_date.split(" ")[0]
                          }}
                        </td>
                        <td class="td-hover" @click="onSelectDraft(item)">
                          {{ parseFormatLabel(item.situation) }}
                        </td>
                        <td>
                          <v-btn
                            depressed
                            fab
                            x-small
                            icon
                            @click="onDialogDelete(item)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <tr v-if="noResult" class="v-data-table__empty-wrapper">
                      <td :colspan="headerDraft.length">
                        No se encontro resultados
                      </td>
                    </tr>
                    <tr v-if="!loadDraft" class="v-data-table__empty-wrapper">
                      <td :colspan="headerDraft.length">
                        Esperando resultados
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </vue-perfect-scrollbar>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            text
            :disabled="!loadDraft"
            class="btn-transform-none"
            depressed
            @click="onCloseDialog()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="400"
      persistent
      open-on-focus
      @keydown.esc="dialogDelete = false"
    >
      <v-card v-if="selectedItem">
        <v-card-text class="py-2">
          <p class="mb-0">
            ¿Estás seguro que deseas eliminar el borrador
            {{ selectedItem.id }} ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="btn-transform-none"
            :disabled="loadDelete"
            text
            small
            @click="onDeleteDraft(false)"
            >Cerrar</v-btn
          >
          <v-btn
            color="primary darken-1"
            class="btn-transform-none"
            text
            small
            @click="onDeleteDraft(true)"
            ><v-progress-circular
              v-if="loadDelete"
              :size="20"
              class="ml-2"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{ loadDelete ? "Eliminando" : "Eliminar" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { findIndex } from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import mixinApiModule from "../modules/mixins/mixinApiModule";

export default {
  name: "DialogDraft",
  components: {
    VuePerfectScrollbar,
  },
  mixins: [mixinApiModule],
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    selectDraft: null,
    dialogDelete: false,
    selectedItem: null,
    loadDelete: false,
    loadDraft: false,
    noResult: false,
    listDraft: [],
    page: 1,
    scrollSettings: { maxScrollbarLength: 160, suppressScrollX: true },
    headerDraft: [
      { text: "Código", value: "id", width: 125 },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Proyecto", value: "project", sortable: false },
      {
        text: "Vencimiento",
        value: "expiration_date",
        sortable: false,
        width: 160,
      },
      { text: "Situación", value: "situation", width: 95 },
      { text: "Acción", value: "actions", sortable: false, width: 90 },
    ],
  }),
  watch: {
    typeModule() {
      this.fetchDraft();
    },
    typeSubmodule() {
      this.fetchDraft();
    },
    showDialog(val) {
      if (val) {
        this.fetchDraft();
      }
    },
  },
  methods: {
    fetchDraft() {
      this.listDraft = [];
      this.loadDraft = false;
      const payload = `?response=lite&page=${this.page}`;
      this.axios
        .get(
          `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}${payload}`,
        )
        .then(({ data }) => {
          this.listDraft = data.success ? data.data : [];
          this.noResult = !data.success;
          this.loadDraft = true;
        });
    },
    onSelectDraft(item) {
      const { id } = item;
      this.axios
        .get(
          `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}/${id}`,
        )
        .then(({ data }) => {
          if (data.success) {
            this.$emit("select", data.data);
          }
        });
    },
    onDialogDelete(item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    onDeleteDraft(flag) {
      if (flag) {
        const { id } = this.selectedItem;
        this.loadDelete = true;
        this.axios
          .delete(
            `/api/${this.apiByModule.module}/drafts/${this.apiByModule.submodule}/${id}/delete`,
          )
          .then(({ data }) => {
            this.loadDelete = false;
            if (data.success) {
              const index = findIndex(this.listDraft, { id });
              this.listDraft.splice(index, 1);
              this.selectedItem = null;
              this.dialogDelete = false;
            }
          });
      } else {
        this.selectedItem = null;
        this.dialogDelete = false;
      }
    },
    onCloseDialog() {
      this.$emit("change", !this.showDialog);
      this.$emit("close");
    },
    validateColor(situation) {
      if (situation && situation.label) {
        const name = situation.label.toLowerCase();
        const type =
          name === "en proceso"
            ? "process"
            : name === "vencido"
            ? "expired"
            : "active";
        return `row__${type}`;
      }
      return "";
    },
  },
};
</script>
