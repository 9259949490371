<template>
  <v-card class="block-report" flat tile>
    <v-btn
      v-if="loading"
      class="btn-bottom"
      fab
      dark
      small
      color="primary"
      @click="goToScroll('chartOne1', 'contentReports')"
    >
      <v-icon dark>keyboard_arrow_down</v-icon>
    </v-btn>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col id="chartOne1" cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 2
            </h4>
            <div class="chart-container">
              <doughnut-chart
                v-if="loading"
                ref="reportOne1"
                chart-id="reportOne1"
                :chart-data="optionChartPie"
                :title="titleChart"
                :semi-circle="true"
              ></doughnut-chart>
            </div>
            <v-row v-if="loading">
              <!-- <v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadOne1"
                  @click="onDownloadChart('reportOne1', 'one1')"
                  >Descargar Reporte
                </v-btn>
              </v-col>!-->
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Situación</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ formatPercentage(item.value, dataTotal) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal }}</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 2 de 2
            </h4>
            <div class="chart-container">
              <bar-chart
                v-if="loading"
                ref="reportOne2"
                chart-id="reportOne2"
                class="bar-height"
                :chart-data="optionChartPie"
                :title="titleChart"
              ></bar-chart>
            </div>
            <v-row v-if="loading">
              <!--<v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadOne2"
                  @click="onDownloadChart('reportOne2', 'one2')"
                  >Descargar Reporte
                </v-btn>
              </v-col>!-->
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Situación</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ formatPercentage(item.value, dataTotal) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal }}</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DoughnutChart from "./charts/DoughnutChart";
import BarChart from "./charts/BarChart";
import { setColors } from "./colors";

export default {
  name: "ReportOne",
  components: {
    DoughnutChart,
    BarChart,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    urlDownload: "",
    loadOne1: false,
    loadOne2: false,
    isFlag: false,
    loading: false,
    optionChartColumn: {},
    optionChartPie: {},
    dataTable: [],
    dataTotal: 0,
  }),
  computed: {
    titleChart() {
      let label = "Situación de ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "Situación general";
    },
  },
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/one${this.filterData}`,
      );
      if (data.success) {
        this.loading = true;
        let dataChart = data.data;
        this.dataTotal = dataChart.total;
        this.dataTable = [
          { label: dataChart.in_process_label, value: dataChart.in_process },
          { label: dataChart.expired_label, value: dataChart.expired },
          { label: dataChart.active_label, value: dataChart.active },
        ];

        let optionChartPie = {
          labels: [
            dataChart.expired_label,
            dataChart.active_label,
            dataChart.in_process_label,
          ],
          datasets: [
            {
              label: "Datos",
              backgroundColor: [setColors[0], setColors[1], setColors[2]],
              data: [dataChart.expired, dataChart.active, dataChart.in_process],
            },
          ],
        };
        this.optionChartPie = optionChartPie;
      }
    },
    async onDownloadChart(nameChart, type) {
      const element = document.getElementById(nameChart);
      if (type === "one1") {
        this.loadOne1 = true;
      } else {
        this.loadOne2 = true;
      }
      var dataURL = element.toDataURL("image/png");
      let file = null;
      await fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "File name", { type: "image/png" });
        });
      const formData = new FormData();
      formData.append("file", file);
      const listFilter = this.parseFilterReport(this.filterData);
      for (let key in listFilter) {
        if (listFilter[key]) {
          formData.append(key, listFilter[key]);
        }
      }
      const { data } = await this.axios({
        method: "post",
        url: "/api/reports/one/export",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (type === "one1") {
        this.loadOne1 = false;
      } else {
        this.loadOne2 = false;
      }
      this.$nextTick(() => {
        document.getElementById(
          "urlDownload",
        ).href = `${this.baseURL}/${data.data.url}`;
        document.getElementById("urlDownload").click();
      });
    },
  },
};
</script>
