<template>
  <v-container fluid class="page-section pa-0 fill-height">
    <v-row>
      <v-container fluid class="py-0">
        <v-card
          class="card-toolbar"
          color="transparent"
          min-width="100%"
          flat
          tile
        >
          <v-toolbar flat>
            <v-toolbar-title>Roles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-transform-none"
              depressed
              small
              @click="$router.push({ name: 'UserPage' })"
            >
              Usuarios
            </v-btn>
          </v-toolbar>
        </v-card>
        <vue-perfect-scrollbar
          style="width: 100%;"
          class="drawer-full--scroll"
          :settings="scrollSettings"
        >
          <v-row style="padding: 8px 20px 8px 20px;">
            <v-col cols="12" class="pa-0">
              <v-card elevation="7" style="overflow:hidden;">
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="headers"
                    :items="roles"
                    :loading="!loadData"
                    :items-per-page="-1"
                    hide-default-footer
                    fixed-header
                    item-key="id"
                    class="table-striped"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <template v-if="loadData">
                          <tr v-for="item in items" :key="item.id">
                            <td
                              class="td-hover"
                              @click="openDialogUpdate(item)"
                            >
                              {{ item.label }}
                            </td>
                            <td
                              class="td-hover"
                              @click="openDialogUpdate(item)"
                            >
                              {{ item.description }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          v-if="!loadData"
                          class="v-data-table__empty-wrapper"
                        >
                          <td :colspan="headers.length">
                            Esperando resultados
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </vue-perfect-scrollbar>
      </v-container>
    </v-row>
    <v-dialog
      v-model="dialogDetail"
      class="dialog-custom"
      :hide-overlay="false"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      width="450"
      @keydown.esc="dialogDetail = false"
    >
      <v-card>
        <v-card-title>
          <span class="subheading">Detalle del Rol</span>
        </v-card-title>
        <vue-perfect-scrollbar style="width: 100%;" :settings="scrollSettings">
          <v-card-text
            v-if="selectedItem"
            :style="
              `height: ${
                selectedItem.abilities.length > 15
                  ? 'calc(100vh - 50px);'
                  : '400px'
              }`
            "
          >
            <v-row>
              <v-list subheader three-line>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Nombre</v-list-item-title>
                    <v-list-item-subtitle>{{
                      selectedItem.label
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Descripción</v-list-item-title>
                    <v-list-item-subtitle>{{
                      selectedItem.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Acceso</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item, index) in selectedItem.abilities"
                      :key="index"
                    >
                      {{ item.label }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-card-text>
        </vue-perfect-scrollbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            text
            small
            @click="dialogDetail = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";

export default {
  name: "RolesPage",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    subId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      optionAccess: [],
      headers: [
        { text: "Nombre", value: "label", sortable: false },
        { text: "Descripción", value: "description", sortable: false },
      ],
      loadData: false,
      selectedItem: null,
      dialogDetail: false,
      flagLoading: false,
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.users.roles,
    }),
  },
  mounted() {
    this.loadData = false;
    this.fetchRoles().then(() => {
      this.loadData = true;
    });
  },
  methods: {
    ...mapActions(["fetchRoles"]),
    openDialogUpdate(item) {
      this.selectedItem = item;
      this.dialogDetail = true;
    },
    concatAbilities(abilities, type = "default") {
      let str = "";
      abilities.forEach((item, i) => {
        str += item.label;
        if (i < abilities.length - 1) {
          str += ", ";
        }
      });
      if (type === "default") {
        return `${str.slice(0, 30)}... (${abilities.length})`;
      } else {
        return `${str.slice(0, 30)}... (${abilities.length})`;
      }
    },
  },
};
</script>
