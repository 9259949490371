<template>
  <v-dialog
    v-model="dialogNew"
    content-class="dialog-custom"
    persistent
    open-on-focus
    scrollable
    max-width="950px"
    @keydown.esc="onCloseDialogUpdate()"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">{{
          typeDialog === "new" ? "Nuevo Proceso Judicial" : "Proceso Judicial"
        }}</span>
        <v-spacer></v-spacer>
        <export-word
          v-if="typeDialog === 'edit' && !loadDetail"
          :id="selectedItem.id"
          :api-module="apiByModule"
        ></export-word>
        <v-btn
          slot="activator"
          class="m-0"
          icon
          fab
          small
          @click="onCloseDialogUpdate()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col v-show="loadDetail" cols="12">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <vue-perfect-scrollbar
        v-show="!loadDetail"
        class="drawer-menu--scroll"
        :settings="scrollSettings"
        :swicher="!loadDetail"
      >
        <v-card-text class="pb-0">
          <v-form
            ref="formUpdate"
            v-model="validUpdate"
            :lazy-validation="lazyUpdate"
          >
            <v-row>
              <v-col
                v-if="typeDialog !== 'new' && selectedItem && selectedItem.id"
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="selectedItem.id"
                  disabled
                  outlined
                  label="Código"
                  height="56"
                  hide-details
                  class="pt-0 input-custom-56"
                ></v-text-field>
              </v-col>
              <v-col :cols="12" class="py-0">
                <v-text-field
                  v-model="selectedItem.file_code"
                  class="pt-0 mb-0 input-custom-56"
                  label="Código de expediente"
                  height="56"
                  outlined
                  :hide-details="isEmpty(listError.file_code)"
                  :rules="rulesFileCode"
                  :readonly="disabledOptions"
                  @input="onChangeFileCode"
                ></v-text-field>
              </v-col>
              <v-col :cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.respondent_party"
                  class="pt-0 mb-0 input-custom-56"
                  label="Parte demandada*"
                  height="56"
                  outlined
                  :hide-details="isEmpty(listError.respondent_party)"
                  :rules="rulesRespondentParty"
                  :disabled="disabledOptions"
                  @input="onChangeRespondentParty"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.complaining_party"
                  class="pt-0 mb-0 input-custom-56"
                  label="Parte demandante*"
                  height="56"
                  outlined
                  :hide-details="isEmpty(listError.complaining_party)"
                  :rules="rulesComplainingParty"
                  :disabled="disabledOptions"
                  @input="onChangeComplainingParty"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="selectedItem.association_honorable"
                  class="pt-0 mb-0 input-custom-56"
                  label="Co-Demandado"
                  height="56"
                  outlined
                  :readonly="disabledOptions"
                  @input="onChangeAssociationHonorable"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.project"
                  class="pt-0 input-custom-56"
                  label="Proyecto*"
                  item-text="label"
                  item-value="id"
                  clearable
                  outlined
                  height="56"
                  return-object
                  :hide-details="isEmpty(listError.project_id)"
                  :rules="rulesProject"
                  :items="listProjects.options"
                  :disabled="disabledOptions"
                  @input="onChangeProject"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.cargo_study"
                  class="pt-0 input-custom-56"
                  label="Estudio de cargo*"
                  item-text="label"
                  item-value="id"
                  height="56"
                  outlined
                  clearable
                  return-object
                  :hide-details="isEmpty(listError.cargo_study_id)"
                  :rules="rulesCargoStudy"
                  :items="listCargoStudies.options"
                  :disabled="disabledOptions"
                  @input="onChangeCargoStudy"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="py-0">
                <selector-user
                  ref="responsibleMain"
                  v-model="selectedItem.responsible"
                  label="Responsable*"
                  classes="pt-0 input-custom-56"
                  placeholder=""
                  :disabled="disabledOptions"
                  :text-error="listError.responsible_id"
                  @update="onChangeResponsible"
                ></selector-user>
              </v-col>
              <v-col cols="6" class="py-0">
                <selector-user
                  ref="responsibleSecondary"
                  v-model="selectedItem.secondary_responsible"
                  label="Responsable secundario"
                  classes="pt-0 input-custom-56"
                  placeholder=""
                  :disabled="disabledOptions"
                  :text-error="listError.responsible_secondary_id"
                  @update="onChangeSecondaryResponsible"
                ></selector-user>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="selectedItem.controversial_matter"
                  class="pt-0 mb-3 input-custom-56"
                  label="Materia controvertida*"
                  height="56"
                  outlined
                  :readonly="disabledOptions"
                  :hide-details="isEmpty(listError.controversial_matter)"
                  :rules="rulesControversialMatter"
                  @input="onChangeControversialMatter"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="selectedItem.amount"
                  class="pt-0 mb-3 input-custom-56"
                  label="Cuantía*"
                  height="56"
                  outlined
                  suffix="S/. "
                  persistent-hint
                  :hide-details="isEmpty(listError.amount)"
                  :rules="rulesAmount"
                  :readonly="disabledOptions"
                  @keypress="validateNumber($event)"
                  @input="onChangeAmount"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <h4>Control de Contigencias</h4>
                      <template v-if="allFieldsValidated" v-slot:actions>
                        <v-icon color="error"> mdi-alert-circle </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="4" class="py-0">
                          <v-menu
                            v-model="showVersionDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                slot="activator"
                                v-model="dateFormattedVersion"
                                label="Fecha de inicio del proceso*"
                                class="my-0 pt-0 input-custom-56"
                                style="margin-bottom: 0 !important"
                                outlined
                                disabled
                                height="56"
                                append-icon="event"
                                :readonly="disabledOptions"
                                @click:append="showVersionDate = true"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="selectedItem.date_process"
                              :show-current="true"
                              locale="es"
                              no-title
                              scrollable
                              disabled
                              reactive
                              :allowed-dates="allowedDates"
                              :disabled="disabledOptions"
                              @input="onChangeDateProcess"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4" class="py-0">
                          <v-text-field
                            v-model="selectedItem.contingency_percentage"
                            class="pt-0 mb-3 input-custom-56"
                            label="Contingencia Estimada"
                            disabled
                            height="56"
                            suffix="%"
                            outlined
                            :readonly="disabledOptions"
                            @keypress="validateNumber($event)"
                            @input="debounceChange"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                          <v-autocomplete
                            v-model="selectedItem.contingency_level"
                            class="pt-0 input-custom-56"
                            label="Nivel de Contingencia"
                            item-text="label"
                            item-value="id"
                            clearable
                            outlined
                            height="56"
                            return-object
                            disabled
                            :items="listContingency.options"
                            :readonly="disabledOptions"
                            @input="onChangeContingencyLevel"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="py-0">
                          <v-text-field
                            v-model="selectedItem.provision_percentage"
                            class="pt-0 mb-3 input-custom-56"
                            label="Provisión Estimada"
                            height="56"
                            outlined
                            disabled
                            :readonly="disabledOptions"
                            suffix="%"
                            @keypress="validateNumber($event)"
                            @input="onChangeProvisionPercentage"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                          <v-text-field
                            v-model="selectedItem.provision_amount"
                            class="pt-0 mb-3 input-custom-56"
                            label="Monto Provisionado"
                            persistent-hint
                            hint="Calculado automáticamente."
                            height="56"
                            outlined
                            disabled
                            :readonly="disabledOptions"
                            suffix="S/."
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-textarea
                            v-model="selectedItem.risk"
                            class="pt-0 mb-3 input-custom-56"
                            label="Riesgos"
                            disabled
                            rows="5"
                            outlined
                            :readonly="disabledOptions"
                            @input="onChangeRisk"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>

          <v-row v-if="!isEmpty(selectedItem.id)">
            <v-col cols="12" class="pb-0 mb-5">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h4>Situación Actual</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="selectedItem.situation_stage"
                          class="pt-0 mb-0 input-custom-56"
                          label="Próxima acción"
                          height="56"
                          outlined
                          :readonly="disabledOptions"
                          @input="onChangeSituationStage"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-menu
                          v-model="showSituationDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              slot="activator"
                              v-model="dateSituationFormattedVersion"
                              label="Fecha de actualización*"
                              class="my-0 pt-0 input-custom-56"
                              style="margin-bottom: 0 !important"
                              outlined
                              height="56"
                              append-icon="event"
                              :readonly="disabledOptions"
                              @click:append="showSituationDate = true"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="selectedItem.situation_date"
                            :show-current="true"
                            locale="es"
                            no-title
                            scrollable
                            reactive
                            :allowed-dates="allowedDates"
                            :disabled="disabledOptions"
                            @input="onChangeSituationDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-textarea
                          v-model="selectedItem.situation_description"
                          class="pt-0 mb-0 input-custom-56"
                          rows="8"
                          label="Últimos Cambios"
                          outlined
                          :readonly="disabledOptions"
                          @input="onChangeSituationDescription"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row v-if="selectedItem.id">
            <v-col v-if="!isEmpty(selectedItem.id)" cols="12" class="pb-0">
              <v-alert
                v-if="actuaciones"
                prominent
                dense
                :type="actuaciones.Nuevos ? 'info' : 'warning'"
                style="padding: 1rem 1rem 1rem 1rem"
              >
                <v-row align="center">
                  <v-col class="grow">
                    {{ actuaciones.Mensaje }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      @click="onClickActuaciones"
                      depressed
                      :color="actuaciones.Nuevos ? 'action' : 'warning'"
                      class="btn-transform-none"
                      dark
                      small
                    >
                      Ver detalle
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="selectedItem.id">
            <v-col cols="12" class="pb-0">
              <v-stepper v-model="activeTab" class="custom-stepper elevation-0">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_1.id)"
                    :step="1"
                    :color="
                      !isEmpty(selectedItem.stage_1.id) ? 'success' : 'primary'
                    "
                    :editable="isEditable"
                    :style="styleCursorStep(1)"
                    @click="onValidateStep(1)"
                  >
                    Investigación Preliminar
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_2.id)"
                    :color="
                      !isEmpty(selectedItem.stage_2.id) ? 'success' : 'primary'
                    "
                    :step="2"
                    :editable="isEditable"
                    :style="styleCursorStep(2)"
                    @click="onValidateStep(2)"
                  >
                    Investigación Preparatoria<br />a cargo del Fiscal
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_3.id)"
                    :color="
                      !isEmpty(selectedItem.stage_3.id) ? 'success' : 'primary'
                    "
                    :step="3"
                    :editable="isEditable"
                    :style="styleCursorStep(3)"
                    @click="onValidateStep(3)"
                  >
                    Etapa de Juzgamiento
                  </v-stepper-step>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_4.id)"
                    :color="
                      !isEmpty(selectedItem.stage_4.id) ? 'success' : 'primary'
                    "
                    :step="4"
                    :editable="isEditable"
                    :style="styleCursorStep(4)"
                    @click="onValidateStep(4)"
                  >
                    Casación
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <!-- Primera instancia -->
                  <v-stepper-content :step="1">
                    <criminal-step-one
                      :id="selectedItem.id"
                      ref="stepOne"
                      v-model="selectedItem.stage_1"
                      :fileCode="selectedItem.file_code"
                      :disabled-options="disabledOptions"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'one')"
                      @flag-loading="changeLoadingSubmit"
                    ></criminal-step-one>
                  </v-stepper-content>
                  <!-- Segunda instancia -->
                  <v-stepper-content :step="2">
                    <criminal-step-two
                      :id="selectedItem.id"
                      ref="stepTwo"
                      v-model="selectedItem.stage_2"
                      :disabled-options="disabledOptions"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'two')"
                      @flag-loading="changeLoadingSubmit"
                    ></criminal-step-two>
                  </v-stepper-content>
                  <!-- Tercera instancia -->
                  <v-stepper-content :step="3">
                    <criminal-step-three
                      :id="selectedItem.id"
                      ref="stepThree"
                      v-model="selectedItem.stage_3"
                      :disabled-options="disabledOptions"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'three')"
                      @flag-loading="changeLoadingSubmit"
                    ></criminal-step-three>
                  </v-stepper-content>
                  <!-- Cuarta instancia -->
                  <v-stepper-content :step="4">
                    <criminal-step-four
                      :id="selectedItem.id"
                      ref="stepFour"
                      v-model="selectedItem.stage_4"
                      :disabled-options="disabledOptions"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'four')"
                      @flag-loading="changeLoadingSubmit"
                    ></criminal-step-four>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </vue-perfect-scrollbar>
      <v-card-actions
        v-show="!loadDetail"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <template v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
          <v-btn
            v-if="typeDialog === 'edit' && !disabledEdit"
            color="primary"
            small
            depressed
            dark
            class="btn-transform-none"
            @click="disabledEdit = true"
            >Habilitar edición</v-btn
          >
        </template>
        <v-spacer></v-spacer>
        <v-btn
          color="action"
          class="btn-transform-none"
          text
          small
          @click="onCloseDialogUpdate()"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="!isEmpty(selectedItem.id) && !selectedItem.finished"
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          @click="dialogFinished = true"
          >Archivar</v-btn
        >
        <v-btn
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          @click="validateSubmitUpdate(null, true)"
          ><v-progress-circular
            v-if="loadContinue"
            :size="22"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadContinue ? "Guardando" : "Guardar y continuar" }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialogFinished"
      max-width="400"
      @keydown.esc="dialogFinished = false"
    >
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">
            ¿Estás seguro que deseas finalizar la supervisión
            <b>{{ selectedItem.id }}</b> ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="btn-transform-none"
            :disabled="loadFinished"
            text
            small
            @click="dialogFinished = false"
            >Continuar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            :ripple="!loadFinished"
            @click="onSubmitFinished()"
          >
            <v-progress-circular
              v-if="loadFinished"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{ loadFinished ? "Archivando" : "Archivar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-question
      v-model="dialogQuestion"
      @force-close="onCloseDialogQuestion"
    ></dialog-question>
  </v-dialog>
</template>
<script>
import { ICriminalProcess } from "@/models";
import { mapState } from "vuex";
import { capitalize, cloneDeep, debounce, isEmpty } from "lodash";
import mixinApiModule from "@/components/modules/mixins/mixinApiModule";
import DialogQuestion from "@/components/commons/DialogQuestion";
import mixinSteps from "./mixins/mixinSteps";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ExportWord from "@/components/commons/ExportWord";
import SelectorUser from "@/components/commons/SelectorUser";
import CriminalStepOne from "./criminal/CriminalStepOne";
import CriminalStepTwo from "./criminal/CriminalStepTwo";
import CriminalStepThree from "./criminal/CriminalStepThree";
import CriminalStepFour from "./criminal/CriminalStepFour";
import { IJudicialProcess } from "@/models";

export default {
  name: "DialogDetailCiminalProcess",
  components: {
    VuePerfectScrollbar,
    DialogQuestion,
    SelectorUser,
    ExportWord,
    CriminalStepOne,
    CriminalStepTwo,
    CriminalStepThree,
    CriminalStepFour,
  },
  mixins: [mixinApiModule, mixinSteps],
  // model: { prop: "dialogNew", event: "change" },
  props: {
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    inputFileCode: null,
    inputRespondentParty: null,
    inputComplainingParty: null,
    inputControversialMatter: null,
    inputAmount: null,
    calculateAmount: null,
    selectedCargoStudy: null,
    selectedProject: null,
    selectedResponsible: null,
    selectedSecondaryResponsible: null,
    disabledEdit: false,
    actuaciones: null,
    selectedItem: new ICriminalProcess(),
    cloneItem: null,
    isDraft: false,
    // angloamerican fields
    dateProcess: null,
    situationDate: null,
    situationDescription: null,
    situationStage: null,
    risk: null,
    provisionAmount: null,
    debounceChange: null,
    provisionPercentage: null,
    calculateProvisionPercentage: null,
    associationHonorable: null,
    contingencyPercentage: null,
    contingencyLevel: null,
    //
    showVersionDate: false,
    showSituationDate: false,
    // nivel de contingencia
    listContingency: {
      options: [
        {
          id: "Posible",
          label: "Posible",
        },
        {
          id: "Remoto",
          label: "Remoto",
        },
        {
          id: "Probable",
          label: "Probable",
        },
      ],
    },
    dialogQuestion: false,
    loadingQuestion: false,
    dialogDetail: false,
    loadUpdate: false,
    loadCreate: false,
    loadDetail: false,
    // finished
    loadFinished: false,
    dialogFinished: false,
    // rules
    rulesFileCode: [],
    rulesProject: [],
    rulesCargoStudy: [],
    rulesRespondentParty: [],
    rulesComplainingParty: [],
    rulesControversialMatter: [],
    rulesAmount: [],
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // export
    listError: {
      respondent_party: null,
      complaining_party: null,
      controversial_matter: null,
      amount: null,
      file_code: null,
      project_id: null,
      cargo_study_id: null,
      responsible_id: null,
    },
    loadContinue: false,
    allFieldsValidated: false,
    isContinue: false,
    // editable
    flagEditForm: false,
    // flagSteps
    flagStepOne: false,
    flagStepTwo: false,
    flagStepThree: false,
    flagStepFour: false,
  }),
  computed: {
    // courts
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),

    dateFormattedVersion() {
      return this.formatDate(this.dateProcess);
    },

    dateSituationFormattedVersion() {
      return this.formatDate(this.situationDate);
    },
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
    stillFieldWithError() {
      return Object.values(this.listError)
        .map((k) => k === null)
        .some((x) => !x);
    },
    listCargoStudies() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === "cargo-studies";
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  watch: {
    selectedItem() {
      if (!isEmpty(this.selectedItem)) {
        this.calculateAmount = this.formatAmount(this.selectedItem.amount);
        this.calculateProvisionPercentage = this.formatAmount(
          this.selectedItem.provision_percentage
        );
      }
    },
  },
  mounted() {
    this.debounceChange = debounce(this.onChangeContingencyPercentage, 700);
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },

    formatAmount(amount) {
      if (amount.includes("S/")) {
        const ammountComma = amount.replace(/\s+/g, "").split("S/")[1];
        return Number(ammountComma.replaceAll(",", ""));
      }

      return Number(amount.replaceAll(",", ""));
    },

    preventResponsibleEmptyInput() {
      this.selectedResponsible = this.selectedItem.responsible;
      this.selectedSecondaryResponsible =
        this.selectedItem.secondary_responsible;
    },

    flagFields() {
      this.flagEditForm = true;
      this.allFieldsValidated = false;
    },

    onSubmitFinished() {
      if (this.loadFinished) return false;
      this.loadFinished = true;
      this.axios
        .post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/finish`
        )
        .then(() => {
          this.loadFinished = false;
        });
    },

    async validateSubmitUpdate(step, isContinue = false) {
      this.isContinue = isContinue;
      if (this.selectedItem.id) {
        const cloneItem = cloneDeep(this.cloneItem);
        const cloneSelectedItem = cloneDeep(this.selectedItem);
        delete cloneSelectedItem.stage_1;
        delete cloneSelectedItem.stage_2;
        delete cloneSelectedItem.stage_3;
        delete cloneSelectedItem.stage_4;
        delete cloneItem.stage_1;
        delete cloneItem.stage_2;
        delete cloneItem.stage_3;
        delete cloneItem.stage_4;
        if (this.flagEditForm) {
          await this.onSubmitCreateBase("edit");
        } else {
          if (this.flagStepOne) {
            //await this.$refs.stepOne.validateStepByStep();
            if (this.flagStepOne.posicion == -1) {
              await this.$refs.stepOne.validateStepByStep();
            } else {
              await this.$refs.stepOne.validateStepByStepDinamicamente(
                this.flagStepOne.posicion
              );
            }
          }
          if (this.flagStepTwo) {
            //await this.$refs.stepTwo.validateStepByStep();
            console.log(44);
            //Se ejecuta solo en el paso uno
            if (this.flagStepTwo.posicion == -1) {
              console.log(222);
              await this.$refs.stepTwo.validateStepByStep();
            } else {
              console.log(111);
              await this.$refs.stepTwo.validateStepByStepDinamicamente(
                this.flagStepTwo.posicion
              );
            }
          }
          if (this.flagStepThree) {
            //await this.$refs.stepThree.validateStepByStep();
            if (this.flagStepThree.posicion == -1) {
              await this.$refs.stepThree.validateStepByStep();
            } else {
              await this.$refs.stepThree.validateStepByStepDinamicamente(
                this.flagStepThree.posicion
              );
            }
          }
          if (this.flagStepFour) {
            //await this.$refs.stepFour.validateStepByStep();
            if (this.flagStepFour.posicion == -1) {
              await this.$refs.stepFour.validateStepByStep();
            } else {
              await this.$refs.stepFour.validateStepByStepDinamicamente(
                this.flagStepFour.posicion
              );
            }
          }
        }
      } else {
        await this.onSubmitCreateBase("new");
      }
    },
    async onSubmitCreateBase(type = "new") {
      if (this.loadCreate) return false;
      if (this.loadContinue) return false;

      if (this.isContinue) {
        this.loadContinue = true;
      } else {
        this.loadCreate = true;
      }
      const payload = {
        file_code: this.inputFileCode ? this.inputFileCode : null,
        amount: this.inputAmount ? this.inputAmount : null,
        association_honorable: this.associationHonorable
          ? this.associationHonorable
          : null,
        contingency_percentage: this.contingencyPercentage
          ? this.contingencyPercentage
          : null,
        contingency_level: this.contingencyLevel ? this.contingencyLevel : null,
        provision_percentage: this.provisionPercentage
          ? this.provisionPercentage
          : null,
        provision_amount: this.provisionAmount ? this.provisionAmount : null,
        risk: this.risk ? this.risk : null,
        date_process: this.dateProcess ? this.dateProcess : null,
        situation_description: this.situationDescription
          ? this.situationDescription
          : null,
        situation_stage: this.situationStage ? this.situationStage : null,
        situation_date: this.situationDate ? this.situationDate : null,
        controversial_matter: this.inputControversialMatter
          ? this.inputControversialMatter
          : null,
        complaining_party: this.inputComplainingParty
          ? this.inputComplainingParty
          : null,
        respondent_party: this.inputRespondentParty
          ? this.inputRespondentParty
          : null,
        cargo_study_id: this.selectedCargoStudy
          ? this.selectedCargoStudy.id
          : null,
        responsible_id: this.selectedResponsible
          ? this.selectedResponsible.id
          : null,
        responsible_secondary_id: this.selectedSecondaryResponsible
          ? this.selectedSecondaryResponsible.id
          : null,
        project_id: !this.isEmpty(this.selectedProject)
          ? this.selectedProject.id
          : null,
      };
      if (type === "new") {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/create`,
          payload
        );
        this.allFieldsValidated = this.stillFieldWithError;
        if (data.success) {
          this.$toast.success(
            `El proceso judicial con código ${data.data.file_code}, ha sido creado correctamente`
          );
          this.$emit("re-fetch", 1);

          let resp = data.data;
          this.selectedItem.id = resp.id;
          this.$set(this.selectedItem, "id", resp.id);
          this.cloneItem = cloneDeep(resp);
          this.$nextTick(async () => {
            if (this.flagStepOne) {
              await this.$refs.stepOne.validateStepByStep();
            }
            if (this.flagStepTwo) {
              await this.$refs.stepTwo.validateStepByStep();
            }
            if (this.flagStepThree) {
              await this.$refs.stepThree.validateStepByStep();
            }
            if (this.flagStepFour) {
              await this.$refs.stepFour.validateStepByStep();
            }
          });
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
          if (!this.isContinue) {
            this.onCloseDialogUpdate();
          }
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      } else {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/update`,
          payload
        );
        this.allFieldsValidated = this.stillFieldWithError;
        if (data.success) {
          this.$toast.success(
            `El proceso judicial con el expediente ${data.data.file_code}, se ha actualizado correctamente!`
          );

          this.$emit("re-fetch", 1);

          if (this.flagStepOne) {
            await this.$refs.stepOne.validateStepByStep();
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.validateStepByStep();
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep();
          }
          if (this.flagStepFour) {
            await this.$refs.stepThree.validateStepByStep();
          }
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      }
    },

    listErrorSection(err) {
      const {
        respondent_party = "",
        complaining_party = "",
        controversial_matter = "",
        amount = "",
        file_code = "",
        project_id = "",
        cargo_study_id = "",
        responsible_id = "",
      } = err;
      // listError
      this.listError.respondent_party = respondent_party
        ? respondent_party[0]
        : null;
      this.listError.complaining_party = complaining_party
        ? complaining_party[0]
        : null;
      this.listError.controversial_matter = controversial_matter
        ? controversial_matter[0]
        : null;
      this.listError.amount = amount ? amount[0] : null;
      this.listError.cargo_study_id = cargo_study_id ? cargo_study_id[0] : null;
      this.listError.file_code = file_code ? file_code[0] : null;
      this.listError.responsible_id = responsible_id ? responsible_id[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      // rules
      this.rulesControversialMatter = this.listError.controversial_matter
        ? [() => this.listError.controversial_matter]
        : [];
      this.rulesAmount = this.listError.amount
        ? [() => this.listError.amount]
        : [];
      this.rulesRespondentParty = this.listError.respondent_party
        ? [() => this.listError.respondent_party]
        : [];
      this.rulesComplainingParty = this.listError.complaining_party
        ? [() => this.listError.complaining_party]
        : [];
      this.rulesCargoStudy = this.listError.cargo_study_id
        ? [() => this.listError.cargo_study_id]
        : [];
      this.rulesFileCode = this.listError.file_code
        ? [() => this.listError.file_code]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.$refs.formUpdate.validate();
    },

    onValidateStep(step) {
      switch (step) {
        case 1:
          this.activeTab = step;
          break;
        case 2:
          if (!this.isEmpty(this.selectedItem.stage_1.id))
            this.activeTab = step;
          break;
        case 3:
          if (!this.isEmpty(this.selectedItem.stage_2.id))
            this.activeTab = step;
          break;
        case 4:
          if (!this.isEmpty(this.selectedItem.stage_3.id))
            this.activeTab = step;
          break;
        default:
          console.log(
            `Lo lamentamos, por el momento no disponemos de stage_${step}.`
          );
      }
    },

    nextStep(step) {
      if (step === 2) {
        this.activeTab = 2;
      } else if (step === 3) {
        this.activeTab = 3;
      } else if (step === 4) {
        this.activeTab = 4;
        this.loadSave = true;
      }
    },

    onCloseDialogQuestion() {
      this.dialogQuestion = false;
      this.selectedItem = new IJudicialProcess();
      this.dateProcess = null;
      this.situationStage = null;
      this.situationDescription = null;
      this.calculateProvisionPercentage = 0;
      this.calculateAmount = 0;
      this.$emit("close");
    },
    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? null : value.id;
      } else {
        return this.isEmpty(value) ? null : value;
      }
    },
    onCloseDialogUpdate() {
      const aux = cloneDeep(this.selectedItem);
      if (
        this.flagEditForm ||
        this.flagStepOne ||
        this.flagStepTwo ||
        this.flagStepThree ||
        this.flagStepFour
      ) {
        this.dialogQuestion = true;
      } else {
        this.$emit("change", false);
        this.dialogQuestion = false;
        this.selectedItem = new ICriminalProcess();
        this.isContinue = false;
        this.$emit("close");
      }
      if (this.selectedItem.id) {
        this.$refs.stepOne.clearForm();
        this.$refs.stepTwo.clearForm();
        this.$refs.stepThree.clearForm();
        this.$refs.stepFour.clearForm();
      }
      if (this.typeDialog === "new" || this.typeDialog === "add") {
        this.$emit("create-active", aux);
      }
    },
    openDialogDetail(item, flagEdit = false) {
      this.activeTab = 1;
      this.clearErrors();
      this.selectedItem = new ICriminalProcess();
      this.cloneItem = new ICriminalProcess();
      this.$nextTick(() => {
        if (flagEdit) this.disabledEdit = flagEdit;
      });
      if (this.$refs.stepOne) {
        this.$refs.stepOne.clearForm();
      }
      if (this.$refs.stepTwo) {
        this.$refs.stepTwo.clearForm();
      }
      if (this.$refs.stepThree) {
        this.$refs.stepThree.clearForm();
      }
      if (this.$refs.stepFour) {
        this.$refs.stepFour.clearForm();
      }
    },
    async openDialogEdit(item) {
      this.activeTab = 1;
      this.loadDetail = true;
      const { data } = await this.axios.get(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${item.id}`
      );
      if (data.success) {
        this.flagEditForm = false;

        const selectedItem = cloneDeep(data.data);
        if (this.isEmpty(selectedItem.stage_1)) {
          selectedItem.stage_1 = { id: null };
        } else this.activeTab = 1;
        if (this.isEmpty(selectedItem.stage_2)) {
          selectedItem.stage_2 = { id: null };
        } else this.activeTab = 2;
        if (this.isEmpty(selectedItem.stage_3)) {
          selectedItem.stage_3 = { id: null };
        } else this.activeTab = 3;
        if (this.isEmpty(selectedItem.stage_4)) {
          selectedItem.stage_4 = { id: null };
        } else this.activeTab = 4;
        this.selectedItem = cloneDeep(selectedItem);
        this.cloneItem = cloneDeep(selectedItem);
        this.setItems(selectedItem);
        this.flagEditForm = false;
        this.disabledEdit = false;
        this.loadDetail = false;
        this.$nextTick(() => {
          if (this.$refs.stepOne) {
            this.$refs.stepOne.setForm();
          }
          if (this.$refs.stepTwo) {
            this.$refs.stepTwo.setForm();
          }
          if (this.$refs.stepThree) {
            this.$refs.stepThree.setForm();
          }
          if (this.$refs.stepFour) {
            this.$refs.stepFour.setForm();
          }
        });
      }
    },
    setItems(selectedItem) {
      this.inputFileCode = selectedItem.file_code;
      this.inputAmount = selectedItem.amount;
      this.associationHonorable = selectedItem.association_honorable;
      this.dateProcess = selectedItem.date_process;
      this.contingencyPercentage = selectedItem.contingency_percentage;
      this.contingencyLevel = selectedItem.contingency_level;
      this.risk = selectedItem.risk;
      this.provisionAmount = selectedItem.provision_amount;
      this.provisionPercentage = selectedItem.provision_percentage;
      this.situationDate = selectedItem.situation_date;
      this.situationDescription = selectedItem.situation_description;
      this.situationStage = selectedItem.situation_stage;
      this.inputControversialMatter = selectedItem.controversial_matter;
      this.inputComplainingParty = selectedItem.complaining_party;
      this.inputRespondentParty = selectedItem.respondent_party;
      this.selectedCargoStudy = selectedItem.cargo_study;
      this.selectedResponsible = selectedItem.responsible;
      this.selectedProject = selectedItem.project;
    },
    clearErrors() {
      // clear roles
      this.rulesControversialMatter = [];
      this.rulesAmount = [];
      this.rulesRespondentParty = [];
      this.rulesComplainingParty = [];
      this.rulesCargoStudy = [];
      this.rulesFileCode = [];
      this.rulesProject = [];
      // clear listError
      this.listError = {
        respondent_party: "",
        complaining_party: "",
        controversial_matter: "",
        amount: "",
        file_code: "",
        project_id: "",
        cargo_study_id: "",
        responsible_id: "",
      };
      // clear Inputs
      this.inputFileCode = null;
      this.inputRespondentParty = null;
      this.inputComplainingParty = null;
      this.inputControversialMatter = null;
      this.inputAmount = null;
      this.selectedCargoStudy = null;
      this.selectedProject = null;
      this.selectedResponsible = null;
    },
    onChangeFileCode(fileCode) {
      if (fileCode === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.file_code = null;
      this.inputFileCode = fileCode;
    },
    onChangeRespondentParty(respondentParty) {
      if (respondentParty === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.respondent_party = null;
      this.inputRespondentParty = respondentParty;
    },
    onChangeComplainingParty(complainingParty) {
      if (complainingParty === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.complaining_party = null;
      this.inputComplainingParty = complainingParty;
    },
    onChangeCargoStudy(cargoStudy) {
      if (cargoStudy === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.cargo_study_id = null;
      this.selectedCargoStudy = cargoStudy;
    },
    onChangeResponsible(responsible) {
      if (responsible === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.responsible_id = null;
      this.selectedResponsible = responsible;
    },

    onChangeSecondaryResponsible(responsible) {
      if (responsible === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.responsible_secondary_id = null;
      this.selectedSecondaryResponsible = responsible;
    },

    onChangeProject(project) {
      if (project === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.project_id = null;
      this.selectedProject = project;
    },
    onChangeControversialMatter(controversialMatter) {
      if (controversialMatter === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.controversial_matter = null;
      this.inputControversialMatter = controversialMatter;
    },
    onChangeAmount(amount) {
      if (amount === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.listError.amount = null;

      this.calculateAmount = this.formatAmount(amount);

      if (this.calculateProvisionPercentage) {
        const calculate = Number(
          this.calculateAmount * this.calculateProvisionPercentage
        ).toFixed(2);
        this.selectedItem.provision_amount = calculate;
        this.provisionAmount = calculate.toString();
      }

      this.inputAmount = amount;
    },

    validateNumber(event) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = event.key;

      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },

    onChangeDateProcess(date_process) {
      if (date_process === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.showVersionDate = false;
      this.dateProcess = date_process ? date_process : null;
    },

    onChangeSituationDate(situation_date) {
      if (situation_date === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.showSituationDate = false;
      this.situationDate = situation_date ? situation_date : null;
    },

    onChangeRisk(risk) {
      if (risk === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.risk = risk;
    },

    onChangeProvisionAmount(provision_amount) {
      if (provision_amount === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.provisionAmount = provision_amount;
    },

    onClickActuaciones() {
      window.open(this.actuaciones.URL, "_blank");
    },

    onChangeSituationDescription(situation_description) {
      if (situation_description === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.situationDescription = situation_description;
    },

    onChangeSituationStage(situation_stage) {
      if (situation_stage === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.situationStage = situation_stage;
    },

    onChangeProvisionPercentage(provision_percentage) {
      if (provision_percentage === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.provisionPercentage = provision_percentage;

      if (provision_percentage.includes("%")) {
        const provisionPercentage = provision_percentage.replace("%", "");
        this.calculateProvisionPercentage = Number(provisionPercentage);
        this.selectedItem.provision_amount = Number(
          this.calculateAmount * Number(provisionPercentage)
        ).toFixed(2);
        this.provisionAmount = Number(
          this.calculateAmount * Number(provisionPercentage)
        )
          .toFixed(2)
          .toString();
      } else {
        this.calculateProvisionPercentage = Number(provision_percentage);
        this.selectedItem.provision_amount = Number(
          this.calculateAmount * Number(provision_percentage)
        ).toFixed(2);
        this.provisionAmount = Number(
          this.calculateAmount * Number(provision_percentage)
        )
          .toFixed(2)
          .toString();
      }
    },

    onChangeAssociationHonorable(association_honorable) {
      if (association_honorable === "") {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.associationHonorable = association_honorable;
    },

    onChangeContingencyLevel(contingency_level) {
      if (contingency_level === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagFields();
      this.contingencyLevel = contingency_level.id;
    },

    onChangeContingencyPercentage(contingency_percentage) {
      if (contingency_percentage === "") {
        this.preventResponsibleEmptyInput();
        this.selectedItem.contingency_level = null;
        this.contingencyLevel = null;
      }

      this.flagFields();
      this.contingencyPercentage = contingency_percentage;

      const percentage = Number(contingency_percentage);

      if (percentage < 49) {
        this.selectedItem.contingency_level = "Remoto";
        this.contingencyLevel = "Remoto";
      } else if (percentage < 79) {
        this.selectedItem.contingency_level = "Posible";
        this.contingencyLevel = "Posible";
      } else {
        this.selectedItem.contingency_level = "Probable";
        this.contingencyLevel = "Probable";
      }
    },
    changeLoadingSubmit(flag) {
      if (this.isContinue) {
        this.loadContinue = flag;
      } else {
        this.loadCreate = flag;
      }
    },
    changeFlagQuestion(flag, step) {
      this.$set(this, `flagStep${capitalize(step)}`, flag);
    },
    styleCursorStep(step = 1) {
      let flag = !this.isEmpty(this.selectedItem[`stage_${step}`].id);
      return `cursor: ${flag ? "pointer" : "initial"}`;
    },
    allowedDates(val) {
      return true;
    },
  },
};
</script>
