<template>
  <v-col :cols="typeSubmodule === 'hidricos' ? 12 : 12" class="py-0">
    <!--<h4 class="mb-2 card-input-title">Responsable</h4>-->
    <v-form
      ref="formUpdateResponsible"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-container fluid class="py-0 px-0">
        <v-row>
          <v-col cols="6" class="py-0">
            <selector-user
              ref="responsibleMain"
              v-model="selectedItem.primary_responsible"
              label="Responsable principal*"
              classes="pt-0 mb-3"
              placeholder=""
              :clearable="false"
              :filter-ejecutor="filterEjecutor"
              :disabled="disabled"
              :text-error="listError.primary_responsible_id"
              @clear-error="onClearResponsible('primary')"
            ></selector-user>
          </v-col>
          <v-col cols="6" class="py-0">
            <selector-user
              ref="responsibleSecondary"
              v-model="selectedItem.secondary_responsible"
              label="Responsable secundario"
              classes="pt-0 mb-3"
              placeholder=""
              :clearable="false"
              :filter-ejecutor="filterEjecutor"
              :disabled="disabled"
              :text-error="listError.secondary_responsible_id"
              @clear-error="onClearResponsible('secondary')"
            ></selector-user>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-col>
</template>
<script>
import SelectorUser from "./SelectorUser";
import { mapActions, mapState } from "vuex";

export default {
  name: "ResponsibleSection",
  components: {
    SelectorUser,
  },
  props: {
    selectedItem: {
      type: Object,
      default: () => {},
    },
    listError: {
      type: Object,
      default: () => {},
    },
    filterEjecutor: {
      type: Boolean,
      defualt: false,
    },
    typeDialog: {
      type: String,
      default: "new",
    },
    typeSubmodule: { type: String, default: "" },
    disabled: {
      type: Boolean,
      defualt: false,
    },
  },
  data: () => ({
    lazyUpdate: false,
    validUpdate: false,
    type: "",
    selectedPrimary: null,
    selectedSecondary: null,
    loadSave: false,
    dialogResponsable: false,
    // listError: {
    //   primary_responsible_id: "",
    //   secondary_responsible_id: "",
    // },
  }),
  computed: {
    ...mapState({
      listAllUsers: (state) => state.users.listAllUsers,
    }),
  },
  mounted() {
    this.$refs.formUpdateResponsible.resetValidation();
  },
  methods: {
    ...mapActions(["createUser"]),
    resetValidation() {
      this.$refs.formUpdateResponsible.resetValidation();
    },
    validateError(err) {
      const {
        primary_responsible_id = "",
        secondary_responsible_id = "",
      } = err;
      this.listError.primary_responsible_id = !this.isEmpty(
        primary_responsible_id,
      )
        ? primary_responsible_id[0]
        : null;
      this.listError.secondary_responsible_id = !this.isEmpty(
        secondary_responsible_id,
      )
        ? secondary_responsible_id[0]
        : null;
      this.$refs.formUpdateResponsible.validate();
    },
    setForm() {
      this.selectedPrimary = this.selectedItem.primary_responsible;
      this.selectedSecondary = this.selectedItem.secondary_responsible;
    },
    onClearResponsible(type) {
      this.$emit("clear-error", type);
    },
  },
};
</script>
