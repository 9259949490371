import { merge } from "lodash";
import { mapState } from "vuex";
import { IBaseInstance } from "../models";

const listNumber = [
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];

export default {
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: null,
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    disabledOptions: {
      type: Boolean,
      default: false,
    },
    typeDialog: {
      type: String,
      default: "new",
    },
  },
  data: () => ({
    listNumber,
    validUpdate: false,
    lazyUpdate: false,
    loadSave: false,
    activeTab: 1,
    flagEdit: false,
    instance: {
      one: new IBaseInstance(),
      two: new IBaseInstance(),
      three: new IBaseInstance(),
      four: new IBaseInstance(),
      five: new IBaseInstance(),
      six: new IBaseInstance(),
      seven: new IBaseInstance(),
      eight: new IBaseInstance(),
      nine: new IBaseInstance(),
    },
    incidencias: [],
    changeInstance: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
      eight: false,
      nine: false,
    },
    payloadInstance: {
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      six: {},
      seven: {},
      eight: {},
      nine: {},
    },
  }),
  computed: {
    // courts
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listProjects: (state) => state.masters.listProjects,
    }),
    listRooms() {
      const rooms = this.listMasters.filter((e) => {
        return e.slug === "rooms";
      });
      return rooms.length ? rooms[0] : { options: [] };
    },
    listCourts() {
      const courts = this.listMasters.filter((e) => {
        return e.slug === "courts";
      });
      return courts.length ? courts[0] : { options: [] };
    },
    selectedId() {
      return this.selectedItem ? this.selectedItem.id : null;
    },
  },
  mounted() {
    this.activeTab = 1;
    this.flagEdit = false;
  },
  methods: {

    onUpdateInstance(nameInstance, payload) {
      this.$set(this.payloadInstance, nameInstance, payload);
    },

    generateInstance(step) {
      return merge(new IBaseInstance(), this.selectedItem[`instance_${step}`]);
    },

    setAllInstances() {

      this.listNumber.forEach((e, i) => {
        if (this.$refs[`instance${this.nameCapitalize(e)}`]) {
          
          this.$set(this.instance, e, this.generateInstance(i + 1));

          if (this.instance[e].id) this.activeTab = i + 1;
          this.$nextTick(() => {
           const refName = `instance${this.nameCapitalize(e)}`;
            if (this.$refs[refName][0] && typeof this.$refs[refName][0].setForm === 'function') {
              this.$refs[refName][0].setForm();
            }
          });
        }
      });
      
    },
    
    clearAllInstances() {
      this.listNumber.forEach((e) => {
        if (this.$refs[`instance${this.nameCapitalize(e)}`]) {
          this.$set(this.instance, e, new IBaseInstance());
          this.$set(this.changeInstance, e, false);
          this.$set(this.payloadInstance, e, {});
        
          this.$nextTick(() => {
            const refName = `instance${this.nameCapitalize(e)}`;
            if (this.$refs[refName][0] && typeof this.$refs[refName][0].clearForm === 'function') {
              this.$refs[refName][0].clearForm();
            }
          });
        }
      });
    },
  },
};
