<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public">
      <v-app id="inspire" class="app">
        <template v-if="loadingApp">
          <v-container fluid class="text-center fill-height">
            <v-progress-circular
              :size="180"
              width="10"
              class="mx-auto"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-container>
        </template>
        <template v-else>
          <template v-if="isResetPage">
            <router-view></router-view>
          </template>
          <template v-else>
            <admin-drawer v-if="!isMobile" class="app--drawer"></admin-drawer>
            <app-toolbar class="app--toolbar"></app-toolbar>
            <v-main class="fill-height bg-jaru">
              <v-container fluid class="page-wrapper pa-0 fill-height">
                <router-view></router-view>
              </v-container>
            </v-main>
          </template>
          <v-snackbar
            v-model="snackbar.show"
            bottom
            color="action"
            right
            :timeout="6000"
            @click.native="onClickSnackBar"
          >
            <span class="h6">{{ snackbar.title }}</span
            ><br />
            <span class="caption">{{ snackbar.description }}</span>
          </v-snackbar>
        </template>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <router-view :key="$route.fullpath"></router-view>
      </transition>
    </template>
  </div>
</template>
<script>
import { nameToken } from "@/const";
import { cloneDeep } from "lodash";
import { mapActions, mapState } from "vuex";
import AdminDrawer from "./layout/drawer/AdminDrawer";
import AppToolbar from "./layout/AppToolbar";
import { isMobile } from "mobile-device-detect";
import { MenuAdmin } from "@/api/menu";

export default {
  name: "AppBase",
  components: {
    AdminDrawer,
    AppToolbar,
  },
  data: () => ({
    listItem: MenuAdmin,
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      title: "",
      description: "",
    },
    notificationEntity: {},
    isMobile,
    loadingApp: true,
  }),
  computed: {
    ...mapState({
      listModules: (state) => state.modules.modules,
      toggleDrawer: (state) => state.session.toggleDrawer,
      currentUser: (state) => state.session.currentUser,
    }),
    isResetPage() {
      return this.$route.name === "ResetPage";
    },
    isDashboard() {
      if (this.isEmpty(this.$route)) {
        return false;
      } else {
        const { name } = this.$route;
        return name === "DashboardPage";
      }
    },
  },
  created() {
    window.getApp = this;
    this.snackbar.show = false;
    if (this.$messaging) {
      this.$messaging.onMessage((payload) => this.onShowNotification(payload));
    }
    if (
      this.$route.name === "SwitchModulePage" &&
      this.listModules.length === 0
    ) {
      this.resetMenuModule();
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.snackbar.show = true;
    //   this.snackbar.title = "dsadsa";
    //   this.snackbar.description = "weqnjenwqj newkq";
    // }, 1200);
    const token = localStorage.getItem(nameToken) || null;
    if (token) {
      this.getMe().then(() => {
        this.fetchModules().then(() => {
          this.loadingApp = false;
        });
      });
    } else {
      this.loadingApp = false;
    }
  },
  methods: {
    ...mapActions([
      "fetchModules",
      "fetchMasters",
      "getMe",
      "setDrawer",
      "clearActiveModule",
      "setActiveModules",
      "setActiveSubModules",
    ]),
    onClickSnackBar(e) {
      this.axios
        .get(`/api/to_dos/${this.notificationEntity.entity_id}`)
        .then(({ data }) => {
          if (data.success) {
            const menu = {
              icon: "icon-modulos.png",
              label: "Módulos",
              key: "modules",
              leaf: false,
              submenu: [],
            };
            this.clearActiveModule("all");
            this.$store.dispatch(`menuAdmin/setMenu`, {
              menu: this.listItem,
              init: true,
              index: 0,
            });
            this.$store.dispatch(`menuAdmin/setMenuActive`, menu);
            this.$store.dispatch(`menuAdmin/setListActive`, {
              active: menu,
              init: true,
              index: 0,
            });
            this.setStoreMenu(data.data.module);
            this.setStoreMenu(data.data.submodule);
            if (this.isNotification) {
              this.$emit("change", false);
              this.resetDialog();
            }
            this.$router.push({
              name: "SwitchModulePage",
              params: {
                typeModule: data.data.module.slug,
                typeSubmodule: data.data.submodule.slug,
              },
              query: {
                ficha: data.data.entity_id,
              },
            });
          }
        });
    },
    onShowNotification(payload) {
      const { data, notification } = payload;
      this.notificationEntity = data;
      this.snackbar.show = true;
      this.snackbar.title = notification.title;
      this.snackbar.description = notification.body;
    },
    onSelectedNotification(e) {
      this.resetMenuModule(true);
    },
    validateUserAuth() {
      this.getMe().then(() => {
        if (!this.isEmpty(this.currentUser))
          this.fetchMasters({ payload: "", step: true });
      });
    },
    resetMenuModule(flag = false) {
      this.clearActiveModule("all");
      this.$store.dispatch(`menuAdmin/setMenu`, {
        menu: MenuAdmin,
        init: true,
        index: 0,
      });
      this.$store.dispatch(`menuAdmin/setMenuActive`, MenuAdmin[0]);
      this.$store.dispatch(`menuAdmin/setListActive`, {
        active: MenuAdmin[0],
        init: true,
        index: 0,
      });
      if (flag) {
        this.axios
          .get(`/api/to_dos/${this.notificationEntity.entity_id}`)
          .then(({ data }) => {
            this.setStoreModules(
              this.item.module,
              this.item.submodule,
              this.item.entity_id,
              true,
            );
          });
      } else {
        const { path, query } = this.$route;
        const pathSplit = path.split("/");
        pathSplit.splice(0, 1);
        const cloneModules = cloneDeep(this.listModules);
        const objModule = cloneModules.find((e) => e.slug === pathSplit[1]);
        const objSubmodule = objModule.submodules.find(
          (e) => e.slug === pathSplit[2],
        );
        this.setStoreModules(
          objModule,
          objSubmodule,
          this.isEmpty(query) ? null : query.ficha,
          false,
        );
      }
    },
    setStoreModules(objModule, objSubmodule, ficha = null, flag = false) {
      this.setStoreMenu(objModule);
      this.setStoreMenu(objSubmodule);
      if (flag && this.isNotification) {
        this.$emit("change", false);
        this.resetDialog();
      }
      const payload = {
        name: "SwitchModulePage",
        params: {
          typeModule: objModule.slug,
          typeSubmodule: objSubmodule.slug,
        },
      };
      if (ficha) {
        payload["query"] = {
          ficha: this.item.entity_id,
        };
      }
      this.$router.push(payload);
    },
    setStoreMenu(item) {
      if (item.slug === "propiedades-mineras") {
        this.setActiveModules(item);
      } else {
        if (item.submodules) {
          this.setActiveModules(item);
        } else {
          this.setActiveSubModules(item);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
