export default {
  data() {
    return {
      sortDesc: null,
      sortBy: null,
    };
  },
  methods: {
    eventSortBy(e) {
      this.sortBy = e.length ? e[0] : null;
      this.fetchSortPage();
    },
    eventSortDesc(e) {
      this.sortDesc = e.length ? e[0] : null;
      this.fetchSortPage();
    },
    fetchSortPage() {
      const sortUrl = `${this.sortName(this.sortBy)}:${
        this.sortDesc ? "desc" : "asc"
      }`;
      if (this.sortBy !== null && this.sortDesc !== null) {
        this.clearMiningProperty();
        this.toggleSortMiningProperty(sortUrl);
        const payload = {
          payload: this.getCompleteUrl(sortUrl),
          step: true,
        };
        this.fetchMiningProperties(payload);
      } else {
        this.clearMiningProperty();
        this.toggleSortMiningProperty(null);
        const payload = {
          payload: "?response=lite&page=1",
          step: true,
        };
        this.fetchMiningProperties(payload);
      }
    },
    getCompleteUrl(sortUrl) {
      return `?response=lite&page=1${this.filterData}&sort=${sortUrl}`;
    },
    sortName(name) {
      if (name === "type.label") {
        return "type";
      } else if (name === "project.label") {
        return "project";
      } else if (name === "situation.label") {
        return "situation";
      }
      return name;
    },
  },
};
