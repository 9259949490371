<template>
  <div
    id="arcgisMap"
    class="gmap-section fill-height"
    style="background-color: #fff;"
  >
    <button
      id="zoomInBtn"
      type="button"
      class="btn-zoom zoom-in"
      :class="{ 'active-collapse': toggleDrawer }"
    >
      +
    </button>
    <button
      id="zoomOutBtn"
      type="button"
      class="btn-zoom zoom-out"
      :class="{ 'active-collapse': toggleDrawer }"
    >
      -
    </button>
    <v-dialog
      v-model="dialogEmpty"
      max-width="300"
      @keydown.esc="dialogEmpty = false"
    >
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">
            Registro no encontrado...
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            @click="dialogEmpty = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import ArcGISMap from "esri/Map";
// import MapView from "esri/views/MapView";
import { loadModules, setDefaultOptions } from "esri-loader";
import { cloneDeep } from "lodash";
import mixinLayers from "./mixinLayers";

const CATASTRO_LAYER =
  "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer";
const AREA_RESERVADA_LAYER =
  "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_AREA_RESERVADA/MapServer";
const CARTOGRAFIA_DEMARCACION_LAYER =
  "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer";

const CATASTRO_QUERY =
  "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO/MapServer/0/";
const COLOR_LAYER = [23, 41, 60];
const COLOR_LAYER_FOCUS = [26, 168, 222];

setDefaultOptions({ version: "3.31" });
let auxQueryTask = null;
let auxQuery = null;
let auxGraphic = null;
let auxPoint = null;
let auxPolyline = null;
let auxPolygon = null;
let auxColor = null;
let auxFeatureLayer = null;
let auxSimpleMarker = null;
let auxSimpleLine = null;
let auxSimpleFill = null;
// let auxGraphicsLayer = null;

export default {
  name: "MapView",
  mixins: [mixinLayers],
  props: {
    geocatminLayer: {
      type: Object,
      default: () => {},
    },
    toggleDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    CATASTRO_LAYER,
    AREA_RESERVADA_LAYER,
    CARTOGRAFIA_DEMARCACION_LAYER,
    map: null,
    marker: null,
    view: null,
    esriColor: null,
    dialogEmpty: false,
    graphicsResultadoZoom: [],
    // graphicsResultado: null
    symbolDpto: null,
    loNumNivel: 0,
    colorFilterDrawer: null,
    colorFilterDrawerOpacity: null,
    indexLayer: 0,
    activeLayer: null,
  }),
  mounted() {
    loadModules(
      [
        "esri/map",
        // "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/tasks/QueryTask",
        "esri/tasks/query", // support/Query
        "esri/geometry/Extent",
        "esri/graphic",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Point",
        "esri/geometry/Polygon",
        "esri/geometry/Polyline",
        // "esri/graphicsUtils",
        "esri/symbols/SimpleFillSymbol",
        "esri/symbols/SimpleLineSymbol",
        "esri/symbols/SimpleMarkerSymbol",
        "esri/Color",
        // dojo
        "dojo/dom",
        "dojo/on",
      ],
      { css: true },
    ).then(
      ([
        ArcGISMap,
        // MapView,
        FeatureLayer,
        QueryTask,
        Query,
        Extent,
        Graphic,
        GraphicsLayer,
        Point,
        Polyline,
        Polygon,
        SimpleFillSymbol,
        SimpleLineSymbol,
        SimpleMarkerSymbol,
        Color,
        dojoDom,
        dojoOn,
      ]) => {
        auxGraphic = Graphic;
        auxPoint = Point;
        auxPolyline = Polyline;
        auxPolygon = Polygon;
        auxColor = Color;
        auxQueryTask = QueryTask;
        auxQuery = Query;
        auxFeatureLayer = FeatureLayer;
        auxSimpleMarker = SimpleMarkerSymbol;
        auxSimpleLine = SimpleLineSymbol;
        auxSimpleFill = SimpleFillSymbol;
        // auxGraphicsLayer = GraphicsLayer;
        this.graphicsResultadoZoom = [];
        this.setOptionsGraphic(GraphicsLayer);
        this.map = new ArcGISMap("arcgisMap", {
          basemap: "satellite", // "topo-vector",
          center: [-75, -10],
          slider: false,
          zoom: 5,
        });
        dojoOn(dojoDom.byId("zoomInBtn"), "click", () => {
          this.map.setZoom(this.map.getZoom() + 1);
        });
        dojoOn(dojoDom.byId("zoomOutBtn"), "click", () => {
          this.map.setZoom(this.map.getZoom() - 1);
        });
        this.map.on("click", this.onClickLayerMap);
        this.$eventBus.$on("create-graphic", (locationByEntity) => {
          this.createGraphic(locationByEntity);
        });
        this.$eventBus.$on("search-query", (payload) => {
          if (this.map) {
            this.searchQueryLayers(
              payload,
              QueryTask,
              Query,
              Extent,
              FeatureLayer,
            );
          }
        });
        this.$eventBus.$on("search-query-clear", () => {
          if (this.graphicsNivel0) {
            this.graphicsNivel0.clear();
            // this.graphicsResultado.clear();
            // this.graphicsResultadoZoom.clear();
          }
        });
      },
    );
  },
  beforeDestroy() {
    if (this.map) {
      // destroy the map view
      this.map.container = null;
    }
  },
  methods: {
    displayLayerCatastro(checkLayers) {
      if (this.map) {
        this.activateLayersMap(checkLayers, auxFeatureLayer);
      }
    },
    onClickGeocatminLayer(evt, inx) {
      this.indexLayer = inx;
      this.onClickLayerMap(evt, true);
    },
    async onClickLayerMap(evt, flag = false) {
      if (evt.graphic) {
        this.map.infoWindow.hide();
        const { searchFilter = false } = evt.graphic.attributes;
        if (searchFilter) {
          const { detailGraph } = evt.graphic.attributes;
          const { data } = await this.axios.get(
            `/api/locations/${detailGraph.paths[0].id}`,
          );
          const detailEntity = data.data.entity;
          let textType = this.isEmpty(detailEntity.type)
            ? "-"
            : detailEntity.type.label;
          const { primary_responsible } = detailEntity;
          let responsibleType = this.isEmpty(primary_responsible)
            ? "-"
            : `${primary_responsible.first_name} ${primary_responsible.last_name}`;
          let content = `
            Código: ${detailGraph.entity_id} <br />
            Módulo: ${detailGraph.module} <br />
            Submódulo: ${detailGraph.submodule} <br />
            Tipo: ${textType} <br />
            Responsable: ${responsibleType} <br />`;
          this.openInfoWindow(
            evt,
            evt.graphic,
            detailGraph.entity_id,
            content,
            "jaru",
          );
        } else if (!searchFilter && flag) {
          const { attributes } = evt.graphic;
          const LAYER_URL = this.geocatminLayer.catastro
            ? CATASTRO_LAYER
            : this.geocatminLayer.area
            ? AREA_RESERVADA_LAYER
            : CARTOGRAFIA_DEMARCACION_LAYER;
          const queryTask = new auxQueryTask(`${LAYER_URL}/${this.indexLayer}`);
          let query = new auxQuery();
          query.returnGeometry = true;
          query.outFields = ["*"];
          query.spatialRel = "esriSpatialRelIntersects";
          query.outSpatialReference = { wkid: 102100 };
          query.where = `OBJECTID=${attributes.OBJECTID}`;
          queryTask.execute(query, ({ features }) => {
            if (features.length) {
              let content = `
              Código: ${features[0].attributes.CODIGOU} <br />
              Titular: ${features[0].attributes.TIT_CONCES} <br />
              Nombre: ${features[0].attributes.CONCESION} <br />
              Hectáreas: ${features[0].attributes.HECTAGIS} <br />
              Estado: ${features[0].attributes.LEYENDA} <br />
              <a class="d-inline-block mt-2" href="https://www.ingemmet.gob.pe/sidemcat?CodDM=${features[0].attributes.CODIGOU}" target="_blank">Ver expediente</a>`;
              this.openInfoWindow(
                evt,
                features[0],
                features[0].attributes.CODIGOU,
                content,
                "geocatmin",
              );
            }
          });
        }
      }
    },
    searchQueryLayers(payload, QueryTask, Query, Extent, FeatureLayer) {
      const layerQuery = new FeatureLayer(CATASTRO_QUERY, {
        outFields: ["*"],
        mode: FeatureLayer.MODE_SELECTION,
      });
      const symbolFill = this.getSimpleFill(COLOR_LAYER_FOCUS);
      let sqlExpression = this.formatSqlExpression(payload);
      const queryTask = new QueryTask(CATASTRO_QUERY);
      const query = new Query();
      query.where = sqlExpression;
      query.spatialRel = "esriSpatialRelIntersects";
      query.outSpatialReference = { wkid: 102100 };
      query.returnGeometry = true;
      query.outFields = ["*"];
      this.loNumNivel = 0;
      queryTask.execute(query, (e) => {
        this.showResults(e, Extent);
      });
      this.clearGraphicZoom();
      layerQuery.queryFeatures(query, (featureSet) => {
        const listFeature = featureSet.features;
        if (listFeature.length > 0) {
          listFeature.forEach((e) => {
            this.graphicsResultadoZoom.push(e.setSymbol(symbolFill));
            this.map.graphics.add(e.setSymbol(symbolFill));
          });
          this.$emit("features", listFeature);
        } else {
          this.dialogEmpty = true;
        }
      });
      layerQuery.setSelectionSymbol(this.symbolDpto);
    },
    showResults(featureSet, Extent) {
      let extent = 0;
      this.graphicsNivel0.clear();
      const il = featureSet.features.length;
      for (var i = 0; i < il; i++) {
        var graphic = featureSet.features[i];
        const geometry = graphic.geometry;
        const ext = geometry.getExtent();
        if (extent) {
          extent = extent.union(ext);
        } else {
          extent = new Extent(ext);
        }
        this.graphicsNivel0.add(
          featureSet.features[i].setSymbol(this.getSimpleFill(COLOR_LAYER)),
        );
      }
      if (!this.isEmpty(this.graphicsNivel0)) {
        this.map.addLayer(this.graphicsNivel0);
        this.map.setExtent(extent.expand(2));
      }
    },
    onChangeLocationsByFilter(locationByEntity) {
      // this.$emit("filter-drawer", locationByEntity);
      this.$eventBus.$emit("create-graphic", locationByEntity);
    },
    createGraphic(locationByEntity) {
      if (this.map) {
        this.map.graphics.graphics.forEach((g) => {
          if (g.attributes && g.attributes.searchFilter) {
            this.map.graphics.remove(g);
          }
        });
      }
      // Draw graphic in map
      if (locationByEntity.length === 1) {
        const mapGraphic = this.getTypeGraph(locationByEntity[0], COLOR_LAYER);
        // let _geometry = mapGraphic.geometry;
        // let extGraphics = _geometry.getExtent();
        this.map.centerAndZoom(mapGraphic.geometry, 7);
        this.map.graphics.add(mapGraphic);
      } else {
        locationByEntity.forEach((e) => {
          const mapGraphic = this.getTypeGraph(e, COLOR_LAYER);
          this.map.graphics.add(mapGraphic);
        });
      }
    },
    onGoToLayer(feature, index, typeFilter) {
      this.clearGraphicZoom();
      feature = cloneDeep(feature);
      if (typeFilter === "geocatmin") {
        const temp = feature.setSymbol(this.getSimpleFill(COLOR_LAYER_FOCUS));
        this.graphicsResultadoZoom.push(temp);
        let _geometry = feature.geometry;
        let extGraphics = _geometry.getExtent();
        this.map.setExtent(extGraphics.expand(5));
        this.map.graphics.add(temp);
      } else {
        const auxFeature = feature.attributes
          ? feature.attributes.detailGraph
          : feature;
        const mapGraphic = this.getTypeGraph(auxFeature, COLOR_LAYER_FOCUS);
        this.graphicsResultadoZoom.push(mapGraphic);
        let _geometry = mapGraphic.geometry;
        let extGraphics = _geometry.getExtent();
        if (extGraphics) {
          this.map.setExtent(extGraphics.expand(5));
        } else {
          this.map.centerAndZoom(mapGraphic.geometry, 7);
        }
        this.map.graphics.add(mapGraphic);
      }
    },
    getTypeGraph(e, color) {
      // Line
      const symbolLine = this.getSimpleLine(color);
      const locationItem = e.paths;
      const paths = [];
      locationItem.forEach((item) => {
        paths.push([item.long, item.lat]);
      });
      let mapGraphic = null;
      let myPoint = null;
      let firstPath = null;
      const optionsGraph = {
        searchFilter: true,
        detailGraph: e,
      };
      // let maxZoom = null;
      switch (paths.length) {
        case 1:
          myPoint = {
            x: paths[0][0],
            y: paths[0][1],
          };
          mapGraphic = new auxGraphic(
            new auxPoint(myPoint),
            this.getSimpleMarker(color),
            optionsGraph,
          );
          break;
        case 2:
          mapGraphic = new auxGraphic(
            new auxPolyline(paths),
            symbolLine,
            optionsGraph,
          );
          break;
        default:
          firstPath = paths[0];
          paths.push(firstPath);
          mapGraphic = new auxGraphic(
            new auxPolygon(paths),
            this.getSimpleFill(color),
            optionsGraph,
          );
          break;
      }
      return mapGraphic;
    },
    getSimpleLine(color) {
      const symbolLine = new auxSimpleLine();
      symbolLine.setColor(new auxColor([...color, 1]));
      return symbolLine;
    },
    getSimpleFill(color) {
      const symbolFill = new auxSimpleFill();
      symbolFill.setColor(new auxColor([...color, 0.5]));
      symbolFill.setOutline(this.getSimpleLine([...color, 1]));
      return symbolFill;
    },
    getSimpleMarker(color) {
      const symbolMarker = new auxSimpleMarker();
      symbolMarker.setOutline(this.getSimpleLine([...color, 1]));
      symbolMarker.setColor(new auxColor([...color, 0.5]));
      return symbolMarker;
    },
    openInfoWindow(evt, graphic, id, content, type) {
      this.map.infoWindow.setTitle(id);
      this.map.infoWindow.setContent(content);
      this.onGoToLayer(graphic, 0, type);
      evt
        ? this.map.infoWindow.show(
            evt.screenPoint,
            this.map.getInfoWindowAnchor(evt.screenPoint),
          )
        : null;
    },
    clearGraphicZoom() {
      if (this.graphicsResultadoZoom) {
        this.graphicsResultadoZoom.forEach((e) => {
          this.map.graphics.remove(e);
          this.map.removeLayer(e);
        });
        this.graphicsResultadoZoom = [];
      }
    },
    clearFilterMap() {
      if (this.map) {
        this.map.graphics.graphics.forEach((g) => {
          if (g.attributes && g.attributes.searchFilter) {
            this.map.graphics.remove(g);
          }
        });
      }
    },
  },
};
</script>
