<template>
  <v-navigation-drawer
    class="sectionDrawer"
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: 100% !important"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll block-filter"
      :settings="scrollModuleSettings"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="searchText"
            placeholder="Buscar por palabra"
            class="input-custom-56"
            height="56"
            append-icon="search"
            outlined
            hide-details
            @input="debounceChange"
            v-on="on"
          ></v-text-field>
        </template>
        <span v-html="generateTooltipSearch(typeModule, typeSubmodule)"></span>
      </v-tooltip>
      <v-row>
        <v-col cols="12" class="mb-2 py-0">
          <span class="font-weight-bold">Filtro</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <!--  <v-col cols="12" class="py-0">
          <v-text-field
            v-model="inputCode"
            class="mt-0 pt-0 input-custom-56"
            label="Código"
            placeholder="Ingrese código"
            height="56"
            outlined
            hide-details
            @input="debounceCode"
          >
          </v-text-field>
        </v-col> !-->
        <v-col cols="12" class="py-0">
          <v-select
            v-model="selectedState"
            class="input-custom-56 mt-0 pt-0"
            item-text="label"
            item-value="id"
            label="Situación"
            :items="listSituations.options"
            height="56"
            clearable
            outlined
            hide-details
            return-object
            @input="debounceChange"
            placeholder="Seleccione una opción"
          ></v-select>
        </v-col>
        <template v-if="typeModule !== 'compromisos'">
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-model="selectedType"
              class="mt-0 pt-0 input-custom-56"
              autocomplete="no"
              label="Tipo"
              placeholder="Seleccione opción"
              item-text="label"
              item-value="id"
              height="56"
              clearable
              outlined
              hide-details
              return-object
              :items="listType.options"
              @input="debounceChange"
            ></v-autocomplete>
          </v-col>
        </template>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            :items="listProjects.options"
            class="mt-0 pt-0 input-custom-56"
            autocomplete="no"
            label="Proyecto"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            height="56"
            clearable
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <selector-user
            ref="selectorUser"
            v-model="selectedResponsible"
            :filter-ejecutor="true"
            @update="onSearch"
          ></selector-user>
        </v-col>
        <v-col cols="12" class="py-0" v-if="typeModule === 'compromisos'">
          <v-text-field
            v-model="inputEntityEmisor"
            class="mt-0 pt-0 input-custom-56"
            label="Entidad Emisora"
            placeholder="Escribe una entidad emisora"
            height="56"
            outlined
            hide-details
            @input="debounceCode"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-0" v-if="typeModule === 'compromisos'">
          <v-text-field
            v-model="inputInternArea"
            class="mt-0 pt-0 input-custom-56"
            label="Área Interna"
            placeholder="Escribe una área interna "
            height="56"
            outlined
            hide-details
            @input="debounceCode"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-0" v-if="typeModule === 'compromisos'">
          <v-autocomplete
            v-model="inputNumberRule"
            class="mt-0 pt-0 input-custom-56"
            label="N° de Norma"
            :items="listItem"
            placeholder="Escribe un número de norma"
            item-text="number_rule"
            item-value="number_rule"
            height="56"
            outlined
            hide-details
            @input="debounceCode"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-btn
                class="ma-0"
                color="action"
                dark
                depressed
                small
                block
                @click="clearFilters()"
                >Limpiar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </vue-perfect-scrollbar>
    <v-dialog
      v-model="dialogAddType"
      max-width="450"
      @keydown.esc="dialogAddType = false"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-2">Agregar Tipo</h3>
          </div>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <h4 class="mb-1">Nombre:</h4>
            <v-text-field
              v-model="formType.name"
              hide-details
              name="formType_name"
              class="pt-0 mb-3"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="btn-transform-none"
            @click="dialogAddType = false"
            >Cerrar
          </v-btn>
          <v-btn
            color="primary darken-1"
            class="btn-transform-none"
            @click="onAddType()"
            >Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { isEmpty, debounce } from "lodash";
import SelectorUser from "@/components/commons/SelectorUser";
import RangeDateFilter from "./RangeDateFilter";
import mixinApiModule from "./mixins/mixinApiModule";

export default {
  name: "FilterDrawer",
  components: {
    VuePerfectScrollbar,
    RangeDateFilter,
    SelectorUser,
  },
  mixins: [mixinApiModule],
  props: {
    sortModule: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    scrollModuleSettings: { maxScrollbarLength: 160, suppressScrollX: true },
    searchText: null,
    inputCode: null,
    situation: null,
    inputEntityEmisor: null,
    inputInternArea: null,
    inputNumberRule: null,
    dateStart: null,
    dateEnd: null,
    selectedResponsible: null,
    selectedState: null,
    selectedType: null,
    selectedProject: null,
    formType: {
      name: "",
    },
    loadSearch: false,
    dialogAddType: false,
    debounceChange: null,
    debounceCode: null,
    listItem: [],
  }),
  computed: {
    ...mapState({
      listAllUsers: (state) => state.users.listAllUsers,
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
      filterData: (state) => state.permits.filterData,
      sortPermit: (state) => state.permits.sort,
    }),
    listType() {
      const types = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-tipo`;
      });
      return types.length ? types[0] : { options: [] };
    },
    typeModule() {
      const { params } = this.$route;
      return params.typeModule || "";
    },
    typeSubmodule() {
      const { params } = this.$route;
      return params.typeSubmodule || "";
    },
  },
  watch: {
    selectedType(val) {
      if (val === "Otros") {
        this.dialogAddType = true;
      }
    },
    typeModule(val) {
      if (!this.isEmpty(val)) {
        this.clearForm(false);
      }
    },
    typeSubmodule(val) {
      if (!this.isEmpty(val)) {
        this.clearForm(false);
      }
    },
  },
  mounted() {
    this.debounceCode = debounce(this.onSearch, 700);
    this.debounceChange = debounce(this.onSearch, 900);
    this.getData();
  },
  methods: {
    getData() {
      this.axios
        .get(`/api/${this.apiByModule.module}/${this.apiByModule.submodule}`)
        .then(({ data }) => {
          this.listItem = data.data;
        });
    },
    onSearch() {
      let payload = "?response=lite&page=1";
      let filterData = "";
      if (!isEmpty(this.inputCode)) {
        filterData += `&id=${this.inputCode}`;
      }
      if (!isEmpty(this.selectedType)) {
        filterData += `&type_id=${this.selectedType.id}`;
      }
      if (!isEmpty(this.dateStart)) {
        filterData += `&expiration_start=${this.dateStart}`;
      }
      if (!isEmpty(this.dateEnd)) {
        filterData += `&expiration_end=${this.dateEnd}`;
      }
      if (!isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!isEmpty(this.selectedResponsible)) {
        filterData += `&primary_responsible_id=${this.selectedResponsible.id}`;
      }
      if (!isEmpty(this.selectedState)) {
        filterData += `&situation_id=${this.selectedState.id}`;
      }
      if (!isEmpty(this.inputEntityEmisor)) {
        filterData += `&entity_emisor=${this.inputEntityEmisor}`;
      }
      if (!isEmpty(this.inputInternArea)) {
        filterData += `&intern_area=${this.inputInternArea}`;
      }
      if (!isEmpty(this.inputNumberRule)) {
        filterData += `&number_rule=${this.inputNumberRule}`;
      }
      if (!isEmpty(this.searchText)) {
        filterData += `&search=${this.searchText}`;
      }
      if (this.sortModule) {
        filterData += `&sort=${this.sortModule}`;
      }
      this.loadSearch = true;
      payload += filterData;
      this.$emit("filter", payload, filterData);
    },
    onChangeRangeDate(dateStart, dateEnd) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
      this.onSearch();
    },
    clearForm(flag = true) {
      this.inputCode = null;
      this.selectedType = null;
      this.selectedResponsible = null;
      this.selectedState = null;
      this.selectedProject = null;
      this.inputNumberRule = null;
      this.dateStart = null;
      this.dateEnd = null;
      this.searchText = null;
      this.$refs.selectorUser.clearSelector();
    },
    clearFilters() {
      this.clearForm();
      this.onSearch();
    },
    setLoadSearch(flag) {
      this.loadSearch = flag;
    },
    onAddType() {
      if (!isEmpty(this.formType.name)) {
        this.types.pop();
        this.types.push(this.formType.name);
        this.types.push("Otros");
        this.selectedType = this.formType.name;
        this.dialogAddType = false;
      }
    },
  },
};
</script>
