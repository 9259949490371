<template>
  <v-dialog
    v-model="showDialog"
    content-class="dialog-custom"
    persistent
    scrollable
    width="450"
    @keydown.esc="closeDialogUser()"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="subheading">Agregar Usuario</span>
      </v-card-title>
      <vue-perfect-scrollbar
        class="drawer-menu--scroll"
        :settings="scrollSettings"
      >
        <v-card-text style="height: 400px;">
          <v-container fluid class="py-2 px-0">
            <v-form
              ref="formUpdateUser"
              v-model="validUpdateUser"
              :lazy-validation="lazyUpdateUser"
            >
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="formUser.first_name"
                    label="Nombres:"
                    class="pt-0 input-custom-56"
                    height="56"
                    outlined
                    :hide-details="isEmpty(listError.first_name)"
                    :disabled="!isEditable"
                    :rules="rulesFirstName"
                    @input="onChangeFirstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="formUser.last_name"
                    label="Apellidos:"
                    class="pt-0 input-custom-56"
                    height="56"
                    outlined
                    :hide-details="isEmpty(listError.last_name)"
                    :disabled="!isEditable"
                    :rules="rulesLastName"
                    @input="onChangeLastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="formUser.email"
                    label="Email:"
                    type="email"
                    class="pt-0 input-custom-56"
                    height="56"
                    outlined
                    :hide-details="isEmpty(listError.email)"
                    :disabled="!isEditable"
                    :rules="rulesEmail"
                    @input="onChangeEmail"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    v-model="formUser.role"
                    class="pt-0 input-custom-56"
                    label="Rol:"
                    item-text="label"
                    item-value="id"
                    height="56"
                    clearable
                    outlined
                    return-object
                    :items="listRoles"
                    :hide-details="isEmpty(listError.role_id)"
                    :disabled="!isEditable"
                    :rules="rulesRole"
                    @input="onChangeRole"
                  >
                    <template v-slot:item="data">
                      <v-tooltip left max-width="280">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">{{ data.item.label }}</div>
                        </template>
                        <span>{{ data.item.description }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="formUser.phone_number"
                    height="56"
                    label="Teléfono:"
                    class="pt-0 input-custom-56"
                    hint="El formato del teléfono que debe incluir +51"
                    persistent-hint
                    outlined
                    :disabled="!isEditable"
                    :rules="rulesPhone"
                    @input="onChangePhone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="formUser.notify_by_email"
                    hide-details
                    :disabled="!isEditable"
                    class="mt-0"
                    label="Notificación via Email"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="formUser.notify_by_sms"
                    hide-details
                    :disabled="!isEditable"
                    class=""
                    label="Notificación via SMS"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="formUser.notify_by_push"
                    hide-details
                    :disabled="!isEditable"
                    class=""
                    label="Notificación via push"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </vue-perfect-scrollbar>
      <v-card-actions>
        <v-btn
          v-if="
            typeDialog === 'edit' &&
              (isAdmin || formUser.id === currentUser.id) &&
              !isEditable
          "
          color="primary"
          depressed
          small
          class="btn-transform-none"
          @click="isEditable = true"
          >Habilitar edición</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="action"
          class="btn-transform-none"
          text
          small
          @click="closeDialogUser()"
          >Cancelar</v-btn
        >
        <v-btn
          color="action"
          class="btn-transform-none"
          :dark="isEditable"
          :disabled="!isEditable"
          small
          depressed
          @click="onSubmitUser()"
        >
          <v-progress-circular
            v-if="loadSubmit"
            :size="20"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          <template v-if="typeDialog === 'add'">
            {{ loadSubmit ? "Creando" : "Crear" }}
          </template>
          <template v-else>
            {{ loadSubmit ? "Guardando" : "Guardar" }}
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { cloneDeep, findIndex } from "lodash";
import { mapActions, mapState } from "vuex";
import { IUsers } from "@/models";

export default {
  name: "DialogUpdateUser",
  components: {
    VuePerfectScrollbar,
  },
  model: { prop: "showDialog", event: "change" },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showError: false,
    validUpdateUser: true,
    lazyUpdateUser: false,
    isEditable: false,
    typeDialog: "add",
    loadSubmit: false,
    formUser: new IUsers(),
    listError: {
      role_id: null,
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
    },
    rulesRole: [],
    rulesFirstName: [],
    rulesLastName: [],
    rulesEmail: [],
    rulesPhone: [],
  }),
  computed: {
    ...mapState({
      listRoles: (state) => state.users.roles,
    }),
    isAdmin() {
      const { role } = this.currentUser;
      return role.slug === "super-admin" || role.slug === "admin";
    },
  },
  methods: {
    ...mapActions(["createUser", "editUser", "editUserSettings"]),
    listErrorUser(err) {
      const {
        role_id = null,
        first_name = null,
        last_name = null,
        email = null,
        phone_number = null,
      } = err;
      this.showError = true;
      this.listError.role_id = role_id ? role_id[0] : null;
      this.listError.first_name = first_name ? first_name[0] : null;
      this.listError.last_name = last_name ? last_name[0] : null;
      this.listError.email = email ? email[0] : null;
      this.listError.phone_number = phone_number ? phone_number[0] : null;
      this.$nextTick(() => {
        this.rulesRole = this.listError.role_id
          ? [() => this.listError.role_id]
          : [];
        this.rulesFirstName = this.listError.first_name
          ? [() => this.listError.first_name]
          : [];
        this.rulesLastName = this.listError.last_name
          ? [() => this.listError.last_name]
          : [];
        this.rulesEmail = this.listError.email
          ? [() => this.listError.email]
          : [];
        this.rulesPhone = this.listError.phone_number
          ? [() => this.listError.phone_number]
          : [];
        this.$refs.formUpdateUser.validate();
      });
    },
    onSubmitUser() {
      if (this.loadSubmit) return false;
      this.loadSubmit = true;
      this.$refs.formUpdateUser.resetValidation();
      if (this.typeDialog === "add") {
        const payload = cloneDeep(this.formUser);
        payload.role_id = !this.isEmpty(this.formUser.role)
          ? this.formUser.role.id
          : null;
        delete payload.role;
        delete payload.settings;
        this.createUser(payload)
          .then((data) => {
            this.loadSubmit = false;
            this.closeDialogUser();
            this.$emit("create", data);
          })
          .catch((err) => {
            this.loadSubmit = false;
            this.listErrorUser(err);
          });
      } else {
        const payload = {
          user_id: this.formUser.id,
          first_name: this.formUser.first_name,
          last_name: this.formUser.last_name,
          email: this.formUser.email,
          phone_number: this.formUser.phone_number,
          role_id: !this.isEmpty(this.formUser.role)
            ? this.formUser.role.id
            : null,
        };
        const settings = {
          user_id: this.formUser.id,
          notify_by_email: this.formUser.notify_by_email,
          notify_by_sms: this.formUser.notify_by_sms,
          notify_by_push: this.formUser.notify_by_push,
        };
        this.editUser(payload)
          .then(() => {
            this.editUserSettings(settings).then(() => {
              this.getUserById();
            });
          })
          .catch((err) => {
            this.loadSubmit = false;
            this.listErrorUser(err);
          });
      }
    },
    async getUserById() {
      const { data } = await this.axios.get(`/api/users/${this.formUser.id}`);
      this.loadSubmit = false;
      this.dialogNew = false;
      if (data.success) {
        const userInfo = data.data;
        const inx = findIndex(this.listItem, { id: userInfo.id });
        this.$set(this.listItem, inx, userInfo);
      }
    },
    openDialogUpdate(typeDialog = "add", item) {
      this.clearRules();
      this.typeDialog = typeDialog;
      if (typeDialog === "add") {
        this.isEditable = true;
        this.formUser = new IUsers();
        delete this.formUser.settings;
      } else {
        this.isEditable = false;
        this.formUser = cloneDeep(item);
        const { settings = null } = item;
        this.formUser.notify_by_email = settings
          ? settings.notify_by_email
          : false;
        this.formUser.notify_by_sms = settings ? settings.notify_by_sms : false;
        this.formUser.notify_by_push = settings
          ? settings.notify_by_push
          : false;
        this.formUser.role_id = item.role.id;
        delete this.formUser.settings;
      }
      this.$emit("change", true);
    },
    closeDialogUser() {
      this.$emit("change", !this.showDialog);
    },
    onChangeFirstName() {
      this.rulesFirstName = [];
      this.listError.first_name = null;
    },
    onChangeLastName() {
      this.rulesLastName = [];
      this.listError.last_name = null;
    },
    onChangeEmail() {
      this.rulesEmail = [];
      this.listError.email = null;
    },
    onChangePhone() {
      this.rulesPhone = [];
      this.listError.phone_number = null;
    },
    onChangeRole() {
      this.rulesRole = [];
      this.listError.role_id = null;
    },
    clearRules() {
      this.rulesRole = [];
      this.rulesFirstName = [];
      this.rulesLastName = [];
      this.rulesEmail = [];
      this.rulesPhone = [];
      this.listError.role_id = null;
      this.listError.first_name = null;
      this.listError.last_name = null;
      this.listError.email = null;
      this.listError.phone_number = null;
    },
  },
};
</script>
