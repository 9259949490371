<template>
  <v-container fluid class="pa-0 fill-height">
    <judicial-processes-page
      v-if="isJudicialProcesses"
      :ficha-id="fichaId"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
    ></judicial-processes-page>
    <supervision-page
      v-else-if="isSupervision"
      :ficha-id="fichaId"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
    ></supervision-page>
    <mining-properties-page
      v-else-if="isMining"
      :ficha-id="fichaId"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
    ></mining-properties-page>
    <common-module-page
      v-else
      :ficha-id="fichaId"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
    ></common-module-page>
  </v-container>
</template>
<script>
import SupervisionPage from "./SupervisionPage";
import JudicialProcessesPage from "./JudicialProcessesPage";
import MiningPropertiesPage from "./MiningPropertiesPage";
import CommonModulePage from "./CommonModulePage";

export default {
  name: "SwitchModulePage",
  components: {
    SupervisionPage,
    JudicialProcessesPage,
    MiningPropertiesPage,
    CommonModulePage,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
  },
  computed: {
    fichaId() {
      const { ficha = null } = this.$route.query;
      return ficha;
    },
    isMining() {
      const { typeModule = null } = this.$route.params;
      if (typeModule) {
        return typeModule === "propiedades-mineras";
      }
      return false;
    },
    isSupervision() {
      const { typeModule = null } = this.$route.params;
      if (typeModule) {
        return typeModule === "supervisiones";
      }
      return false;
    },
    isJudicialProcesses() {
      const { typeModule = null } = this.$route.params;
      if (typeModule) {
        return typeModule === "procesos-judiciales";
      }
      return false;
    },
  },
};
</script>
