<template>
  <v-card class="block-report" flat tile>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 1
            </h4>
            <horizontal-bar-chart
              v-if="loading"
              ref="reportFive1"
              chart-id="reportFive1"
              :chart-data="optionChartBar"
              :show-legend="true"
              title="Fichas por vencer al día de hoy"
            ></horizontal-bar-chart>
            <v-row v-if="loading">
              <!--<v-col cols="12" class="text-right">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadFive1"
                  @click="onDownloadChart('reportFive1')"
                  >Descargar Reporte
                </v-btn>
              </v-col>!-->
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Semánforo de to-dos</th>
                        <th class="text-left">Con alerta</th>
                        <th class="text-left">Sin alerta</th>
                        <th class="text-left">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.withAlert }}</td>
                        <td>{{ item.withoutAlert }}</td>
                        <td>{{ item.withAlert + item.withoutAlert }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal.withAlert }}</td>
                        <td>{{ dataTotal.withoutAlert }}</td>
                        <td>
                          {{ dataTotal.withAlert + dataTotal.withoutAlert }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { setColors } from "./colors";
import HorizontalBarChart from "./charts/HorizontalBarChart";

export default {
  name: "ReportFive",
  components: {
    HorizontalBarChart,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loadFive1: false,
    loading: false,
    optionChartBar: {},
    dataTable: [],
    dataTotal: 0,
  }),
  computed: {
    titleChart() {
      let label = "Estado de ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "Estado General";
    },
  },
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/six${this.filterData}`
      );
      if (data.success) {
        let dataChart = data.data;
        this.dataTable = [
          {
            label: dataChart.green_label,
            withAlert: dataChart.green_alert_true,
            withoutAlert: dataChart.green_alert_false,
          },
          {
            label: dataChart.yellow_label,
            withAlert: dataChart.yellow_alert_true,
            withoutAlert: dataChart.yellow_alert_false,
          },
          {
            label: dataChart.red_label,
            withAlert: dataChart.red_alert_true,
            withoutAlert: dataChart.red_alert_false,
          },
        ];

        this.dataTotal = {
          withAlert:
            dataChart.green_alert_true +
            dataChart.yellow_alert_true +
            dataChart.red_alert_true,
          withoutAlert:
            dataChart.green_alert_false +
            dataChart.yellow_alert_false +
            dataChart.red_alert_false,
        };
        let optionChartPie = {
          labels: [
            dataChart.green_label,
            dataChart.yellow_label,
            dataChart.red_label,
          ],
          datasets: [
            {
              label: dataChart.alert_true_label,
              backgroundColor: [setColors[0], setColors[0], setColors[0]],
              data: [
                dataChart.green_alert_true,
                dataChart.yellow_alert_true,
                dataChart.red_alert_true,
              ],
            },
            {
              label: dataChart.alert_false_label,
              backgroundColor: [setColors[1], setColors[1], setColors[1]],
              data: [
                dataChart.green_alert_false,
                dataChart.yellow_alert_false,
                dataChart.red_alert_false,
              ],
            },
          ],
        };
        this.optionChartBar = optionChartPie;
        this.loading = true;
      }
    },
    async onDownloadChart(nameChart) {
      const element = document.getElementById(nameChart);
      this.loadFive1 = true;
      var dataURL = element.toDataURL("image/png");
      let file = null;
      await fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "File name", { type: "image/png" });
        });
      const formData = new FormData();
      formData.append("file", file);
      const listFilter = this.parseFilterReport(this.filterData);
      for (let key in listFilter) {
        if (listFilter[key]) {
          formData.append(key, listFilter[key]);
        }
      }
      const { data } = await this.axios({
        method: "post",
        url: "/api/reports/six/export",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.loadFive1 = false;
      this.$nextTick(() => {
        document.getElementById(
          "urlDownload"
        ).href = `${this.baseURL}/${data.data.url}`;
        document.getElementById("urlDownload").click();
      });
    },
    formatDecimal(val) {
      return parseFloat((val * 100).toFixed(2));
    },
  },
};
</script>
