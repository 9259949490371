import axios from "../../http";
import { isEmpty, uniqBy } from "lodash";

export default {
  state: {
    activeSearch: false,
    selectSituation: "",
    metadata: {},
    listUser: [],
    listAllUsers: [],
    filterData: "",
    checkAlert: false,
    noResult: false,
    loadData: false,
    pages: 1,
    sort: null,
    // filter
    roles: [],
    abilities: [],
  },
  getters: {
    getActive: (state) => {
      return state.activeSearch;
    },
    getSituation: (state) => {
      return state.selectSituation;
    },
    getCheckAlert: (state) => {
      return state.checkAlert;
    },
  },
  mutations: {
    setActiveSearch(state, flag) {
      state.activeSearch = flag;
    },
    setListUser(state, value) {
      let noRepeat = uniqBy([...state.listUser, ...value], "id");
      state.listUser = noRepeat;
    },
    setListAllUsers(state, value) {
      state.listAllUsers = value;
    },
    setUserMetadata(state, value) {
      state.metadata = value;
    },
    setFilterSituation(state, payload) {
      state.selectSituation = payload;
    },
    setCheckAlert(state, payload) {
      state.checkAlert = payload;
    },
    setLoadUsers(state, payload) {
      state.loadData = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setAbilities(state, payload) {
      state.abilities = payload;
    },
    setUserNoResult(state, payload) {
      state.noResult = payload;
    },
    updatePageUsers(state, value) {
      state.pages = value;
    },
    updateFilterUsers(state, value) {
      state.filterData = value;
    },
    clearUsers(state) {
      state.loadData = false;
      state.noResult = false;
      state.pages = 1;
      state.listUser = [];
    },
    setSortUser(state, value) {
      state.sort = value;
    },
  },
  actions: {
    fetchUsers({ commit, dispatch }, { payload = "", step }) {
      commit("setUserNoResult", false);
      return new Promise(async (resolve, reject) => {
        if (step) {
          commit("setLoadUsers", false);
        }
        const { data } = await axios.get(`/api/users${payload}`);
        if (data.success) {
          commit("setUserMetadata", data.meta);
          if (!isEmpty(data.data)) {
            commit("setUserNoResult", false);
            commit("setListUser", data.data);
          } else {
            dispatch("setToDoNoResult");
          }
          if (step) {
            setTimeout(() => {
              commit("setLoadUsers", true);
            }, 500);
          }
          resolve(data.data);
        } else {
          dispatch("setToDoNoResult");
          if (step) {
            setTimeout(() => {
              commit("setLoadUsers", true);
            }, 500);
          }
          reject();
        }
      });
    },
    fetchAllUsers({ commit }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/users/all`);
        if (data.success) {
          commit("setListAllUsers", data.data);
          resolve(data.data);
        } else {
          commit("setListAllUsers", []);
          reject();
        }
      });
    },
    setUserNoResult({ commit }) {
      commit("setUserMetadata", {
        current_page: 0,
        from: 0,
        last_page: 0,
        path: "",
        per_page: 0,
        to: 0,
        total: 0,
      });
      commit("setUserNoResult", true);
      commit("setListUser", []);
    },
    createUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/auth/create", payload).then(({ data }) => {
          if (data.success) {
            commit("setListUser", [data.data]);
            resolve(data.data);
          } else {
            reject(data.error.data);
          }
        });
      });
    },
    editUser({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(
          `/api/users/${payload.user_id}/update`,
          payload,
        );
        if (data.success) {
          resolve(data.data);
        } else {
          reject(data.error.data);
        }
      });
    },
    editUserSettings({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(
          `/api/users/${payload.user_id}/update-settings`,
          payload,
        );
        if (data.success) {
          // commit("setListUser", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    deleteUser({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.delete(`/api/users/${payload.id}/delete`);
        if (data.success) {
          // commit("setListUser", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    fetchRoles({ commit }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/users/roles`);
        if (data.success) {
          commit("setRoles", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    setRoles({ commit }, payload) {
      commit("setRoles", payload);
    },
    setAbilities({ commit }, payload) {
      commit("setAbilities", payload);
    },
    setActiveSearch({ commit }, flag) {
      commit("setActiveSearch", flag);
    },
    setFilterSituation({ commit }, payload) {
      commit("setFilterSituation", payload);
    },
    setCheckAlert({ commit }, payload) {
      commit("setCheckAlert", payload);
    },
    updateFilterUsers({ commit }, filterData) {
      commit("updateFilterUsers", filterData);
    },
    updatePageUsers({ commit }, pages) {
      commit("updatePageUsers", pages);
    },
    clearUsers({ commit }) {
      commit("clearUsers", []);
    },
    setSortUser({ commit }, payload) {
      commit("setSortUser", payload);
    },
  },
};
