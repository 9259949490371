<template>
  <v-navigation-drawer
    class="sectionDrawer"
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: 100% !important;"
    :class="{ 'collapse-drawer': toggleDrawer, 'drawer-mobile': isMobile }"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll block-filter"
      :settings="scrollSettings"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="searchText"
            placeholder="Buscar"
            height="56"
            outlined
            class="input-custom-56"
            hide-details
            append-icon="search"
            @input="debounceChange"
            v-on="on"
          ></v-text-field>
        </template>
        <span>Búsqueda por palabra</span>
      </v-tooltip>
      <v-row>
        <v-col cols="12" class="mb-2 py-0">
          <span class="font-weight-bold">Filtrar</span>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedMenu"
            :items="listModule"
            class="mt-0 pt-0 input-custom-56"
            label="Módulo"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceModule"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedSubMenu"
            :items="optionsSelectSub"
            :disabled="disabledSubmenu"
            class="mt-0 pt-0 input-custom-56"
            label="Submódulo"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            :items="listProjects.options"
            class="mt-0 pt-0 input-custom-56"
            label="Proyecto"
            placeholder="Seleccione proyecto"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <selector-user
            v-model="selectedResponsible"
            :filter-ejecutor="true"
            @update="onSearch"
          ></selector-user>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedState"
            :items="formatListState"
            class="mt-0 pt-0 input-custom-56"
            label="Estado"
            placeholder="Seleccione estado"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedAlert"
            :items="optionsAlert"
            class="mt-0 pt-0 input-custom-56"
            label="Alerta"
            placeholder="Seleccione alerta"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedTask"
            :items="optionsTask"
            class="mt-0 pt-0 input-custom-56"
            label="Check"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            clearable
            height="56"
            outlined
            hide-details
            return-object
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-btn
                class="ma-0"
                color="action"
                dark
                depressed
                small
                block
                @click="clearFilters()"
                >Limpiar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SelectorUser from "@/components/commons/SelectorUser";
import { mapState } from "vuex";
import { isEmpty, debounce } from "lodash";
import { isMobile } from "mobile-device-detect";

export default {
  name: "TodoDrawer",
  components: {
    VuePerfectScrollbar,
    SelectorUser,
  },
  props: {
    toggleDrawer: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isMobile,
    optionsAlert: [
      { label: "Con alerta", id: "1" },
      { label: "Sin alerta", id: "0" },
    ],
    optionsTask: [
      { label: "To-Do realizado", id: "1" },
      { label: "To-Do pendiente", id: "0" },
    ],
    searchText: null,
    selectedMenu: null,
    selectedSubMenu: null,
    disabledSubmenu: true,
    selectedProject: null,
    selectedResponsible: null,
    optionsSelectSub: [],
    selectedState: null,
    optionChart: {},
    checkTaskPending: false,
    selectedAlert: null,
    selectedTask: null,
    loadSearch: false,
    debounceChange: null,
    debounceModule: null,
  }),
  computed: {
    ...mapState({
      listModule: (state) => state.modules.modules,
      listUser: (state) => state.users.listUser,
      listMasters: (state) => state.masters.listMasters,
      listState: (state) => state.masters.listState,
      listProjects: (state) => state.masters.listProjects,
    }),
    formatListState() {
      if (!this.isEmpty(this.listState)) {
        const { options = [] } = this.listState;
        options.forEach((e) => {
          if (!this.isEmpty(e.label)) {
            e.label = e.label.split(" . ")[1];
          }
        });
        return options;
      }
      return [];
    },
  },
  watch: {
    selectedMenu(val) {
      if (val) {
        this.disabledSubmenu = false;
        this.optionsSelectSub = val.submodules;
      }
    },
    // checkAlert(val) {
    //   this.$store.dispatch("report/checkAlert", val);
    // },
  },
  mounted() {
    this.debounceModule = debounce(this.onSearch, 700);
    this.debounceChange = debounce(this.onSearch, 900);
  },
  methods: {
    clearFilters() {
      this.selectedMenu = null;
      this.selectedSubMenu = null;
      this.selectedResponsible = null;
      this.selectedProject = null;
      this.selectedState = null;
      this.checkAlert = false;
      this.selectedAlert = null;
      this.selectedTask = null;
      this.onSearch();
    },
    onSearch() {
      let payload = "?response=lite&page=1";
      let filterData = "";
      if (!isEmpty(this.selectedMenu)) {
        filterData += `&module_id=${this.selectedMenu.id}`;
      }
      if (!isEmpty(this.selectedSubMenu)) {
        filterData += `&submodule_id=${this.selectedSubMenu.id}`;
      }
      if (!isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!isEmpty(this.selectedResponsible)) {
        filterData += `&responsible_id=${this.selectedResponsible.id}`;
      }
      if (!isEmpty(this.selectedState)) {
        filterData += `&state_id=${this.selectedState.id}`;
      }
      if (!isEmpty(this.searchText)) {
        filterData += `&search=${this.searchText}`;
      }
      if (!isEmpty(this.selectedAlert)) {
        filterData += `&alert=${this.selectedAlert.id}`;
      }
      if (!isEmpty(this.selectedTask)) {
        filterData += `&check=${this.selectedTask.id}`;
      }
      this.loadSearch = true;
      payload += filterData;
      this.$emit("filter", payload, filterData);
    },
    setLoadSearch(flag) {
      this.loadSearch = flag;
    },
  },
};
</script>
