<template>
  <v-container
    id="containerTable"
    fluid
    class="page-section py-0 px-0 fill-height"
    style="position: relative;"
  >
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title>Usuarios</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-transform-none"
          depressed
          small
          @click="$router.push({ name: 'RolesPage' })"
        >
          Roles
        </v-btn>
        <v-btn
          :loading="loadExcel"
          class="btn-transform-none ml-2"
          small
          depressed
          @click="onSubmitExcel()"
        >
          Exportar
        </v-btn>
        <v-btn
          class="btn-transform-none ml-2"
          color="primary"
          small
          depressed
          @click="openDialogUpdate('add')"
        >
          Agregar usuarios
        </v-btn>
        <a id="urlDownload" :href="urlDownload" class="d-none" target="_blank"
          >download...</a
        >
      </v-toolbar>
    </v-card>
    <user-drawer></user-drawer>
    <div class="content-table-module">
      <v-row
        class="fill-height"
        style="position: relative;padding: 0 12px 0 12px;"
      >
        <vue-perfect-scrollbar
          ref="perfectScrollbar"
          style="width: 100%;"
          class="content-meta-scroll"
          :settings="scrollSettings"
          :swicher="loadData"
          @ps-y-reach-end="$emit('scroll')"
        >
          <v-col cols="12">
            <v-card elevation="7" style="overflow:hidden;">
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="headers"
                  :items="listItem"
                  :loading="!loadData"
                  :items-per-page="-1"
                  hide-default-footer
                  fixed-header
                  item-key="id"
                  class="table-striped"
                  @update:sort-by="eventSortBy"
                  @update:sort-desc="eventSortDesc"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-if="!noResult && loadData">
                        <tr v-for="item in items" :key="item.id">
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item)"
                          >
                            {{ item.id }}
                          </td>
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item)"
                          >
                            {{ `${item.first_name} ${item.last_name}` }}
                          </td>
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item)"
                          >
                            {{ item.email }}
                          </td>
                          <td
                            class="td-hover"
                            @click="openDialogUpdate('edit', item)"
                          >
                            {{ item.role.label }}
                          </td>
                          <td>
                            <v-menu left offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list class="py-0">
                                <v-list-item class="td-hover">
                                  <v-list-item-title
                                    @click="onResetDialog(item)"
                                    >Reset contraseña</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                      <tr v-if="noResult" class="v-data-table__empty-wrapper">
                        <td :colspan="headers.length">
                          No se encontró resultados
                        </td>
                      </tr>
                      <tr
                        v-if="!loadData && !noResult"
                        class="v-data-table__empty-wrapper"
                      >
                        <td :colspan="headers.length">
                          Esperando resultados
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="loadScroll" cols="12" class="pt-2 pb-1 text-center">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="d-inline-block primary--text caption"
              >Cargando más registros</span
            >
          </v-col>
          <v-col
            v-if="isFinishedScroll"
            cols="12"
            class="pt-2 pb-1 text-center"
          >
            <span class="caption">No hay mas registros.</span>
          </v-col>
        </vue-perfect-scrollbar>
        <module-detail-table
          v-if="loadData"
          :is-todo="true"
          :meta-data="metaData"
        ></module-detail-table>
      </v-row>
    </div>
    <dialog-update-user
      ref="dialogUpdateUser"
      v-model="dialogNew"
      @create="openDialogCodeUser"
    ></dialog-update-user>

    <v-dialog
      v-model="dialogCodeUser"
      content-class="dialog-custom"
      max-width="400"
      persistent
      @keydown.esc="dialogCodeUser = false"
    >
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">
            El usuario con código <b>{{ codeUser.id }}</b> ha sido creado.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="action"
            class="btn-transform-none"
            text
            small
            @click="dialogCodeUser = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-detail
      ref="dialogDetail"
      v-model="dialogDetail"
      :item="selectedItem"
      title="Detalle de usuario"
    ></dialog-detail>

    <dialog-delete
      ref="dialogDelete"
      v-model="dialogDelete"
      title="Eliminar Usuario"
      :item="selectedItem"
      @delete="onSubmitDelete"
    ></dialog-delete>

    <dialog-reset-password
      ref="dialogReset"
      v-model="dialogReset"
      title="Restablecer contraseña"
      :item="selectedItem"
      @delete="onSubmitResetPassword"
    ></dialog-reset-password>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import UserDrawer from "./UserDrawer";
import DialogDelete from "@/components/commons/DialogDelete";
import ModuleDetailTable from "@/components/commons/ModuleDetailTable";
import DialogResetPassword from "./DialogResetPassword";
import DialogUpdateUser from "./DialogUpdateUser";
import DialogDetail from "./DialogDetail";

export default {
  name: "UserTable",
  components: {
    UserDrawer,
    VuePerfectScrollbar,
    DialogDetail,
    DialogDelete,
    DialogResetPassword,
    ModuleDetailTable,
    DialogUpdateUser,
  },
  props: {
    listItem: {
      type: Array,
      default() {
        return [];
      },
    },
    loadData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    optionResolution: null,
    optionProcess: null,
    scrollSettings: {
      maxScrollbarLength: 160,
      suppressScrollX: true,
    },
    loadScroll: false,
    isFinishedScroll: false,
    disabledEdit: true,
    headers: [
      { text: "id", value: "id", sortable: true, width: 100 },
      { text: "Nombre", value: "name", sortable: true },
      { text: "Email", value: "email", sortable: true, width: 100 },
      { text: "Rol", value: "role", sortable: false, width: 200 },
      { text: "Acciones", sortable: false, width: 40 },
    ],
    dialogAddRequire: false,
    flagNewRequire: false,
    dialogEditAccess: false,
    selectedItem: null,
    loadSubmit: false,
    dialogDelete: false,
    dialogReset: false,
    dialogNew: false,
    dialogDetail: false,
    loadExcel: false,
    urlDownload: "",
    codeUser: {},
    dialogCodeUser: false,
  }),
  computed: {
    ...mapState({
      listAbilities: (state) => state.users.abilities,
      filterData: (state) => state.users.filterData,
      noResult: (state) => state.users.noResult,
      metaData: (state) => state.users.metadata,
      currentUser: (state) => state.session.currentUser,
    }),
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "createUser",
      "editUser",
      "editUserSettings",
      "deleteUser",
      "setSortUser",
      "updatePageUsers",
      "updateFilterUsers",
      "clearUsers",
    ]),
    async onSubmitExcel() {
      if (this.loadExcel) {
        return false;
      }
      this.loadExcel = true;
      const { data } = await this.axios.post("/api/users/export/excel");
      if (data.success) {
        this.urlDownload = `${this.baseURL}/${data.data.url}`;
        this.$nextTick(() => {
          document.getElementById("urlDownload").click();
        });
      }
      this.loadExcel = false;
    },
    openDialogUpdate(typeDialog = "add", item) {
      this.$refs.dialogUpdateUser.openDialogUpdate(typeDialog, item);
    },
    onResetDialog(item) {
      this.selectedItem = item;
      this.dialogReset = true;
    },
    onSubmitResetPassword() {
      const { email } = this.selectedItem;
      this.$refs.dialogReset.toggleLoad(true);
      this.axios.post("/api/auth/password/reset", { email }).then(() => {
        this.$refs.dialogReset.toggleLoad(false);
        this.dialogReset = false;
      });
    },
    onDeleteDialog(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    onSubmitDelete() {
      this.$refs.dialogDelete.toggleLoad(true);
      this.deleteUser(this.selectedItem).then(() => {
        this.dialogDelete = false;
        this.$refs.dialogDelete.toggleLoad(false);
      });
    },
    eventSortBy(e) {
      this.sortBy = e.length ? e[0] : null;
      this.fetchUserSort();
    },
    eventSortDesc(e) {
      this.sortDesc = e.length ? e[0] : null;
      this.fetchUserSort();
    },
    getCompleteUrl(sortUrl) {
      return `?response=lite&page=1${this.filterData}&sort=${sortUrl}`;
    },
    sortName(name) {
      if (name === "module.label") {
        return "module";
      } else if (name === "submodule.label") {
        return "submodule";
      } else if (name === "state.label") {
        return "state";
      }
      return name;
    },
    fetchUserSort() {
      const sortUrl = `${this.sortName(this.sortBy)}:${
        this.sortDesc ? "desc" : "asc"
      }`;
      if (this.sortBy !== null && this.sortDesc !== null) {
        this.setSortUser(sortUrl);
        this.clearUsers();
        const payload = {
          payload: this.getCompleteUrl(sortUrl),
          step: true,
        };
        this.fetchUsers(payload);
      } else {
        this.setSortUser(null);
        this.clearUsers();
        const payload = {
          payload: "?response=lite&page=1",
          step: true,
        };
        this.fetchUsers(payload);
      }
    },
    concatAbilities(abilities) {
      let text = "";
      abilities.forEach((e, index) => {
        text += e.label;
        if (index < abilities.length - 1) text += ", ";
      });
      return text;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    openDialogCodeUser(data) {
      this.dialogCodeUser = true;
      this.codeUser = data;
    },
    setLoadScroll(flag) {
      this.loadScroll = flag;
    },
    setFinishedScroll(flag) {
      this.isFinishedScroll = flag;
    },
  },
};
</script>
