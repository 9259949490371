<template>
  <v-list class="pa-0" two-line>
    <template v-for="(item, index) in notifications">
      <v-list-item
        :key="item.id"
        :class="{ 'block-notifications__unread': !item.read }"
        @click="onSelectedNotification(item, index)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{
            item.updated_at | parseDate
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider
        v-if="index < notifications.length - 1"
        :key="index"
      ></v-divider>
    </template>
    <!-- Detail -->
    <dialog-detail-card
      ref="dialogDetailCard"
      v-model="dialogCard"
      type-dialog="edit"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
      :selected-spij="selectedSpij"
      :load-draft="false"
      :flag-select-draft="false"
      @update-draft="onChangeAlertToDo"
    />

    <dialog-todo-detail
      ref="dialogDetail"
      v-model="dialogTodo"
      :item="selectedItem"
      :show-edit="false"
      :from-to-do="true"
      :is-notification="true"
      @alert="onChangeAlertToDo"
    ></dialog-todo-detail>
  </v-list>
</template>
<script>
import * as moment from "moment";
import DialogTodoDetail from "@/components/todos/DialogTodoDetail";
import DialogDetailCard from "../modules/DialogDetailCard";
import mixinApiModule from "../modules/mixins/mixinApiModule";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "BlockNotifications",
  components: {
    DialogDetailCard,
    DialogTodoDetail,
  },
  filters: {
    parseDate(timestamp) {
      return moment(timestamp * 1000)
        .locale("es")
        .fromNow();
    },
  },
  mixins: [mixinApiModule],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedItem: null,
    dialogCard: false,
    dialogTodo: false,
    typeModule: null,
    typeSubmodule: null,
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    loadNotification: false,
  }),
  created() {
    this.fetchSettingSpij();
  },
  methods: {
    ...mapActions(["updateReadNotification"]),
    onChangeAlertToDo() {
      console.log("ewqewq");
    },
    onSelectedNotification(e, inx) {
      this.dialogCard = false;
      this.dialogTodo = false;
      if (!e.read) {
        this.axios
          .post(`/api/notifications/${e.id}/read`)
          .then(({ data }) => {
            if (data.success) {
              this.$set(this.notifications[inx], "read", 1);
              this.updateReadNotification();
              this.openDialogNotification(e);
            }
          })
          .catch(() => {
            this.selectedItem = null;
            this.dialogTodo = false;
          });
      } else {
        this.openDialogNotification(e);
      }
    },
    openDialogNotification(e) {
      const isToDo = e.entity_type.includes(`App\\ToDo`);
      if (!isToDo) {
        this.typeModule = e.module.slug;
        this.typeSubmodule = e.submodule.slug;
        this.dialogCard = true;
        this.$nextTick(() => {
          this.$refs.dialogDetailCard.openDialogUpdate("edit", e.entity);
        });
      } else {
        const entityId =
          e.entity_type === `App\\ToDo` ? e.entity_id : e.entity.id;
        this.axios
          .get(`/api/to_dos/${entityId}`)
          .then(({ data }) => {
            if (data.success) {
              this.$refs.dialogDetail.resetDialog();
              this.selectedItem = cloneDeep(data.data);
              this.$refs.dialogDetail.setTodo(this.selectedItem);
              this.dialogTodo = true;
            } else {
              this.selectedItem = null;
              this.dialogTodo = false;
            }
          })
          .catch(() => {
            this.selectedItem = null;
            this.dialogTodo = false;
          });
        // this.$refs.dialogDetailCard.openDialogUpdate("edit", e.entity);
      }
    },
    async fetchSettingSpij() {
      const { data } = await this.axios.get(`/api/settings`);
      if (data.success) {
        this.selectedSpij = data.data;
      }
    },
  },
};
</script>
