import { indexOf } from "lodash";

export default {
  methods: {
    onValidateStep(step) {
      switch (step) {
        case 1:
          this.activeTab = step;
          break;
        case 2:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.one.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.two.id))
          )
            this.activeTab = step;
          break;
        case 3:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.two.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.three.id))
          )
            this.activeTab = step;
          break;
        case 4:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.three.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.four.id))
          )
            this.activeTab = step;
          break;
        case 5:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.four.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.five.id))
          )
            this.activeTab = step;
          break;
        case 6:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.five.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.six.id))
          )
            this.activeTab = step;
          break;
        case 7:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.six.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.seven.id))
          )
            this.activeTab = step;
          break;
        case 8:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.seven.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.eight.id))
          )
            this.activeTab = step;
          break;
        case 9:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.eight.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.nine.id))
          )
            this.activeTab = step;
          break;
        case 10:
          if (
            (!this.disabledOptions && !this.isEmpty(this.instance.nine.id)) ||
            (this.disabledOptions && !this.isEmpty(this.instance.ten.id))
          )
            this.activeTab = step;
          break;
        default:
          console.log(
            "Lo lamentamos, por el momento no disponemos de " + step + ".",
          );
      }
    },
    async validateStepByStep() {
      const listEditedStep = [];
      for (let prop in this.changeInstance) {
        if (this.changeInstance[prop]) {
          listEditedStep.push(prop);
        }
      }
      if (listEditedStep.length) {
        if (listEditedStep.length === 1 && listEditedStep[0] === "one") {
          this.validateStepInstance(2, "one");
        } else {
          for (let inx = 0; inx < listEditedStep.length; inx++) {
            const index = indexOf(this.listNumber, listEditedStep[inx]);
            await this.nextInstance(index + 2);
          }
        }
      }
    },
    validateStepInstance(step, name) {
      if (this.isEmpty(this.instance[name].id)) {
        if (name === "one") {
          this.validateStepOne(step - 1, name);
        } else {
          this.nextInstance(step, name);
        }
      } else {
        this.nextInstance(step, name);
      }
    },
    async nextInstance(step) {
      if (step === 2) {
        if (this.changeInstance.one) this.validateStepOne(step);
        else this.activeTab = 2;
      } else if (step === 3) {
        if (this.changeInstance.two || this.isEmpty(this.instance.two.id))
          await this.createInstanceTwo(step);
        else this.activeTab = 3;
      } else if (step === 4) {
        if (this.changeInstance.three || this.isEmpty(this.instance.three.id))
          await this.createInstanceThree(step);
        else this.activeTab = 4;
      } else if (step === 5) {
        if (this.changeInstance.four || this.isEmpty(this.instance.four.id))
          await this.createInstanceFour(step);
        else this.activeTab = 5;
      } else if (step === 6) {
        if (this.changeInstance.five || this.isEmpty(this.instance.five.id))
          await this.createInstanceFive(step);
        else this.activeTab = 6;
      } else if (step === 7) {
        if (this.changeInstance.six || this.isEmpty(this.instance.six.id))
          await this.createInstanceSix(step);
        else this.activeTab = 7;
      } else if (step === 8) {
        if (this.changeInstance.seven || this.isEmpty(this.instance.seven.id))
          await this.createInstanceSeven(step);
        else this.activeTab = 8;
      } else if (step === 9) {
        if (this.changeInstance.eight || this.isEmpty(this.instance.eight.id))
          await this.createInstanceEight(step);
        else this.activeTab = 9;
      } else if (step === 10) {
        if (this.changeInstance.nine || this.isEmpty(this.instance.nine.id))
          await this.createInstanceNine(step);
        else this.activeTab = 10;
      } else if (step === 11) {
        if (this.changeInstance.ten || this.isEmpty(this.instance.ten.id))
          await this.createInstanceTen(step);
        else this.activeTab = 10;
      }
    },
    onSubmitFinished() {
      this.axios
        .post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/finish`,
        )
        .then(() => {});
    },
    async createInstanceTen() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "first_instance_resolution_result",
        this.payloadInstance.ten.status,
      );
      formData.append(
        "second_instance_resolution_attachment",
        this.payloadInstance.ten.file,
      );
      formData.append(
        "second_instance_resolution_summary",
        this.payloadInstance.ten.comment,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/10/create`;
      if (!this.isEmpty(this.instance.ten.id)) {
        formData.append("model_id", this.instance.ten.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/10/${this.instance.ten.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.ten = false;
        this.axios
          .post(
            `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/finish`,
          )
          .then(() => {
            this.$set(this.instance, "ten", data.data);
            this.activeTab = 10;
          });
      } else {
        const err = data.error.data;
        const payload = {
          status: this.isEmpty(err.first_instance_resolution_result)
            ? null
            : err.first_instance_resolution_result[0],
          file: this.isEmpty(err.second_instance_resolution_attachment)
            ? null
            : err.second_instance_resolution_attachment[0],
          comment: this.isEmpty(err.second_instance_resolution_summary)
            ? null
            : err.second_instance_resolution_summary[0],
        };
        this.$refs.instanceEight.setListError(payload);
      }
    },
    async createInstanceNine() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "oral_report_notification_disabled",
        this.payloadInstance.nine.toggle,
      );
      formData.append(
        "oral_report_notification",
        this.payloadInstance.nine.file,
      );
      formData.append("date_oral_report", this.payloadInstance.nine.date);
      formData.append("pending_tasks", this.payloadInstance.nine.todo);
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/9/create`;
      if (!this.isEmpty(this.instance.nine.id)) {
        formData.append("model_id", this.instance.nine.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/9/${this.instance.nine.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.nine = false;
        this.$set(this.instance, "nine", data.data);
        if (this.payloadInstance.nine.todo.length) {
          await this.loopToDoStep(
            9,
            data.data.id,
            this.payloadInstance.nine.todo,
            0,
            null,
            "nine",
          );
        }
        this.activeTab = 10;
      } else {
        const err = data.error.data;
        const payload = {
          date: this.isEmpty(err.date_oral_report)
            ? null
            : err.date_oral_report[0],
          file: this.isEmpty(err.oral_report_notification)
            ? null
            : err.oral_report_notification[0],
        };
        this.$refs.instanceNine.setListError(payload);
      }
    },
    async createInstanceEight() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append("appeal_fee_disabled", this.payloadInstance.eight.toggle);
      formData.append("appeal_fee", this.payloadInstance.eight.file);
      formData.append("pending_tasks", this.payloadInstance.eight.todo);
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/8/create`;
      if (!this.isEmpty(this.instance.eight.id)) {
        formData.append("model_id", this.instance.eight.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/8/${this.instance.eight.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.eight = false;
        this.$set(this.instance, "eight", data.data);
        if (this.payloadInstance.eight.todo.length) {
          this.loopToDoStep(
            8,
            data.data.id,
            this.payloadInstance.eight.todo,
            0,
            null,
            "eight",
          );
        } else {
          this.activeTab = 9;
        }
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.appeal_fee) ? null : err.appeal_fee[0],
        };
        this.$refs.instanceEight.setListError(payload);
      }
    },
    async createInstanceSeven() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "first_instance_resolution_result",
        this.payloadInstance.seven.status,
      );
      formData.append(
        "first_instance_resolution_attachment",
        this.payloadInstance.seven.file,
      );
      formData.append(
        "first_instance_resolution_summary",
        this.payloadInstance.seven.comment,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/7/create`;
      if (!this.isEmpty(this.instance.seven.id)) {
        formData.append("model_id", this.instance.seven.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/7/${this.instance.seven.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.seven = false;
        this.$set(this.instance, "seven", data.data);
        this.activeTab = 8;
      } else {
        const err = data.error.data;
        const payload = {
          status: this.isEmpty(err.first_instance_resolution_result)
            ? null
            : err.first_instance_resolution_result[0],
          file: this.isEmpty(err.first_instance_resolution_attachment)
            ? null
            : err.first_instance_resolution_attachment[0],
          comment: this.isEmpty(err.first_instance_resolution_summary)
            ? null
            : err.first_instance_resolution_summary[0],
        };
        this.$refs.instanceSeven.setListError(payload);
      }
    },
    async createInstanceSix() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "release_writing_charge_1",
        this.payloadInstance.six.file,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/6/create`;
      if (!this.isEmpty(this.instance.six.id)) {
        formData.append("model_id", this.instance.six.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/6/${this.instance.six.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.six = false;
        this.$set(this.instance, "six", data.data);
        if (this.payloadInstance.six.todo.length) {
          await this.loopToDoStep(
            6,
            data.data.id,
            this.payloadInstance.six.todo,
            0,
            null,
            "six",
          );
        }
        this.activeTab = 7;
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.release_writing_charge_1)
            ? null
            : err.release_writing_charge_1[0],
        };
        this.$refs.instanceSix.setListError(payload);
      }
    },
    async createInstanceFive() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append("attachment", this.payloadInstance.five.file);
      formData.append("receipt_date", this.payloadInstance.five.date);
      formData.append(
        "summary_resolution_sanction",
        this.payloadInstance.five.comment,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/5/create`;
      if (!this.isEmpty(this.instance.five.id)) {
        formData.append("model_id", this.instance.five.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/5/${this.instance.five.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.five = false;
        this.$set(this.instance, "five", data.data);
        this.activeTab = 6;
      } else {
        const err = data.error.data;
        const payload = {
          date: this.isEmpty(err.receipt_date) ? null : err.receipt_date[0],
          comment: this.isEmpty(err.summary_resolution_sanction)
            ? null
            : err.summary_resolution_sanction[0],
          file: this.isEmpty(err.attachment) ? null : err.attachment[0],
        };
        this.$refs.instanceFive.setListError(payload);
      }
    },
    async createInstanceFour() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append("additional_information", this.payloadInstance.four.file);

      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/4/create`;
      if (!this.isEmpty(this.instance.four.id)) {
        formData.append("model_id", this.instance.four.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/4/${this.instance.four.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.four = false;
        this.$set(this.instance, "four", data.data);
        if (this.payloadInstance.four.todo.length) {
          await this.loopToDoStep(
            4,
            data.data.id,
            this.payloadInstance.four.todo,
            0,
            null,
            "four",
          );
        }
        this.activeTab = 5;
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.additional_information)
            ? null
            : err.additional_information[0],
        };
        this.$refs.instanceFour.setListError(payload);
      }
    },
    async createInstanceThree() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "release_writing_charge_2",
        this.payloadInstance.three.file,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/3/create`;
      if (!this.isEmpty(this.instance.three.id)) {
        formData.append("model_id", this.instance.three.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/3/${this.instance.three.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.success) {
        this.$emit("flag-loading", false);
        this.changeInstance.three = false;
        this.$set(this.instance, "three", data.data);
        if (this.payloadInstance.three.todo.length) {
          await this.loopToDoStep(
            3,
            data.data.id,
            this.payloadInstance.three.todo,
            0,
            null,
            "three",
          );
        }
        this.activeTab = 4;
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.release_writing_charge_2)
            ? null
            : err.release_writing_charge_2[0],
        };
        this.$refs.instanceThree.setListError(payload);
      }
    },
    async createInstanceTwo() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append(
        "release_writing_charge_1",
        this.payloadInstance.two.file,
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/2/create`;
      if (!this.isEmpty(this.instance.two.id)) {
        formData.append("model_id", this.instance.two.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/2/${this.instance.two.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.two = false;
        this.$set(this.instance, "two", data.data);
        if (this.payloadInstance.two.todo.length) {
          await this.loopToDoStep(
            2,
            data.data.id,
            this.payloadInstance.two.todo,
            0,
            null,
            "two",
          );
        }
        this.activeTab = 3;
      } else {
        const err = data.error.data;
        const payload = {
          file: this.isEmpty(err.release_writing_charge_1)
            ? null
            : err.release_writing_charge_1[0],
        };
        this.$refs.instanceTwo.setListError(payload);
      }
    },
    async createInstanceOne() {
      this.$emit("flag-loading", true);
      const formData = new FormData();
      formData.append("attachment", this.payloadInstance.one.file || "");
      formData.append(
        "receipt_date",
        this.validateDateFormat(this.payloadInstance.one.date),
      );
      formData.append(
        "summary_resolution_sanction",
        this.payloadInstance.one.comment || "",
      );
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/instance/1/create`;
      if (!this.isEmpty(this.instance.one.id)) {
        formData.append("model_id", this.instance.one.id);
        url = `/api/${this.apiByModule.module}/stage/3/instance/1/${this.instance.one.id}/update`;
      }
      const { data } = await this.axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.$emit("flag-loading", false);
      if (data.success) {
        this.changeInstance.one = false;
        this.$set(this.instance, "one", data.data);
        this.activeTab = 2;
      } else {
        const err = data.error.data;
        const payload = {
          date: this.isEmpty(err.receipt_date) ? null : err.receipt_date[0],
          file: this.isEmpty(err.attachment) ? null : err.attachment[0],
          comment: this.isEmpty(err.summary_resolution_sanction)
            ? null
            : err.summary_resolution_sanction[0],
        };
        this.$refs.instanceOne.setListError(payload);
      }
    },
    onUpdatePendingTask({ item, name }, instance) {
      this.loopToDoStep(
        instance,
        this.instance[name].id,
        [item],
        0,
        true,
        name,
      );
    },
    async loopToDoStep(
      instance,
      id,
      checklist,
      index,
      flagChecklist = false,
      name,
    ) {
      if (index === checklist.length) {
        this.$set(this.payloadInstance.four, "todo", []);
        return false;
      }
      const item = checklist[index];
      item.entity_id = id;
      // item["model_id"] = this.id;
      delete item.responsible;
      const { data } = await this.axios.post(
        `/api/${this.apiByModule.module}/stage/3/instance/${instance}/${this.id}/to_do/create`,
        item,
      );
      if (data.success) {
        if (flagChecklist) {
          this.instance[this.listNumber[instance - 1]].pending_tasks.push(
            data.data,
          );
          const nameInstance = `instance${this.nameCapitalize(name)}`;
          this.$refs[nameInstance].updateLoadChecklist(false);
          this.$refs[nameInstance].setForm();
        } else if (flagChecklist === null) {
          const nameInstance = `instance${this.nameCapitalize(name)}`;
          this.instance[name].pending_tasks.push(data.data);
          this.$refs[nameInstance].setForm();
        }
        this.loopToDoStep(
          instance,
          id,
          checklist,
          index + 1,
          flagChecklist,
          name,
        );
      }
    },
    async validateStepOne(step) {
      if (!this.selectedItem.id) {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/create`,
        );
        this.$emit("change", data.data);
        this.createInstanceOne(step);
      } else {
        this.createInstanceOne(step);
      }
    },
  },
};
