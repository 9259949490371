<template>
  <v-app id="login">
    <v-main fill-height>
      <div class="page-login">
        <div class="page-login__right">
          <div class="bg__black"></div>
          <!--<v-row align="center" justify="center">
            <v-col cols="12" sm="10">
              <div class="layout column">
                <h1 class="flex mt-3">Plataforma Jaru</h1>
              </div>
            </v-col>
          </v-row>-->
        </div>
        <div class="page-login__left">
          <v-row>
            <v-col cols="12" class="py-0">
              <div class="block-logo mx-0">
                <img class="logo" src="@/assets/logo-j.svg" alt="Jaru Logo" />
              </div>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-card class="elevation-0">
                <v-card-title class="px-0">
                  <p
                    class="page-login__title"
                    :class="{ 'title-forgot': !isLogin }"
                  >
                    {{ isLogin ? "Bienvenidos" : "Recuperar contraseña" }}
                  </p>
                </v-card-title>
                <v-card-text class="px-0 pb-2">
                  <v-form v-if="isLogin">
                    <v-text-field
                      v-model="form.email"
                      append-icon="person"
                      class=" input-custom-56"
                      label="Correo"
                      hide-details
                      type="text"
                      height="56"
                      outlined
                      @keydown.enter="onSubmitLogin"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      v-model="form.password"
                      class="mt-3 input-custom-56"
                      append-icon="lock"
                      label="Contraseña"
                      type="password"
                      height="56"
                      hide-details
                      outlined
                      @keydown.enter="onSubmitLogin"
                    ></v-text-field>
                    <div v-if="error.show" class="text-center red--text mt-1">
                      <span>{{ error.text }}</span>
                    </div>
                    <div class="text-lg-right">
                      <button
                        type="button"
                        class="btn-forget caption primary--text"
                        @click="isLogin = !isLogin"
                      >
                        ¿Olvidaste tu contraseña?
                      </button>
                    </div>
                  </v-form>
                  <v-form v-else>
                    <v-text-field
                      v-model="forget.email"
                      append-icon="person"
                      name="Correo"
                      label="Correo"
                      class="mt-0 input-custom-56"
                      type="text"
                      hide-details
                      height="56"
                      outlined
                    ></v-text-field>
                    <div
                      v-if="errorForget.show"
                      class="text-center red--text mt-1"
                    >
                      <span>{{ errorForget.text }}</span>
                    </div>
                    <div class="text-lg-right">
                      <button
                        type="button"
                        class="btn-forget caption primary--text"
                        @click="isLogin = !isLogin"
                      >
                        Ir a iniciar sesión
                      </button>
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions class="px-0">
                  <v-btn
                    v-if="isLogin"
                    block
                    color="primary"
                    :loading="loadLogin"
                    :disabled="loadLogin"
                    @click="onSubmitLogin"
                    >INICIAR SESIÓN</v-btn
                  >
                  <v-btn
                    v-else
                    block
                    color="primary"
                    :loading="loadForget"
                    :disabled="loadForget"
                    @click="onSubmitForget"
                    >ENVIAR</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <!--
            <div class="message">
              Simplificamos tus proyectos, facilitamos tus sueños.
            </div>
          -->
        </div>
      </div>
    </v-main>
    <v-dialog v-model="dialogForget" max-width="290">
      <v-card>
        <v-card-text class="pt-4 pb-2">
          Si tu correo electrónico está registrado en el sistema, en breve
          estarás recibiendo un correo con los pasos a seguir.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="btn-transform-none"
            text
            @click="dialogForget = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRoles" max-width="290">
      <v-card>
        <v-card-title class="py-1">
          <p class="subheading mb-0">No autorizado</p>
        </v-card-title>
        <v-card-text>
          El usuario <b>{{ form.email }}</b> no cumple con el roles para acceder
          a la plataforma desde un dispositivo movil.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="btn-transform-none"
            text
            @click="dialogRoles = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
const uuidv4 = require("biri");
import { mapActions, mapState } from "vuex";
import { isMobile } from "mobile-device-detect";
import { emailStore, rolesModules } from "@/const";

export default {
  name: "LoginPage",
  data() {
    const email = localStorage.getItem(emailStore) || null;
    return {
      loadLogin: false,
      dialogForget: false,
      loadForget: false,
      isLogin: true,
      error: { show: false, text: "" },
      errorForget: { show: false, text: "" },
      form: { email, password: null },
      // { email: "admin@jaru.pe", password: "admin" },
      forget: { email: "" },
      dialogRoles: false,
      tokenNotification: null,
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.session.currentUser }),
  },
  mounted() {
    if (this.$messaging) {
      this.$messaging
        .requestPermission()
        .then(() => {
          return this.$messaging.getToken();
        })
        .then((token) => {
          this.tokenNotification = token;
        })
        .catch((error) => {
          this.tokenNotification = null;
          if (error.code === "messaging/permission-blocked") {
            console.log("Please Unblock Notification Request Manually");
          } else {
            console.log("Error Occurred", error);
          }
        });
    }
  },
  methods: {
    ...mapActions(["fetchMasters", "login", "getMe"]),
    async onSubmitLogin() {
      this.loadLogin = true;
      this.error.show = false;
      this.error.text = "";
      const payload = {
        email: this.form.email,
        password: this.form.password,
      };
      if (this.tokenNotification) {
        payload["token"] = this.tokenNotification;
        payload["device_uuid"] = await uuidv4();
      }
      this.login(payload)
        .then(() => {
          this.getMe().then((user) => {
            const { role } = user;
            localStorage.setItem(emailStore, this.form.email);
            if (isMobile) {
              if (rolesModules.includes(role.slug)) {
                this.fetchModules();
              } else {
                this.dialogRoles = true;
                this.loadLogin = false;
                this.error.show = false;
                this.error.text = "";
              }
            } else {
              this.fetchModules();
            }
          });
        })
        .catch((error) => {
          this.error.show = true;
          this.error.text = error.message;
          this.loadLogin = false;
        });
    },
    async onSubmitForget() {
      this.errorForget.show = false;
      this.errorForget.text = "";
      if (this.loadForget) {
        return false;
      }
      this.loadForget = true;
      this.axios
        .post("/api/auth/password/reset", this.forget)
        .then(({ data }) => {
          if (data.success) {
            this.dialogForget = true;
          } else {
            const options = data.error.data;
            if (options.token) this.errorForget.text = data.error.data.token[0];
            if (options.email) this.errorForget.text = data.error.data.email[0];
            this.errorForget.show = true;
          }
          this.loadForget = false;
          this.isLogin = false;
        })
        .catch(() => {
          this.loadForget = false;
        });
    },
    fetchModules() {
      this.fetchMasters({ payload: "", step: true }).then(() => {
        this.loadLogin = false;
        this.$router.push("/dashboard");
      });
    },
  },
};
</script>
