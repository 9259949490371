<template>
  <v-row>
    <v-col cols="12">
      <v-card class="block-report" flat tile>
        <v-card-text>
          <v-row>
            <template v-if="loading">
              <v-col cols="12">
                <div
                  class="flex flex-col gap-5"
                  v-if="!this.isEmpty(optionChartPie.labels)"
                >
                  <h4 class="subtitle-2 primary--text font-weight-black">
                    Gráfico 1 de 2
                  </h4>
                  <p class="text-center"></p>
                  <pie-chart
                    ref="report6"
                    chart-id="report6"
                    :chart-data="optionChartPie"
                  />
                </div>
                <template v-else>
                  <p class="text-center text-black text-h5 my-10">
                    No se ha encontrado ningún resultado.
                  </p>
                </template>
              </v-col>
              <v-col
                cols="12"
                class="text-left"
                v-if="!this.isEmpty(optionChartBar.labels)"
              >
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nivel de contigencia</th>
                        <th class="text-left">Procesos judiciales en total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.contingency_level }}</td>
                        <td>{{ item.total }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="text-center mt-5">
                <v-progress-circular
                  :size="22"
                  class="d-inline-block mr-3"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <span class="primary--text">Cargando reporte</span>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="block-report" flat tile>
        <v-card-text>
          <v-row>
            <template v-if="loading">
              <v-col cols="12">
                <div
                  class="flex flex-col gap-5"
                  v-if="!this.isEmpty(optionChartBar.labels)"
                >
                  <h4 class="subtitle-2 primary--text font-weight-black">
                    Gráfico 2 de 2
                  </h4>
                  <p class="text-center"></p>
                  <bar-chart
                    ref="report11"
                    chart-id="report11"
                    class="bar-height"
                    :chart-data="optionChartBar"
                  />
                </div>
                <template v-else>
                  <p class="text-center text-black text-h5 my-10">
                    No se ha encontrado ningún resultado.
                  </p>
                </template>
              </v-col>
              <v-col
                cols="12"
                class="text-left"
                v-if="!this.isEmpty(optionChartBar.labels)"
              >
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nivel de contigencia</th>
                        <th class="text-left">Monto provisionado total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.contingency_level }}</td>
                        <td>S/. {{ item.total_amount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="text-center mt-5">
                <v-progress-circular
                  :size="22"
                  class="d-inline-block mr-3"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <span class="primary--text">Cargando reporte</span>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { generatePalette } from "../../utils/palette";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";

export default {
  components: { PieChart, BarChart },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loading: false,
    optionChartBar: {},
    optionChartPie: {},
    dataTable: [],
    dataTotal: 0,
    backgroundColor: [],
  }),
  computed: {
    titleChart() {
      let label = "Situación de ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "Situación general";
    },
  },
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/twelve${this.filterData}`
      );

      if (data) {
        let dataChart = data;

        const colors = generatePalette(dataChart.length);

        this.optionChartBar = {
          labels: [...dataChart.map((x) => x.contingency_level)],
          datasets: this.datasetBarChart(dataChart, colors),
          options: {
            scales: {
              y: {
                type: "logarithmic", // Escala logarítmica para el eje Y
                beginAtZero: true,
                ticks: {
                  min: 1, // Aseguramos que los valores pequeños aparezcan
                  callback: function (value) {
                    if (value === 100000) return "100K"; // Mostrar valores grandes de forma abreviada
                    if (value === 1000) return "1K"; // Mostrar valores medianos
                    if (value === 100) return "100"; // Mostrar valores pequeños
                    return Number(value.toString()); // Otros valores
                  },
                },
              },
            },
          },
        };

        this.optionChartPie = {
          labels: [...dataChart.map((x) => x.contingency_level)],
          datasets: this.datasetPieChart(dataChart, colors),
        };

        this.dataTable = dataChart;

        this.loading = true;
      }
    },
    datasetBarChart(dataChart, colors) {
      return [
        {
          label: "Montos provisionados por nivel de contigencia",
          backgroundColor: colors,
          data: dataChart.map((item) => item.total_amount), // Cada barra tendrá su valor individual
        },
      ];
    },
    datasetPieChart(dataChart, colors) {
      return [
        {
          backgroundColor: colors,
          data: dataChart.map((item) => item.total), // Cada barra tendrá su valor individual
        },
      ];
    },
  },
};
</script>
