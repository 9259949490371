<template>
  <v-card class="px-1">
    <v-card-text class="px-0 pt-0">
      <v-stepper v-model="activeTab" vertical :alt-labels="false">
        <v-stepper-step
          :complete="!isEmpty(instance.one.id)"
          :color="isEmpty(instance.one.id) ? 'primary' : 'success'"
          :step="1"
          :style="styleCursorStep(1)"
          @click="onValidateStep(1)"
        >
          Resolución de inicio de PAS
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceOne"
              v-model="changeInstance.one"
              name="one"
              :instance-id="instance.one.id"
              :show-switch-file="false"
              title-file="Adjunto de resolución de inicio de PAS*"
              :show-file="true"
              :value-file="instance.one.attachment"
              title-date="Fecha de recepción"
              :show-date="true"
              :value-date="instance.one.receipt_date"
              :show-todo="false"
              title-textarea="Resumen de la Resolución de sanción"
              :show-textarea="true"
              :value-textarea="instance.one.summary_resolution_sanction"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 1)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.two.id)"
          :color="isEmpty(instance.two.id) ? 'primary' : 'success'"
          :step="2"
          :style="styleCursorStep(2)"
          @click="onValidateStep(2)"
        >
          Escrito de descargos 1
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceTwo"
              v-model="changeInstance.two"
              name="two"
              :instance-id="instance.two.id"
              :show-switch-file="false"
              title-file="Adjunto*"
              :show-file="true"
              :value-file="instance.two.release_writing_charge_1"
              :show-todo="true"
              :value-todo="instance.two.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 2)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.three.id)"
          :color="isEmpty(instance.three.id) ? 'primary' : 'success'"
          :step="3"
          :style="styleCursorStep(3)"
          @click="onValidateStep(3)"
        >
          Escrito de descargos 2
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceThree"
              v-model="changeInstance.three"
              name="three"
              :instance-id="instance.three.id"
              :show-switch-file="true"
              :value-switch="instance.three.release_writing_charge_2_disabled"
              title-file="Adjunto*"
              :show-file="true"
              :value-file="instance.three.release_writing_charge_2"
              :show-todo="true"
              :value-todo="instance.three.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 3)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.four.id)"
          :color="isEmpty(instance.four.id) ? 'primary' : 'success'"
          :step="4"
          :style="styleCursorStep(4)"
          @click="onValidateStep(4)"
        >
          Información complementaria
        </v-stepper-step>

        <v-stepper-content step="4">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceFour"
              v-model="changeInstance.four"
              name="four"
              :instance-id="instance.four.id"
              :show-switch-file="true"
              :value-switch="instance.four.additional_information_disabled"
              title-file="Información complementaria"
              :show-file="true"
              :value-file="instance.four.additional_information"
              :show-todo="true"
              :value-todo="instance.four.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 4)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.five.id)"
          :color="isEmpty(instance.five.id) ? 'primary' : 'success'"
          :step="5"
          :style="styleCursorStep(5)"
          @click="onValidateStep(5)"
        >
          Informe Final de Instrucción
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceFive"
              v-model="changeInstance.five"
              name="five"
              :instance-id="instance.five.id"
              :show-switch-file="false"
              title-file="Informe Final de Instrucción"
              :show-file="true"
              :value-file="instance.five.attachment"
              title-date="Fecha de notificación"
              :show-date="true"
              :value-date="instance.five.receipt_date"
              :show-todo="false"
              title-textarea="Resumen de la Resoución de sanción"
              :show-textarea="true"
              :value-textarea="instance.five.summary_resolution_sanction"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 5)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.six.id)"
          :color="isEmpty(instance.six.id) ? 'primary' : 'success'"
          :step="6"
          :style="styleCursorStep(6)"
          @click="onValidateStep(6)"
        >
          Escrito de descargos 1
        </v-stepper-step>

        <v-stepper-content step="6">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceSix"
              v-model="changeInstance.six"
              name="six"
              :instance-id="instance.six.id"
              :show-switch-file="false"
              title-file="Adjunto(*)"
              :show-file="true"
              :value-file="instance.six.release_writing_charge_1"
              :show-todo="true"
              :value-todo="instance.six.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 6)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.seven.id)"
          :color="isEmpty(instance.seven.id) ? 'primary' : 'success'"
          :step="7"
          :style="styleCursorStep(7)"
          @click="onValidateStep(7)"
        >
          Resolución de primera instancia
        </v-stepper-step>

        <v-stepper-content step="7">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceSeven"
              v-model="changeInstance.seven"
              name="seven"
              :instance-id="instance.seven.id"
              :show-switch-status="true"
              title-status="Resultado de resolución de primera instancia(*)"
              :value-switch-status="
                instance.seven.first_instance_resolution_result
              "
              :show-switch-file="false"
              title-file="Adjunto de resolución de primera instancia(*)"
              :show-file="true"
              :value-file="instance.seven.first_instance_resolution_attachment"
              :show-textarea="true"
              :value-textarea="instance.seven.first_instance_resolution_summary"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 7)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.eight.id)"
          :color="isEmpty(instance.eight.id) ? 'primary' : 'success'"
          :step="8"
          :style="styleCursorStep(8)"
          @click="onValidateStep(8)"
        >
          Apelación
        </v-stepper-step>

        <v-stepper-content step="8">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceEight"
              v-model="changeInstance.eight"
              name="eight"
              :instance-id="instance.eight.id"
              :show-switch-file="true"
              :value-switch="instance.eight.appeal_fee_disabled"
              title-file="Cargo de apelación(*)"
              :show-file="true"
              :value-file="instance.eight.appeal_fee"
              :show-todo="true"
              :value-todo="instance.eight.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 8)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.nine.id)"
          :color="isEmpty(instance.nine.id) ? 'primary' : 'success'"
          :step="9"
          :style="styleCursorStep(9)"
          @click="onValidateStep(9)"
        >
          Informe oral
        </v-stepper-step>

        <v-stepper-content step="9">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceNine"
              v-model="changeInstance.nine"
              name="nine"
              :instance-id="instance.nine.id"
              position-file="down"
              :show-switch-file="true"
              :value-switch="instance.nine.oral_report_notification_disabled"
              title-file="Notificación informe oral(*)"
              :show-file="true"
              :value-file="instance.nine.oral_report_notification"
              :show-date="true"
              title-date="Fecha de informe oral"
              :value-date="instance.nine.date_oral_report"
              :show-todo="true"
              :value-todo="instance.nine.pending_tasks"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 9)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-step
          :complete="!isEmpty(instance.ten.id)"
          :color="isEmpty(instance.ten.id) ? 'primary' : 'success'"
          :step="10"
          :style="styleCursorStep(10)"
          @click="onValidateStep(10)"
        >
          Resolución de segunda instancia
        </v-stepper-step>

        <v-stepper-content step="10">
          <v-card-text class="px-0 pt-0">
            <instance-step
              ref="instanceTen"
              v-model="changeInstance.ten"
              name="ten"
              :instance-id="instance.ten.id"
              :show-switch-status="true"
              :value-switch-status="
                instance.ten.first_instance_resolution_result
              "
              title-status="Resultado de resolución de primera instancia(*)"
              :show-switch-file="false"
              :show-file="true"
              :value-file="instance.ten.second_instance_resolution_attachment"
              title-file="Adjunto de resolución de segunda instancia(*)"
              :show-textarea="true"
              title-textarea="Resumen de resolución de segunda instancia"
              :value-textarea="instance.ten.second_instance_resolution_summary"
              :type-dialog="typeDialog"
              :disabled-options="!disabledOptions"
              @update-todo="onUpdatePendingTask($event, 10)"
              @update="onUpdateInstance"
            ></instance-step>
          </v-card-text>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>
<script>
import { merge } from "lodash";
import mixinApiModule from "../../modules/mixins/mixinApiModule";
import mixinCommon from "../mixins/mixinCommon";
import mixinInstance from "../mixins/mixinInstance";
import InstanceStep from "@/components/commons/InstanceStep";

import { IStepInstances } from "../models";

const listNumber = [
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
];

const instanceGlobal = new IStepInstances();

export default {
  name: "StepThree",
  components: {
    InstanceStep,
  },
  mixins: [mixinCommon, mixinApiModule, mixinInstance],
  data: () => ({
    listNumber,
    activeTab: 1,
    instance: {
      one: instanceGlobal.getInstanceOne(),
      two: instanceGlobal.getInstanceTwo(),
      three: instanceGlobal.getInstanceThree(),
      four: instanceGlobal.getInstanceFour(),
      five: instanceGlobal.getInstanceFive(),
      six: instanceGlobal.getInstanceSix(),
      seven: instanceGlobal.getInstanceSeven(),
      eight: instanceGlobal.getInstanceEight(),
      nine: instanceGlobal.getInstanceNine(),
      ten: instanceGlobal.getInstanceTen(),
    },
  }),
  watch: {
    changeInstance: {
      deep: true,
      handler(obj) {
        let flag = false;
        for (let prop in obj) {
          if (obj[prop]) {
            flag = true;
            break;
          }
        }
        this.$emit("flag-edit", flag);
      },
    },
  },
  mounted() {
    this.activeTab = 1;
  },
  methods: {
    styleCursorStep(step = 1) {
      let flag = !this.isEmpty(this.instance[`${listNumber[step - 1]}`].id);
      return `cursor: ${flag ? "pointer" : "initial"}`;
    },
    setForm() {
      this.activeTab = 1;
      let inxTab = 1;
      this.listNumber.forEach((e, index) => {
        this.$set(
          this.instance,
          e,
          merge(
            instanceGlobal[`getInstance${this.nameCapitalize(e)}`](),
            this.selectedItem[`instance_${index + 1}`],
          ),
        );
        if (!this.isEmpty(this.selectedItem[`instance_${index + 1}`])) {
          inxTab = index + 1;
        }
      });
      this.activeTab = inxTab;
      this.$nextTick(() => {
        this.listNumber.forEach((e) => {
          this.$refs[`instance${this.nameCapitalize(e)}`].setForm();
        });
      });
    },
    clearForm() {
      this.listNumber.forEach((e) => {
        this.$set(
          this.instance,
          e,
          instanceGlobal[`getInstance${this.nameCapitalize(e)}`](),
        );
      });
      this.$nextTick(() => {
        this.listNumber.forEach((e) => {
          this.$refs[`instance${this.nameCapitalize(e)}`].clearForm();
        });
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
