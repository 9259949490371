export class ICommitments {
  constructor() {
    this.id = null;
    this.type = null;
    this.oportunity = null;
    this.situation = {
      created_at: 1678127636,
      id: "MTOP000004",
      label: "En proceso",
      master_id: "MT00000002",
      order: 0,
      slug: "en-proceso",
      updated_at: 1689792101,
    };
    this.detail = null;
    this.project = {};
    // Term
    this.term = 0;
    // Dates
    this.expiration_date = null;
    this.expiration_date_disabled = true;
    // User | Responsible
    this.creator = {};
    this.primary_responsible = {};
    this.secondary_responsible = {};
    // Checklist
    this.pending_tasks = [];
    this.parts = null;
    this.main_obligations = null;
    // Map
    this.zone = {};
    this.datum = {};
    this.locations = [];
    this.location_disabled = true;
    this.autoridad_competente = null;
    this.plazo_legal = null;
    this.plazo_estimado = null;
    this.consecuencia_incumplimiento = null;
    this.medidad_correctivas = null;
    this.observaciones = null;
    this.sansiones_osinergmin = null;
    this.observaciones_ambiental = null;
  }
}
