<template>
  <v-row class="checklist-actitivy">
    <v-col cols="12" class="py-0">
      <v-card
        ref="outsideCard"
        v-click-outside="hideOptions"
        class="mx-auto checklist-actitivy__card elevation-0"
        outlined
        :ripple="false"
        @click="focusInput = true"
      >
        <v-card-text class="pa-2">
          <div class="block-comment">
            <contenteditable
              v-model="comment"
              class="block-comment__editable"
              tag="div"
              :contenteditable="true"
              :no-n-l="true"
              @returned="enterPressed"
            />
            <span v-if="isEmpty(comment)" class="block-comment__placeholder"
              >Escribe un comentario</span
            >
          </div>
          <ul v-if="listFiles.length" class="checklist-actitivy__files">
            <li
              v-for="(item, index) in listFiles"
              :key="index"
              class="checklist-actitivy__file"
            >
              {{ item.name }}
              <v-btn
                text
                icon
                x-small
                :ripple="false"
                @click="onDeleteFile(index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions v-if="focusInput" class="py-2">
          <input
            ref="inputFile"
            type="file"
            class="d-none"
            @change="handleFileUpload"
          />
          <v-btn text small class="btn-transform-none" @click="clickUpdate"
            ><i class="material-icons mr-1">
              attachment
            </i>
            Incluir adjunto</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            :loading="loadSubmit"
            color="primary"
            class="btn-transform-none"
            @click="onSubmitActivity"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0">
      <activity-item
        v-for="item in listItem"
        :key="item.id"
        :activity="item"
        @delete="onDeleteActivity"
      ></activity-item>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import { findIndex } from "lodash";
import ClickOutside from "vue-click-outside";
import ActivityItem from "./ActivityItem";

export default {
  name: "ActivityList",
  components: {
    ActivityItem,
  },
  directives: {
    ClickOutside,
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    focusInput: false,
    loadData: false,
    loadSubmit: false,
    showActions: false,
    comment: null,
    file: null,
    listFiles: [],
    listItem: [],
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
  },
  watch: {
    activities: {
      immediate: true,
      deep: true,
      handler(val) {
        this.listItem = val;
      },
    },
  },
  methods: {
    enterPressed() {
      console.log("ewqewq");
    },
    onDeleteActivity({ id }) {
      const inx = findIndex(this.listItem, { id });
      if (inx >= 0) {
        this.listItem.splice(inx, 1);
      }
    },
    onDeleteFile(index) {
      this.listFiles.splice(index, 1);
      this.$nextTick(() => {
        this.$refs.outsideCard.click();
        this.focusInput = true;
      });
    },
    // fetchActivities() {
    //   this.loadData = true;
    //   this.axios
    //     .get(`/api/to_do_activities/to_do/${this.id}`)
    //     .then(({ data }) => {
    //       this.listItem = data.success ? data.data : [];
    //       this.loadData = false;
    //     })
    //     .catch(() => {
    //       this.listItem = [];
    //       this.loadData = false;
    //     });
    // },
    async onSubmitActivity() {
      if (this.loadSubmit) {
        return false;
      }
      this.loadSubmit = true;
      const payload = {
        to_do_id: this.id,
        responsible_id: this.currentUser.id,
        type: "comment",
        activity: this.comment,
      };
      this.axios
        .post(`/api/to_do_activities/create`, payload)
        .then(({ data }) => {
          if (data.success) {
            const activity = data.data;
            if (this.listFiles.length > 0) {
              this.listFiles.forEach(async (itemFile) => {
                await this.onSubmitAttachment(itemFile, data.data, true).then(
                  (resp) => {
                    activity.attachments.push(resp.data);
                  },
                );
              });

              this.clearActivityForm();
            } else {
              this.clearActivityForm();
            }
            this.listItem.unshift(activity);
            this.loadSubmit = false;
          }
        })
        .catch(() => {
          this.loadSubmit = false;
        });
    },
    async onSubmitAttachment(
      itemFile,
      activity,
      isCreate = false,
      idAttach = null,
    ) {
      return new Promise(async (resolve) => {
        const formData = new FormData();
        formData.append("to_do_activity_id", activity.id);
        formData.append("name", "name_file");
        formData.append("file", itemFile || "");
        const url = isCreate ? "create" : `${idAttach}/update`;
        const dataAttach = await this.axios({
          method: "post",
          url: `/api/to_do_attachments/${url}`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        resolve(dataAttach.data);
      });
    },
    clearActivityForm() {
      this.comment = null;
      this.listFiles = [];
      this.focusInput = false;
    },
    clickUpdate() {
      this.$refs.inputFile.click();
    },
    handleFileUpload(e) {
      if (!e.target.files) {
        return false;
      }
      this.listFiles.push(e.target.files[0]);
    },
    hideOptions() {
      this.focusInput = false;
    },
    onFocusInput() {
      this.focusInput = true;
    },
  },
};
</script>
