export const extendedPrefix = {
  'internal_obligation': 'submodule_internal_id',
  'formal_obligation': 'submodule_id',
  'water_obligation': 'submodule_water_id',
  'air_obligation': 'submodule_air_id',
  'biodiversity_obligation': 'submodule_bio_id',
  'environmental_emergencies_obligation': 'submodule_env_amb_id',
  'explosives_obligation': 'submodule_explosives_id',
  'igas_obligation': 'submodule_igas_id',
  'auto_permission_obligation': 'submodule_auto_per_id',
  'general_obligation': 'submodule_general_id',
  'heritage_obligation': 'submodule_heritage_id',
  'hydrocarbons_obligation': 'submodule_hydro_id',
  'environment_rules_obligation': 'submodule_env_rules_id',
  'solid_waste_obligation': 'submodule_sol_wst_id',
};


export const submoduleNamePrefix = {
  'internal_obligation': 'submodule_internal_id',
  'formal_obligation': 'submodule_id',
  'water_obligation': 'Matriz Agua',
  'air_obligation': 'Matriz Aire',
  'biodiversity_obligation': 'Matriz Biodiversidad',
  'environmental_emergencies_obligation': 'Matriz Emergencias Ambientales',
  'explosives_obligation': 'Matriz Explosivos',
  'igas_obligation': 'Matriz IGAs',
  'auto_permission_obligation': 'Matriz Permisos - Auto',
  'general_obligation': 'Matriz General',
  'heritage_obligation': 'Matriz Patrimonial',
  'hydrocarbons_obligation': 'Matriz Hidrocarburos',
  'environment_rules_obligation': 'Matriz Reglas Ambiental',
  'solid_waste_obligation': 'Matriz Residuos Sólidos',
};
