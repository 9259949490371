<template>
  <v-container
    fluid
    fill-height
    class="gmap-section pa-0"
    style=" height: calc(100vh - 48px);"
  >
    <map-drawer
      :id="id"
      ref="mapDrawer"
      @catastro="onShowCatastroQuery"
      @geocatmin-layer="onSelectGeocatminLayer"
      @clear-filter="onClearFilterMap"
      @collapse="onToggleCollapse"
      @filter="onFilterMap"
    ></map-drawer>
    <div class="content-map" :class="{ 'active-collapse': toggleDrawer }">
      <block-search-query
        v-if="showCatastroQuery"
        :class="{ 'active-collapse': toggleDrawer }"
        @query="onSearchQuery"
        @clear="onClearQuery"
      />
      <div
        v-if="flagQuery"
        class="features"
        :class="{ 'feature-empty': !listFeature.length }"
        :style="`height: ${toggleForm ? '350px' : 'auto'}`"
      >
        <v-card
          class="features__wrapper"
          :class="{ 'feature-empty': !listFeature.length }"
          :style="`height: ${toggleForm ? '350px' : 'auto'}`"
        >
          <v-expansion-panels v-model="activeCollapse" accordion>
            <v-expansion-panel v-if="flagFilterJaru">
              <v-expansion-panel-header expand-icon="keyboard_arrow_up"
                >Resultados de búsqueda de Jaru</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div v-show="listFeatureJaru.length" class="features__table">
                  <vue-perfect-scrollbar
                    ref="perfectScrollbar"
                    style="width: 100%;"
                    class="content-meta-scroll"
                    :settings="scrollSettings"
                    :swicher="true"
                  >
                    <v-data-table
                      class="my-0 table-map"
                      :headers="headerJaru"
                      :items="listFeatureJaru"
                      item-key="id"
                      dense
                      :items-per-page="-1"
                      :fixed-header="false"
                      hide-default-footer
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="(item, index) in items"
                            :key="index"
                            :class="{ 'tr-active': activeIndex === index }"
                          >
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'drawer')"
                            >
                              {{ item.entity_id }}
                            </td>
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'drawer')"
                            >
                              {{ item.module }}
                            </td>
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'drawer')"
                            >
                              {{ item.submodule }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </vue-perfect-scrollbar>
                </div>
                <div
                  v-if="!listFeatureJaru.length"
                  class="d-block col-12 text-center"
                >
                  <span>No se encontraron resultados.</span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="flagFilterGeocatmin">
              <v-expansion-panel-header
                >Resultados de búsqueda en Geocatmin</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div
                  v-show="listFeatureGeocatmin.length"
                  class="features__table"
                >
                  <vue-perfect-scrollbar
                    ref="perfectScrollbar"
                    style="width: 100%;"
                    class="content-meta-scroll"
                    :settings="scrollSettings"
                    :swicher="true"
                  >
                    <v-data-table
                      class="my-0 table-map"
                      :headers="headerJaru"
                      :items="listFeatureGeocatmin"
                      item-key="id"
                      dense
                      :items-per-page="-1"
                      :fixed-header="false"
                      hide-default-footer
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="(item, index) in items"
                            :key="index"
                            :class="{ 'tr-active': activeIndex === index }"
                          >
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'geocatmin')"
                            >
                              {{ item.attributes.CODIGOU }}
                            </td>
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'geocatmin')"
                            >
                              {{ item.attributes.CONCESION }}
                            </td>
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'geocatmin')"
                            >
                              {{ item.attributes.TIT_CONCES }}
                            </td>
                            <td
                              class="td-hover"
                              @click="onGoToLayer(item, index, 'geocatmin')"
                            >
                              {{ item.attributes.HECTAGIS }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </vue-perfect-scrollbar>
                </div>
                <div
                  v-if="!listFeatureGeocatmin.length"
                  class="d-block col-12 text-center"
                >
                  <span>No se encontraron resultados.</span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </div>
      <map-view
        ref="mapView"
        :toggle-drawer="toggleDrawer"
        :geocatmin-layer="geocatminLayer"
        @features="setListFeature"
        @filter-drawer="setListFilterDrawer"
      ></map-view>
      <v-alert
        v-model="showAlert"
        class="block-alert my-0"
        color="red"
        type="error"
        dark
        dismissible
      >
        <span class="caption"
          >La información de Geocatmin puede demorar un poco en cargar.</span
        >
      </v-alert>
    </div>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { groupBy } from "lodash";
import MapDrawer from "@/components/map/MapDrawer";
import MapView from "@/components/map/MapView";
import BlockSearchQuery from "@/components/map/BlockSearchQuery";

export default {
  name: "MapPage",
  components: {
    VuePerfectScrollbar,
    MapDrawer,
    MapView,
    BlockSearchQuery,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    map: null,
    activeCollapse: null,
    showAlert: false,
    listFeatureJaru: [],
    listFeatureGeocatmin: [],
    listFilter: [],
    showCatastroQuery: false,
    toggleForm: true,
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    flagQuery: false,
    activeIndex: null,
    toggleDrawer: false,
    headerGeo: [
      { text: "Código", value: "attributes.CODIGOU", sortable: false },
      {
        text: "Nombre",
        value: "attributes.CONCESION",
        sortable: false,
      },
      { text: "Titular", value: "attributes.TIT_CONCES", sortable: false },
      { text: "Has.", value: "attributes.HECTAGIS", sortable: false },
    ],
    headerJaru: [
      { text: "Código", value: "entity_id", sortable: false },
      {
        text: "Módulo",
        value: "module",
        sortable: false,
      },
      { text: "Submódulo", value: "submodule", sortable: false },
    ],
    typeFilter: "drawer",
    flagFilterJaru: false,
    flagFilterGeocatmin: false,
    geocatminLayer: {
      catastro: false,
      area: false,
      cartografia: false,
    },
  }),
  computed: {
    tableHeaders() {
      return this.typeFilter === "drawer" ? this.headerJaru : this.headerGeo;
    },
  },
  watch: {
    showAlert(val) {
      if (val) {
        setTimeout(() => {
          this.showAlert = false;
        }, 10000);
      }
    },
  },
  created() {
    this.onClearQuery();
    this.flagQuery = false;
  },
  beforeDestroy() {
    this.$eventBus.$off("search-query");
    this.$eventBus.$off("search-query-clear");
  },
  methods: {
    onToggleCollapse(val) {
      this.toggleDrawer = val;
    },
    onClearFilterMap() {
      this.$refs.mapView.clearFilterMap();
    },
    onFilterMap(filterData) {
      this.axios.get(`/api/locations${filterData}`).then(({ data }) => {
        if (data.success) {
          const entityById = groupBy(data.data, function(n) {
            return n.entity_id;
          });
          const listEntities = [];
          for (let item in entityById) {
            listEntities.push({
              entity_id: item,
              entity: entityById[item][0].entity,
              module: entityById[item][0].module.label,
              submodule: entityById[item][0].submodule.label,
              paths: entityById[item],
            });
          }
          this.$refs.mapView.onChangeLocationsByFilter(listEntities);
          this.setListFilterDrawer(listEntities);
          this.$refs.mapDrawer.setLoadSearch(false);
        } else {
          this.$refs.mapDrawer.setLoadSearch(false);
        }
      });
    },
    onSelectGeocatminLayer(val) {
      this.geocatminLayer = val;
      this.$refs.mapView.displayLayerCatastro(val);
    },
    onShowCatastroQuery(val) {
      this.showAlert = val;
      this.showCatastroQuery = val;
    },
    onClearQuery() {
      this.flagQuery = false;
      this.listFeature = [];
    },
    onGoToLayer(item, index, typeFilter) {
      this.activeIndex = index;
      this.$refs.mapView.onGoToLayer(item, index, typeFilter);
    },
    onSearchQuery(payload) {
      // this.$refs.mapView
      this.$eventBus.$emit("search-query", payload);
    },
    setListFilterDrawer(listFilter = []) {
      this.flagFilterJaru = true;
      this.listFeatureJaru = listFilter;
      this.activeCollapse = 0;
      this.flagQuery = true;
    },
    setListFeature(listFeature = []) {
      if (!this.flagFilterJaru) {
        this.activeCollapse = null;
      }
      setTimeout(() => {
        this.flagFilterGeocatmin = true;
        this.listFeatureGeocatmin = listFeature;
        this.flagQuery = true;
        if (this.flagFilterJaru) {
          this.activeCollapse = 1;
        } else {
          this.activeCollapse = 0;
        }
      }, 900);
    },
  },
};
</script>
