<template>
  <v-autocomplete
    v-model="selectedUser"
    item-text="last_name"
    item-value="id"
    autocomplete="no"
    height="56"
    :clearable="clearable"
    return-object
    outlined
    :hide-details="isEmpty(textError)"
    :rules="rulesUser"
    :error="!isEmpty(textError)"
    :label="label"
    :class="`input-custom-56 ${classes}`"
    :items="filterUsers"
    :disabled="disabled"
    :placeholder="placeholder"
    :filter="filterResponsible"
    :menu-props="{ 'max-height': 150 }"
    @input="debounceChange"
  >
    <template v-slot:selection="data">
      <span>{{ `${data.item.first_name} ${data.item.last_name}` }}</span>
    </template>
    <template v-slot:item="data">
      <span>{{ `${data.item.first_name} ${data.item.last_name}` }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
  name: "SelectorUser",
  model: { prop: "selectedResponsible", event: "change" },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Responsable",
    },
    classes: {
      type: String,
      default: "mt-0 pt-0 mb-4",
    },
    selectedResponsible: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Seleccione responsable",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    textError: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterEjecutor: {
      type: Boolean,
      defualt: false,
    },
  },
  data: () => ({
    debounceChange: null,
    selectedUser: null,
    rulesUser: [],
    validUpdateUser: false,
    lazyUpdateUser: false,
  }),
  computed: {
    ...mapState({
      listAllUsers: (state) => state.users.listAllUsers,
    }),
    filterUsers() {
      return !this.filterEjecutor
        ? this.listAllUsers
        : this.listAllUsers.filter((e) => e.role.slug !== "ejecutor");
    },
  },
  watch: {
    selectedResponsible: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedUser = val;
      },
    },
    textError(val) {
      this.$nextTick(() => {
        this.rulesUser = this.isEmpty(val) ? [] : [() => this.textError];
      });
    },
  },
  mounted() {
    this.debounceChange = debounce(this.onChangeFilter, 300);
    this.selectedUser = this.selectedResponsible;
    this.rulesUser = !this.isEmpty(this.textError)
      ? [() => this.textError]
      : [];
  },
  methods: {
    // validateSelectorUser() {
    //   this.$nextTick(() => {
    //     this.$refs.formSelectorUser.validate();
    //   });
    // },
    filterResponsible(item, queryText) {
      const fullName = `${item.first_name} ${item.last_name}`;
      return (
        fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.first_name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.last_name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    clearSelector() {
      this.selectedUser = null;
      this.rulesUser = [];
    },
    onChangeFilter(val) {
      this.$emit("clear-error");
      this.$emit("change", val);
      this.$nextTick(() => {
        this.$emit("update", val);
      });
    },
  },
};
</script>
