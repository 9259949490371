<template>
  <v-card>
    <v-tabs v-model="tab" fixed-tabs height="" centered >
        <v-tab v-for="(item, index) in items" :key="item" :class="{active: currentTab === index,'text-left': true}" @click="currentTab = index" >
          {{ item }}
          <v-btn v-if="index !== 0 && index === items.length - 1" icon class="m-0" fab small text @click="handleClickClose(index)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-tab>
        <v-tab left aria-selected="false" @click="handleClickAdd">
          <v-btn btn-transform-none icon small color="primary">
            <v-icon>add</v-icon>
            Agregar </br> Incidencia
          </v-btn>
        </v-tab>

      <v-tabs-items v-model="tab">
        <v-card flat>
          <div v-for="(item, index) in items" :key="index" >
            <v-card-text v-show="currentTab === index">
              <!-- Content -->
              <v-card  v-if=" index === 0" class="px-3">
                <v-card-text class="px-0">
                  <v-form ref="formUpdate" v-model="validUpdate" :lazy-validation="lazyUpdate" >
                    <v-row>
                      <v-col cols="6" class="py-0">
                          <v-text-field
                            @input="onChangeRoom(-1)"
                            v-model="room"
                            class="pt-0 mb-3 input-custom-56"
                            label="Sala"
                            height="56"
                            :disabled="disabledOptions"
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>

                      <v-col cols="6" class="py-0">
                        <v-text-field
                          @input="onChangeRoom(-1)"
                          v-model="file_code"
                          class="pt-0 mb-3 input-custom-56"
                          label="Código Expediente"
                          height="56"
                          :disabled="disabledOptions"
                          hide-details
                          outlined
                          ></v-text-field>
                         <!--  @input="onChangeFileCode" -->
                      </v-col>
                      <v-col cols="12" class="py-0 mb-3">
                        <v-textarea
                          @input="onChangeComment(-1)"
                          v-model="inputComment"
                          class="pt-0"
                          label="Comentarios"
                          hide-details
                          outlined
                          :disabled="disabledOptions"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row v-if="selectedId">
                    <v-col cols="12">
                      <v-stepper v-model="activeTab" vertical>
                        <v-stepper-step
                          :complete="!isEmpty(instance.one.id)"
                          :color="isEmpty(instance.one.id) ? 'primary' : 'success'"
                          :step="1"
                          :style="styleCursorStep(1)"
                          @click="onValidateStep(1)"
                        >
                          Solicitud de casación
                        </v-stepper-step>
                        <v-stepper-content step="1">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceOne"
                              v-model="changeInstance.one"
                              name="one"
                              :instance-id="instance.one.id"
                              title-file="Documento*"
                              :value-switch="instance.one.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.one.attachment"
                              :show-todo="true"
                              :value-todo="instance.one.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.one.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 1)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(instance.two.id)"
                          :color="isEmpty(instance.two.id) ? 'primary' : 'success'"
                          :step="2"
                          :style="styleCursorStep(2)"
                          @click="onValidateStep(2)"
                        >
                          Solicitud de improcedencia
                        </v-stepper-step>
                        <v-stepper-content step="2">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceTwo"
                              v-model="changeInstance.two"
                              name="two"
                              :instance-id="instance.two.id"
                              title-file="Documento*"
                              :value-switch="instance.two.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.two.attachment"
                              :show-todo="true"
                              :value-todo="instance.two.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.two.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 2)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(instance.three.id)"
                          :color="isEmpty(instance.three.id) ? 'primary' : 'success'"
                          :step="3"
                          :style="styleCursorStep(3)"
                          @click="onValidateStep(3)"
                        >
                          Auto de procedencia
                        </v-stepper-step>
                        <v-stepper-content step="3">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceThree"
                              v-model="changeInstance.three"
                              name="three"
                              :instance-id="instance.three.id"
                              title-file="Documento*"
                              :value-switch="instance.three.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.three.attachment"
                              :show-todo="true"
                              :value-todo="instance.three.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.three.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 3)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(instance.four.id)"
                          :color="isEmpty(instance.four.id) ? 'primary' : 'success'"
                          :step="4"
                          :style="styleCursorStep(4)"
                          @click="onValidateStep(4)"
                        >
                          Programación de vista de la causa
                        </v-stepper-step>
                        <v-stepper-content step="4">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceFour"
                              v-model="changeInstance.four"
                              name="four"
                              :instance-id="instance.four.id"
                              title-file="Documento*"
                              :value-switch="instance.four.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.four.attachment"
                              :show-todo="true"
                              :value-todo="instance.four.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.four.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 4)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(instance.five.id)"
                          :color="isEmpty(instance.five.id) ? 'primary' : 'success'"
                          :step="5"
                          :style="styleCursorStep(5)"
                          @click="onValidateStep(5)"
                        >
                          Solicitud de uso de palabra
                        </v-stepper-step>
                        <v-stepper-content step="5">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceFive"
                              v-model="changeInstance.five"
                              name="five"
                              :instance-id="instance.five.id"
                              title-file="Documento*"
                              :value-switch="instance.five.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.five.attachment"
                              :show-todo="true"
                              :value-todo="instance.five.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.five.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 5)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(instance.six.id)"
                          :color="isEmpty(instance.six.id) ? 'primary' : 'success'"
                          :step="6"
                          :style="styleCursorStep(6)"
                          @click="onValidateStep(6)"
                        >
                          Sentencia de fondo
                        </v-stepper-step>

                        <v-stepper-content step="6">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              ref="instanceSix"
                              v-model="changeInstance.six"
                              name="six"
                              :instance-id="instance.six.id"
                              title-file="Documento*"
                              :value-switch="instance.six.attachment_disabled"
                              :show-file="true"
                              :value-file="instance.six.attachment"
                              :show-todo="true"
                              :value-todo="instance.six.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="instance.six.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 5)"
                              @update="onUpdateInstance"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- Content -->
              <v-card  v-else class="px-3">
                <v-card-text class="px-0">
                  <v-form
                    ref="formUpdate"
                    v-model="validUpdate"
                    :lazy-validation="lazyUpdate"
                  >
                    <v-row>
                      <v-col cols="6" class="py-0">
                        <v-text-field
                            @input="onChangeRoom(index-1)"
                            v-model="incidencias[index-1].room"
                            class="pt-0 mb-3 input-custom-56"
                            label="Sala"
                            height="56"
                            hide-details
                            outlined
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-0">
                          <v-text-field
                            @input="onChangeRoom(index-1)"
                            v-model="incidencias[index-1].file_code"
                            class="pt-0 mb-3 input-custom-56"
                            label="Código Expediente"
                            height="56"
                            hide-details
                            outlined
                          ></v-text-field>
                        <!--  @input="onChangeFileCode" -->
                      </v-col>
                      <v-col cols="12" class="py-0 mb-3">
                        <v-textarea
                            @input="onChangeComment(index-1)"
                            v-model="incidencias[index-1].comments"
                            class="pt-0"
                            label="Comentarios"
                            hide-details
                            outlined
                            :disabled="disabledOptions"
                          >
                          </v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                  <!--  Inicio secciones -------------------------------------------------->
                  <v-row v-if="incidencias[index-1].id">
                    <v-col cols="12">
                      <v-stepper v-model="incidencias[index-1].activeTab" vertical>
                        <v-stepper-step
                          :complete="!isEmpty(incidencias[index-1].instance_1.id)"
                          :color="isEmpty(incidencias[index-1].instance_1.id) ? 'primary' : 'success'"
                          :step="1"
                          :style="styleCursorStep(1)"
                          @click="onValidateStepDinamico(1, index-1)"
                        >
                        Solicitud de casación
                        </v-stepper-step>
                        <v-stepper-content step="1">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                                v-model="incidencias[index-1].changeInstance.one"
                                :instance-id="incidencias[index-1].instance_1.id"
                                title-file="Documento*"
                                :value-switch="incidencias[index-1].instance_1.attachment_disabled"
                                :show-file="true"
                                :value-file="incidencias[index-1].instance_1.attachment"
                                :show-todo="true"
                                :value-todo="incidencias[index-1].instance_1.pending_tasks"
                                title-textarea="Comentarios"
                                :show-textarea="true"
                                :value-textarea="incidencias[index-1].instance_1.comments"
                                :type-dialog="typeDialog"
                                :disabled-options="disabledOptions"
                                @update-todo="onUpdatePendingTask($event, 1)"
                                @update="(nameInstance, payload) => { incidencias[index-1].instance_1.comments = payload.comment; incidencias[index-1].instance_1.attachment = payload.file }"
                                ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(incidencias[index-1].instance_2.id)"
                          :color="isEmpty(incidencias[index-1].instance_2.id) ? 'primary' : 'success'"
                          :step="2"
                          :style="styleCursorStep(2)"
                          @click="onValidateStepDinamico(2, index-1)"
                        >
                          Solicitud de improcedencia
                        </v-stepper-step>
                        <v-stepper-content step="2">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                                v-model="incidencias[index-1].changeInstance.two"
                                :ref="'instanceTwo' + (index-1)"
                                :name="'two'  + (index-1)"
                                :instance-id="incidencias[index-1].instance_2.id"
                                title-file="Documento*"
                                :value-switch="incidencias[index-1].instance_2.attachment_disabled"
                                :show-file="true"
                                :value-file="incidencias[index-1].instance_2.attachment"
                                :show-todo="true"
                                :value-todo="incidencias[index-1].instance_2.pending_tasks"
                                title-textarea="Comentarios"
                                :show-textarea="true"
                                :value-textarea="incidencias[index-1].instance_2.comments"
                                :type-dialog="typeDialog"
                                :disabled-options="disabledOptions"
                                @update-todo="onUpdatePendingTask($event, 2)"
                                @update="(nameInstance, payload) => { incidencias[index-1].instance_2.comments = payload.comment; incidencias[index-1].instance_2.attachment = payload.file }"
                              ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                            :complete="!isEmpty(incidencias[index-1].instance_3.id)"
                            :color="isEmpty(incidencias[index-1].instance_3.id) ? 'primary' : 'success'"
                            :step="3"
                            :style="styleCursorStep(3)"
                            @click="onValidateStepDinamico(3, index-1)"
                          >
                            Auto de procedencia
                        </v-stepper-step>
                        <v-stepper-content step="3">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              :ref="'instanceThree' + (index-1)"
                              :name="'three'  + (index-1)"
                              v-model="incidencias[index-1].changeInstance.three"
                              :instance-id="incidencias[index-1].instance_3.id"
                              title-file="Documento*"
                              :value-switch="incidencias[index-1].instance_3.attachment_disabled"
                              :show-file="true"
                              :value-file="incidencias[index-1].instance_3.attachment"
                              :show-todo="true"
                              :value-todo="incidencias[index-1].instance_3.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="incidencias[index-1].instance_3.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 3)"
                              @update="(nameInstance, payload) => { incidencias[index-1].instance_3.comments = payload.comment; incidencias[index-1].instance_3.attachment = payload.file }"

                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(incidencias[index-1].instance_4.id)"
                          :color="isEmpty(incidencias[index-1].instance_4.id) ? 'primary' : 'success'"
                          :step="4"
                          :style="styleCursorStep(4)"
                          @click="onValidateStepDinamico(4, index-1)"
                        >
                          Programación de vista de la causa
                        </v-stepper-step>
                        <v-stepper-content step="4">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              :ref="'instanceFour' + (index-1)"
                              :name="'four'  + (index-1)"
                              v-model="incidencias[index-1].changeInstance.four"
                              :instance-id="incidencias[index-1].instance_4.id"
                              title-file="Documento*"
                              :value-switch="incidencias[index-1].instance_4.attachment_disabled"
                              :show-file="true"
                              :value-file="incidencias[index-1].instance_4.attachment"
                              :show-todo="true"
                              :value-todo="incidencias[index-1].instance_4.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="incidencias[index-1].instance_4.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 4)"
                              @update="(nameInstance, payload) => { incidencias[index-1].instance_4.comments = payload.comment; incidencias[index-1].instance_4.attachment = payload.file }"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(incidencias[index-1].instance_5.id)"
                          :color="isEmpty(incidencias[index-1].instance_5.id) ? 'primary' : 'success'"
                          :step="5"
                          :style="styleCursorStep(5)"
                          @click="onValidateStepDinamico(5, index-1)"
                        >
                          Solicitud de uso de palabra
                        </v-stepper-step>
                        <v-stepper-content step="5">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              :ref="'instanceFive' + (index-1)"
                              :name="'five'  + (index-1)"

                              v-model="incidencias[index-1].changeInstance.five"

                              :instance-id="incidencias[index-1].instance_5.id"
                              title-file="Documento*"
                              :value-switch="incidencias[index-1].instance_5.attachment_disabled"
                              :show-file="true"
                              :value-file="incidencias[index-1].instance_5.attachment"
                              :show-todo="true"
                              :value-todo="incidencias[index-1].instance_5.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="incidencias[index-1].instance_5.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 5)"
                              @update="(nameInstance, payload) => { incidencias[index-1].instance_5.comments = payload.comment; incidencias[index-1].instance_5.attachment = payload.file }"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-step
                          :complete="!isEmpty(incidencias[index-1].instance_6.id)"
                          :color="isEmpty(incidencias[index-1].instance_6.id) ? 'primary' : 'success'"
                          :step="6"
                          :style="styleCursorStep(6)"
                          @click="onValidateStepDinamico(6, index-1)"
                        >
                          Sentencia de fondo
                        </v-stepper-step>
                        <v-stepper-content step="6">
                          <v-card-text class="px-0 pt-0">
                            <instance-step
                              :ref="'instanceSix' + (index-1)"
                              :name="'six'  + (index-1)"
                              v-model="incidencias[index-1].changeInstance.six"
                              :instance-id="incidencias[index-1].instance_6.id"
                              title-file="Documento*"
                              :value-switch="incidencias[index-1].instance_6.attachment_disabled"
                              :show-file="true"
                              :value-file="incidencias[index-1].instance_6.attachment"
                              :show-todo="true"
                              :value-todo="incidencias[index-1].instance_6.pending_tasks"
                              title-textarea="Comentarios"
                              :show-textarea="true"
                              :value-textarea="incidencias[index-1].instance_6.comments"
                              :type-dialog="typeDialog"
                              :disabled-options="disabledOptions"
                              @update-todo="onUpdatePendingTask($event, 6)"
                              @update="(nameInstance, payload) => { incidencias[index-1].instance_6.comments = payload.comment; incidencias[index-1].instance_6.attachment = payload.file }"
                            ></instance-step>
                          </v-card-text>
                        </v-stepper-content>

                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </div>
        </v-card>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>
<script>
import { cloneDeep } from "lodash";
import mixinApiModule from "../../modules/mixins/mixinApiModule";
import mixinInstance from "../mixins/mixinInstance";
import mixinCommon from "../mixins/mixinCommon";
import InstanceStep from "@/components/commons/InstanceStep";
import { baseIncidencia } from "@/models";

export default {
  name: "CommonStepThree",
  props: {
    fileCode: {
      type: String,
      default: null,
    },
  },
  components: {
    InstanceStep,
  },
  mixins: [mixinCommon, mixinInstance, mixinApiModule],
  watch: {
    fileCode(newValue) {
      this.file_code = newValue;
    }
  },
  data: () => ({
    stage: 3,
    countStep: 6,
    // form
    inputComment: null,
    selectedRoom: null,
    file_code : '',
    room: "",
    currentTab: 0,
    tab: null,
    items: ['Expediente Principal'],
    active_incidence : false,
    activeTab : 0
  }),
  methods: {
    handleClickClose(index) {
      this.items.splice(index, 1);
      this.currentTab = this.items.length - 1;
      this.incidencias = []
    },
    handleClickAdd() {
      // Acción a realizar cuando se hace clic en el icono
      // Por ejemplo, agregar un nuevo tab
      let me = this
      let nueva_insidencia = new baseIncidencia;
      nueva_insidencia.stage_id = this.selectedItem.id
      this.incidencias.push(nueva_insidencia);
      this.items.push(`Incidencia ${this.items.length}`);

      setTimeout(function(){
        me.currentTab = me.items.length - 1;
        me.tab        =  me.items.length
      }, 200);
    },
    setForm() {
      this.activeTab = 0;
      this.flagEdit = false;

      this.$nextTick(() => {
        /* this.inputComment = this.isEmpty(this.selectedItem) ? null  : cloneDeep(this.selectedItem.comments);
        this.selectedRoom = this.isEmpty(this.selectedItem)  ? null  : cloneDeep(this.selectedItem.room); */
        this.inputComment = this.isEmpty(this.selectedItem)  ? null   : cloneDeep(this.selectedItem.comments);
        this.room         = this.isEmpty(this.selectedItem)  ? null   : cloneDeep(this.selectedItem.room);
        this.file_code    = this.fileCode;

        if(this.selectedItem.incidencias){

            this.selectedItem.incidencias.forEach((incidencia, index) => {
              console.log(incidencia)
              const incidenciaObj = new baseIncidencia(incidencia);
              // Ahora 'incidenciaObj' contiene una instancia de baseIncidencia inicializada con los datos de 'incidencia'
              // Puedes hacer lo que necesites con 'incidenciaObj'
              this.incidencias.push(incidenciaObj);
              this.items.push(`Incidencia ${index + 1}`);
              this.currentTab = index;
            });
            }

            if(this.selectedItem?.instance > 0){
              this.active_incidence = true
            }else{
              this.active_incidence = false
            }

      });

      this.setAllInstances();
    },
    clearForm() {
      this.inputComment = null;
      this.room = null;
      this.items= ['Expediente Principal'];
      this.currentTab= 0;
      this.active_incidence = false
      this.incidencias = []
      Object.assign(this.$data, this.$options.data.call(this));
      this.clearAllInstances();
    },
    async onSubmitStep2() {
      if (!this.flagEdit) return false;
      this.$emit("flag-loading", true);
      const payload = {
        room_id: this.selectedRoom.id,
        comments: this.inputComment,
      };
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/2/create`;
      if (!this.isEmpty(this.selectedId)) {
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/2/update`;
      }
      const { data } = await this.axios.post(url, payload);
      this.$emit("flag-loading", false);
      if (this.isEmpty(this.selectedId)) {
        this.$emit("flag-edit", false);
        this.flagEdit = false;
        this.$emit("change", data.data);
      }
      if (data.success) {
        this.$emit("next", 3);
      }
    },
    async onSubmitStep(flag = true, incidencia_indice = -1) {

      if(incidencia_indice > -1){
        if(!this.incidencias[incidencia_indice].flagEdit) return false;
      }else{
        if (!this.flagEdit) return false;
      }

      this.$emit("flag-loading", true);
      var payload = {
        room: this.room,
        comments: this.inputComment,
        file_code: this.file_code,
      };

      // Crea el stage (expediente principal)
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/create`;

      //Creamos una incidencica, si existe una incidencia con el indice, entonces es una actualizacion
      if(incidencia_indice > -1){
        payload = {
          room  : this.incidencias[incidencia_indice].room,
          comments  : this.incidencias[incidencia_indice].comments,
          file_code : this.incidencias[incidencia_indice].file_code,
          incident : incidencia_indice,
          parent_id: this.incidencias[incidencia_indice].stage_id
        };
        // Se genera la url para crear una incidencia
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/incidencia/create`;
      }

      // Si existe un id, entonces es una actualizacion del stage.
      if (!this.isEmpty(this.selectedId) && incidencia_indice == -1) {
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.id}/stage/3/update`;
      }
      // Si existe la incidencia dentro del array, entonces es una actualizacion de la incidencia
      else if(this.incidencias[incidencia_indice] && this.incidencias[incidencia_indice].id !== null){
        url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.incidencias[incidencia_indice].id}/stage/3/incidencia/update`;
      }

      // Llama al metodo post del mixinApiModule.js
      const { data } = await this.axios.post(url, payload);

      if(incidencia_indice > -1){
        //this.incidencias[incidencia_indice] = new baseIncidencia(data.data);
        //this.incidencias[incidencia_indice].activeTab = 1
        //Le decimos que mantenga la reactividad al minimo cambio
        this.$set(this.incidencias, incidencia_indice, new baseIncidencia(data.data));
        this.incidencias[incidencia_indice].activeTab = 1;
      }else{
        this.activeTab = 1
      }

      this.$emit("flag-loading", false);
      if (data.success) {
        this.$toast.success('Se han guardado los cambios.')
        this.$emit("flag-edit", {'estado' : false, 'posicion' : -1});
        this.flagEdit = false;
        if (this.isEmpty(this.selectedId)) {
          this.$emit("change", data.data);
        }
        if (flag) {
          this.$emit("next", 2);
        }
      }
    },
    onChangeRoom(incidencia_posicion = -1) {
      this.$emit("flag-edit", {'estado' : true, 'posicion' : incidencia_posicion});
      //Sirve para saber si el principal esta para editar
      if(incidencia_posicion > -1){
        this.incidencias[incidencia_posicion].flagEdit = true;
      }else{
        this.flagEdit = true;
      }
    },
    onChangeComment(incidencia_posicion = -1) {
      this.$emit("flag-edit", {'estado' : true, 'posicion' : incidencia_posicion});
      if(incidencia_posicion > -1){
        this.incidencias[incidencia_posicion].flagEdit = true;
      }else{
        this.flagEdit = true;
      }
    },
  },
};
</script>
