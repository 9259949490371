const listApiByModules = {
  permisos: {
    name: "permits",
    children: {
      hidricos: "water",
      "autorizaciones-mineras": "mining_authorization",
      hidrocarburos: "hydrocarbon",
      explosivos: "explosive",
      "insumos-quimicos": "chemical_input",
      "terrenos-erizados-del-estado": "state_ruffled_land",
      telecomunicaciones: "telecommunication",
      "restos-arqueologicos": "archaeological_remain",
      electricidad: "electricity",
      "otros-permisos": "other",
    },
  },
  contratos: {
    name: "contracts",
    children: {
      "terrenos-superficiales": "surface_land",
      proveedores: "supplier",
      "contratistas-mineros": "mining_contractor",
      "convenio-autoridades-publicas": "public_authority_agreement",
      "convenios-comunidades": "community_agreement",
    },
  },
  compromisos: {
    name: "commitments",
    children: {
      "obligaciones-tecnicas": "technical_obligation",
      "obligaciones-formales": "formal_obligation",
      "obligaciones-internas": "internal_obligation",
      "obligaciones-agua": "water_obligation",
      "obligaciones-aire": "air_obligation",
      "obligaciones-biodiversidad": "biodiversity_obligation",
      "obligaciones-emergencias-ambientales": "environmental_emergencies_obligation",
      "obligaciones-explosivos": "explosives_obligation",
      "obligaciones-igas": "igas_obligation",
      "obligaciones-permisos-auto": "auto_permission_obligation",
      "obligaciones-generales": "general_obligation",
      "obligaciones-patrimoniales": "heritage_obligation",
      "obligaciones-hidrocarburos": "hydrocarbons_obligation",
      "obligaciones-reglas-ambientales": "environment_rules_obligation",
      "obligaciones-residulos-solidos": "solid_waste_obligation",
      laboral: "laboral",
    },
  },
  seguridad: {
    name: "occupational_safetys",
    children: {
      laboral: "health",
      "de-la-infraestructura": "infrastructure",
    },
  },
  ambiental: {
    name: "environmentals",
    children: {
      "ficha-tecnica-ambiental": "technical_data_sheet",
      "declaracion-de-impacto-ambiental": "impact_statement",
      "estudio-de-impacto-ambiental-semi-detallado":
        "semi_detailed_impact_study",
      "estudio-de-impacto-ambiental-detallado": "detailed_impact_study",
      "obligaciones-ambientales-generales": "general_obligation",
    },
  },
  supervisiones: {
    name: "supervisions",
    children: {
      "supervisiones-oefa": "oefa",
      "supervisiones-osinergmin": "osinergmin",
      "supervisiones-ana": "ana",
      "supervisiones-sunafil": "sunafil",
    },
  },
  "procesos-judiciales": {
    name: "judicial_processes",
    children: {
      "procesos-judiciales-administrativos": "administrative_contentious",
      "procesos-judiciales-constitucionales": "constitutional_process",
      "procesos-judiciales-civiles": "civil_proceeding",
      "procesos-judiciales-penales": "criminal_proceeding",
    },
  },
  "propiedades-mineras": {
    name: "mining_properties",
    children: {},
  },
};

export default {
  computed: {
    apiByModule() {
      let apiModule = listApiByModules[this.typeModule];
      let submodule = this.isEmpty(apiModule.children)
        ? null
        : apiModule.children[this.typeSubmodule];
      return {
        module: apiModule.name,
        submodule,
      };
    },
  },
};
