<template>
  <div>
    <dialog-detail-judicial-process
      v-if="typeSubmodule !== 'procesos-judiciales-penales'"
      ref="dialogJudicialCard"
      :dialog-new="dialogNew"
      :type-dialog="typeDialog"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
      @create-active="onActiveNewItem"
      @close="onCloseDetailCard"
      @re-fetch="reFetch"
    ></dialog-detail-judicial-process>
    <dialog-detail-criminal-process
      v-else
      ref="dialogCriminalCard"
      :dialog-new="dialogNew"
      :type-dialog="typeDialog"
      :type-module="typeModule"
      :type-submodule="typeSubmodule"
      @create-active="onActiveNewItem"
      @close="onCloseDetailCard"
      @re-fetch="reFetch"
    ></dialog-detail-criminal-process>
  </div>
</template>
<script>
import DialogDetailJudicialProcess from "@/components/judicialprocess/DialogDetailJudicialProcess";
import DialogDetailCriminalProcess from "@/components/judicialprocess/DialogDetailCriminalProcess";

export default {
  name: "DialogDetailBaseProcess",
  components: {
    DialogDetailJudicialProcess,
    DialogDetailCriminalProcess,
  },
  model: { prop: "dialogNew", event: "change" },
  props: {
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    onActiveNewItem(item) {
      this.$emit("create-active", item);
    },

    reFetch(response) {
      this.$emit('re-fetch', 1);
    },

    onCloseDetailCard() {
      this.clearErrors();
      this.$emit("change", false);
    },
    clearErrors() {
      if (this.$refs.dialogJudicialCard) {
        this.$refs.dialogJudicialCard.clearErrors();
      }
      if (this.$refs.dialogCriminalCard) {
        this.$refs.dialogCriminalCard.clearErrors();
      }
    },
    openDialogDetail() {
      if (this.$refs.dialogJudicialCard) {
        this.$refs.dialogJudicialCard.openDialogDetail();
      }
      if (this.$refs.dialogCriminalCard) {
        this.$refs.dialogCriminalCard.openDialogDetail();
      }
    },
    openDialogEdit(item) {
      if (this.$refs.dialogJudicialCard) {
        this.$refs.dialogJudicialCard.openDialogEdit(item);
      }
      if (this.$refs.dialogCriminalCard) {
        this.$refs.dialogCriminalCard.openDialogEdit(item);
      }
    },
  },
};
</script>
