<template>
  <v-col cols="12" md="4">
    <v-card class="mx-auto" elevation="1">
      <v-card-text class="align-center justify-center pb-0">
        <div class="d-flex">
          <span class="d-flex mr-4">{{ item.label }}</span>
          <v-btn
            v-if="
              !isDisabled &&
              item.id !== 'MTGENERALMODULE' &&
              item.id !== 'MTGENERALSUBMODULE'
            "
            text
            icon
            color="blue"
            x-small
            class="my-0"
            @click="onSelectedUpdate('master')"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="px-4 pb-3">
        <v-autocomplete
          :items="item.options"
          autocomplete="no"
          class="pt-0 input-custom-56"
          height="56"
          item-text="label"
          item-value="id"
          placeholder="Opciones"
          clearable
          outlined
          :disabled="isDisabled"
          :loading="loadOptions"
          hide-details
          return-object
          @change="onChangeSelect"
        ></v-autocomplete>
        <v-btn
          v-if="!isDisabled"
          text
          icon
          color="blue"
          class="ml-4 my-0 py-0"
          @click="openDialogOption()"
        >
          <v-icon>edit</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialogUpdate"
      content-class="dialog-custom"
      persistent
      scrollable
      max-width="450"
      @keydown.esc="onCloseDialogUpdate()"
    >
      <v-card>
        <v-card-title class="py-2">
          <span class="subheading"
            >Editar {{ typeDialogEdit === "master" ? "" : " Opcion" }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-row v-if="selectedMaster">
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="selectedMaster.label"
                class="pt-0 input-custom-56"
                height="56"
                label="Nombre*"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <!--
            <v-col v-if="typeDialogEdit === 'option'" cols="12" class="py-0">
              <v-text-field
                v-model="selectedMaster.slug"
                label="Slug*"
                class="pt-0 input-custom-56"
                height="56"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            text
            small
            @click="onCloseDialogUpdate()"
            >Cerrar</v-btn
          >
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            @click="onSubmitMaster()"
          >
            <v-progress-circular
              v-if="loadUpdate"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{ loadSubmit ? "Guardando" : "Guardar" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDetailMaster"
      content-class="dialog-custom"
      max-width="550"
      persistent
      @keydown.esc="onCloseDialogDetail()"
    >
      <v-card>
        <v-card-title class="py-2">
          <span class="subheading">Editar Opciones</span>
        </v-card-title>
        <v-card-text>
          <h4 class="mb-0 font-weight-bold pt-2">{{ label }}</h4>
          <p class="caption mb-0">{{ item.label }}</p>
          <div class="clearfix text-right">
            <v-btn
              v-if="
                item.id !== 'MTGENERALMODULE' &&
                item.id !== 'MTGENERALSUBMODULE'
              "
              class="btn-transform-none"
              depressed
              small
              @click="dialogCreate = true"
            >
              <v-icon>add</v-icon>
              Agregar opción
            </v-btn>
          </div>
          <v-data-table
            ref="sortableTable"
            :headers="headers"
            :items="item.options"
            :items-per-page="-1"
            hide-default-footer
            item-key="id"
            class="table-striped elevation-0"
          >
            <template v-slot:headers="{ props: { headers } }">
              <thead>
                <tr>
                  <th v-for="header in headers" :key="header.value">
                    <span :key="`span_${header.value}`">{{ header.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="option in items"
                  :key="itemKey(option)"
                  class="sortableRow"
                >
                  <td
                    style="cursor: move"
                    class="sortHandle td-hover"
                    @click="onSelectedUpdate('option', option)"
                  >
                    {{ option.id }}
                  </td>
                  <td
                    style="cursor: move"
                    class="sortHandle td-hover"
                    @click="onSelectedUpdate('option', option)"
                  >
                    {{ option.label }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            text
            small
            @click="onCloseDialogDetail()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCreate"
      content-class="dialog-custom"
      max-width="450"
      persistent
      @keydown.esc="onCloseDialogCreate()"
    >
      <v-card>
        <v-card-title class="py-2">
          <span class="subheading">Agregar opción</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="selectedOption.label"
                class="pt-0 mb-5 input-custom-56"
                height="56"
                label="Nombre*"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <!--
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="selectedOption.slug"
                class="pt-0 input-custom-56"
                height="56"
                label="Slug*"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-transform-none"
            text
            small
            @click="onCloseDialogCreate()"
            >Cerrar</v-btn
          >
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            @click="dialogQuestion = true"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogQuestion"
      content-class="dialog-custom"
      max-width="400"
      persistent
      @keydown.esc="dialogQuestion = false"
    >
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">¿Estás seguro que deseas agregar una opción?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="btn-transform-none"
            :disabled="loadSubmit"
            text
            small
            @click="onCloseDialogQuestion()"
            >Descartar</v-btn
          >
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            @click="onSubmitMasterOption()"
          >
            <v-progress-circular
              v-if="loadSubmit"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{ loadSubmit ? "Guardando" : "Guardar" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import Sortable from "sortablejs";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { IMastersOption } from "@/models";

export default {
  name: "MasterGroupCard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    typeMaster: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    itemKeys: new WeakMap(),
    currentItemKey: 0,
    selectedOption: new IMastersOption(),
    disabledEdit: false,
    selectedMaster: null,
    dialogCreate: false,
    dialogUpdate: false,
    dialogDetailMaster: false,
    loadUpdate: false,
    headers: [
      { text: "Id", sortable: false, value: "id" },
      { text: "Nombre", sortable: false, value: "label" },
    ],
    mastersOption: [],
    loadOptions: false,
    loadSubmit: false,
    typeDialogEdit: "master",
    dialogQuestion: false,
    loadQuestion: false,
  }),
  watch: {
    "item.options": {
      deep: true,
      handler(val) {
        const ids = val.map((e) => e.id);
        this.onSubmitPositions(ids);
      },
    },
  },
  methods: {
    ...mapActions([
      "editModule",
      "editSubmodule",
      "editMasters",
      "editMasterOptions",
      "createMasterOptions",
    ]),
    async onSubmitPositions(ids) {
      await this.axios.post(`/api/masters/${this.item.id}/update/order`, {
        ids,
      });
    },
    onCloseDialogQuestion() {
      this.dialogQuestion = false;
      this.dialogCreate = false;
      this.selectedOption = new IMastersOption();
    },
    setLoadOptions(flag) {
      this.loadOptions = flag;
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.item.options.splice(oldIndex, 1)[0];
      this.item.options.splice(newIndex, 0, movedItem);
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    onSubmitMasterOption() {
      if (this.loadSubmit) return false;
      this.loadSubmit = true;
      this.selectedOption.master_id = this.item.id;
      this.createMasterOptions(this.selectedOption)
        .then((resp) => {
          this.item.options.push(resp);
          this.loadSubmit = false;
          this.dialogCreate = false;
          setTimeout(() => {
            this.selectedOption = new IMastersOption();
          }, 600);
        })
        .catch(() => {
          this.dialogQuestion = false;
          this.loadSubmit = false;
          this.dialogCreate = false;
        });
    },
    onSelectedUpdate(type = "master", item = null) {
      this.typeDialogEdit = type;
      this.selectedMaster = type === "master" ? cloneDeep(this.item) : item;
      this.dialogUpdate = true;
    },
    onSubmitMaster() {
      if (this.loadUpdate) return false;
      if (
        this.typeDialogEdit === "master" ||
        this.item.isModules ||
        this.item.isSubmodules
      ) {
        let payload = {
          id: this.selectedMaster.id,
          payload: {
            // master_id: this.selectedMaster.id,
            label: this.selectedMaster.label,
          },
        };
        if (this.item.isModules) {
          payload.payload["module_id"] = this.selectedMaster.id;
        } else if (this.item.isSubmodules) {
          payload.payload["submodule_id"] = this.selectedMaster.id;
        } else {
          payload.payload["master_id"] = this.selectedMaster.id;
        }
        this.loadUpdate = true;
        this.validateMaster(payload)
          .then(() => {
            this.$set(this.item, "label", this.selectedMaster.label);
            this.loadUpdate = false;
            this.dialogUpdate = false;
          })
          .catch(() => {
            this.loadUpdate = false;
            this.dialogUpdate = false;
          });
      } else {
        const payload = {
          id: this.selectedMaster.id,
          payload: {
            master_id: this.selectedMaster.master_id,
            master_option_id: this.selectedMaster.id,
            label: this.selectedMaster.label,
            order: parseInt(`${this.selectedMaster.order}`),
          },
        };
        this.loadUpdate = true;
        this.editMasterOptions(payload)
          .then(() => {
            this.loadUpdate = false;
            this.dialogUpdate = false;
          })
          .catch(() => {
            this.loadUpdate = false;
            this.dialogUpdate = false;
          });
      }
    },
    validateMaster(payload) {
      if (this.item.isModules) {
        return this.editModule(payload);
      } else if (this.item.isSubmodules) {
        return this.editSubmodule(payload);
      } else {
        return this.editMasters(payload);
      }
    },
    openDialogOption() {
      this.dialogDetailMaster = true;
      this.$nextTick(() => {
        new Sortable(
          this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0],
          {
            draggable: ".sortableRow",
            handle: ".sortHandle",
            onEnd: this.dragReorder,
          },
        );
      });
    },
    onCloseDialogUpdate() {
      this.selectedMaster = null;
      this.dialogUpdate = false;
      this.loadUpdate = false;
    },
    onCloseDialogDetail() {
      this.dialogDetailMaster = false;
      this.selectedMaster = null;
    },
    onCloseDialogCreate() {
      this.dialogCreate = false;
      this.selectedOption = new IMastersOption();
    },
    onChangeSelect(item) {
      if (this.typeMaster) {
        this.$emit("change", item);
      }
    },
  },
};
</script>
