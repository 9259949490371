<template>
  <v-row class="checklist-actitivy__item">
    <v-col cols="12" class="py-0">
      <p class="mb-0 body-2">
        <b>{{
          `${activityItem.responsible.first_name} ${activityItem.responsible.last_name}`
        }}</b>
        <span class="caption ml-2">{{
          activityItem.updated_at | parseDate
        }}</span>
      </p>
      <v-card
        class="mx-auto"
        outlined
        :elevation="0"
        :class="{
          'comment-color': !flagEdit,
        }"
      >
        <v-card-text
          class="checklist-actitivy__comment pa-2"
          :class="{
            'd-files': activityItem.attachments.length,
            'comment-color': !flagEdit,
          }"
        >
          <div v-show="flagEdit" class="block-comment">
            <content-editable
              ref="vContentEditable"
              v-model="comment"
              class="block-comment__editable"
              tag="div"
              :contenteditable="true"
              :no-n-l="true"
            />
            <span v-if="isEmpty(comment)" class="block-comment__placeholder"
              >Escribe un comentario</span
            >
          </div>
          <div
            v-show="!flagEdit"
            class="block-comment"
            v-html="activityItem.activity"
          ></div>
          <v-btn
            v-if="activityItem.attachments.length"
            :class="{ 'only-file': isEmpty(comment) && !flagEdit }"
            text
            icon
            small
            color="primary"
            :ripple="false"
            @click="dialogFiles = true"
          >
            <v-icon>attachment</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card-text
        v-if="currentUser.id === activity.responsible_id"
        class="pt-0 mt-0 px-0 pb-2"
      >
        <v-btn
          v-if="flagEdit"
          text
          small
          height="20"
          class="btn-transform-none"
          @click="flagEdit = false"
          >Cerrar</v-btn
        >
        <v-btn
          text
          small
          height="20"
          class="btn-transform-none"
          :loading="loadEdit"
          @click="onEditActivity()"
        >
          Editar</v-btn
        >
        <v-btn
          text
          small
          height="20"
          color="primary"
          class="btn-transform-none"
          :loading="loadDeleteActivity"
          @click="onDeleteActivity"
          >Eliminar</v-btn
        >
      </v-card-text>
    </v-col>

    <v-dialog
      v-model="dialogFiles"
      content-class="checklist-actitivy__dialog"
      persistent
      open-on-focus
      max-width="450"
    >
      <v-card>
        <v-card-title class="py-1 title-detail-todo">
          <div class="subheading mb-0" style="width: 100%;">
            Adjuntos
          </div>
        </v-card-title>
        <v-card-text class="pt-3">
          <ul class="checklist-actitivy__files mt-0">
            <li
              v-for="(item, index) in activityItem.attachments"
              :key="index"
              class="checklist-actitivy__file"
            >
              <a :href="formatFileURL(item.url)" target="_blank">{{
                getNameFile(item.url)
              }}</a>
              <v-btn
                text
                icon
                small
                :ripple="false"
                @click="onDeleteFile(item, index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="btn-transform-none"
            text
            small
            @click="dialogFiles = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import * as moment from "moment";
import ContentEditable from "@/components/commons/ContentEditable";
import { mapState } from "vuex";

export default {
  name: "ActivityItem",
  components: {
    ContentEditable,
  },
  filters: {
    parseDate(timestamp) {
      return moment(timestamp * 1000)
        .locale("es")
        .fromNow();
    },
  },
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    activityItem: null,
    comment: null,
    loadDeleteActivity: false,
    showActions: false,
    dialogFiles: false,
    isEditable: false,
    loadAttach: false,
    loadEdit: false,
    flagEdit: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.session.currentUser,
    }),
  },
  created() {
    this.activityItem = this.activity;
  },
  mounted() {
    this.comment = this.activityItem.activity;
  },
  methods: {
    formatFileURL(url) {
      if (url.includes("http")) {
        return url;
      }
      return `${this.baseURL}/${url}`;
    },
    onEditActivity() {
      if (this.flagEdit) {
        if (this.loadEdit) {
          return false;
        }
        this.loadEdit = true;
        const payload = {
          to_do_activity_id: this.activity.id,
          activity: this.comment,
        };
        this.axios
          .post(`/api/to_do_activities/${this.activity.id}/update`, payload)
          .then(({ data }) => {
            this.loadEdit = false;
          });
      } else {
        this.flagEdit = true;
        this.$nextTick(() => {
          this.$refs.vContentEditable.onFocusDiv();
        });
      }
    },
    onDeleteActivity() {
      this.loadAttach = true;
      this.axios
        .delete(`/api/to_do_activities/${this.activity.id}/delete`)
        .then(({ data }) => {
          this.loadAttach = false;
          if (data.success) {
            this.$emit("delete", this.activityItem);
          }
        });
    },
    onDeleteFile(item, index) {
      this.loadDeleteActivity = true;
      this.axios
        .delete(`/api/to_do_attachments/${item.id}/delete`)
        .then(({ data }) => {
          this.loadDeleteActivity = false;
          if (data.success) {
            this.activityItem.splice(index, 1);
          }
        });
    },
    getNameFile(url) {
      const arr = url.split("/");
      return arr[arr.length - 1];
    },
  },
};
</script>
