<template>
  <div
    class="v-datatable table-action-fixed v-table"
    :class="{ 'table-mobile': isMobile }"
    style="border-top: 1px solid rgba(0, 0, 0, 0.1);"
  >
    <div class="v-datatable__actions">
      <v-container v-if="showContent" fluid class="pt-3 pb-0">
        <v-row>
          <!-- <template v-if="!isTodo">
            <v-col cols="3" class="py-0">
              <div class="v-datatable__actions__pagination body-2">
                Vigentes: <span>{{ metaData.total_situation_active }}</span>
              </div>
            </v-col>
            <v-col cols="3" class="py-0">
              <div class="v-datatable__actions__pagination body-2">
                Vencidas: <span>{{ metaData.total_situation_expired }}</span>
              </div>
            </v-col>
            <v-col cols="3" class="py-0">
              <div class="v-datatable__actions__pagination body-2">
                En proceso:
                <span>{{ metaData.total_situation_in_process }}</span>
              </div>
            </v-col>
          </template>! -->
          <!-- <template v-else>
            <v-col v-if="!hidePages" cols="6" class="py-0">
              <div class="v-datatable__actions__pagination body-2">
                Pagina: <span>{{ metaData.current_page }}</span>
              </div>
            </v-col>
          </template> !-->
          <v-col
            :cols="!isTodo ? 3 : !hidePages ? 6 : 12"
            class="py-0"
            :class="{ 'text-right': hidePages }"
          >
            <div class="v-datatable__actions__pagination body-2">
              <template v-if="totalTodo">
                <span>{{ !isTodo ? totalSituation : metaData.total }} </span
                >resultados
              </template>
              <template v-else>
                Fichas totales:
                <span>{{ !isTodo ? totalSituation : this.totalCount }}</span>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "ModuleDetailTable",
  data: function()  {
    return {
      isMobile,
    }
  },
  props: {
    showContent: {
      type: Boolean,
      default: true,
    },
    totalCount: {
      type: Number,
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
    totalTodo: {
      type: Boolean,
      default: false,
    },
    hidePages: {
      type: Boolean,
      default: false,
    },
    metaData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    totalSituation() {
      if (!this.isEmpty(this.metaData)) {
        const {
          total_situation_active,
          total_situation_expired,
          total_situation_in_process,
        } = this.metaData;
        return (
          total_situation_active +
          total_situation_expired +
          total_situation_in_process
        );
      }
      return 0;
    },
  },
};
</script>
