<template>
  <v-card class="elevation-0" style="min-width: 300px;">
    <v-card-text class="pa-0">
      <template v-if="loadNotification">
        <div class="block-notifications">
          <div v-if="notifications.length" class="block-notifications__scroll">
            <vue-perfect-scrollbar
              ref="perfectScrollbar"
              style="width: 100%;overflow: auto;height: 350px;"
              :settings="scrollSettings"
            >
              <block-notifications
                class="pa-0"
                :notifications="notifications"
              ></block-notifications>
            </vue-perfect-scrollbar>
          </div>
          <div v-else>
            <v-col cols="12" class="px-0 text-center">
              <span class="d-inline-block primary--text caption"
                >No tiene notificaciones</span
              >
            </v-col>
          </div>
        </div>
      </template>
      <template v-else>
        <v-col cols="12" class="px-0 text-center">
          <v-progress-circular
            :size="22"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <span class="d-inline-block primary--text caption"
            >Cargando notificaciones</span
          >
        </v-col>
      </template>
      <v-divider></v-divider>
      <v-btn
        color="action"
        :dark="loadNotification"
        block
        class="ma-0"
        style="border-radius: 0;"
        :disabled="!loadNotification"
        @click="goToNotifications()"
        >Ver todos</v-btn
      >
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import BlockNotifications from "../components/users/BlockNotifications";
import { MenuAdmin } from "@/api/menu";

export default {
  name: "NotificationList",
  components: {
    VuePerfectScrollbar,
    BlockNotifications,
  },
  data: () => ({
    scrollSettings: {
      maxScrollbarLength: 160,
      suppressScrollX: true,
    },
    selectedItem: null,
    dialogCard: false,
    dialogTodo: false,
    typeModule: null,
    typeSubmodule: null,
    notifications: [],
    selectedSpij: {
      spij_url: "",
      spij_user: "",
      spij_password: "",
    },
    loadNotification: false,
  }),
  created() {
    this.fetchNotifications();
  },
  methods: {
    fetchNotifications() {
      this.loadNotification = false;
      this.axios
        .get("/api/notifications/latest")
        .then(({ data }) => {
          this.loadNotification = true;
          if (data.success) {
            this.notifications = data.data;
          }
        })
        .catch(() => {
          this.loadNotification = true;
          this.notifications = [];
        });
    },
    goToNotifications() {
      this.$store.dispatch(`menuAdmin/setMenu`, {
        menu: MenuAdmin,
        init: true,
        index: 0,
      });
      this.$store.dispatch(`menuAdmin/setMenuActive`, null);
      this.$store.dispatch(`menuAdmin/setListActive`, {
        active: null,
        init: true,
        index: 0,
      });
      this.$router.push({ name: "NotificationsPage" });
    },
  },
};
</script>
