<template>
  <v-card class="px-3">
    <v-card-text class="px-0">
      <v-form
        ref="formUpdate"
        v-model="validUpdate"
        :lazy-validation="lazyUpdate"
      >
        <v-row>
          <v-col cols="4" class="py-0 mb-3">
            <v-menu
              v-model="showDateVisit"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  slot="activator"
                  v-model="dateFormattedVisit"
                  label="Fecha de visita"
                  class="my-0 pt-0 input-custom-56"
                  style="margin-bottom: 0 !important;"
                  outlined
                  height="56"
                  :disabled="!disabledOptions"
                  :hide-details="isEmpty(listError.visit_date)"
                  :rules="rulesDateVisit"
                  append-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateVisit"
                no-title
                locale="es"
                scrollable
                reactive
                :show-current="true"
                :allowed-dates="allowedDates"
                @input="onChangeDateVisit"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="py-0 mb-3">
            <v-row>
              <v-col cols="12" class="py-0 mb-3">
                <p class="mb-1 card-input-title">Acta de supervisión*</p>
                <upload-file
                  ref="supervisory_act"
                  :item-file="fileSupervisoryAct"
                  :disabled="!disabledOptions"
                  :is-multiple="false"
                  :text-error="listError.supervisory_act"
                  :is-table="true"
                  @update="onChangeFile($event, 'supervisory_act')"
                ></upload-file>
              </v-col>
              <v-col cols="12" class="py-0 mb-3">
                <p class="mb-1 card-input-title">
                  Requerimiento de información*
                </p>
                <upload-file
                  :item-file="fileRequestForInformation"
                  :disabled="!disabledOptions"
                  :is-multiple="false"
                  :text-error="listError.request_for_information"
                  :is-table="true"
                  @update="onChangeFile($event, 'request_for_information')"
                ></upload-file>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="mb-1 card-input-title">
                  Cargo de cumplimiento de requerimiento
                </p>
                <upload-file
                  :item-file="fileRequirementComplianceCharge"
                  :disabled="!disabledOptions"
                  :is-multiple="false"
                  :text-error="listError.requirement_compliance_charge"
                  :is-table="true"
                  @update="
                    onChangeFile($event, 'requirement_compliance_charge')
                  "
                ></upload-file>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0 mt-4 mb-3">
            <v-divider></v-divider>
          </v-col>
          <check-list-task
            :id="selectedItem.id"
            ref="checklist"
            class="mb-0"
            page="supervision"
            :tasks="pendingTasks"
            :type="typeDialog"
            :type-module="typeModule"
            :type-submodule="typeSubmodule"
            :disabled="!disabledOptions"
            @update="onChangePendingTask"
            @todo-step="onUpdatePendingTask"
          ></check-list-task>
          <v-col cols="12" class="py-0 mt-3 mb-5">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-textarea
              v-model="inputPreventiveMeasures"
              class="pt-0 mb-0"
              hide-details
              outlined
              label="Medidas Preventivas (Detallar)"
              :disabled="!disabledOptions"
              @input="onChangePreventiveMeasures"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
      <template v-if="id">
        <v-spacer></v-spacer>
        <v-btn
          class="btn-transform-none"
          color="action"
          dark
          small
          depressed
          @click="$emit('next', 2)"
        >
          Ir a etapa de supervisión
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script>
import mixinApiModule from "../../modules/mixins/mixinApiModule";
import mixinCommon from "../mixins/mixinCommon";
import CheckListTask from "../../commons/CheckListTask";
import UploadFile from "../../commons/UploadFile";
import { isEqual, cloneDeep } from "lodash";

export default {
  name: "StepOne",
  components: {
    CheckListTask,
    UploadFile,
  },
  mixins: [mixinCommon, mixinApiModule],
  data: () => ({
    // form
    auxPendingTasks: [],
    pendingTasks: [],
    flagEdit: false,
    dateVisit: null,
    showDateVisit: false,
    fileRequestForInformation: null,
    fileRequestForInformationDisabled: 0,
    fileSupervisoryAct: null,
    fileRequirementComplianceCharge: null,
    inputPreventiveMeasures: null,
    // rulesDate
    rulesSupervisoryAct: [],
    rulesRequestInformation: [],
    rulesDateVisit: [],
    listError: {
      visit_date: "",
      supervisory_act: "",
      request_for_information: "",
      requirement_compliance_charge: "",
      preventive_measures: "",
    },
  }),
  computed: {
    dateFormattedVisit() {
      return this.formatDate(this.dateVisit);
    },
  },
  methods: {
    validateSubmitStep() {
      if (this.isEmpty(this.selectedItem.id)) {
        this.onSubmitStep();
      } else {
        this.validateEdit();
      }
    },
    validateEdit() {
      const equalTasks = isEqual(
        this.pendingTasks,
        this.selectedItem.pending_tasks,
      );
      const equalDate = isEqual(this.dateVisit, this.selectedItem.visit_date);
      const equalFile = isEqual(
        this.fileRequestForInformation,
        this.selectedItem.request_for_information,
      );
      // const equalToggleFile = isEqual(
      //   this.fileRequestForInformationDisabled,
      //   this.selectedItem.request_for_information_disabled,
      // );
      const equalFile2 = isEqual(
        this.fileRequirementComplianceCharge,
        this.selectedItem.requirement_compliance_charge,
      );
      const equalInput = isEqual(
        this.inputPreventiveMeasures,
        this.selectedItem.preventive_measures,
      );
      if (
        equalTasks &&
        equalDate &&
        equalFile &&
        // equalToggleFile &&
        equalFile2 &&
        equalInput
      ) {
        this.$emit("next", 2);
      } else {
        this.onSubmitStep();
      }
    },
    async onSubmitStep(parentId = null) {
      let flagCreate = true;
      parentId = this.id ? this.id : parentId;
      const formData = new FormData();
      formData.append("visit_date", this.validateDateFormat(this.dateVisit));
      formData.append("supervisory_act", this.fileSupervisoryAct || "");
      // formData.append(
      //   "request_for_information_disabled",
      //   this.fileRequestForInformationDisabled ? 1 : 0,
      // );
      formData.append(
        "request_for_information",
        this.fileRequestForInformation || "",
      );
      formData.append(
        "requirement_compliance_charge",
        this.fileRequirementComplianceCharge || "",
      );
      formData.append("preventive_measures", this.inputPreventiveMeasures);
      let url = `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${parentId}/stage/1/create`;
      if (!this.isEmpty(this.selectedItem.id)) {
        formData.append("model_id", this.id);
        url = `/api/${this.apiByModule.module}/stage/1/${this.selectedItem.id}/update`;
        flagCreate = false;
      }
      const { data } = await this.axios({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.success) {
        this.$toast.success(`La ficha ha sido actualizada correctamente.`);
        if (this.auxPendingTasks.length) {
          this.loopToDoStep(data.data.id, this.auxPendingTasks, 0);
          this.$emit("change", data.data);
        } else {
          this.$emit("change", data.data);
        }
        if (flagCreate) {
          this.$emit("next", 2);
        }
        this.$emit("flag-edit", false);
        this.flagEdit = false;
      } else {
        this.listErrorSection(data.error.data);
      }
    },
    listErrorSection(err) {
      this.$nextTick(() => {
        this.rulesDateVisit = [
          (v) => !this.isEmpty(v) || this.listError.visit_date,
        ];
      });
      const {
        visit_date = null,
        supervisory_act = null,
        request_for_information = null,
        requirement_compliance_charge = null,
      } = err;
      this.listError.visit_date = visit_date ? visit_date[0] : "";
      this.listError.supervisory_act = supervisory_act
        ? supervisory_act[0]
        : "";
      this.listError.request_for_information = request_for_information
        ? request_for_information[0]
        : "";
      this.listError.requirement_compliance_charge = requirement_compliance_charge
        ? requirement_compliance_charge[0]
        : "";
      this.$refs.formUpdate.validate();
      this.$refs.supervisory_act.validateError(err);
    },
    onUpdatePendingTask(item) {
      this.$refs.checklist.updateLoadApp(true);
      this.loopToDoStep(item.entity_id, [item], 0, true);
    },
    loopToDoStep(id, checklist, index, flagChecklist = false) {
      if (index === checklist.length) {
        this.$refs.checklist.updateLoadApp(false);
        this.auxPendingTasks = [];
        return false;
      }
      const item = checklist[index];
      item.entity_id = id;
      delete item.responsible;
      this.axios
        .post(
          `/api/${this.apiByModule.module}/stage/1/${this.id}/to_do/create`,
          item,
        )
        .then(({ data }) => {
          if (flagChecklist) {
            this.$nextTick(() => {
              this.pendingTasks.push(data.data);
            });
          }
          // this.$refs.checklist.updateTasks(data);
          this.loopToDoStep(id, checklist, index + 1, flagChecklist);
          // this.$set(this.selectedItem.pending_tasks, 0, data);
        });
    },
    setForm() {
      this.rulesDateVisit = [];
      this.$nextTick(() => {
        this.pendingTasks = this.isEmpty(this.selectedItem)
          ? []
          : cloneDeep(this.selectedItem.pending_tasks);
        this.dateVisit = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.visit_date);
        this.fileRequestForInformation = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.request_for_information);
        this.fileSupervisoryAct = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.supervisory_act);
        this.fileRequirementComplianceCharge = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.requirement_compliance_charge);
        this.inputPreventiveMeasures = this.isEmpty(this.selectedItem)
          ? null
          : cloneDeep(this.selectedItem.preventive_measures);
      });
    },
    onChangeFile($event, type) {
      this.$emit("flag-edit", true);
      if (type === "supervisory_act") {
        this.listError.supervisory_act = null;
        this.fileSupervisoryAct = $event;
      } else if (type === "request_for_information") {
        this.listError.request_for_information = null;
        this.fileRequestForInformation = $event;
      } else if (type === "requirement_compliance_charge") {
        this.listError.requirement_compliance_charge = null;
        this.fileRequirementComplianceCharge = $event;
      }
    },
    onChangeDateVisit() {
      this.$emit("flag-edit", true);
      this.flagEdit = true;
      this.listError.visit_date = null;
      this.showDateVisit = false;
    },
    onChangePreventiveMeasures() {
      this.$emit("flag-edit", true);
      this.flagEdit = true;
      this.listError.preventive_measures = null;
    },
    onChangePendingTask(task) {
      this.$emit("flag-edit", true);
      this.flagEdit = true;
      this.auxPendingTasks = task;
      this.listError.pending_tasks = null;
    },
    clearForm() {
      this.$emit("flag-edit", false);
      this.flagEdit = false;
      this.dateVisit = null;
      this.showDateVisit = false;
      this.fileRequestForInformation = null;
      // this.fileRequestForInformationDisabled = 0;
      this.fileSupervisoryAct = null;
      this.fileRequirementComplianceCharge = null;
      this.inputPreventiveMeasures = null;
    },
    formatDate(date) {
      if (!date) return null;
      date = date.split(" ");
      const [year, month, day] = date[0].split("-");
      return `${day}/${month}/${year}`;
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>
