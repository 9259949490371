<template>
  <v-navigation-drawer
    class="sectionDrawer"
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: 100% !important;"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll"
      :settings="scrollSettings"
    >
      <div class="block-filter">
        <template v-if="pathRoute.name === 'UserPage'">
          <v-text-field
            v-model="searchText"
            placeholder="Buscar"
            height="56"
            outlined
            class="pt-0 input-custom-56"
            hide-details
            append-icon="search"
            @input="debounceChange"
          ></v-text-field>
        </template>
        <v-row>
          <v-col cols="12" class="mb-2 py-0">
            <span class="font-weight-bold">Filtro</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-if="pathRoute.name !== 'UserPage'"
              v-model="selectedProject"
              autocomplete="no"
              class="pt-0 input-custom-56"
              height="56"
              label="Proyecto"
              item-text="label"
              item-value="id"
              placeholder="Seleccione proyecto"
              clearable
              outlined
              hide-details
              return-object
              :items="listProjects.options"
              @input="debounceChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-model="selectedRol"
              autocomplete="no"
              class="pt-0 input-custom-56"
              height="56"
              label="Roles"
              item-text="label"
              item-value="id"
              placeholder="Seleccione rol"
              clearable
              outlined
              hide-details
              return-object
              :items="optionsRoles"
              @input="debounceChange"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <v-btn
              class="ma-0"
              color="action"
              dark
              depressed
              small
              block
              @click="clearFilters()"
              >Limpiar
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import { debounce, isEmpty } from "lodash";

export default {
  name: "UserDrawer",
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    searchText: "",
    selectedRol: null,
    selectedAbilities: null,
    selectedProject: null,
    disabledBtn: true,
    disabledOptions: true,
    optionChart: {},
    optionsRoles: [],
    optionsAbilities: [],
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    checkAlert: false,
    pathRoute: {},
    debounceChange: null,
  }),
  computed: {
    ...mapState({
      // pages: state => state.waterPermit.pages,
      listUser: (state) => state.users.listUser,
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
      filterData: (state) => state.waterPermit.filterData,
      sortUser: (state) => state.users.sort,
    }),
  },
  watch: {
    $route(val) {
      this.pathRoute = val;
    },
  },
  created() {
    this.pathRoute = this.$route;
    // this.$store.dispatch("users/setActiveSearch", false);
  },
  mounted() {
    this.debounceChange = debounce(this.onChangeFilter, 900);
    this.fetchRoles();
    this.fetchAbilities();
  },
  methods: {
    ...mapActions([
      "setRoles",
      "setAbilities",
      "fetchUsers",
      "clearUsers",
      "updatePageUsers",
      "updateFilterUsers",
    ]),
    async fetchRoles() {
      const { data } = await this.axios.get(`/api/users/roles`);
      this.optionsRoles = data.success ? data.data : [];
      this.setRoles(this.optionsRoles);
    },
    async fetchAbilities() {
      const { data } = await this.axios.get(`/api/users/abilities`);
      this.optionsAbilities = data.success ? data.data : [];
      this.setAbilities(this.optionsAbilities);
    },
    onSearch() {
      let payload = "?response=lite&page=1";
      let filterData = "";
      this.updatePageUsers(1);
      this.clearUsers();
      if (!isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!isEmpty(this.selectedResponsible)) {
        filterData += `&responsible_id=${this.selectedResponsible.id}`;
      }
      if (!isEmpty(this.selectedSituation)) {
        filterData += `&state_id=${this.selectedSituation.id}`;
      }
      if (!isEmpty(this.selectedRol)) {
        filterData += `&role_id=${this.selectedRol.id}`;
      }
      if (!isEmpty(this.selectedAbilities)) {
        filterData += `&ability_id=${this.selectedAbilities.id}`;
      }
      if (!isEmpty(this.searchText)) {
        filterData += `&search=${this.searchText}`;
      }
      if (this.sortUser) {
        filterData += `&sort=${this.sortUser}`;
      }
      this.loadSearch = true;
      payload += filterData;
      this.fetchUsers({ payload, step: true })
        .then(() => {
          this.updateFilterUsers(filterData);
          this.loadSearch = false;
        })
        .catch(() => {
          this.loadSearch = false;
        });
    },
    onChangeFilter() {
      this.onSearch();
    },
    clearFilters() {
      this.searchText = "";
      this.selectedRol = null;
      this.selectedAbilities = null;
      this.selectedProject = null;
      this.updateFilterUsers("");
    },
  },
};
</script>
