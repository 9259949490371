import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: {},
    },
    options: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    chartData() {
      this.$data._chart.update();
      // this.renderChartReport();
    },
  },
  mounted() {
    // this.$data._chart.destroy();
    this.renderChartReport();
  },
  methods: {
    renderChartReport() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        title: {
          display: true,
          text: this.title,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        height: 450,
      };
      // this.$data._chart.destroy();
      this.renderChart(this.chartData, options);
      // this.$nextTick(() => {
      //   this.$data._chart.update();
      // });
    },
  },
};
