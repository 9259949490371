<template>
  <v-navigation-drawer
    class="sectionDrawer"
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: 100% !important;"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll block-filter"
      :settings="scrollSettings"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="searchText"
            placeholder="Buscar por palabra"
            class="input-custom-56"
            height="56"
            append-icon="search"
            outlined
            hide-details
            @input="debounceChange"
            v-on="on"
          ></v-text-field>
        </template>
        <span v-html="generateTooltipSearch(typeModule)"></span>
      </v-tooltip>
      <v-row>
        <v-col cols="12" class="mb-2 py-0">
          <span class="font-weight-bold">Filtro</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="inputCode"
            class="mt-0 pt-0 input-custom-56"
            label="Código"
            placeholder="Ingrese código"
            height="56"
            outlined
            hide-details
            @input="debounceCode"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            class="mt-0 pt-0 input-custom-56"
            autocomplete="no"
            label="Proyecto"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            height="56"
            clearable
            outlined
            hide-details
            return-object
            :items="listProjects.options"
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <selector-user
            ref="selectorUser"
            v-model="selectedResponsible"
            :filter-ejecutor="true"
            @update="onSearch"
          ></selector-user>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedAuthority"
            autocomplete="no"
            class="mt-0 pt-0 input-custom-56"
            label="Autoridad"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            height="56"
            clearable
            outlined
            hide-details
            return-object
            :items="listAuthority.options"
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedState"
            autocomplete="no"
            class="mt-0 pt-0 input-custom-56"
            label="Situación"
            placeholder="Seleccione opción"
            item-text="label"
            item-value="id"
            height="56"
            clearable
            outlined
            hide-details
            return-object
            :items="listSituations.options"
            @input="debounceChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-btn
                class="ma-0"
                color="action"
                dark
                depressed
                small
                block
                @click="clearFilters()"
                >Limpiar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import { isEmpty, debounce } from "lodash";
import SelectorUser from "@/components/commons/SelectorUser";
import mixinApiModule from "./mixins/mixinApiModule";

export default {
  name: "SurveillanceDrawer",
  components: {
    VuePerfectScrollbar,
    SelectorUser,
  },
  mixins: [mixinApiModule],
  data: () => ({
    searchText: "",
    selectedState: null,
    selectedAuthority: null,
    selectedResponsible: null,
    selectedProject: null,
    inputCode: null,
    disabledBtn: true,
    disabledOptions: true,
    optionChart: {},
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    checkAlert: false,
    debounceChange: null,
    debounceCode: null,
  }),
  computed: {
    ...mapState({
      listAllUsers: (state) => state.users.listAllUsers,
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    typeModule() {
      const { params } = this.$route;
      return params.typeModule || "";
    },
    typeSubmodule() {
      const { params } = this.$route;
      return params.typeSubmodule || "";
    },
    listAuthority() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === `${this.typeSubmodule}-autoridad`;
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  watch: {
    selectedAuthority(val) {
      if (val) {
        this.disabledBtn = false;
        this.disabledOptions = false;
      }
    },
    typeModule(val) {
      if (!this.isEmpty(val)) {
        this.clearForm();
      }
    },
    typeSubmodule(val) {
      if (!this.isEmpty(val)) {
        this.clearForm();
      }
    },
  },
  mounted() {
    this.debounceCode = debounce(this.onSearch, 700);
    this.debounceChange = debounce(this.onSearch, 900);
  },
  methods: {
    onSearch() {
      let payload = "?response=lite&page=1";
      let filterData = "";
      if (!isEmpty(this.inputCode)) {
        filterData += `&id=${this.inputCode}`;
      }
      if (!isEmpty(this.selectedAuthority)) {
        filterData += `&authority_id=${this.selectedAuthority.id}`;
      }
      if (!isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!isEmpty(this.selectedResponsible)) {
        filterData += `&responsible_id=${this.selectedResponsible.id}`;
      }
      if (!isEmpty(this.selectedState)) {
        filterData += `&situation_id=${this.selectedState.id}`;
      }
      if (!isEmpty(this.searchText)) {
        filterData += `&search=${this.searchText}`;
      }
      if (this.sortPermit) {
        filterData += `&sort=${this.sortPermit}`;
      }
      this.loadSearch = true;
      payload += filterData;
      this.$emit("filter", payload, filterData);
    },
    clearForm() {
      this.inputCode = null;
      this.selectedProject = null;
      this.selectedResponsible = null;
      this.selectedState = null;
      this.selectedAuthority = null;
    },
    clearFilters() {
      this.clearForm();
      this.onSearch();
    },
    setLoadSearch(flag) {
      this.loadSearch = flag;
    },
  },
};
</script>
