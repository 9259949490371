import axios from "../../http";

const modules = {
  state: {
    modules: [],
    activeModule: null,
    subModules: [],
    activeSubModule: null,
    loadModules: false,
  },
  mutations: {
    setLoadModules(state, value) {
      state.loadModules = value;
    },
    setModules(state, payload) {
      state.modules = payload;
    },
    setActiveModules(state, payload) {
      state.activeModule = payload;
    },
    setSubModules(state, payload) {
      state.subModules = payload;
    },
    setActiveSubModules(state, payload) {
      state.activeSubModule = payload;
    },
    clearActiveModule(state, type) {
      if (type === "all") {
        state.activeModule = null;
        state.activeSubModule = null;
        state.subModules = [];
      } else if (type === "module") {
        state.activeModule = null;
        state.subModules = [];
      } else {
        state.activeSubModule = null;
      }
    },
  },
  actions: {
    fetchModules({ commit }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/modules`);
        if (data.success) {
          commit("setModules", data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    setActiveModules({ commit }, modules) {
      return new Promise((resolve) => {
        commit("setActiveModules", modules);
        commit("setSubModules", modules.submodules);
        resolve();
      });
    },
    setActiveSubModules({ commit }, modules) {
      return new Promise((resolve) => {
        commit("setActiveSubModules", modules);
        resolve();
      });
    },
    fetchSubModule({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.get(`/api/submodules/${id}`);
        if (data.success) {
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    async createModule({ commit }, payload) {
      const { data } = await axios.post(`/api/modules/create`, payload);
      if (data.success) {
        commit("setToken", data.data);
      }
    },
    async editModule({ commit }, { id, payload }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(`/api/modules/${id}/update`, payload);
        if (data.success) {
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    async editSubmodule({ commit }, { id, payload }) {
      return new Promise(async (resolve, reject) => {
        const { data } = await axios.post(
          `/api/submodules/${id}/update`,
          payload,
        );
        if (data.success) {
          resolve(data.data);
        } else {
          reject();
        }
      });
    },
    async deleteModule({ commit }, values) {
      const { data } = await axios.delete(`/api/modules`, values);
      if (data.success) {
        commit("setToken", data.data);
      }
    },
    clearActiveModule({ commit }, type) {
      commit("clearActiveModule", type);
    },
  },
  getters: {
    loggedIn: (state) => !!state.currentUser,
    isAdmin: (state) => state.currentUser && state.currentUser.role_id == 2,
  },
};

export default modules;
